import client, { AxiosResponse } from 'axios'
import { ProductPackage } from './bean/productPackage'
import { ProductPackagePostBody } from './bean/productPackagePostBody'
import { ProductPackagePatchBody } from './bean/productPackagePatchBody'
import { ProductDisplayPackage } from './bean/productDisplayPackage'

const url = `/api/v1/product-packages`
class ProductPackageService {
  static getPackages(): Promise<AxiosResponse<ProductPackage[]>> {
    return client.get(url)
  }

  static getPackagesByCategoryId(
    categoryId: number
  ): Promise<AxiosResponse<ProductPackage[]>> {
    return client.get(url, {
      params: {
        categoryId,
      },
    })
  }

  static getDisplayPackages(
    packageId: string | number
  ): Promise<AxiosResponse<ProductDisplayPackage>> {
    return client.get(`${url}/${packageId}/display`)
  }

  static postPackage(
    body: ProductPackagePostBody
  ): Promise<AxiosResponse<any>> {
    return client.post(url, { ...body })
  }

  static deletePackage(id: number): Promise<AxiosResponse> {
    return client.delete(`${url}/${id}`)
  }

  static patchPackage(
    id: number,
    patchBody: ProductPackagePatchBody
  ): Promise<AxiosResponse> {
    return client.patch(`${url}/${id}`, patchBody, {})
  }

  static duplicatePackage(
    id: number,
    newPackageName: string
  ): Promise<AxiosResponse> {
    return client.post(`${url}/${id}/duplicate`, {
      newPackageName,
    })
  }
}

export default ProductPackageService
