import React, { useEffect, useState } from 'react'
import { ProductPackage } from './bean/productPackage'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core'

export interface SimpleDialogProps {
  open?: ProductPackage
  onClose: (productPackage?: ProductPackage) => void
}

const ProductPackageDuplicateDialog = (props: SimpleDialogProps) => {
  const { onClose, open } = props
  const [pkg, setPkg] = useState<ProductPackage | undefined>(open)

  const handleClose = (productPackage?: ProductPackage) => {
    onClose(productPackage)
  }

  useEffect(() => {
    setPkg(open)
  }, [open])

  const onChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const name = event.target.name
    const value = event.target.value

    if (name === 'price' && isNaN(Number(value))) {
      return
    }

    const newPkg = {
      ...pkg,
      [name]: value,
    } as ProductPackage

    setPkg(newPkg)
  }
  return (
    <Dialog
      onClose={() => handleClose()}
      aria-labelledby='simple-dialog-title'
      open={!!open}
      maxWidth={'xs'}
      fullWidth
    >
      <DialogTitle id='simple-dialog-title'>
        Edit Package for {open?.name}
      </DialogTitle>
      <DialogContent className='space-y-4'>
        <TextField
          label={'New Name'}
          name='name'
          value={pkg?.name}
          onChange={onChange}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleClose()}
          variant={'outlined'}
          color={'secondary'}
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleClose(pkg)}
          variant={'contained'}
          color={'primary'}
          disabled={!pkg?.name}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ProductPackageDuplicateDialog
