import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OrderSummaryBean } from '../../adminPortal/settings/bean/OrderSummaryBean'
import SystemSettingService from '../../common/service/SystemSettingService'
import { catchErrorWithDispatch } from '../../common/ApiUtils'
import { AppDispatch, AppState } from '../store'

const type = 'orderSummaryMessage'
interface OrderSummaryMessageProp {
  settings: Record<string, OrderSummaryBean>
}

const initialState: OrderSummaryMessageProp = {
  settings: {},
}

const orderSummaryMessageSlice = createSlice({
  name: type,
  initialState: initialState,
  reducers: {
    fetchSuccess(
      state,
      action: PayloadAction<Record<string, OrderSummaryBean>>
    ) {
      state.settings = action.payload
    },

    add(
      state,
      action: PayloadAction<{ key: string; value: OrderSummaryBean }>
    ) {
      const { key, value } = action.payload
      state.settings = {
        ...state.settings,
        [key]: value,
      }
    },
  },
  extraReducers: {},
})

export default orderSummaryMessageSlice

export const getAllOrderSummaryMessage = createAsyncThunk<
  undefined,
  undefined,
  { dispatch: AppDispatch }
>(`${type}/getAllOrderSummaryMessage`, async (arg, { dispatch }) => {
  try {
    const value = await SystemSettingService.getSetting('ORDER_SUMMARY_MESSAGE')
    const result = JSON.parse(value.data.value) as Record<
      string,
      OrderSummaryBean
    >

    dispatch(orderSummaryMessageSlice.actions.fetchSuccess(result))
  } catch (e) {
    catchErrorWithDispatch(dispatch)(e)
  }

  return undefined
})

export const updateOrderSummaryMessage = createAsyncThunk<
  undefined,
  Record<string, OrderSummaryBean>,
  {
    dispatch: AppDispatch
    state: AppState
  }
>(`${type}/updateOrderSummaryMessage`, async (arg, { dispatch, getState }) => {
  const settings = getState().orderSummaryMessage.settings

  const patchBody = {
    ...settings,
    ...arg,
  }

  await SystemSettingService.updateSetting('ORDER_SUMMARY_MESSAGE', patchBody)
  dispatch(getAllOrderSummaryMessage())

  return undefined
})
