import { LoadingAction } from './loadingBackdropReducer'
import { SnackbarAction } from './snackbarReducer'
import { catchErrorWithDispatch } from '../../common/ApiUtils'
import { AppThunk } from '../store'
import { PayloadAction, ReduxReducerAction } from './index'
import ClaimService from '../../features/claim/service/claimService'
import { WithdrawalBean } from '../../features/claim/bean/WithdrawalBean'
import TransactionType from '../../common/constant/transactionType'
import WithdrawalService from '../../features/claim/service/withdrawalService'
import _ from 'lodash'

export interface ClaimReducerProps {
  selectedIds: number[]
  withdrawals: WithdrawalBean[]
}

const ActionType = {
  INIT: `[ClaimReducer] INIT`,
  SUBMIT_CLAIM: `[ClaimReducer] SUBMIT CLAIM`,
  FETCH_CLAIM_BY_AGENT_ID: `[ClaimReducer] FETCH CLAIM BY AGENT ID`,
  FETCH_CLAIM_SUCCESS: `[ClaimReducer] FETCH CLAIM SUCCESS`,
  WITHDRAWAL_CLICK: `[ClaimReducer] WITHDRAWAL CLICK`,
  CHECK_ALL_CLICK: `[ClaimReducer] CHECK ALL CLICK`,
  RESET: `[ClaimReducer] RESET`,
}

const initialState: ClaimReducerProps = {
  selectedIds: [],
  withdrawals: [],
}

const ClaimReducerReducer = (
  state = initialState,
  action: ReduxReducerAction
) => {
  switch (action.type) {
    case ActionType.INIT:
      return initialState

    case ActionType.RESET:
      // Compulsory to submit all all CHARGES and CLAWBACK
      //  so it wont be reset
      const compulsoryIds = getChargesAndClawback(state.withdrawals).map(
        withdrawal => withdrawal.id
      )

      return {
        ...state,
        selectedIds: _.uniq(compulsoryIds),
      }
    case ActionType.WITHDRAWAL_CLICK:
      const handleClick = (): number[] =>
        _.includes(state.selectedIds, action.payload)
          ? _.without(state.selectedIds, action.payload)
          : _.concat(state.selectedIds, action.payload)

      return {
        ...state,
        selectedIds: handleClick(),
      }

    case ActionType.CHECK_ALL_CLICK:
      const handleCheckAll = (): number[] => {
        const withdrawalIds: number[] = action.payload.withdrawalIds

        if (action.payload.checked) {
          return _.concat(state.selectedIds, withdrawalIds)
        } else {
          return _.without(state.selectedIds, ...withdrawalIds)
        }
      }

      return {
        ...state,
        selectedIds: _.uniq(handleCheckAll()),
      }

    case ActionType.FETCH_CLAIM_SUCCESS:
      return {
        ...state,
        withdrawals: action.payload,
      }
    default:
      return state
  }
}

export const ClaimReducerActions = {
  init: () => ({ type: ActionType.INIT }),

  submitClaim(withdrawalIds: number[], agentId: number): AppThunk {
    return async dispatch => {
      dispatch({ type: ActionType.SUBMIT_CLAIM })
      dispatch(LoadingAction.open('Submitting Claim...'))
      ClaimService.createNewClaim(withdrawalIds, agentId)
        .then(() => {
          dispatch(SnackbarAction.open('Success create claim.'))

          dispatch(ClaimReducerActions.fetchWithdrawalsById(agentId))
        })
        .catch(catchErrorWithDispatch(dispatch))
        .finally(() => dispatch(LoadingAction.close()))
    }
  },

  reset: () => ({ type: ActionType.RESET }),
  withdrawalClick: (withdrawalId: number): PayloadAction<number> => ({
    type: ActionType.WITHDRAWAL_CLICK,
    payload: withdrawalId,
  }),
  checkAllClick: (
    ids: number[],
    checked: boolean
  ): PayloadAction<{ withdrawalIds: number[]; checked: boolean }> => {
    return {
      type: ActionType.CHECK_ALL_CLICK,
      payload: {
        withdrawalIds: ids,
        checked: checked,
      },
    }
  },

  fetchWithdrawalsById: (agentId: number): AppThunk => {
    return async dispatch => {
      dispatch(ClaimReducerActions.init())

      WithdrawalService.findAllUnclaimedByAgentId(agentId)
        .then(response => {
          const withdrawalBeans = response.data.filter(
            withdrawal => !withdrawal.claimId
          )

          dispatch(ClaimReducerActions.fetchClaimSuccess(withdrawalBeans))

          // Set Charges and Clawback to CHECKED
          dispatch(
            ClaimReducerActions.checkAllClick(
              getChargesAndClawback(withdrawalBeans).map(v => v.id),
              true
            )
          )
        })
        .catch(catchErrorWithDispatch(dispatch))
    }
  },

  fetchClaimSuccess: (
    withdrawals: WithdrawalBean[]
  ): PayloadAction<WithdrawalBean[]> => {
    return {
      type: ActionType.FETCH_CLAIM_SUCCESS,
      payload: withdrawals,
    }
  },
}
export default ClaimReducerReducer

function getChargesAndClawback(
  withdrawals: WithdrawalBean[]
): WithdrawalBean[] {
  return withdrawals.filter(withdrawal =>
    [TransactionType.CLAWBACK, TransactionType.CHARGES].includes(
      withdrawal.transactionType
    )
  )
}
