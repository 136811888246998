import { ReduxReducerAction } from './index'
import { UserCodeBean } from '../../common/service/UserCodeBean'
import SystemSettingService from '../../common/service/SystemSettingService'
import { AppThunk } from '../store'
import { LoadingAction } from './loadingBackdropReducer'
import { SnackbarAction } from './snackbarReducer'
import { catchErrorWithDispatch } from '../../common/ApiUtils'
import {
  defaultOrderSummaryBean,
  OrderSummaryBean,
} from '../../adminPortal/settings/bean/OrderSummaryBean'
import { OrderSummaryContent } from '../../adminPortal/settings/bean/OrderSummaryContent'
import UserRole from '../../common/constant/userRole'

export interface PortalSettingReducer {
  userCode: UserCodeBean
  paginator: {
    rowPerPageOptions: number[]
    defaultRowPerPage: number
  }
  order: {
    packageShowDescription: boolean
    showCommissionRate: boolean
    roleAbleToViewCommissionRate: string[]
  }
  notification: {
    orderSummary: OrderSummaryBean
  }
}

const initialState: PortalSettingReducer = {
  userCode: {
    manager: '',
    admin: '',
    staff: '',
    agent: '',
  },
  paginator: {
    rowPerPageOptions: [10, 20, 50, 100, 150, 300],
    defaultRowPerPage: 100,
  },
  order: {
    packageShowDescription: true,
    showCommissionRate: false,
    roleAbleToViewCommissionRate: [
      UserRole.ROLE_MANAGER,
      UserRole.ROLE_ADMIN,
      UserRole.ROLE_STAFF,
      UserRole.ROLE_AGENT,
    ],
  },
  notification: {
    orderSummary: defaultOrderSummaryBean,
  },
}

const actionType = {
  SUCCESS_GET_ORDER_SUMMARY_SETTING: `[PortalSettingReducer] SUCCESS GET ORDER SUMMARY SETTING`,

  SUCCESS_GET_SHOW_DESCRIPTION: `[PortalSettingReducer] SUCCESS GET SHOW DESCRIPTION`,
  SUCCESS_GET_SHOW_COMMISSION_RATE: `[PortalSettingReducer] SUCCESS GET SHOW COMMISSION RATE`,
  SUCCESS_GET_USER_CODE: `[PortalSettingReducer] SUCCESS GET USER CODE`,
  SUCCESS_GET_ROLE_ABLE_TO_VIEW_COMMISSION_RATE: `[PortalSettingReducer] SUCCESS GET ROLE ABLE TO VIEW COMMISSION RATE`,
}

const portalSettingReducer = (
  state = initialState,
  action: ReduxReducerAction
) => {
  switch (action.type) {
    case actionType.SUCCESS_GET_USER_CODE:
      return {
        ...state,
        userCode: action.payload,
      }
    case actionType.SUCCESS_GET_SHOW_DESCRIPTION:
      return {
        ...state,
        order: {
          ...state.order,
          packageShowDescription: action.payload,
        },
      }
    case actionType.SUCCESS_GET_SHOW_COMMISSION_RATE:
      return {
        ...state,
        order: {
          ...state.order,
          showCommissionRate: action.payload,
        },
      }
    case actionType.SUCCESS_GET_ROLE_ABLE_TO_VIEW_COMMISSION_RATE:
      return {
        ...state,
        order: {
          ...state.order,
          roleAbleToViewCommissionRate: action.payload,
        },
      }

    case actionType.SUCCESS_GET_ORDER_SUMMARY_SETTING:
      return {
        ...state,
        notification: {
          orderSummary: action.payload,
        },
      }
    default:
      return state
  }
}
export const PortalSettingActions = {
  getUserCode: (): AppThunk => {
    return async dispatch => {
      SystemSettingService.getUserCode().then(value => {
        dispatch(PortalSettingActions.successGetUserCode(value.data))
      })
    }
  },
  successGetUserCode: (userCode: UserCodeBean) => {
    return {
      type: actionType.SUCCESS_GET_USER_CODE,
      payload: userCode,
    }
  },
  updateUserCode: (body: {
    manager?: string
    admin?: string
    staff?: string
    agent?: string
  }): AppThunk => {
    return async dispatch => {
      dispatch(LoadingAction.open('Updating User Code...'))
      SystemSettingService.updateUserCode(body)
        .then(() => {
          dispatch(SnackbarAction.open('Success Update User Code'))
          dispatch(PortalSettingActions.getUserCode())
        })
        .catch(catchErrorWithDispatch(dispatch))
        .finally(() => dispatch(LoadingAction.close()))
    }
  },

  getPackageShowDescription: (): AppThunk => {
    return async dispatch => {
      SystemSettingService.getPackageShowDescription()
        .then(value => {
          dispatch(
            PortalSettingActions.successGetPackageShowDescription(
              value.data.showDescription
            )
          )
        })
        .catch(catchErrorWithDispatch(dispatch))
    }
  },
  successGetPackageShowDescription(showDescription: boolean) {
    return {
      type: actionType.SUCCESS_GET_SHOW_DESCRIPTION,
      payload: showDescription,
    }
  },

  updatePackageShowDescription: (showDescription: boolean): AppThunk => {
    return async dispatch => {
      dispatch(LoadingAction.open('Updating Package Show Description...'))
      SystemSettingService.updatePackageShowDescription(showDescription)
        .then(() => {
          dispatch(
            SnackbarAction.open('Success Update Package Show Description')
          )
          dispatch(PortalSettingActions.getPackageShowDescription())
        })
        .catch(catchErrorWithDispatch(dispatch))
        .finally(() => dispatch(LoadingAction.close()))
    }
  },

  getOrderSummarySetting: (): AppThunk => {
    return async dispatch => {
      SystemSettingService.getSetting('ORDER_SUMMARY_SETTING').then(value => {
        const parse = JSON.parse(value.data.value) as OrderSummaryBean

        dispatch(PortalSettingActions.successGetOrderSummarySetting(parse))
      })
    }
  },
  successGetOrderSummarySetting: (orderSummarySetting: OrderSummaryBean) => {
    return {
      type: actionType.SUCCESS_GET_ORDER_SUMMARY_SETTING,
      payload: orderSummarySetting,
    }
  },

  updateOrderSummarySetting: (
    title: string,
    footer: string,
    content: OrderSummaryContent
  ): AppThunk => {
    return async dispatch => {
      const patchBody: OrderSummaryBean = {
        title: title,
        footer: footer,
        content: {
          product: content['product'],
          productCategory: content['productCategory'],
          productPackage: content['productPackage'],
          price: content['price'],
          companyName: content['companyName'],
          companyRegNo: content['companyRegNo'],
          address: content['address'],
          fullName: content['fullName'],
          nric: content['nric'],
          email: content['email'],
          mobileNo: content['mobileNo'],
          telNo: content['telNo'],
          alternativeNo: content['alternativeNo'],
          orderStatus: content['orderStatus'],
          orderNo: content['orderNo'],
          loginId: content['loginId'],
          eform: content['eform'],
          activatedOn: content['activatedOn'],
          invoiceNo: content['invoiceNo'],
          remarks: content['remarks'],
          adminRemarks: content['adminRemarks'],
          m2uRemarks: content['m2uRemarks'],
        },
      }

      dispatch(LoadingAction.open('Updating Order Summary Setting...'))
      SystemSettingService.updateSetting('ORDER_SUMMARY_SETTING', patchBody)
        .then(() => {
          dispatch(SnackbarAction.open('Success Update Order Summary Setting'))
          dispatch(PortalSettingActions.getOrderSummarySetting())
        })
        .catch(catchErrorWithDispatch(dispatch))
        .finally(() => dispatch(LoadingAction.close()))
    }
  },

  getOrderShowCommissionRate: (): AppThunk => {
    return async dispatch => {
      SystemSettingService.getSetting('ORDER_SHOW_COMMISSION_RATE').then(
        value => {
          console.log(value)
          const resp = JSON.parse(value.data.value) as boolean

          dispatch(PortalSettingActions.successGetOrderShowCommissionRate(resp))
        }
      )
    }
  },
  successGetOrderShowCommissionRate: (isShow: boolean) => {
    return {
      type: actionType.SUCCESS_GET_SHOW_COMMISSION_RATE,
      payload: isShow,
    }
  },

  updateOrderShowCommissionRate: (isShow: boolean): AppThunk => {
    return async dispatch => {
      dispatch(LoadingAction.open('Updating Order Show Commission Rate...'))
      SystemSettingService.updateSetting('ORDER_SHOW_COMMISSION_RATE', isShow)
        .then(() => {
          dispatch(
            SnackbarAction.open('Success Update Order Show Commission Rate')
          )
          dispatch(PortalSettingActions.getOrderShowCommissionRate())
        })
        .catch(catchErrorWithDispatch(dispatch))
        .finally(() => dispatch(LoadingAction.close()))
    }
  },

  getRoleAbleToViewCommissionRate: (): AppThunk => {
    return async dispatch => {
      SystemSettingService.getSetting('ROLE_ABLE_TO_VIEW_COMMISSION_RATE').then(
        value => {
          console.log(value)
          const resp = JSON.parse(value.data.value) as string[]

          dispatch(
            PortalSettingActions.successRoleAbleToViewCommissionRate(resp)
          )
        }
      )
    }
  },
  successRoleAbleToViewCommissionRate: (roles: string[]) => {
    return {
      type: actionType.SUCCESS_GET_ROLE_ABLE_TO_VIEW_COMMISSION_RATE,
      payload: roles,
    }
  },

  updateRoleAbleToViewCommissionRate: (roles: string[]): AppThunk => {
    return async dispatch => {
      dispatch(LoadingAction.open('Updating Settings...'))
      SystemSettingService.updateSetting(
        'ROLE_ABLE_TO_VIEW_COMMISSION_RATE',
        roles
      )
        .then(() => {
          dispatch(SnackbarAction.open('Success Update Settings'))
          dispatch(PortalSettingActions.getRoleAbleToViewCommissionRate())
        })
        .catch(catchErrorWithDispatch(dispatch))
        .finally(() => dispatch(LoadingAction.close()))
    }
  },
}

export default portalSettingReducer
