import React from 'react'
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import { useTypedSelector } from './redux/reducer'
import {
  Avatar,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

interface Props {
  navList: React.ReactNode
  switch: React.ReactNode
}

const AppNavContainer = (props: Props) => {
  const routeMatch = useRouteMatch()
  const history = useHistory()

  const user = useTypedSelector(state => state.userProfile)
  const [open, setOpen] = React.useState(true)
  return (
    <div className='flex flex-row h-screen bg-gray-50'>
      {open ? (
        <nav
          className='flex-none shadow overflow-y-auto'
          style={{ width: '250px' }}
        >
          <List>
            <NavLink
              to={routeMatch.path + '/profile-detail'}
              activeClassName='text-indigo-600 italic'
            >
              <ListItem button>
                <Avatar className='not-italic'>
                  {user.fullName
                    .split(' ')
                    .map(value => value.charAt(0))
                    .join('')}
                </Avatar>
                <div className='pl-4'>
                  <div className='font-bold'>{user.fullName}</div>
                  <div className='italic text-gray-500'>
                    {user.role.replace('ROLE_', '')}
                  </div>
                </div>
              </ListItem>
            </NavLink>
            <Divider />

            {props.navList}

            <Divider />
            <ListItem button onClick={() => history.push('/')}>
              <ListItemText inset primary='Logout' />
            </ListItem>

            <Divider />

            <Button onClick={() => setOpen(false)} fullWidth>
              <ChevronLeftIcon />
            </Button>
          </List>
        </nav>
      ) : (
        <>
          <Button onClick={() => setOpen(true)} size={'small'}>
            <ChevronRightIcon />
          </Button>
        </>
      )}

      <main className='flex-1 h-screen overflow-y-auto'>{props.switch}</main>
    </div>
  )
}

export default AppNavContainer
