import { StateBean } from '../../common/service/StateBean'
import { AppThunk } from '../store'
import CommonApiService from '../../common/service/CommonApiService'
import { AxiosError } from 'axios'
import { ErrorResponse } from '../../errorResponse'
import { SnackbarAction } from './snackbarReducer'
import { BankBean } from '../../common/service/BankBean'
import { LandingPageAttachmentsBean } from '../../adminPortal/landingPageAttachments/bean/landingPageAttachmentsBean'
import landingPageAttachmentsService from '../../adminPortal/landingPageAttachments/service/landingPageAttachmentsService'
import { LoadingAction } from './loadingBackdropReducer'
import { catchErrorWithDispatch } from '../../common/ApiUtils'

export interface CommonReducer {
  states: StateBean[]
  isStateLoading: boolean
  banks: BankBean[]
  isBankLoading: boolean
  landingPageAttachments: LandingPageAttachmentsBean[]
  isLandingPageAttachmentsLoading: boolean
}

const initialState: CommonReducer = {
  states: [],
  isStateLoading: false,
  banks: [],
  isBankLoading: false,
  landingPageAttachments: [],
  isLandingPageAttachmentsLoading: false,
}

const commonReducer = (
  state = initialState,
  action: {
    type: string
    payload: any
  }
) => {
  switch (action.type) {
    case CommonType.GET_STATES_SUCCESS:
      return {
        ...state,
        states: action.payload,
        isStateLoading: false,
      }

    case CommonType.GET_STATES:
      return {
        ...state,
        isStateLoading: true,
      }

    case CommonType.GET_BANK_SUCCESS:
      return {
        ...state,
        banks: action.payload,
        isBankLoading: false,
      }

    case CommonType.GET_BANK:
      return {
        ...state,
        isBankLoading: true,
      }

    case CommonType.GET_LANDING_PAGE_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        landingPageAttachments: action.payload,
        isLandingPageAttachmentsLoading: false,
      }

    case CommonType.GET_LANDING_PAGE_ATTACHMENTS:
      return {
        ...state,
        isLandingPageAttachmentsLoading: true,
      }
    default:
      return state
  }
}

export const CommonType = {
  GET_STATES: '[COMMON] GET STATES',
  GET_STATES_SUCCESS: '[COMMON] GET STATES SUCCESS',
  GET_BANK: '[COMMON] GET BANK',
  GET_BANK_SUCCESS: '[COMMON] GET BANK SUCCESS',
  GET_LANDING_PAGE_ATTACHMENTS: '[COMMON] GET LANDING PAGE ATTACHMENTS',
  GET_LANDING_PAGE_ATTACHMENTS_SUCCESS:
    '[COMMON] GET LANDING PAGE ATTACHMENTS SUCCESS',
  CREATE_LANDING_PAGE_ATTACHMENTS: '[COMMON] CREATE LANDING PAGE ATTACHMENTS',
}

export const CommonActions = {
  getStates(): AppThunk {
    return async dispatch => {
      dispatch({ type: CommonType.GET_STATES })

      CommonApiService.getStates()
        .then(value => {
          dispatch(CommonActions.getStatesSuccess(value.data))
        })
        .catch(catchErrorWithDispatch(dispatch))
    }
  },
  getStatesSuccess(states: StateBean[]) {
    return {
      type: CommonType.GET_STATES_SUCCESS,
      payload: states,
    }
  },

  getBanks(): AppThunk {
    return async dispatch => {
      dispatch({ type: CommonType.GET_BANK })

      CommonApiService.getBanks()
        .then(value => {
          dispatch(CommonActions.getBanksSuccess(value.data))
        })
        .catch(catchErrorWithDispatch(dispatch))
    }
  },
  getBanksSuccess(states: BankBean[]) {
    return {
      type: CommonType.GET_BANK_SUCCESS,
      payload: states,
    }
  },

  getLandingPageAttachments(): AppThunk {
    return async dispatch => {
      dispatch({ type: CommonType.GET_LANDING_PAGE_ATTACHMENTS })

      landingPageAttachmentsService
        .findAll()
        .then(value => {
          dispatch(CommonActions.getLandingPageAttachmentsSuccess(value.data))
        })
        .catch(catchErrorWithDispatch(dispatch))
    }
  },
  getLandingPageAttachmentsSuccess(payloads: LandingPageAttachmentsBean[]) {
    return {
      type: CommonType.GET_LANDING_PAGE_ATTACHMENTS_SUCCESS,
      payload: payloads,
    }
  },

  createLandingPageAttachments(name: string, file: File): AppThunk {
    return async dispatch => {
      dispatch(LoadingAction.open('Uploading Attachment...'))
      landingPageAttachmentsService
        .create(name)
        .then(value => {
          const id = value.data.id

          dispatch(this.patchLandingPageAttachments(id, file))
        })
        .catch((reason: AxiosError<ErrorResponse>) => {
          dispatch(LoadingAction.close())

          const errorResponse = reason.response?.data.message || reason.message
          dispatch(SnackbarAction.openError(errorResponse))
        })
    }
  },

  patchLandingPageAttachments(id: number, file: File): AppThunk {
    return async dispatch => {
      landingPageAttachmentsService
        .addAttachment(id, file)
        .then(value => {
          dispatch(this.getLandingPageAttachments())
          dispatch(LoadingAction.close())
        })
        .catch(catchErrorWithDispatch(dispatch))
    }
  },
}

export default commonReducer
