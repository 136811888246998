import React, { useEffect, useState } from 'react'
import AuditService from '../../../adminPortal/audit/auditService'
import { Pageable } from '../../../common/bean/Pageable'
import { AuditBean } from '../../../adminPortal/audit/bean/AuditBean'
import AuditDetailDialog from '../../../adminPortal/audit/auditDetailDialog'
import AuditListComponent from '../../../adminPortal/audit/auditListComponent'
import { TablePagination } from '@material-ui/core'
import { useTypedSelector } from '../../../redux/reducer'

const OrderAuditComponent = ({ orderId }: { orderId: number }) => {
  const [selectedAudit, setSelectedAudit] = useState<AuditBean | undefined>()

  const [pageableAudit, setPageableAudit] = useState<
    Pageable<AuditBean> | undefined
  >()

  const paginatorSetting: {
    rowPerPageOptions: number[]
    defaultRowPerPage: number
  } = useTypedSelector(state => state.portalSetting.paginator)

  const [pageable, setPageable] = useState({
    page: 0,
    size: paginatorSetting.defaultRowPerPage || 20,
  })

  useEffect(() => {
    AuditService.getOrderAuditByOrderId(
      orderId,
      pageable.page,
      pageable.size
    ).then(value => {
      setPageableAudit(value.data)
    })
  }, [orderId, pageable.page, pageable.size])

  function handleChangePage(event: unknown, newPage: number) {
    setPageable({
      ...pageable,
      page: newPage,
    })
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setPageable({
      page: 0,
      size: Number(event.target.value),
    })
  }

  function renderPaginator() {
    return (
      <TablePagination
        rowsPerPageOptions={paginatorSetting.rowPerPageOptions}
        component='div'
        count={pageableAudit?.totalElements || 0}
        rowsPerPage={pageableAudit?.size || 0}
        page={pageableAudit?.number || 0}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    )
  }

  return (
    <div>
      <div className='m-8'>{renderPaginator()}</div>

      <AuditListComponent
        audits={pageableAudit?.content || []}
        setSelectedAudit={setSelectedAudit}
      />

      <div className='m-8'>{renderPaginator()}</div>

      <AuditDetailDialog
        selectedAudit={selectedAudit}
        setSelectedAudit={setSelectedAudit}
      />
    </div>
  )
}

export default OrderAuditComponent
