import UserBean from './bean/userBean'
import UserRole from '../../common/constant/userRole'

export interface ProfileDisplayCategory {
  title: string
  disable?: boolean
  items: ProfileDisplayItem[]
}

export interface ProfileDisplayItem {
  label: string
  name: string
  value?: string
  className?: string
  disabled?: boolean
}

export class ProfileDetailUtil {
  static getProfile(userBean: UserBean): ProfileDisplayCategory {
    return {
      title: 'Profile',
      items: [
        {
          label: 'Username',
          name: 'username',
          value: userBean?.username,
          disabled: true,
        },
        {
          label: 'Full Name',
          name: 'fullName',
          value: userBean?.fullName,
          disabled: false,
        },
        {
          label: 'NRIC',
          name: 'nric',
          value: userBean?.nric,
          disabled: false,
        },
        {
          label: 'Email',
          name: 'email',
          value: userBean?.email,
          disabled: false,
        },
        {
          label: 'Mobile No',
          name: 'mobileNo',
          value: userBean?.mobileNo,
          disabled: false,
        },
        {
          label: 'Tel No',
          name: 'telNo',
          value: userBean?.telNo,
          disabled: false,
        },
      ],
    }
  }
  static getAgentDisplayItem(userBean: UserBean): ProfileDisplayCategory[] {
    return [
      {
        title: 'Profile',
        items: [
          {
            label: 'Username',
            name: 'username',
            value: userBean?.username,
            disabled: true,
          },
          {
            label: 'Full Name',
            name: 'fullName',
            value: userBean?.fullName,
            disabled: false,
          },
          {
            label: 'NRIC',
            name: 'nric',
            value: userBean?.nric,
            disabled: false,
          },
          {
            label: 'Email',
            name: 'email',
            value: userBean?.email,
            disabled: false,
          },
          {
            label: 'Mobile No',
            name: 'mobileNo',
            value: userBean?.mobileNo,
            disabled: false,
          },
          {
            label: 'Tel No',
            name: 'telNo',
            value: userBean?.telNo,
            disabled: false,
          },
        ],
      },
      {
        title: 'Bank Information',
        items: [
          {
            label: 'Bank Name',
            name: 'bankName',
            value: userBean?.bankName,
            disabled: false,
          },
          {
            label: 'Bank Acc No',
            name: 'bankAccNo',
            value: userBean?.bankAccNo,
            disabled: false,
          },
          {
            label: 'Bank Beneficial Name',
            name: 'bankBeneficialName',
            value: userBean?.bankBeneficialName,
            className: 'col-span-2',
            disabled: false,
          },
        ],
      },
      {
        title: 'Address',
        items: [
          {
            label: 'Address',
            name: 'address',
            value: userBean?.address,
            className: 'col-span-2',
            disabled: false,
          },
          {
            label: 'City',
            name: 'city',
            value: userBean?.city,
            disabled: false,
          },
          {
            label: 'Country',
            name: 'country',
            value: userBean?.country,
            disabled: false,
          },
          {
            label: 'Postcode',
            name: 'postcode',
            value: userBean?.postcode,
            disabled: false,
          },
          {
            label: 'State',
            name: 'state',
            value: userBean?.state,
            disabled: false,
          },
        ],
      },
      {
        title: 'Company Detail',
        disable: !userBean?.companyName && !userBean?.companyRegNo,
        items: [
          {
            label: 'Company Name',
            name: 'companyName',
            value: userBean?.companyName,
            disabled: false,
          },
          {
            label: 'Company Reg No',
            name: 'companyRegNo',
            value: userBean?.companyRegNo,
            disabled: false,
          },
        ],
      },
    ]
  }

  static getAdminDisplayItem(userBean: UserBean): ProfileDisplayCategory[] {
    return [
      {
        title: 'Profile',
        items: [
          {
            label: 'Username',
            name: 'username',
            value: userBean?.username,
            disabled: true,
          },
          {
            label: 'Full Name',
            name: 'fullName',
            value: userBean?.fullName,
            disabled: false,
          },

          {
            label: 'Email',
            name: 'email',
            value: userBean?.email,
            disabled: false,
          },

          {
            label: 'Role',
            name: 'role',
            value: UserRole.toDisplay[userBean?.role || ''],
            disabled: true,
          },
        ],
      },
    ]
  }
}
