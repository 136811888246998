import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AppDispatch, AppState } from '../store'
import SystemSettingService from '../../common/service/SystemSettingService'
import { LoadingAction } from '../reducer/loadingBackdropReducer'

const type = 'claimSettings'

const actionsName = {
  claimSettings: 'CLAIM_SETTINGS',
}

interface ClaimSettingProp {
  sentEmailToAgentWhenStatus: string[]
  requiredInvoiceNoToCompleteClaim: boolean
}

const initialState: ClaimSettingProp = {
  sentEmailToAgentWhenStatus: [],
  requiredInvoiceNoToCompleteClaim: true,
}

export const claimSettingsSlice = createSlice({
  name: type,
  initialState: initialState,
  reducers: {
    successGetClaimSettings: (state, actions) => {
      const { payload } = actions
      const {
        sentEmailToAgentWhenStatus = [],
        requiredInvoiceNoToCompleteClaim = true,
      } = payload

      return {
        sentEmailToAgentWhenStatus,
        requiredInvoiceNoToCompleteClaim,
      }
    },
    sentEmailToAgentWhenStatusChange: (state, { payload }) => {
      const includes = state.sentEmailToAgentWhenStatus.includes(payload)

      if (includes) {
        state.sentEmailToAgentWhenStatus = state.sentEmailToAgentWhenStatus.filter(
          o => o !== payload
        )
      } else {
        state.sentEmailToAgentWhenStatus = [
          ...state.sentEmailToAgentWhenStatus,
          payload,
        ]
      }
    },
    requiredInvoiceNoToCompleteClaimToggle: state => {
      state.requiredInvoiceNoToCompleteClaim = !state.requiredInvoiceNoToCompleteClaim
    },
  },
})

export const getClaimSettings = createAsyncThunk<
  undefined,
  undefined,
  { dispatch: AppDispatch; state: AppState }
>(`${type}/getClaimSettings`, async (arg, { dispatch, getState }) => {
  const state = getState().claimSettings
  console.log('getClaimSettings', state)
  try {
    const value = await SystemSettingService.getSetting(
      actionsName.claimSettings
    )
    const result = JSON.parse(value.data.value)
    dispatch(claimSettingsSlice.actions.successGetClaimSettings(result))
  } catch (e) {
    dispatch(claimSettingsSlice.actions.successGetClaimSettings(initialState))

    // catchErrorWithDispatch(dispatch)(e)
  }

  return undefined
})

export const updateClaimSettings = createAsyncThunk<
  undefined,
  undefined,
  { dispatch: AppDispatch; state: AppState }
>(`${type}/updateClaimSettings`, async (arg, { dispatch, getState }) => {
  const state = getState().claimSettings

  dispatch(LoadingAction.open('Updating Claim Setting...'))
  await SystemSettingService.updateSetting(
    actionsName.claimSettings,
    state
  ).finally(() => {
    dispatch(LoadingAction.close())
  })

  dispatch(getClaimSettings())

  return undefined
})
