import React from 'react'
import { SimpleStatus } from '../bean/simpleStatus'
import CurrencyUtils from '../currencyUtils'

interface SimpleStatusComponentProps {
  status: SimpleStatus
}

const SimpleStatusComponent = ({ status }: SimpleStatusComponentProps) => {
  const { color, desc, icon, amount } = status

  return (
    <div className='flex items-center mx-2 w-60'>
      <div className={`m-2 p-2 bg-${color}-400 rounded-full`}>{icon}</div>
      <div>
        <div className='text-gray-700 text-2xl'>
          RM {CurrencyUtils.format(amount)}
        </div>
        <div>
          <span className='text-gray-500 text-sm'>{desc}</span>
        </div>
      </div>
    </div>
  )
}

export default SimpleStatusComponent
