import React, { useEffect, useState } from 'react'
import { AppBar, Button, Toolbar, Tooltip, Typography } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { useClaimQs } from './hook/useClaimQs'
import { Pageable } from '../../common/bean/Pageable'
import { ClaimBean } from './bean/claimBean'
import ClaimService from './service/claimService'
import { catchErrorWithDispatch } from '../../common/ApiUtils'
import UserBean from '../../sharePortal/userManagement/bean/userBean'
import ClaimFilterComponent from './component/claimFilterComponent'
import PageableClaimTableComponent from './component/pageableClaimTableComponent'
import AgentService from '../../adminPortal/userManagement/service/agentService'
import GetAppIcon from '@material-ui/icons/GetApp'
import FileSaver from 'file-saver'
import userProfileService from '../../common/service/userProfileService'

const ClaimPageV2 = () => {
  const dispatch = useDispatch()

  const [claimFilterProps] = useClaimQs()
  const [claims, setClaims] = useState<Pageable<ClaimBean> | undefined>()
  const [displayAgent, setDisplayAgent] = useState<UserBean[]>([])

  useEffect(() => {
    AgentService.getAllAgents({ excludeDisable: true, excludeSelf: false })
      .then(value => value.data)
      .then(value => value.sort((a, b) => a.id - b.id))
      .then(value => setDisplayAgent(value))
  }, [])

  useEffect(() => {
    ClaimService.getAll({
      isPage: true,
      claimStatus: claimFilterProps.claimStatusFilter,
      completedDateFrom: claimFilterProps.completeDateFrom,
      completedDateTo: claimFilterProps.completeDateTo,
      agentId: claimFilterProps.agentIdFilter,
      createdDateFrom: claimFilterProps.submitDateFrom,
      createdDateTo: claimFilterProps.submitDateTo,
      sort: 'id,desc',
      size: claimFilterProps.size,
      page: claimFilterProps.page,
    })
      .then(value => setClaims(value.data))
      .catch(catchErrorWithDispatch(dispatch))
  }, [
    claimFilterProps.agentIdFilter,
    claimFilterProps.claimStatusFilter,
    claimFilterProps.completeDateFrom,
    claimFilterProps.completeDateTo,
    claimFilterProps.page,
    claimFilterProps.size,
    claimFilterProps.submitDateFrom,
    claimFilterProps.submitDateTo,
    dispatch,
  ])

  const exportClaimList = () => {
    ClaimService.exportCsv()
      .then(value => {
        const contentDisposition = value.headers[
          'content-disposition'
        ].toString()
        contentDisposition.replace('attachment;', '')
        const fileName = contentDisposition.split('=')[1]

        FileSaver.saveAs(value.data, fileName)
      })

      .catch(catchErrorWithDispatch(dispatch))
  }
  return (
    <div>
      <AppBar position='relative'>
        <Toolbar className='space-x-3'>
          <Typography variant='h6' noWrap>
            View Claims
          </Typography>
          {userProfileService.isAgent() && (
            <Tooltip title={'Download Claim List'}>
              <Button
                variant={'outlined'}
                color='inherit'
                onClick={exportClaimList}
                startIcon={<GetAppIcon />}
              >
                Own Claim List
              </Button>
            </Tooltip>
          )}
        </Toolbar>
      </AppBar>

      <div className='m-4'>
        <ClaimFilterComponent agents={displayAgent} filter={claimFilterProps} />
      </div>

      <div className='m-4'>
        <PageableClaimTableComponent claims={claims} />
      </div>
    </div>
  )
}

export default ClaimPageV2
