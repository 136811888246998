import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { useHistory, useLocation } from 'react-router-dom'
import { AuthenticationService } from './service/authenticationService'
import * as faker from 'faker'
import { useDispatch } from 'react-redux'
import { LoadingType } from '../redux/reducer/loadingBackdropReducer'
import CommonUtils from '../common/commonUtils'
import { AlertDialogType } from '../redux/reducer/alertDialogReducer'
import { StateBean } from '../common/service/StateBean'
import { BankBean } from '../common/service/BankBean'
import CommonApiService from '../common/service/CommonApiService'
import qs from 'qs'
import { AgentRegisterPostBody } from './bean/agentRegisterPostBody'
import { RegexUtils } from '../common/regexUtils'
import { catchErrorWithDispatch } from '../common/ApiUtils'
import { SnackbarAction } from '../redux/reducer/snackbarReducer'
import { OrderUploadFileKey } from '../common/orderUtils'
import { UserProfileAttachemntService } from './service/userProfileAttachemntService'
import UserProfileAttachmentTable from '../common/component/userProfileAttachmentTable'

const SignUpPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const [inviteUsername, setInviteUsername] = useState<string>()
  const [failDecodeInviteUsername, setFailDecodeInviteUsername] = useState(
    false
  )
  const [statess, setStatess] = useState<StateBean[]>([])
  const [banks, setBanks] = useState<BankBean[]>([])
  const [files, setFiles] = useState<Record<string, File[]>>({})
  const [open, setOpen] = React.useState(false)
  const [checked, setChecked] = useState(false)
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: '',
  })

  const onCheckboxClick = () => {
    setChecked(pre => !pre)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose2 = () => {
    setOpen(false)
  }

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackBarOpen({ message: '', open: false })
  }

  const initialState = {
    username: '',
    password: '',
    fullName: '',
    nric: '',
    email: '',
    mobileNo: '',
    bankName: '',
    bankAccNo: '',
    bankBeneficialName: '',
    address: '',
    city: '',
    state: '',
    postcode: '',
    country: '',
    companyName: '',
    companyRegNo: '',
    telNo: '',
    superiorUsername: '',
  }
  const [formState, setFormState] = useState<AgentRegisterPostBody>(
    initialState
  )
  const [errorState, setErrorState] = useState<AgentRegisterPostBody>(
    initialState
  )

  useEffect(() => {
    if (CommonUtils.isDevMode()) {
      setFormState({
        username: faker.internet.userName(),
        password: 'password',
        fullName: faker.name.findName(),
        nric: faker.random.number().toString(),
        email: 'kevin999990@yahoo.com',
        mobileNo: faker.phone.phoneNumber('01#-#######'),
        bankName: 'Maybank',
        bankAccNo: faker.finance.account(),
        bankBeneficialName: faker.name.findName(),
        address: faker.address.streetAddress(),
        city: faker.address.city(),
        state: 'Johor',
        postcode: faker.phone.phoneNumber('#####'),
        country: faker.address.country(),
        companyName: '',
        companyRegNo: '',
        telNo: '',
        superiorUsername: '',
      })
    }
  }, [])

  useEffect(() => {
    const { token } = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (token) {
      try {
        const inviteUsername = atob(token?.toString() || '')
        if (inviteUsername) {
          setInviteUsername(inviteUsername)
        }
      } catch (e) {
        setFailDecodeInviteUsername(true)
      }
    }
  }, [failDecodeInviteUsername, history, location])

  useEffect(() => {
    CommonApiService.getBanks().then(value => setBanks(value.data))

    CommonApiService.getStates().then(value => setStatess(value.data))
  }, [])

  const onSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (Object.values(errorState).filter(v => !!v).length) {
      dispatch(SnackbarAction.openError('Form Error, Please check.'))
      return
    }

    if (!files[OrderUploadFileKey.MY_KAD]) {
      dispatch(SnackbarAction.openError('Missing MyKad, please upload.'))
      return
    }

    dispatch({
      type: LoadingType.TOGGLE_LOADING,
      payload: 'Submitting...',
    })
    const postBody: AgentRegisterPostBody = {
      ...formState,
    }
    if (inviteUsername) {
      postBody.superiorUsername = inviteUsername
    }

    AuthenticationService.register(postBody)
      .then(async value => {
        const userId = value.data.id

        await Promise.all(
          Object.entries(files).map(entry => {
            const [category, file] = entry
            return UserProfileAttachemntService.createUserProfileAttachment(
              userId,
              file,
              category
            )
          })
        )
        dispatch({
          type: AlertDialogType.TOGGLE_OPEN,
          payload: {
            title: 'Success Register',
            message: 'Please check your email to verify your account.',
          },
        })

        history.push('/')
      })
      .catch(catchErrorWithDispatch(dispatch))
      .finally(() => {
        dispatch({
          type: LoadingType.TOGGLE_LOADING,
        })
      })

    event.preventDefault()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    const trim = value.trim()

    switch (name) {
      case 'username':
        setFormState({
          ...formState,
          [name]: trim,
        })

        if (!RegexUtils.usernameRegex.test(trim)) {
          setErrorState({
            ...errorState,
            username:
              'Minimum 8 character, Only Use [A-Z], [a-z], [0-9], and [.], [_] in between Alphanumeric',
          })
        } else {
          setErrorState({
            ...errorState,
            username: '',
          })
        }

        break
      case 'mobileNo':
        setFormState({
          ...formState,
          [name]: trim,
        })

        if (!RegexUtils.phoneNumberRegex.test(trim)) {
          setErrorState({
            ...errorState,
            [name]: 'Mobile Number Wrong Format',
          })
        } else {
          setErrorState({
            ...errorState,
            [name]: '',
          })
        }

        break
      case 'postcode':
        setFormState({
          ...formState,
          [name]: trim,
        })

        if (!RegExp('[0-9]{5}').test(trim)) {
          setErrorState({
            ...errorState,
            [name]: 'Postcode Wrong Format',
          })
        } else {
          setErrorState({
            ...errorState,
            [name]: '',
          })
        }
        break
      default:
        setFormState({
          ...formState,
          [name]: value,
        })
        break
    }
  }

  const handleSelectChange = (
    event: React.ChangeEvent<{ name?: string; value: any }>
  ) => {
    if (event.target.name) {
      setFormState({
        ...formState,
        [event.target.name]: event.target.value,
      })
    }
  }
  if (failDecodeInviteUsername) {
    return (
      <div className='bg-gray-300 min-h-screen p-10'>
        <Container className='m-4'>
          <Card>
            <CardHeader title={'Invalid Token'} />
            <CardContent>
              You token is invalid, please check your received link.
            </CardContent>
            <CardActions>
              <Button
                onClick={() => history.replace('/')}
                fullWidth
                color={'secondary'}
              >
                Back
              </Button>
            </CardActions>
          </Card>
        </Container>
      </div>
    )
  } else
    return (
      <div className='bg-gray-300 min-h-screen p-10'>
        <Container className='m-4'>
          {inviteUsername && (
            <Card className='my-4 p-4 text-center'>
              <span>Your are invited by </span>
              <span className='font-bold'>{inviteUsername}</span>
            </Card>
          )}

          <Card>
            <CardContent>
              <Typography variant='h4' className='text-center'>
                Registration
              </Typography>

              <form onSubmit={onSubmitForm}>
                <div>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        onChange={handleChange}
                        value={formState['username']}
                        required
                        id='username'
                        name='username'
                        label='Username'
                        helperText={
                          errorState['username'] || `Your login username`
                        }
                        error={!!errorState['username']}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        onChange={handleChange}
                        value={formState['password']}
                        required
                        id='password'
                        name='password'
                        label='Password'
                        helperText='Your login password'
                        type='password'
                        fullWidth
                      />
                    </Grid>
                    <hr />
                    <Grid item xs={12} sm={6}>
                      <TextField
                        onChange={handleChange}
                        value={formState['fullName']}
                        required
                        id='fullName'
                        name='fullName'
                        label='Full Name'
                        helperText='Name as NRIC'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        onChange={handleChange}
                        value={formState['nric']}
                        required
                        id='nric'
                        name='nric'
                        label='NRIC'
                        helperText={`(${formState['nric'].length}/14) Please enter nric number WITH '-'`}
                        placeholder='Eg: 830101-07-1234'
                        inputProps={{ maxLength: 14 }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        onChange={handleChange}
                        value={formState['email']}
                        required
                        id='email'
                        name='email'
                        label='Email'
                        helperText='Please use actual email, we will sent you activation code'
                        type='email'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        onChange={handleChange}
                        value={formState['mobileNo']}
                        error={!!errorState['mobileNo']}
                        helperText={
                          errorState['mobileNo'] +
                          " Please enter mobile number with '-'"
                        }
                        placeholder='Eg. 016-8889188 / 010-1112233'
                        required
                        id='mobileNo'
                        name='mobileNo'
                        label='Mobile Number'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl required fullWidth>
                        <InputLabel id='bankName'>Bank Name</InputLabel>
                        <Select
                          id='bankName'
                          name='bankName'
                          label='bankName'
                          value={formState['bankName']}
                          onChange={handleSelectChange}
                        >
                          <MenuItem value=''>
                            <em>None</em>
                          </MenuItem>
                          {banks.map(({ name }) => (
                            <MenuItem key={name} value={name}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        onChange={handleChange}
                        value={formState['bankAccNo']}
                        required
                        id='bankAccNo'
                        name='bankAccNo'
                        label='Bank Account Number'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        onChange={handleChange}
                        value={formState['bankBeneficialName']}
                        required
                        id='bankBeneficialName'
                        name='bankBeneficialName'
                        label='Bank Beneficial Name'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        onChange={handleChange}
                        value={formState['address']}
                        required
                        id='address'
                        name='address'
                        label='Address'
                        helperText='Your current address'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        onChange={handleChange}
                        value={formState['city']}
                        required
                        id='city'
                        name='city'
                        label='City'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl required fullWidth>
                        <InputLabel id='state'>State</InputLabel>
                        <Select
                          id='state'
                          name='state'
                          label='State'
                          value={formState['state']}
                          onChange={handleSelectChange}
                        >
                          <MenuItem value=''>
                            <em>None</em>
                          </MenuItem>
                          {statess.map(({ name }) => (
                            <MenuItem key={name} value={name}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        onChange={handleChange}
                        value={formState['postcode']}
                        error={!!errorState['postcode']}
                        helperText={
                          errorState['postcode'] ||
                          `(${formState['postcode'].length}/5)`
                        }
                        required
                        id='postcode'
                        name='postcode'
                        label='Postal code'
                        inputProps={{ maxLength: 5 }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        onChange={handleChange}
                        value={formState['country']}
                        required
                        id='country'
                        name='country'
                        label='Country'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        onChange={handleChange}
                        value={formState['companyName']}
                        id='companyName'
                        name='companyName'
                        label='Company Name'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        onChange={handleChange}
                        value={formState['companyRegNo']}
                        id='companyRegNo'
                        name='companyRegNo'
                        label='Company Register Number'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        onChange={handleChange}
                        value={formState['telNo']}
                        helperText={"Please enter tel number WITHOUT '-'"}
                        placeholder='Eg. 06123456 / 0887394900'
                        id='telNo'
                        name='telNo'
                        label='Tel Number'
                        fullWidth
                      />
                    </Grid>
                    {!inviteUsername && (
                      <Grid item xs={12} sm={6}>
                        <TextField
                          onChange={handleChange}
                          value={formState['superiorUsername']}
                          id='superiorUsername'
                          name='superiorUsername'
                          label='Superior Username'
                          helperText="Your superior's login username"
                          fullWidth
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid item xs={12}>
                      <Alert severity='info' className='prose-sm'>
                        <AlertTitle>
                          Please upload the following documents
                        </AlertTitle>
                      </Alert>
                      <UserProfileAttachmentTable
                        files={files}
                        onChange={setFiles}
                      />
                    </Grid>
                  </Grid>
                </div>
                <br />
                <Grid item xs={12}>
                  <FormGroup row>
                    <FormControlLabel
                      value='end'
                      control={
                        <Checkbox color='primary' onClick={onCheckboxClick} />
                      }
                      label={
                        <div>
                          <span>
                            By clicking this, I hereby agree to and accept the{' '}
                          </span>
                          <span onClick={handleClickOpen}>
                            <u>Terms and Conditions</u>
                          </span>
                        </div>
                      }
                      labelPlacement='end'
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  className='text-right'
                  container
                  spacing={2}
                  justify='flex-end'
                >
                  <Grid item>
                    <Button
                      variant='text'
                      color='secondary'
                      size='large'
                      onClick={() => history.push('/')}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant='contained'
                      color='primary'
                      size='large'
                      type='submit'
                      disabled={!checked}
                    >
                      Submit
                    </Button>
                    <Dialog
                      open={open}
                      onClose={handleClose2}
                      aria-labelledby='responsive-dialog-title'
                    >
                      <DialogTitle id='responsive-dialog-title'>
                        {'Fibre2u Terms and Conditions'}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          <div className={'prose'}>
                            <u>Principle Products</u>
                            <ol>
                              <li>
                                Principle products are products or services such
                                as (UNIFIHOME, TIMEBIZ, etc) offered by
                                principle (such as TM, TIME, Maxis, etc) whereby
                                fibre2u.com has the authority to handle, manage
                                the registration, operation, payment, and agent
                                commission of the products.
                              </li>

                              <li>
                                fibre2u.com provides an online platform to all
                                the users involved to manage principle products,
                                and to communicate with business partners.
                              </li>

                              <li>
                                The registration, operation, and payment of any
                                principle products are solely managed by
                                fibre2u.com.
                              </li>

                              <li>
                                All sales and applications submitted by all
                                business partners are required to be genuine and
                                in compliance with all laws, rules, and
                                regulations by any relevant authority.
                              </li>

                              <li>
                                All applications or sales submitted by business
                                partner are compulsory to have a complete set of
                                documents uploaded according to the principle
                                product requirements.
                              </li>

                              <li>
                                All business partners shall not quote a product
                                price, promotion package on behalf of
                                fibre2u.com other than those approved by
                                fibre2u.com and/or the related product
                                principle.
                              </li>

                              <li>
                                It is the business partner’s responsibility to
                                ensure that all customer information entered in
                                to the system is genuine and correct.
                              </li>

                              <li>
                                All business partners shall not mislead or
                                provide incorrect information to the customers
                                or applicants.
                              </li>
                            </ol>

                            <u>Payment</u>
                            <ol>
                              <li>
                                Commission will be paid to all business partners
                                according to the terms and conditions of
                                principle product and fibre2u.com.
                              </li>

                              <li>
                                Commission payout will be following the
                                commission rates that are set in fibre2u.com and
                                fibre2u.com reserves the right to amend the
                                commission rate from time to time.
                              </li>

                              <li>
                                fibre2u.com reserves the right to forfeit a
                                business partner’s commission if the business
                                partner fails to submit a complete set of
                                documents.
                              </li>

                              <li>
                                Payment will be made once a week according to
                                principle claim list and the supporting
                                documents that are submitted.
                              </li>

                              <li>
                                fibre2u.com reserves the right to amend payment
                                date and payment method from time to time.
                              </li>

                              <li>
                                All payee name must be same as the name
                                registered during the agent registration process
                                at the beginning. (E.g. Agent registered name:
                                Michael Jones, bank payee name must be Michael
                                Jones)
                              </li>

                              <li>
                                Any sales that are made and uploaded without any
                                supporting documents, commission will be
                                deducted, and penalty will be imposed based on
                                Principal Penalty Fee.
                              </li>
                            </ol>
                            <u>Showcase Letter Penalty from Principle</u>
                            <ol>
                              <li>
                                Business partner shall be required to respond to
                                a ShowCause letter from principle within 3 days
                                if any.
                              </li>

                              <li>
                                Failure to respond to a ShowCause letter may
                                lead to serious penalty from principle.
                              </li>

                              <li>
                                fibre2u.com will not bear any losses arised from
                                the misconduct of business partner’s sales
                                practices
                              </li>

                              <li>
                                Debit note will be issued to business partner if
                                fibre2u.com receives Debit note from the
                                principle for any related applications or sales.
                              </li>

                              <li>
                                Penalty will be issued to the business partner
                                if fibre2u.com receives penalty from the related
                                principle for any related applications or sales.
                              </li>
                            </ol>
                            <u>Clawback Commission</u>
                            <ol>
                              <li>
                                Clawback commission shall claw based on the
                                Principle report (TM, Maxis, TIME, etc.)
                              </li>

                              <li>Common claw back issues include:</li>
                              <ul>
                                <li>Termination of service within 6 months</li>
                                <li>Re-subscribed customer</li>
                                <li>Customer does not make a payment</li>
                                <li>Customer denies subscription</li>
                              </ul>

                              <li>
                                If the downline commission clawback and owing
                                company, the upline need as guarantor and bare
                                the clawback.
                              </li>
                            </ol>
                          </div>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          variant='contained'
                          color='primary'
                          size='large'
                          onClick={handleClose2}
                        >
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Container>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackBarOpen.open}
          autoHideDuration={6000}
          onClose={handleClose}
          key='snackbar-error'
        >
          <Alert onClose={handleClose} severity='error'>
            {snackBarOpen.message}
          </Alert>
        </Snackbar>
      </div>
    )
}

SignUpPage.propTypes = {}

export default SignUpPage
