import React from 'react'
import { TextField } from '@material-ui/core'
import userProfileService from '../service/userProfileService'

interface OrderCustomerDetailComponentProps {
  isCreateMode: boolean
  form: any
  errorForm: any
  onChange: (name: string, value: string) => void
}

const OrderCustomerDetailComponent = (
  props: OrderCustomerDetailComponentProps
) => {
  const { isCreateMode, form, errorForm } = props

  function handleOnChange(
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<any>
  ) {
    props.onChange(e.target.name, e.target.value)
  }

  function isNameDisable() {
    const isAgent = userProfileService.isAgent()
    const isTopAgent = userProfileService.isTopAgent()

    const isAgentButNotTopAgent = isAgent && !isTopAgent

    return !isCreateMode && isAgentButNotTopAgent
  }
  return (
    <div className='grid grid-cols-2 gap-4'>
      <TextField
        id='companyName'
        name='companyName'
        label='Company Name'
        value={form['companyName']}
        onChange={handleOnChange}
      />
      <TextField
        id='companyRegNo'
        name='companyRegNo'
        label='Company Register Number'
        value={form['companyRegNo']}
        onChange={handleOnChange}
      />
      <TextField
        id='fullName'
        name='fullName'
        label='Full Name'
        value={form['fullName']}
        onChange={handleOnChange}
        disabled={isNameDisable()}
        required
      />

      <TextField
        id='nric'
        name='nric'
        label='Customer NRIC/Passport'
        value={form['nric']}
        onChange={handleOnChange}
        inputProps={{ maxLength: 14 }}
        helperText={`${form['nric'].length}/14`}
        placeholder='Eg: 830101-07-1234 / PA090443'
        required
      />
      <TextField
        id='email'
        name='email'
        label='Email'
        value={form['email']}
        onChange={handleOnChange}
        inputMode='email'
        type='email'
      />
      <TextField
        id='mobileNo'
        name='mobileNo'
        label='Mobile No'
        value={form['mobileNo']}
        onChange={handleOnChange}
        error={!!errorForm['mobileNo']}
        helperText={`${errorForm['mobileNo']} Please enter mobile number with '-'`}
        placeholder='Eg. 016-8889188 / 010-1112233'
        inputMode='tel'
      />
      <TextField
        id='telNo'
        name='telNo'
        label='Tel No'
        onChange={handleOnChange}
        helperText={"Please enter tel number WITHOUT '-'"}
        placeholder='Eg. 06123456 / 0887394900'
        value={form['telNo']}
      />

      <TextField
        id='alternativeNo'
        name='alternativeNo'
        label={'Alternative No'}
        value={form['alternativeNo']}
        onChange={handleOnChange}
        error={!!errorForm['alternativeNo']}
        helperText={`${errorForm['alternativeNo']} Please enter alternative number with '-'`}
        placeholder='Eg. 06-123456 / 088-7394900 / 016-8119900'
      />
    </div>
  )
}

export default OrderCustomerDetailComponent
