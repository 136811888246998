import React from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { BaseInputFieldProps } from './index'
import { useTypedSelector } from '../../redux/reducer'
import { BankBean } from '../../common/service/BankBean'

interface BankSelectFieldProps extends BaseInputFieldProps {
  onChange: (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => void
}
const BankSelectField = (props: BankSelectFieldProps) => {
  const banks: BankBean[] = useTypedSelector(state => state.common.banks)

  const { value, disabled = false, label, name, onChange } = props
  return (
    <FormControl
      disabled={disabled}
      required={props.required || false}
      fullWidth
    >
      <InputLabel>{label}</InputLabel>
      <Select name={name} label={label} value={value || ''} onChange={onChange}>
        <MenuItem value='' disabled>
          <em>None</em>
        </MenuItem>
        {banks.map(({ name }) => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default BankSelectField
