import React, { useEffect } from 'react'
import {
  AppBar,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import { useTypedSelector } from '../../redux/reducer'
import { UserActions, UserReducer } from '../../redux/reducer/userReducer'
import { useDispatch } from 'react-redux'
import UserRole from '../../common/constant/userRole'
import { UserCodeBean } from '../../common/service/UserCodeBean'
import CommonUtils from '../../common/commonUtils'
import AdminActionsMenuComponent from './component/adminActionsMenu.component'
import userProfileService from '../../common/service/userProfileService'

const AdminManagementPage = () => {
  const disabled = !userProfileService.isManager()

  const history = useHistory()
  const dispatch = useDispatch()
  const userReducer: UserReducer = useTypedSelector(state => state.user)
  const userCode: UserCodeBean = useTypedSelector(
    state => state.portalSetting.userCode
  )

  useEffect(() => {
    dispatch(UserActions.getAdmins())
  }, [dispatch])

  return (
    <div>
      <AppBar position='relative'>
        <Toolbar className='space-x-3'>
          <Typography variant='h6'>Admin Management</Typography>
          {!disabled && (
            <IconButton
              color='inherit'
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                history.push('new-admin')
              }}
            >
              <GroupAddIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      <div className='m-8'>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {['ID', 'Username', 'Full Name', 'Email', 'Role', ''].map(
                  text => (
                    <TableCell key={text}>
                      <span className='font-bold text-gray-700'>{text}</span>
                    </TableCell>
                  )
                )}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {userReducer.admins.map(admin => {
                return (
                  <TableRow key={admin.id} className='hover:bg-gray-200'>
                    <TableCell>
                      {CommonUtils.getUserCode(admin, userCode)}
                    </TableCell>
                    <TableCell>{admin.username}</TableCell>
                    <TableCell>{admin.fullName}</TableCell>
                    <TableCell>{admin.email}</TableCell>
                    <TableCell>{UserRole.toDisplay[admin.role]}</TableCell>
                    <TableCell align='right'>
                      <div className=' text-white'>
                        {admin.enabled ? (
                          <span className='p-1 rounded bg-green-300'>
                            ENABLE
                          </span>
                        ) : (
                          <span className='p-1 rounded bg-red-300'>
                            DISABLE
                          </span>
                        )}
                      </div>
                    </TableCell>
                    <TableCell align='right'>
                      <AdminActionsMenuComponent user={admin} />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}

export default AdminManagementPage
