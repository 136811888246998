import React from 'react'
import UserBean from '../../../sharePortal/userManagement/bean/userBean'
import { Autocomplete } from '@material-ui/lab'
import { LinearProgress, TextField } from '@material-ui/core'
import CommonUtils from '../../../common/commonUtils'
import userProfileService from '../../../common/service/userProfileService'
import { UserCodeBean } from '../../../common/service/UserCodeBean'
import { useTypedSelector } from '../../../redux/reducer'

interface SelectAgentTextComponentProp {
  users: UserBean[] | undefined
  selectedUser: UserBean | undefined
  setUser: (user: UserBean | null) => void
}

const SelectAgentTextV2Component = (props: SelectAgentTextComponentProp) => {
  const { setUser, users, selectedUser } = props

  const userCode: UserCodeBean = useTypedSelector(
    state => state.portalSetting.userCode
  )

  if (!users) {
    return <LinearProgress />
  }

  return (
    <Autocomplete
      renderInput={params => (
        <TextField
          {...params}
          label='Agent'
          inputProps={{ ...params.inputProps }}
          required
        />
      )}
      getOptionLabel={option => {
        const prefix = CommonUtils.getUserCode(option, userCode)
        const { fullName, username, id } = option

        const displayName =
          option.companyName && option.companyRegNo
            ? `${option.companyName} (${option.companyRegNo})`
            : `${fullName} (${username})`

        const suffix =
          userProfileService.getUserProfile()?.id === id ? '[YOURSELF]' : ''

        return `[${prefix}] ${displayName} ${suffix}`
      }}
      options={users}
      value={selectedUser || null}
      onChange={(event, value) => {
        if (value) {
          setUser(value)
        } else {
          setUser(null)
        }
      }}
    />
  )
}

export default SelectAgentTextV2Component
