import React, { useEffect } from 'react'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import { OrderBean } from '../../sharePortal/orderManagement/bean/orderBean'
import { CommonActions, CommonReducer } from '../../redux/reducer/commonReducer'
import { useTypedSelector } from '../../redux/reducer'
import { useDispatch } from 'react-redux'
import CommonUtils from '../commonUtils'

interface AddressFormProp {
  unitNo: string
  streetType: string
  streetName: string
  section: string
  floorNo: string
  buildingName: string
  city: string
  state: string
  postcode: string
}

interface OrderAddressComponentProps {
  order?: OrderBean
  form: AddressFormProp
  errorForm: AddressFormProp
  onChange: (name: string, value: string) => void
}

const OrderAddressComponent = (props: OrderAddressComponentProps) => {
  const dispatch = useDispatch()
  const { errorForm, form, order, onChange } = props
  const commonReducerState: CommonReducer = useTypedSelector(
    state => state.common
  )

  useEffect(() => {
    dispatch(CommonActions.getStates())
  }, [dispatch])

  function onTextChange(
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<any>
  ) {
    onChange(e.target.name, e.target.value)
  }

  function handleSelectChange(
    e: React.ChangeEvent<{ name?: string; value: any }>
  ) {
    if (e.target.name) {
      onChange(e.target.name, e.target.value)
    }
  }

  return (
    <div className='grid grid-cols-2 gap-4'>
      <TextField
        disabled
        className='col-span-2'
        label={'Address'}
        value={order?.address || CommonUtils.getJoinedNewFormatAddress(order)}
        name='address'
        fullWidth
        helperText={
          (order &&
            order.address &&
            'This Address is old format, do not use. This only display for old order.') ||
          ''
        }
      />{' '}
      <TextField
        onChange={onTextChange}
        name='unitNo'
        label={'Unit No'}
        value={form['unitNo']}
        placeholder='Eg: 2'
      />
      <TextField
        onChange={onTextChange}
        name='streetType'
        label={'Street Type'}
        value={form['streetType']}
        placeholder='Eg: JALAN'
      />
      <TextField
        onChange={onTextChange}
        name='streetName'
        label={'Street Name'}
        value={form['streetName']}
        placeholder='Eg: SIERRA 2/3A'
      />
      <TextField
        onChange={onTextChange}
        name='section'
        label={'Section'}
        value={form['section']}
        placeholder='Eg: SIERRA 16'
      />
      <TextField
        onChange={onTextChange}
        name='floorNo'
        label={'Floor No'}
        value={form['floorNo']}
      />
      <TextField
        onChange={onTextChange}
        name='buildingName'
        label={'Building Name'}
        value={form['buildingName']}
      />
      <TextField
        onChange={onTextChange}
        name='city'
        label={'City'}
        value={form['city']}
        placeholder='Eg: PETALING JAYA'
      />
      <FormControl>
        <InputLabel id='state'>State</InputLabel>
        <Select
          id='state'
          name='state'
          label='State'
          value={form['state']}
          onChange={handleSelectChange}
        >
          <MenuItem value=''>
            <em>None</em>
          </MenuItem>
          {commonReducerState.states.map(({ name }) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        id='postcode'
        name='postcode'
        label='Postcode'
        onChange={onTextChange}
        value={form['postcode']}
        placeholder='Eg: 47100'
        error={!!errorForm['postcode']}
        helperText={`${errorForm['postcode']} (${form['postcode'].length}/5)`}
        inputProps={{ maxLength: 5 }}
      />
    </div>
  )
}

export default OrderAddressComponent
