import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { demoOrder } from '../../../../sharePortal/orderManagement/bean/orderBean'
import { OrderDisplayModel } from './model/OrderDisplayModel'
import {
  arrayMove,
  SortableContainer,
  SortableElement,
} from 'react-sortable-hoc'
import { DragHandle } from '@material-ui/icons'
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
} from '@material-ui/core'

const SortableList = SortableContainer(({ children }: { children: any }) => {
  return <div>{children}</div>
})

const SortableItem = SortableElement(({ value }: { value: any }) => (
  <div
    key={value}
    className='flex p-1 border bg-white cursor-move items-center'
  >
    <IconButton disabled={true}>
      <DragHandle />
    </IconButton>
    <div className='flex-1'> {_.startCase(value)}</div>
  </div>
))

const OrderPanelComponent = (props: {
  orderDisplayList: string[]
  onSaveClick: (result: string[]) => void
}) => {
  const orderDisplayModel = new OrderDisplayModel(demoOrder)
  const [items, setItems] = useState<string[]>([])

  useEffect(() => {
    setItems(props.orderDisplayList)
  }, [props.orderDisplayList])

  const handleSaveClick = () => {
    props.onSaveClick(items)
  }
  const keys = _.keys(orderDisplayModel)

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number
    newIndex: number
  }) => {
    setItems(arrayMove(items, oldIndex, newIndex))
  }

  return (
    <div>
      <div className='grid-cols-6 grid'>
        {keys.map(value => {
          const isSelected = _.includes(items, value)

          return (
            <FormControlLabel
              control={
                <Checkbox
                  name={value}
                  color='primary'
                  checked={isSelected}
                  onClick={() => {
                    setItems(prevState => {
                      if (isSelected) {
                        return _.filter(prevState, i => !_.isEqual(i, value))
                      } else {
                        return _.concat(prevState, value)
                      }
                    })
                  }}
                />
              }
              label={_.startCase(value)}
            />
          )
        })}
      </div>

      <span className='m-4'>
        <Divider />
      </span>

      <SortableList onSortEnd={onSortEnd} lockAxis='y'>
        {items.map((value: any, index: number) => (
          <SortableItem key={`item-${value}`} index={index} value={value} />
        ))}
      </SortableList>

      <div className='text-right mt-4'>
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={handleSaveClick}
        >
          SAVE
        </Button>
      </div>
    </div>
  )
}

export default OrderPanelComponent
