import React, { useEffect } from 'react'
import { AppBar, Button, Toolbar, Typography } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import userProfileService from '../../common/service/userProfileService'
import SubmitClaimTableComponent from '../../common/withdrawal/SubmitClaimTableComponent'
import {
  ClaimReducerActions,
  ClaimReducerProps,
} from '../../redux/reducer/claimReducer'
import { useTypedSelector } from '../../redux/reducer'
import SubmitClaimTotalPriceComponent from '../../common/withdrawal/SubmitClaimTotalPriceComponent'

function SubmitClaimPage() {
  const dispatch = useDispatch()

  const { withdrawals, selectedIds }: ClaimReducerProps = useTypedSelector(
    state => state.claim
  )

  const handleSubmitClaim = () => {
    dispatch(
      ClaimReducerActions.submitClaim(
        selectedIds,
        userProfileService.getCurrentUserId()
      )
    )
  }

  function resetSelectedWithdrawals() {
    dispatch(ClaimReducerActions.reset())
  }

  const handleClick = (id: number) => {
    dispatch(ClaimReducerActions.withdrawalClick(id))
  }
  const handleCheckAllClick = (ids: number[], checked: boolean) => {
    dispatch(ClaimReducerActions.checkAllClick(ids, checked))
  }

  useEffect(() => {
    dispatch(
      ClaimReducerActions.fetchWithdrawalsById(
        userProfileService.getCurrentUserId()
      )
    )
  }, [dispatch])

  return (
    <div>
      <AppBar position='relative'>
        <Toolbar className='space-x-3'>
          <Typography variant='h6' noWrap>
            Submit Claims
          </Typography>
        </Toolbar>
      </AppBar>

      <hr />

      <div>
        <SubmitClaimTotalPriceComponent
          withdrawals={withdrawals}
          selectedWithdrawals={selectedIds}
        />
      </div>

      <div className='m-4'>
        <SubmitClaimTableComponent
          agentId={userProfileService.getCurrentUserId()}
          onClaimClick={handleClick}
          onCheckAllClick={handleCheckAllClick}
          selectedWithdrawals={selectedIds}
          withdrawals={withdrawals}
        />
      </div>

      <div className='m-4 text-right space-x-3'>
        <Button
          color='secondary'
          variant='outlined'
          onClick={resetSelectedWithdrawals}
        >
          Reset
        </Button>
        <Button
          color='primary'
          variant='contained'
          disabled={!selectedIds.length}
          onClick={handleSubmitClaim}
        >
          Submit
        </Button>
      </div>
    </div>
  )
}

SubmitClaimPage.propTypes = {}

export default SubmitClaimPage
