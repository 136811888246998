import client, { AxiosResponse } from 'axios'
import UserBean from '../../../sharePortal/userManagement/bean/userBean'
import { AgentStaffPostBody } from '../bean/agentStaffPostBody'

const url = `/api/v1/users/agent-staffs`

export class AgentStaffService {
  static register(data: AgentStaffPostBody): Promise<AxiosResponse<UserBean>> {
    return client.post(url, { ...data })
  }

  static getAll(): Promise<AxiosResponse<UserBean[]>> {
    return client.get(url)
  }

  static verifyAgentStaff = function (userId: number): Promise<AxiosResponse> {
    return client.post(`${url}/${userId}/enable`)
  }
  static disableAgentStaff = function (userId: number): Promise<AxiosResponse> {
    return client.post(`${url}/${userId}/disable`)
  }
}
