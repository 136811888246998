import client, { AxiosResponse } from 'axios'
import { OrderAttachmentBean } from './bean/orderAttachmentBean'

const url = '/api/v1/order-attachments'
export default class OrderAttachmentService {
  static getOrderAttachmentByOrderId(
    orderId: number
  ): Promise<AxiosResponse<OrderAttachmentBean[]>> {
    return client.get(`${url}/order/${orderId}`)
  }

  static createOrderAttachment(
    orderId: number,
    files: File[]
  ): Promise<AxiosResponse<OrderAttachmentBean>> {
    const formData = new FormData()
    files.forEach(file => formData.append('files', file))
    formData.append('orderId', orderId.toString())

    return client.post(url, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
  }

  static createOrderAttachmentWithCategory(
    orderId: number,
    files: File[],
    category: string
  ): Promise<AxiosResponse<OrderAttachmentBean>> {
    if (!category) {
      throw Error('Category is mandatory.')
    }

    const formData = new FormData()
    files.forEach(file => formData.append('files', file))
    formData.append('orderId', orderId.toString())

    return client.post(`${url}/${category}`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
  }

  static deleteOrderAttachmentById(id: number): Promise<AxiosResponse> {
    return client.delete(`${url}/${id}`)
  }
}
