import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import { PortalSettingActions } from '../../../../../redux/reducer/portalSettingReducer'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../../../../redux/reducer'
import UserRole from '../../../../../common/constant/userRole'
import _ from 'lodash'

const PackageShowDescriptionComponent = () => {
  const dispatch = useDispatch()

  const packageShowDescription = useTypedSelector(
    state => state.portalSetting.order.packageShowDescription
  )
  const showCommissionRate = useTypedSelector(
    state => state.portalSetting.order.showCommissionRate
  )

  const roleAbleToViewCommissionRate = useTypedSelector(
    state => state.portalSetting.order.roleAbleToViewCommissionRate
  )

  const [showDesc, setShowDesc] = useState(false)
  const [showCommRate, setShowCommRate] = useState(false)
  const [roles, setRoles] = useState<string[]>([])

  useEffect(() => {
    setShowDesc(packageShowDescription)
  }, [packageShowDescription])

  useEffect(() => {
    setShowCommRate(showCommissionRate)
  }, [showCommissionRate])

  useEffect(() => {
    setRoles(roleAbleToViewCommissionRate)
  }, [roleAbleToViewCommissionRate])

  useEffect(() => {
    dispatch(PortalSettingActions.getPackageShowDescription())
    dispatch(PortalSettingActions.getOrderShowCommissionRate())
    dispatch(PortalSettingActions.getRoleAbleToViewCommissionRate())
  }, [dispatch])

  return (
    <Card>
      <CardHeader title={'New Order'} />
      <CardContent>
        <div>
          <div className='font-bold'>Order Package Show: </div>
          <div>
            Name
            <Switch
              name='packageShowDescription'
              checked={showDesc}
              onChange={(event, checked) => {
                setShowDesc(checked)
              }}
            />
            Description
          </div>
        </div>

        <div>
          <div className='font-bold'>Order Show Commission Rate: </div>
          <div>
            No
            <Switch
              name='packageShowDescription'
              checked={showCommRate}
              onChange={(event, checked) => {
                setShowCommRate(checked)
              }}
            />
            Yes
          </div>
        </div>

        <div>
          <div className='font-bold'>
            Role Able To View Commission Rate in Order Detail:{' '}
          </div>
          <div>
            {[
              UserRole.ROLE_MANAGER,
              UserRole.ROLE_ADMIN,
              UserRole.ROLE_STAFF,
              UserRole.ROLE_AGENT,
            ].map(value => {
              return (
                <FormControlLabel
                  key={value}
                  label={UserRole.toDisplay[value]}
                  control={
                    <Checkbox
                      name={value}
                      checked={roles.includes(value)}
                      onChange={() => {
                        setRoles(prevState =>
                          prevState.includes(value)
                            ? prevState.filter(v => value !== v)
                            : [...prevState, value]
                        )
                      }}
                    />
                  }
                />
              )
            })}
          </div>
        </div>
      </CardContent>
      <CardActions className='justify-end'>
        <Button
          variant={'outlined'}
          color={'secondary'}
          onClick={() => {
            setShowDesc(packageShowDescription)
            setShowCommRate(showCommissionRate)
            setRoles(roleAbleToViewCommissionRate)
          }}
        >
          RESET
        </Button>
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={() => {
            if (showDesc !== packageShowDescription) {
              dispatch(
                PortalSettingActions.updatePackageShowDescription(showDesc)
              )
            }

            if (showCommRate !== showCommissionRate) {
              dispatch(
                PortalSettingActions.updateOrderShowCommissionRate(showCommRate)
              )
            }

            if (
              !_.isEqual(
                _.sortBy(roles),
                _.sortBy(roleAbleToViewCommissionRate)
              )
            ) {
              dispatch(
                PortalSettingActions.updateRoleAbleToViewCommissionRate(roles)
              )
            }
          }}
          disabled={
            _.isEqual(showDesc, packageShowDescription) &&
            _.isEqual(showCommRate, showCommissionRate) &&
            _.isEqual(_.sortBy(roles), _.sortBy(roleAbleToViewCommissionRate))
          }
        >
          UPDATE
        </Button>
      </CardActions>
    </Card>
  )
}

export default PackageShowDescriptionComponent
