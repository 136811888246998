import React from 'react'
import { ProductPackage } from '../../../adminPortal/productPackageManagement/bean/productPackage'
import CommissionType from '../../../common/constant/commissionType'
import { CommissionEntity } from '../../../adminPortal/userManagement/bean/commissionEntity'

interface CommissionRateComponentProps {
  packageId: string | number
  commissions: CommissionEntity[]
  packages: ProductPackage[]
}
const CommissionRateComponent = (props: CommissionRateComponentProps) => {
  const { commissions, packageId, packages } = props

  const findCommission: CommissionEntity | undefined = commissions.find(
    value => value.productPackageId.toString() === packageId.toString()
  )

  const findPackage: ProductPackage | undefined = packages.find(
    value => value.id.toString() === packageId.toString()
  )

  if (findCommission && findPackage) {
    const percentage = findCommission.percentage
    const isAmount = findPackage.commissionType === CommissionType.AMOUNT

    const display = isAmount ? 'RM ' + percentage : percentage + '%'

    const finalCommission = isAmount
      ? percentage
      : (percentage / 100) * findPackage.price

    return (
      <div>
        <div>Commission Rate is {display}</div>
        <div>Package price is RM {findPackage.price.toFixed(2)}</div>
        <div>Final Commission is RM {finalCommission.toFixed(2)}</div>
      </div>
    )
  } else {
    return <div className='text-red-400'>No Commission Found</div>
  }
}

export default CommissionRateComponent
