import React, { useMemo, useState } from 'react'
import {
  Paper,
  TableContainer,
  TablePagination,
  TextField,
} from '@material-ui/core'
import { ClaimBean } from '../bean/claimBean'
import { useTypedSelector } from '../../../redux/reducer'
import { Pageable } from '../../../common/bean/Pageable'
import { useClaimQs } from '../hook/useClaimQs'
import ClaimTable from './claimTable'

const PageableClaimTableComponent = (props: {
  claims?: Pageable<ClaimBean>
}) => {
  const claims = props.claims?.content || []

  const [, setClaimFilter] = useClaimQs()

  const portalSettingReducer = useTypedSelector(state => state.portalSetting)

  // Paginator
  const { rowPerPageOptions, defaultRowPerPage } =
    portalSettingReducer.paginator

  const [filterString, setFilterString] = useState('')
  const handleChangePage = (event: unknown, newPage: number) => {
    setClaimFilter('pageNumber', newPage.toString())
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setClaimFilter('pageSize', event.target.value)
  }

  // END Paginator

  const displayClaims = useMemo(() => {
    if (filterString === '') {
      return claims
    }
    return claims.filter(value => {
      const _filterString = filterString.toLowerCase()

      const _targetValue = [
        value.referenceNo,
        value.id,
        value.remarks,
      ]
        .filter(Boolean)
        .map(o => (o || '').toString().toLowerCase())

      return Boolean(_targetValue.find(o => o.includes(_filterString)))
    })
  }, [claims, filterString])

  return (
    <TableContainer component={Paper}>
      <div className='m-4'>
        <TextField
          name='filterString'
          value={filterString}
          onChange={e => {
            setFilterString(e.target.value)
          }}
          fullWidth
          placeholder='Filter by Claim ID, Reference No, Remarks...'
        />
      </div>
      <ClaimTable claims={displayClaims} />
      <TablePagination
        rowsPerPageOptions={rowPerPageOptions}
        component='div'
        count={props.claims?.totalElements || 0}
        rowsPerPage={props.claims?.size || defaultRowPerPage}
        page={props.claims?.number || 0}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </TableContainer>
  )
}

export default PageableClaimTableComponent
