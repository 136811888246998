import client, { AxiosResponse } from 'axios'
import UserBean from '../../../sharePortal/userManagement/bean/userBean'
import { AdminPostRequest } from '../bean/adminPostRequest'

const url = `/api/v1/users`

export class AdminService {
  static register(data: AdminPostRequest): Promise<AxiosResponse<UserBean>> {
    return client.post(`${url}/admins`, { ...data })
  }

  static getAll(): Promise<AxiosResponse<UserBean[]>> {
    return client.get(`${url}/admins`)
  }

  static updateRole(adminId: number, role: string): Promise<AxiosResponse> {
    return client.patch(`${url}/${adminId}/role`, { role })
  }
}
