import { combineReducers } from 'redux'
import alertDialogReducer from './alertDialogReducer'
import loadingBackdropReducer from './loadingBackdropReducer'
import { TypedUseSelectorHook, useSelector } from 'react-redux'
import userProfileReducer from './userProfileReducer'
import productReducer from './productReducer'
import snackbarReducer from './snackbarReducer'
import productCategoryReducer from './productCategoryReducer'
import productPackageReducer from './productPackageReducer'
import commonReducer from './commonReducer'
import userReducer from './userReducer'
import portalSettingReducer from './portalSettingReducer'
import ProfileDetailPageReducer from './page/profileDetailPage'
import orderPackageFormReducer from './orderPackageFormReducer'
import claimReducer from './claimReducer'
import orderSummaryMessageSlice from '../slice/orderSummaryMessageSlice'
import { orderSettingsSlice } from '../slice/orderSettingsSlice'
import { claimSettingsSlice } from '../slice/claimSettings.slice'
import { templateSlice } from '../slice/template.slice'
import userSettingsSlice from '../slice/userSettingsSlice'

export interface ReduxReducerAction {
  type: string
  payload: any
}

export const rootReducer = combineReducers({
  alert: alertDialogReducer,
  loading: loadingBackdropReducer,
  snackbar: snackbarReducer,
  userProfile: userProfileReducer,
  product: productReducer,
  productCategory: productCategoryReducer,
  productPackage: productPackageReducer,
  common: commonReducer,
  user: userReducer,
  portalSetting: portalSettingReducer,

  profileDetailPage: ProfileDetailPageReducer,
  orderPackageForm: orderPackageFormReducer,
  claim: claimReducer,
  orderSummaryMessage: orderSummaryMessageSlice.reducer,
  orderSettings: orderSettingsSlice.reducer,
  claimSettings: claimSettingsSlice.reducer,
  templates: templateSlice.reducer,
  userSettings: userSettingsSlice.reducer,
})
export type RootState = ReturnType<typeof rootReducer>
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector

/**
 * An action with a string type and an associated payload. This is the
 * type of action returned by `createAction()` action creators.
 *
 * @template P The type of the action's payload.
 * @template T the type used for the action type.
 * @template M The type of the action's meta (optional)
 * @template E The type of the action's error (optional)
 *
 * @public
 */
export type PayloadAction<
  P = void,
  T extends string = string,
  M = never,
  E = never
> = {
  payload: P
  type: T
} & ([M] extends [never]
  ? {}
  : {
      meta: M
    }) &
  ([E] extends [never]
    ? {}
    : {
        error: E
      })
