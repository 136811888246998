import { useCallback, useEffect, useMemo, useState } from 'react'
import { WithdrawalBean } from '../bean/WithdrawalBean'
import { UserCodeBean } from '../../../common/service/UserCodeBean'
import { useTypedSelector } from '../../../redux/reducer'
import WithdrawalService from '../service/withdrawalService'
import CommonUtils from '../../../common/commonUtils'

export function useAllWithdrawal() {
  const [unclaimedWithdrawals, setUnclaimedWithdrawals] = useState<
    WithdrawalBean[] | undefined
  >(undefined)

  const userCode: UserCodeBean = useTypedSelector(
    state => state.portalSetting.userCode
  )

  const getUnclaimedWithdrawal = useCallback(() => {
    WithdrawalService.findAllUnclaimed()
      .then(value => value.data)
      .then(setUnclaimedWithdrawals)
  }, [])

  useEffect(getUnclaimedWithdrawal, [getUnclaimedWithdrawal])

  const displayModel: Record<
    string,
    {
      agentCode: string
      companyDisplay: string
      personalDisplay: string
    }
  > = useMemo(() => {
    if (!unclaimedWithdrawals) {
      return {}
    }

    const map = unclaimedWithdrawals
      .map(value => value.refUserEntity)
      .map(user => {
        const agentCode = CommonUtils.getUserCode(user, userCode)

        const companyDisplay = user.companyName
          ? `${user.companyName} (${user.companyRegNo})`
          : ''
        const personalDisplay = `${user.fullName} (${user.username})`

        return [
          user.id.toString(),

          {
            agentCode,

            companyDisplay,
            personalDisplay,
          },
        ]
      })

    return Object.fromEntries(map)
  }, [unclaimedWithdrawals, userCode])

  const withdrawals: Record<string, WithdrawalBean[]> = useMemo(() => {
    if (!unclaimedWithdrawals) {
      return {}
    }

    return unclaimedWithdrawals.reduce<Record<string, WithdrawalBean[]>>(
      (previousValue, currentValue) => {
        const refUserEntity = currentValue?.refUserEntity
        const username = refUserEntity?.id.toString()
        const newArr = [...(previousValue[username] || []), currentValue]

        const newObj = { ...previousValue }
        newObj[username] = newArr

        return newObj
      },
      {}
    )
  }, [unclaimedWithdrawals])

  const unclaimedAmount = useMemo(() => {
    if (!unclaimedWithdrawals) {
      return 0
    }

    return unclaimedWithdrawals
      .filter(v => v.amount > 0)
      .map(v => v.amount)
      .reduce((pre, cur) => pre + cur, 0)
  }, [unclaimedWithdrawals])
  const deductAmount = useMemo(() => {
    if (!unclaimedWithdrawals) {
      return 0
    }

    return unclaimedWithdrawals
      .filter(v => v.amount < 0)
      .map(v => v.amount)
      .reduce((pre, cur) => pre + cur, 0)
  }, [unclaimedWithdrawals])

  return {
    withdrawals,
    unclaimedAmount,
    deductAmount,
    unclaimedWithdrawals,
    displayModel,
    getUnclaimedWithdrawal,
  }
}
