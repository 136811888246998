import { useDispatch } from 'react-redux'
import React, { useState } from 'react'
import CommissionType from '../../common/constant/commissionType'
import { SnackbarAction } from '../../redux/reducer/snackbarReducer'
import { ProductPackageAction } from '../../redux/reducer/productPackageReducer'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'

const AddProductPackageComponent = ({ categoryId }: { categoryId: number }) => {
  const dispatch = useDispatch()
  const [formState, setFormState] = useState({
    name: '',
    description: '',
    price: 0.0,
    commissionType: CommissionType.PERCENTAGE,
  })

  const handleSubmit = () => {
    if (formState.price <= 0) {
      dispatch(SnackbarAction.open('Price must be bigger than 0', 'warning'))
      return
    }

    dispatch(
      ProductPackageAction.postPackage({
        price: formState.price,
        categoryId: categoryId,
        name: formState.name,
        description: formState.description,
        commissionType: formState.commissionType,
      })
    )
    resetName()
  }

  const resetName = () =>
    setFormState({
      name: '',
      description: '',
      price: 0.0,
      commissionType: CommissionType.PERCENTAGE,
    })

  const onTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      event.target.name === 'price' &&
      Number.isNaN(Number(event.target.value))
    ) {
      return
    }

    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    })
  }

  const handleSelectChange = (
    event: React.ChangeEvent<{ name?: string; value: any }>
  ) => {
    if (event.target.name) {
      setFormState({
        ...formState,
        [event.target.name]: event.target.value,
      })
    }
  }

  return (
    <Card>
      <CardHeader title='Add Product Package' />
      <CardContent className='grid-cols-4 gap-4 grid'>
        <TextField
          className='col-span-4'
          id='name'
          name='name'
          label='Name'
          margin='normal'
          InputLabelProps={{ shrink: true }}
          value={formState.name}
          onChange={onTextFieldChange}
          fullWidth
        />
        <TextField
          className='col-span-4'
          id='description'
          name='description'
          label='Description'
          margin='normal'
          InputLabelProps={{ shrink: true }}
          value={formState.description}
          onChange={onTextFieldChange}
          rows={3}
          multiline
          fullWidth
        />
        <TextField
          id='price'
          name='price'
          label='Price'
          margin='normal'
          InputLabelProps={{ shrink: true }}
          value={formState.price}
          onChange={onTextFieldChange}
        />

        <FormControl component='fieldset' fullWidth>
          <FormLabel component='legend' id='commissionType'>
            Commission Type
          </FormLabel>

          <RadioGroup
            row
            aria-label='commissionType'
            name='commissionType'
            value={formState.commissionType || CommissionType.PERCENTAGE}
            onChange={handleSelectChange}
          >
            <FormControlLabel
              value={CommissionType.PERCENTAGE}
              label={CommissionType.PERCENTAGE}
              control={<Radio />}
            />
            <FormControlLabel
              value={CommissionType.AMOUNT}
              label={CommissionType.AMOUNT}
              control={<Radio />}
            />
          </RadioGroup>
        </FormControl>
      </CardContent>
      <CardActions className='justify-end'>
        <Button onClick={resetName}>Reset</Button>
        <Button color='primary' variant='contained' onClick={handleSubmit}>
          Submit
        </Button>
      </CardActions>
    </Card>
  )
}

export default AddProductPackageComponent
