import { OrderSummaryContent } from './OrderSummaryContent'

export interface OrderSummaryBean {
  title: string
  content: OrderSummaryContent
  footer: string
}

export const defaultOrderSummaryBean: OrderSummaryBean = {
  title: '',
  footer: '',
  content: {
    companyName: { enabled: false, displayText: '' },
    companyRegNo: { enabled: false, displayText: '' },
    activatedOn: { enabled: false, displayText: '' },
    address: { enabled: false, displayText: '' },
    adminRemarks: { enabled: false, displayText: '' },
    alternativeNo: { enabled: false, displayText: '' },
    eform: { enabled: false, displayText: '' },
    email: { enabled: false, displayText: '' },
    fullName: { enabled: false, displayText: '' },
    invoiceNo: { enabled: false, displayText: '' },
    loginId: { enabled: false, displayText: '' },
    m2uRemarks: { enabled: false, displayText: '' },
    mobileNo: { enabled: false, displayText: '' },
    nric: { enabled: false, displayText: '' },
    orderNo: { enabled: false, displayText: '' },
    orderStatus: { enabled: false, displayText: '' },
    price: { enabled: false, displayText: '' },
    productCategory: { enabled: false, displayText: '' },
    productPackage: { enabled: false, displayText: '' },
    remarks: { enabled: false, displayText: '' },
    telNo: { enabled: false, displayText: '' },
  },
}
