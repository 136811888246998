import React, { useEffect, useState } from 'react'
import {
  AppBar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { OrderFilterBean } from '../../sharePortal/orderManagement/bean/orderFilterBean'
import { OrderBean } from '../../sharePortal/orderManagement/bean/orderBean'
import OrderService from './orderService'
import OrderBgColorByStatus from '../../common/constant/orderBgColorByStatus'
import OrderStatus from '../../common/constant/orderStatus'
import { useTypedSelector } from '../../redux/reducer'
import qs from 'qs'
import { catchErrorWithDispatch } from '../../common/ApiUtils'

const CompletedOrderPage = () => {
  const toDisplayList = [
    'Customer Name',
    'Product Package',
    'Category',
    'Activated On',
    'User ID',
    'Agent',
    'Doc Completed',
    'Payment Voucher No.',
    'Comm. Value (RM)',
    'Agent Comm. (%)',
    'Agent Comm. (RM)',
    'Paid On',
  ]

  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { pageNumber = 0 } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })
  const initFilterDetail: OrderFilterBean = {
    key: '',
    label: '',
    value: [],
  }
  const filterInitState = {
    product: '',
    productCategory: '',
    productPackage: '',
    agent: '',
    status: '',
    submittedDate: '',
    activatedDate: '',
    docCompleted: '',
  }
  const [orders, setOrders] = useState<OrderBean[]>([])
  const [filter, setFilter] = useState<Record<string, string>>(filterInitState)

  const [filterOption, setFilterOption] = useState<
    Record<string, OrderFilterBean>
  >({
    product: initFilterDetail,
    productCategory: initFilterDetail,
    productPackage: initFilterDetail,
    agent: initFilterDetail,
    status: initFilterDetail,
    submittedDate: initFilterDetail,
    activatedDate: initFilterDetail,
    docCompleted: initFilterDetail,
  })

  const portalSettingReducer = useTypedSelector(state => state.portalSetting)

  // Paginator
  const rowPerPageOptions = portalSettingReducer.paginator.rowPerPageOptions
  const [rowsPerPage, setRowsPerPage] = React.useState(
    portalSettingReducer.paginator.defaultRowPerPage
  )
  const [page, setPage] = useState(0)

  const handleChangePage = (event: unknown, newPage: number) => {
    history.replace({
      search: `?pageNumber=${newPage}`,
    })
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    history.replace({
      search: `?pageNumber=${0}`,
    })
  }
  useEffect(() => {
    if (orders.length) {
      setPage(Number(pageNumber))
    }
  }, [orders, pageNumber])

  // END Paginator

  function handleFilterChange(
    e: React.ChangeEvent<{ name?: string; value: any }>
  ) {
    console.log(e.target.name, e.target.value)
    setFilter({
      ...filter,
      [e.target.name!!]: e.target.value,
    })
  }
  const handleFilterClear = () => {
    setFilter(filterInitState)
  }
  const getDisplayOrders = (): OrderBean[] => {
    return orders

      .filter(value => {
        if (filter['product'] === '') return true
        return filter['product'] === value.product
      })
      .filter(value => {
        if (filter['productCategory'] === '') return true
        return filter['productCategory'] === value.productCategory
      })
      .filter(value => {
        if (filter['productPackage'] === '') return true
        return filter['productPackage'] === value.productPackage
      })
      .filter(value => {
        if (filter['agent'] === '') return true
        return filter['agent'] === value.refUser.username
      })

      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  }

  useEffect(() => {
    OrderService.findAll()
      .then(value =>
        setOrders(
          value.data.filter(
            value1 => value1.orderStatus === OrderStatus.POST_COMPLETE
          )
        )
      )
      .catch(catchErrorWithDispatch(dispatch))
      .finally()
  }, [dispatch])

  useEffect(() => {
    const productSet = new Set<string>()
    const productCategorySet = new Set<string>()
    const productPackageSet = new Set<string>()
    const agentSet = new Set<string>()
    const statusSet = new Set<string>()

    orders.forEach(value => {
      productSet.add(value.product)
      productCategorySet.add(value.productCategory)
      productPackageSet.add(value.productPackage)
      agentSet.add(value.refUser.username)
      statusSet.add(value.orderStatus)
    })

    setFilterOption({
      product: {
        key: 'product',
        label: 'Product',
        value: [...Array.from(productSet)],
      },
      productCategory: {
        key: 'productCategory',
        label: 'Product category',
        value: [...Array.from(productCategorySet)],
      },
      productPackage: {
        key: 'productPackage',
        label: 'Product package',
        value: [...Array.from(productPackageSet)],
      },
      agent: {
        key: 'agent',
        label: 'Agent',
        value: [...Array.from(agentSet)],
      },
    })
  }, [orders])

  const renderOrder = (order: OrderBean) => {
    const orderBgColor = OrderBgColorByStatus[order.orderStatus]

    return (
      <TableRow
        className={`${orderBgColor} hover:bg-gray-200 cursor-pointer`}
        id={order.id.toString()}
      >
        <TableCell>{order?.fullName}</TableCell>
        <TableCell>{order.productPackage}</TableCell>
        <TableCell>{order.productCategory}</TableCell>
        <TableCell>{order.activatedOn}</TableCell>
        <TableCell>{order.loginId}</TableCell>
        <TableCell>{order.refUser?.fullName}</TableCell>
        <TableCell>{order.documentCompleted}</TableCell>

        <TableCell>{'PAYMENT VOUCHER NO'}</TableCell>
        <TableCell>{'COMM VALUE'}</TableCell>
        <TableCell>{'AGENT COMM %'}</TableCell>
        <TableCell>{'AGENT COMM RTM'}</TableCell>
        <TableCell>{'PAID ON'}</TableCell>
      </TableRow>
    )
  }
  return (
    <div>
      <AppBar position='relative'>
        <Toolbar className='space-x-3'>
          <Typography variant='h6' noWrap>
            Order Management
          </Typography>
        </Toolbar>
      </AppBar>
      <Card className='m-4'>
        <CardHeader title='Filter' />
        <CardContent className='grid grid-cols-4 gap-4'>
          {Object.entries(filterOption).map(([k, filterDetail]) => {
            return (
              <FormControl key={k}>
                <InputLabel id={k}>{filterDetail.label}</InputLabel>
                <Select
                  id={k}
                  name={k}
                  value={filter[k]}
                  onChange={handleFilterChange}
                >
                  <MenuItem value={''}>
                    <em>None</em>
                  </MenuItem>
                  {filterDetail.value.map(name => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )
          })}
        </CardContent>
        <CardActions className='justify-end'>
          <Button variant='outlined' onClick={handleFilterClear}>
            Clear
          </Button>
        </CardActions>
      </Card>
      <div className='m-4'>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {toDisplayList.map(text => (
                  <TableCell id={text}>{text}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {getDisplayOrders().map(value => renderOrder(value))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={rowPerPageOptions}
            component='div'
            count={orders.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
    </div>
  )
}

export default CompletedOrderPage
