import React, { useMemo, useState } from 'react'
import userProfileService from '../../../common/service/userProfileService'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { Permission } from '../../../common/constant/permission'
import UserUpdatePermissionDialog from '../dialog/userUpdatePermission.dialog'
import UserBean from '../../../sharePortal/userManagement/bean/userBean'
import UpdateRoleDialog from '../dialog/updateRole.dialog'
import tokenService from '../../../common/service/tokenService'
import { AlertDialogAction } from '../../../redux/reducer/alertDialogReducer'
import { AuthenticationService } from '../../../preLogin/service/authenticationService'
import { SnackbarAction } from '../../../redux/reducer/snackbarReducer'
import { catchErrorWithDispatch } from '../../../common/ApiUtils'
import { useDispatch } from 'react-redux'
import { UserActions } from '../../../redux/reducer/userReducer'

interface AdminActionsMenuProps {
  user: UserBean
}

const AdminActionsMenuComponent = (props: AdminActionsMenuProps) => {
  const { user } = props
  const dispatch = useDispatch()

  const [selectedAgent, setSelectedAgent] = useState(false)

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [updatePermission, setUpdatePermission] = useState(false)

  const handleClick = (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const permissions = useMemo(() => {
    return tokenService.getPermissions()
  }, [])

  function handleDisableUser() {
    dispatch(
      AlertDialogAction.open(
        `Disable User`,
        `Are you sure to disable ${user?.fullName}`,
        () => {
          if (user?.id) {
            AuthenticationService.disableUser(user?.id)
              .then(() => {
                dispatch(SnackbarAction.open('Success Enable User'))
                dispatch(UserActions.getAdmins())
              })
              .catch(catchErrorWithDispatch(dispatch))
          }
        }
      )
    )
  }

  function handleEnableUser() {
    dispatch(
      AlertDialogAction.open(
        `Enable User`,
        `Are you sure to enable ${user?.fullName}`,
        () => {
          if (user?.id) {
            AuthenticationService.verifyUser(user?.id)
              .then(() => {
                dispatch(SnackbarAction.open('Success Enable User'))
                dispatch(UserActions.getAdmins())
              })
              .catch(catchErrorWithDispatch(dispatch))
          }
        }
      )
    )
  }

  return (
    <>
      <IconButton
        aria-controls='admin-actions-menu'
        aria-haspopup='true'
        onClick={handleClick}
        color={'inherit'}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id='admin-actions-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          disabled={!userProfileService.isManager()}
          onClick={() => setSelectedAgent(true)}
        >
          Update Role
        </MenuItem>
        <MenuItem
          onClick={() => setUpdatePermission(true)}
          disabled={
            ![
              Permission.PERMISSION_ASSIGN,
              Permission.PERMISSION_REMOVE,
            ].some(o => permissions.includes(o))
          }
        >
          Update Permission
        </MenuItem>

        {user?.enabled ? (
          <MenuItem
            onClick={handleDisableUser}
            disabled={!userProfileService.isManager()}
          >
            Disable User
          </MenuItem>
        ) : (
          <MenuItem
            onClick={handleEnableUser}
            disabled={!userProfileService.isManager()}
          >
            Enable User
          </MenuItem>
        )}
      </Menu>

      <UpdateRoleDialog
        agent={selectedAgent ? user : undefined}
        onClose={() => {
          setSelectedAgent(false)
        }}
      />
      <UserUpdatePermissionDialog
        agentId={user.id}
        open={updatePermission}
        onDialogClose={() => {
          setUpdatePermission(false)
        }}
      />
    </>
  )
}

export default AdminActionsMenuComponent
