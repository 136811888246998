import { ReduxReducerAction } from '../index'
import UserBean from '../../../sharePortal/userManagement/bean/userBean'
import { CommissionQuota } from '../../../adminPortal/userManagement/bean/commissionQuota'
import { AppThunk } from '../../store'
import AgentService from '../../../adminPortal/userManagement/service/agentService'
import { catchErrorWithDispatch } from '../../../common/ApiUtils'
import UserRole from '../../../common/constant/userRole'

export interface ProfileDetailPageProps {
  profile?: UserBean
  subAgents: UserBean[]
  principle?: UserBean
  commissions: Record<string, CommissionQuota[]>
  selectedTab: string
  haveUnsetCommission: boolean
}

const ActionType = {
  SET_PROFILE: `[ProfileDetailPage] SET PROFILE`,
  SET_COMMISSIONS: `[ProfileDetailPage] SET COMMISSIONS`,
  SET_PRINCIPLE: `[ProfileDetailPage] SET PRINCIPLE`,
  SET_SELECTED_TAB: `[ProfileDetailPage] SET SELECTED TAB`,
  SET_SUB_AGENTS: `[ProfileDetailPage] SET SUB AGENTS`,
  SET_HAVE_UNSET_COMMISSION: `[ProfileDetailPage] SET HAVE UNSET COMMISSION`,
}

const initialState: ProfileDetailPageProps = {
  profile: undefined,
  subAgents: [],
  principle: undefined,
  commissions: {},
  selectedTab: '',
  haveUnsetCommission: false,
}

const ProfileDetailPageReducer = (
  state = initialState,
  { payload, type }: ReduxReducerAction
) => {
  switch (type) {
    case ActionType.SET_PROFILE:
      return {
        ...state,
        profile: payload,
      }
    case ActionType.SET_SUB_AGENTS:
      return {
        ...state,
        subAgents: payload,
      }
    case ActionType.SET_COMMISSIONS:
      return {
        ...state,
        commissions: payload,
      }
    case ActionType.SET_PRINCIPLE:
      return {
        ...state,
        principle: payload,
      }
    case ActionType.SET_SELECTED_TAB:
      return {
        ...state,
        selectedTab: payload,
      }

    case ActionType.SET_HAVE_UNSET_COMMISSION:
      return {
        ...state,
        haveUnsetCommission: payload,
      }

    default:
      return state
  }
}

export const ProfileDetailPageActions = {
  setProfile(profile: UserBean): AppThunk {
    return async dispatch => {
      // Add profile to Store
      dispatch({
        type: ActionType.SET_PROFILE,
        payload: profile,
      })

      // Get Commission Rate if is agent
      if (profile && UserRole.isAgent(profile.role) && profile.id) {
        const { id } = profile
        AgentService.getAgentCommission(id, undefined)
          .then(value => {
            dispatch(ProfileDetailPageActions.setCommissions(value.data))
            dispatch(
              ProfileDetailPageActions.setHaveUnsetCommission(
                value.data.some(value => value.percentage <= 0)
              )
            )
          })
          .catch(catchErrorWithDispatch(dispatch))

        AgentService.getSubAgents(id)
          .then(resp =>
            dispatch(ProfileDetailPageActions.setSubAgents(resp.data))
          )
          .catch(catchErrorWithDispatch(dispatch))
      }

      // Get Get Principle Id
      if (profile?.superiorId) {
        const { superiorId } = profile
        AgentService.findById(superiorId)
          .then(value =>
            dispatch(ProfileDetailPageActions.setPrinciple(value.data))
          )
          .catch(catchErrorWithDispatch(dispatch))
      } else {
        dispatch(ProfileDetailPageActions.setPrinciple(undefined))
      }
    }
  },

  setSubAgents(subAgents: UserBean[]) {
    return {
      type: ActionType.SET_SUB_AGENTS,
      payload: subAgents,
    }
  },

  setCommissions(commissions: CommissionQuota[]) {
    return {
      type: ActionType.SET_COMMISSIONS,
      payload: commissions.reduce(groupCommissionQuotaByCategoryName, {}),
    }
  },

  setPrinciple(principle?: UserBean) {
    return {
      type: ActionType.SET_PRINCIPLE,
      payload: principle,
    }
  },

  setSelectedTab(tabName: string) {
    return {
      type: ActionType.SET_SELECTED_TAB,
      payload: tabName,
    }
  },
  setHaveUnsetCommission(haveUnsetCommission: boolean) {
    return {
      type: ActionType.SET_HAVE_UNSET_COMMISSION,
      payload: haveUnsetCommission,
    }
  },
}
export default ProfileDetailPageReducer

function groupCommissionQuotaByCategoryName(
  all: Record<string, CommissionQuota[]>,
  cur: CommissionQuota
): Record<string, CommissionQuota[]> {
  const categoryCommissions = all[cur.categoryName] || []

  return {
    ...all,
    [cur.categoryName]: [...categoryCommissions, cur],
  }
}
