import client, { AxiosResponse } from 'axios'
import { LandingPageAttachmentsBean } from '../bean/landingPageAttachmentsBean'

const url = '/api/v1/landing-page-attachments'

export default class LandingPageAttachmentsService {
  static findAll(): Promise<AxiosResponse<LandingPageAttachmentsBean[]>> {
    return client.get(url)
  }

  static create(
    name: string
  ): Promise<AxiosResponse<LandingPageAttachmentsBean>> {
    return client.post(url, { name })
  }

  static addAttachment(id: number, file: File): Promise<AxiosResponse> {
    const formData = new FormData()
    formData.append('file', file)

    return client.patch(`${url}/${id}/attachment`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
  }

  static deleteById(
    id: number
  ): Promise<AxiosResponse<LandingPageAttachmentsBean>> {
    return client.delete(url + '/' + id)
  }
}
