import React from 'react'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { UserCodeBean } from '../../../common/service/UserCodeBean'
import { useTypedSelector } from '../../../redux/reducer'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import ClaimStatus from '../../../common/constant/claimStatus'
import { DatePicker } from '@material-ui/pickers'
import { useHistory } from 'react-router'
import CommonUtils from '../../../common/commonUtils'
import userProfileService from '../../../common/service/userProfileService'
import { Autocomplete } from '@material-ui/lab'
import UserBean from '../../../sharePortal/userManagement/bean/userBean'
import { ClaimFilterProps } from '../bean/claimFilterProps'
import { useClaimQs } from '../hook/useClaimQs'

interface ClaimFilterComponentProps {
  agents: UserBean[]
  filter: ClaimFilterProps
}
const ClaimFilterComponent = ({
  agents,
  filter,
}: ClaimFilterComponentProps) => {
  const history = useHistory()

  const userCode: UserCodeBean = useTypedSelector(
    state => state.portalSetting.userCode
  )

  const [, setFilter] = useClaimQs()

  const handleFilterChange = (name: string, value: string) => {
    setFilter(name, value)
  }

  const handleFilterDateChange = (
    name: string,
    newDate: MaterialUiPickersDate | null
  ) => {
    handleFilterChange(name, newDate?.toDateString() || '')
  }

  const resetFilter = () => {
    history.replace({
      search: ``,
    })
  }

  return (
    <Card>
      <CardHeader title='Filter' />
      <CardContent className='grid gap-4 grid-cols-4'>
        <DatePicker
          disableToolbar
          format='dd MMM yyyy'
          id='submitDateFrom'
          name='submitDateFrom'
          label='Submit Date From'
          value={filter['submitDateFrom']}
          onChange={(e: MaterialUiPickersDate) => {
            handleFilterDateChange('submitDateFrom', e)
          }}
        />
        <DatePicker
          disableToolbar
          format='dd MMM yyyy'
          id='submitDateTo'
          name='submitDateTo'
          label='Submit Date To'
          value={filter['submitDateTo']}
          onChange={(e: MaterialUiPickersDate) => {
            handleFilterDateChange('submitDateTo', e)
          }}
        />

        <DatePicker
          disableToolbar
          format='dd MMM yyyy'
          id='completeDateFrom'
          name='completeDateFrom'
          label='Complete Date From'
          value={filter['completeDateFrom']}
          onChange={(e: MaterialUiPickersDate) => {
            handleFilterDateChange('completeDateFrom', e)
          }}
        />
        <DatePicker
          disableToolbar
          format='dd MMM yyyy'
          id='completeDateTo'
          name='completeDateTo'
          label='Complete Date To'
          value={filter['completeDateTo']}
          onChange={(e: MaterialUiPickersDate) => {
            handleFilterDateChange('completeDateTo', e)
          }}
        />

        <FormControl>
          <InputLabel id='claimStatusFilter'>Status</InputLabel>
          <Select
            id='claimStatusFilter'
            name='claimStatusFilter'
            label='claimStatusFilter'
            value={filter['claimStatusFilter']}
            onChange={e =>
              handleFilterChange('claimStatusFilter', String(e.target.value))
            }
          >
            <MenuItem value={''}>
              <em>None</em>
            </MenuItem>

            {Object.entries(ClaimStatus.toDisplay).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Autocomplete
          className='col-span-3'
          renderInput={params => (
            <TextField
              {...params}
              label='Agent'
              inputProps={{ ...params.inputProps }}
            />
          )}
          getOptionLabel={option => {
            const prefix = CommonUtils.getUserCode(option, userCode)
            const { fullName, username, id } = option

            const displayName =
              option.companyName && option.companyRegNo
                ? `${option.companyName} (${option.companyRegNo})`
                : `${fullName} (${username})`

            const suffix =
              userProfileService.getUserProfile()?.id === id ? '[YOURSELF]' : ''

            return `[${prefix}] ${displayName} ${suffix}`
          }}
          options={agents}
          value={
            agents.find(
              v => v.id.toString() === filter.agentIdFilter.toString()
            ) || null
          }
          onChange={(event, value) => {
            if (value) {
              handleFilterChange('agentIdFilter', value.id.toString())
            } else {
              handleFilterChange('agentIdFilter', '')
            }
          }}
        />
      </CardContent>
      <CardActions className='justify-end'>
        <Button variant='outlined' onClick={resetFilter}>
          Clear
        </Button>
      </CardActions>
    </Card>
  )
}

export default ClaimFilterComponent
