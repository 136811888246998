import React, { useState } from 'react'
import AppTitleBar from '../../component/appTitleBar'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  TextField,
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { LoadingAction } from '../../redux/reducer/loadingBackdropReducer'
import { SnackbarAction } from '../../redux/reducer/snackbarReducer'
import { catchErrorWithDispatch } from '../../common/ApiUtils'
import { AgentStaffService } from '../../adminPortal/userManagement/service/agentStaffService'
import { ROUTES } from '../../common/constant/pageKeyConstant'

const AgentStaffCreatePage = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const initialState = {
    username: '',
    password: '',
    fullName: '',
    email: '',
  }
  const [formState, setFormState] = useState(initialState)

  const onSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    dispatch(LoadingAction.open('Creating New Agent Staff...'))
    AgentStaffService.register({
      email: formState.email.trim(),
      fullName: formState.fullName.trim(),
      password: formState.password.trim(),
      username: formState.username.trim(),
    })
      .then(() => {
        dispatch(SnackbarAction.open('Success Register'))
        history.replace('/agent-portal/' + ROUTES.agentStaffListing())
      })
      .catch(catchErrorWithDispatch(dispatch))
      .finally(() => {
        dispatch(LoadingAction.close())
      })
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    })
  }

  function onResetClick() {
    setFormState(initialState)
  }

  return (
    <React.Fragment>
      <AppTitleBar title='Create Agent Staff' hasBackButton />
      <Card className='m-8'>
        <form onSubmit={onSubmitForm}>
          <CardHeader title={'New Staff'} />

          <CardContent className='grid grid-cols-2 gap-4'>
            <TextField
              onChange={handleChange}
              value={formState['username']}
              required
              id='username'
              name='username'
              label='Username'
              helperText='Your login username'
              fullWidth
            />

            <TextField
              onChange={handleChange}
              value={formState['password']}
              required
              id='password'
              name='password'
              label='Password'
              helperText='Your login password'
              type='password'
              fullWidth
            />
            <TextField
              onChange={handleChange}
              value={formState['fullName']}
              required
              id='fullName'
              name='fullName'
              label='Full Name'
              helperText='Name as NRIC'
              fullWidth
            />

            <TextField
              onChange={handleChange}
              value={formState['email']}
              required
              id='email'
              name='email'
              label='Email'
              helperText='Please use actual email, we will sent you activation code'
              type='email'
              fullWidth
            />
          </CardContent>
          <CardActions className='justify-end'>
            <Button
              variant='outlined'
              color='secondary'
              size='large'
              onClick={onResetClick}
            >
              Reset
            </Button>
            <Button
              variant='contained'
              color='primary'
              size='large'
              type='submit'
            >
              Submit
            </Button>
          </CardActions>
        </form>
      </Card>
    </React.Fragment>
  )
}

export default AgentStaffCreatePage
