import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  TablePagination,
} from '@material-ui/core'
import { OrderBean } from '../../../sharePortal/orderManagement/bean/orderBean'
import { useTypedSelector } from '../../../redux/reducer'
import qs from 'qs'
import { useHistory, useLocation } from 'react-router-dom'

const EmptyEformComponent = (props: { orders: OrderBean[] }) => {
  const { orders } = props
  const history = useHistory()
  const location = useLocation()

  const portalSettingReducer = useTypedSelector(state => state.portalSetting)
  const rowPerPageOptions = portalSettingReducer.paginator.rowPerPageOptions

  const [eformPage, setEformPage] = useState(0)

  const { eformPageNumber = 0 } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  const [eformRowsPerPage, setEformNoRowsPerPage] = React.useState(
    portalSettingReducer.paginator.defaultRowPerPage
  )
  const handleEformChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEformNoRowsPerPage(parseInt(event.target.value, 10))
    handleEformChangePage(undefined, 0)
  }

  const handleEformChangePage = (event: unknown, newPage: number) => {
    const query: Record<any, any> = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    })
    query['eformPageNumber'] = newPage
    const newQuery = qs.stringify(query)
    history.replace({
      search: `?${newQuery}`,
    })
  }

  useEffect(() => {
    if (orders.length) {
      setEformPage(Number(eformPageNumber))
    }
  }, [eformPageNumber, orders])

  return (
    <Card>
      <CardHeader title={'Empty E-Form'} />
      <CardContent>
        <div>
          {orders
            .slice(
              eformPage * eformRowsPerPage,
              eformPage * eformRowsPerPage + eformRowsPerPage
            )
            .map(value => (
              <Button
                key={value.id}
                variant={'outlined'}
                onClick={() =>
                  history.push(`/admin-portal/order-detail/${value.id}`)
                }
              >
                {value.id.toString()}
              </Button>
            ))}
        </div>

        <TablePagination
          className='w-full'
          rowsPerPageOptions={rowPerPageOptions}
          component='div'
          count={orders.length}
          rowsPerPage={eformRowsPerPage}
          page={eformPage}
          onChangePage={handleEformChangePage}
          onChangeRowsPerPage={handleEformChangeRowsPerPage}
        />
      </CardContent>
    </Card>
  )
}

export default EmptyEformComponent
