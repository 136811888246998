import UserRole from '../constant/userRole'
import UserBean from '../../sharePortal/userManagement/bean/userBean'

class UserProfileService {
  USER_PROFILE_KEY = 'USER_PROFILE'

  setUserProfile = (userProfile: UserBean) => {
    sessionStorage.setItem(this.USER_PROFILE_KEY, JSON.stringify(userProfile))
  }
  getUserProfile = (): UserBean | null => {
    const item = sessionStorage.getItem(this.USER_PROFILE_KEY)
    return item ? (JSON.parse(item) as UserBean) : null
  }

  isAgent = (): boolean => {
    return UserRole.isAgent(this.getUserProfile()?.role)
  }

  isTopAgent = (): boolean => {
    const user = this.getUserProfile()
    return user?.role === 'ROLE_AGENT' && !user?.superiorId
  }

  isManager = (): boolean => UserRole.isManager(this.getUserProfile()?.role)

  isAdmin = (): boolean => UserRole.isAdmin(this.getUserProfile()?.role)

  isStaff = (): boolean => UserRole.isStaff(this.getUserProfile()?.role)

  isAgentStaff = (): boolean =>
    UserRole.isAgentStaff(this.getUserProfile()?.role)

  getCurrentUserId = (): number => {
    return this.getUserProfile()?.id || 0
  }
}

const userProfileService = new UserProfileService()
export default userProfileService
