import client, { AxiosResponse } from 'axios'
import { Customer } from '../enquiry/bean/customer'
import { CustomerPostBody } from './bean/customerPostBody'

const url = `/api/v1/customers`
class CustomerService {
  static getCustomerByNric(nric: string): Promise<AxiosResponse<Customer>> {
    return client.get(`${url}/nric/${nric}`)
  }

  static postCustomer(
    body: CustomerPostBody
  ): Promise<AxiosResponse<Customer>> {
    return client.post(`${url}`, body)
  }
}

export default CustomerService
