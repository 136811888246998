import React, { useState } from 'react'
import {
  AppBar,
  Button,
  Card,
  CardActions,
  CardContent,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { ClaimBean } from './bean/claimBean'
import { catchErrorWithDispatch } from '../../common/ApiUtils'
import { useDispatch } from 'react-redux'
import ClaimTableComponent from './component/claimTableComponent'
import { WithdrawalBean } from './bean/WithdrawalBean'
import WithdrawalSearchTableComponent from './component/withdrawalSearchTableComponent'
import { Alert, TabContext, TabPanel } from '@material-ui/lab'
import { SearchService } from './service/search.service'

const SearchClaimPage = () => {
  const CLAIM = 'CLAIM'
  const WITHDRAWAL = 'WITHDRAWAL'

  const dispatch = useDispatch()
  const [claims, setClaims] = useState<ClaimBean[]>([])
  const [withdrawals, setWithdrawals] = useState<WithdrawalBean[]>([])
  const [searchString, setSearchString] = useState('')
  const [noResult, setNoResult] = useState(false)
  const [tabIndex, setTabIndex] = useState(CLAIM)

  function handleSearch() {
    if (noResult) {
      setNoResult(false)
    }
    SearchService.searchWithdrawalAndClaim(searchString)
      .then(value => {
        const { withdrawals, claims } = value.data

        setWithdrawals(withdrawals)
        setClaims(claims)

        if (!withdrawals.length && !claims.length) {
          setNoResult(true)
        }
      })
      .catch(catchErrorWithDispatch(dispatch))
  }

  return (
    <TabContext value={tabIndex}>
      <AppBar position='relative'>
        <Toolbar className='space-x-3'>
          <Typography variant='h6' noWrap>
            Search Claim and Withdrawal
          </Typography>
        </Toolbar>
      </AppBar>

      {noResult && (
        <Alert severity='warning'>
          <span>No Result on Search: {searchString}</span>
        </Alert>
      )}

      <div className='m-4'>
        <Card>
          <CardContent>
            <TextField
              label={
                'Transaction Detail, Order No, NRIC, Account No or Customer Full Name'
              }
              value={searchString}
              onChange={e => {
                setSearchString(e.target.value)
              }}
              fullWidth={true}
            />
          </CardContent>
          <CardActions>
            <Button
              onClick={handleSearch}
              color={'primary'}
              variant={'contained'}
              fullWidth={true}
            >
              Search
            </Button>
          </CardActions>
        </Card>
      </div>

      {(!!claims.length || !!withdrawals.length) && (
        <div>
          <Tabs
            value={tabIndex}
            onChange={(event, value) => {
              setTabIndex(value)
            }}
          >
            <Tab label={CLAIM} value={CLAIM} />
            <Tab label={WITHDRAWAL} value={WITHDRAWAL} />
          </Tabs>

          <TabPanel value={CLAIM}>
            <ClaimTableComponent claims={claims} />
          </TabPanel>
          <TabPanel value={WITHDRAWAL}>
            <WithdrawalSearchTableComponent withdrawals={withdrawals} />
          </TabPanel>
        </div>
      )}
    </TabContext>
  )
}

export default SearchClaimPage
