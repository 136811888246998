import React, { useState } from 'react'

import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import { NavLink } from 'react-router-dom'

interface NavItem {
  key: string
  name: string
  url: string
}

interface NavListItemProps {
  title: String
  icon: React.ReactNode
  navItems: NavItem[]
}

const NavListItem = ({ title, icon, navItems }: NavListItemProps) => {
  const [open, setOpen] = useState(true)
  if (!navItems.length) return <></>
  else
    return (
      <>
        <ListItem button onClick={() => setOpen(!open)}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={title} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {navItems.map(value => {
              return (
                <NavLink
                  key={value.name}
                  to={value.url}
                  activeClassName='text-indigo-600 italic'
                >
                  <ListItem button>
                    <ListItemText inset primary={value.name} />
                  </ListItem>
                </NavLink>
              )
            })}
          </List>
        </Collapse>
        <Divider />
      </>
    )
}

export default NavListItem
