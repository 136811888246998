import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import AgentService from '../service/agentService'
import UserBean from '../../../sharePortal/userManagement/bean/userBean'
import { catchErrorWithDispatch } from '../../../common/ApiUtils'
import { useDispatch } from 'react-redux'
import SelectAgentTextV2Component from '../../../agentPortal/orderManagement/component/selectAgentTextV2.component'
import { AlertDialogAction } from '../../../redux/reducer/alertDialogReducer'
import { LoadingAction } from '../../../redux/reducer/loadingBackdropReducer'
import { SnackbarAction } from '../../../redux/reducer/snackbarReducer'

interface ChangeSuperiorDialog {
  open: boolean
  agent: UserBean | undefined
  onDialogClose: () => void
  onSuccess: () => void
}

const ChangeSuperiorDialog = ({
  open,
  onDialogClose,
  agent,
  onSuccess,
}: ChangeSuperiorDialog) => {
  const dispatch = useDispatch()

  const [users, setUsers] = useState<UserBean[] | undefined>()
  const [selectedUser, setSelectedUsers] = useState<UserBean | undefined>(
    undefined
  )

  const handleUpdate = () => {
    function getDisplayName(o: UserBean | undefined) {
      if (o) {
        const last = o.companyName ? ` (${o.companyName})` : ''
        return o.fullName + last
      } else {
        return 'NO SUPERIOR'
      }
    }

    const find = users?.find(users => users.id === agent?.superiorId)
    dispatch(
      AlertDialogAction.open(
        <>Update Superior</>,
        <>
          Are you sure to update Superior from
          <div>
            <span className='font-bold'>{getDisplayName(find)}</span>
          </div>
          <div>to</div>
          <div>
            <span className='font-bold'>{getDisplayName(selectedUser)}</span>?
          </div>
        </>,
        () => {
          if (agent?.id) {
            handleDialogClose()
            dispatch(LoadingAction.open('Updating Superior...'))

            const promise = selectedUser
              ? AgentService.patchAgent(agent?.id, {
                  superiorId: selectedUser?.id?.toString(),
                })
              : AgentService.deleteSuperior(agent?.id)

            promise
              .then(() => {
                onSuccess()
                dispatch(SnackbarAction.open('Success Update Superior'))
              })
              .catch(catchErrorWithDispatch(dispatch))
              .finally(() => dispatch(LoadingAction.close()))
          }
        }
      )
    )
  }

  const handleDialogClose = () => {
    setUsers(undefined)
    setSelectedUsers(undefined)
    onDialogClose()
  }

  useEffect(() => {
    if (open && agent?.id) {
      AgentService.getAvailableSuperior(agent.id)
        .then(value => value.data)
        .then(setUsers)
        .catch(catchErrorWithDispatch(dispatch))
    }
  }, [agent, dispatch, open])

  useEffect(() => {
    if (open && agent?.superiorId) {
      const find = users?.find(users => users.id === agent?.superiorId)
      console.log(find)
      setSelectedUsers(find)
    }
  }, [agent, open, users])
  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      fullWidth={true}
      maxWidth={'md'}
    >
      <DialogTitle>Update Principle for Agent {agent?.fullName}</DialogTitle>
      <DialogContent>
        <SelectAgentTextV2Component
          selectedUser={selectedUser}
          setUser={user => setSelectedUsers(user || undefined)}
          users={users}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant={'outlined'}
          color={'default'}
          onClick={handleDialogClose}
        >
          Cancel
        </Button>
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={handleUpdate}
          disabled={
            (!agent?.superiorId && !selectedUser?.id) ||
            agent?.superiorId === selectedUser?.id
          }
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ChangeSuperiorDialog
