import { AppThunk } from '../store'
import { ProductCategory } from '../../adminPortal/productCategoryManagement/bean/productCategory'
import { ProductCategoryPostBody } from '../../adminPortal/productCategoryManagement/bean/productCategoryPostBody'
import ProductCategoryService from '../../adminPortal/productCategoryManagement/productCagetoryService'
import { SnackbarAction } from './snackbarReducer'
import { LoadingAction } from './loadingBackdropReducer'
import { catchErrorWithDispatch } from '../../common/ApiUtils'

const initialState: { categories: ProductCategory[] } = {
  categories: [],
}

const actionType = {
  GET_PRODUCT_PACKAGE_SUCCESS: 'GET_PRODUCT_CATEGORY_SUCCESS',
}

const productCategoryReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case actionType.GET_PRODUCT_PACKAGE_SUCCESS:
      return {
        ...state,
        categories: [...action.payload],
      }
    default:
      return state
  }
}

export default productCategoryReducer

export const ProductCategoryAction = {
  getCategorySuccess: (payload: ProductCategory[]) => {
    return {
      type: actionType.GET_PRODUCT_PACKAGE_SUCCESS,
      payload: payload,
    }
  },

  getCategory: (): AppThunk => async dispatch => {
    ProductCategoryService.getProductCategories()
      .then(value => {
        const data: ProductCategory[] = value.data

        dispatch(ProductCategoryAction.getCategorySuccess(data))
      })
      .catch(catchErrorWithDispatch(dispatch))
  },
  getCategoryByProductId: (productId: number): AppThunk => async dispatch => {
    ProductCategoryService.getProductCategoriesByProductId(productId)
      .then(value => {
        const data: ProductCategory[] = value.data

        dispatch(ProductCategoryAction.getCategorySuccess(data))
      })
      .catch(catchErrorWithDispatch(dispatch))
  },
  postCategory: (body: ProductCategoryPostBody): AppThunk => async dispatch => {
    ProductCategoryService.postProductCategory(body)
      .then(() => {
        dispatch(ProductCategoryAction.getCategoryByProductId(body.productId))
      })
      .catch(catchErrorWithDispatch(dispatch))
  },
  deleteCategory: (
    id: number,
    productId?: number
  ): AppThunk => async dispatch => {
    dispatch(LoadingAction.open('Deleting Product Category...'))

    ProductCategoryService.deleteProductCategory(id)
      .then(() => {
        dispatch(SnackbarAction.open('Success Delete Category'))

        dispatch(
          productId
            ? ProductCategoryAction.getCategoryByProductId(productId)
            : ProductCategoryAction.getCategory()
        )
      })
      .catch(catchErrorWithDispatch(dispatch))
      .finally(() => dispatch(LoadingAction.close()))
  },
}
