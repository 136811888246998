import React, { useEffect, useMemo } from 'react'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import { ProductAction } from '../../redux/reducer/productReducer'
import { useTypedSelector } from '../../redux/reducer'
import { useDispatch } from 'react-redux'
import { Product } from '../../adminPortal/productManagement/bean/product'
import { ProductCategoryAction } from '../../redux/reducer/productCategoryReducer'
import { ProductPackageAction } from '../../redux/reducer/productPackageReducer'
import { ProductCategory } from '../../adminPortal/productCategoryManagement/bean/productCategory'
import { ProductPackage } from '../../adminPortal/productPackageManagement/bean/productPackage'

interface ProductSelectionComponentProps {
  onProductChange: (value: Product | null) => void
  onCategoryChange: (value: ProductCategory | null) => void
  onPackageChange: (value: ProductPackage | null) => void
  productValue: Product | null
  categoryValue: ProductCategory | null
  packageValue: ProductPackage | null
  disabled: boolean
}

const ProductSelectionComponent = (props: ProductSelectionComponentProps) => {
  const {
    categoryValue,
    packageValue,
    productValue,
    onCategoryChange,
    onPackageChange,
    onProductChange,
    disabled,
  } = props

  const dispatch = useDispatch()
  const products: Product[] = useTypedSelector(state => state.product.products)
  const categories: ProductCategory[] = useTypedSelector(
    state => state.productCategory.categories
  )
  const packages: ProductPackage[] = useTypedSelector(
    state => state.productPackage.packages
  )
  const packageShowDescription: boolean = useTypedSelector(
    state => state.portalSetting.order.packageShowDescription
  )

  function handleProductChange(value: Product | null) {
    onProductChange(value)
  }

  function handleCategoryChange(value: ProductCategory | null) {
    onCategoryChange(value)
  }

  function handlePackageChange(value: ProductPackage | null) {
    onPackageChange(value)
  }

  const displayCategory = useMemo(() => {
    if (productValue) {
      return categories.filter(value => value.productId === productValue?.id)
    } else return []
  }, [categories, productValue])

  const displayPackage = useMemo(() => {
    if (categoryValue) {
      return packages.filter(value => value.categoryId === categoryValue.id)
    } else return []
  }, [categoryValue, packages])

  useEffect(() => {
    dispatch(ProductAction.getProduct())
    dispatch(ProductCategoryAction.getCategory())
    dispatch(ProductPackageAction.getPackage())
  }, [dispatch])

  return (
    <div className='grid-cols-3 grid gap-4'>
      <Autocomplete
        disabled={disabled}
        renderInput={params => (
          <TextField
            {...params}
            label='Product'
            inputProps={{ ...params.inputProps }}
            required
          />
        )}
        options={products}
        getOptionLabel={option => option.name}
        value={productValue}
        onChange={(event, value) => handleProductChange(value)}
      />
      <Autocomplete
        disabled={!displayCategory.length || disabled}
        renderInput={params => (
          <TextField
            {...params}
            label='Product Category'
            inputProps={{ ...params.inputProps }}
            required
          />
        )}
        options={displayCategory}
        getOptionLabel={option => option.name}
        value={categoryValue}
        onChange={(event, value) => handleCategoryChange(value)}
      />
      <Autocomplete
        disabled={!displayPackage.length || disabled}
        renderInput={params => (
          <TextField
            {...params}
            label='Product Package'
            inputProps={{ ...params.inputProps }}
            required
          />
        )}
        options={displayPackage}
        getOptionLabel={option =>
          (packageShowDescription ? option.description : option.name) ||
          option.description ||
          option.name ||
          'EMPTY'
        }
        value={packageValue}
        onChange={(event, value) => handlePackageChange(value)}
      />
    </div>
  )
}

export default ProductSelectionComponent
