import React from 'react'
import { FileUtils } from '../../common/fileUtils'
import { FileUtilError } from '../../common/error/fileUtilError'
import { AlertDialogAction } from '../../redux/reducer/alertDialogReducer'
import { useAppDispatch } from '../../redux/store'

interface Props {

  multiple?: boolean
  onChange: (files: File[]) => void
}

const AppFileInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const dispatch = useAppDispatch()

  return (
    <input
      ref={ref}
      type='file'
      multiple={props.multiple}
      accept='image/*,application/pdf'
      onChange={event => {
        try {
          const files = FileUtils.handleFileChange(event)
          props.onChange(files)
        } catch (error) {
          if (error instanceof FileUtilError) {
            event.target.value = ''
            dispatch(AlertDialogAction.open(error.title, error.message))
          }
        }
      }}
    />
  )
})

export default AppFileInput
