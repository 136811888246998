import React, { useMemo, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import { useAppDispatch } from '../../redux/store'
import { useTypedSelector } from '../../redux/reducer'
import { OrderBean } from './bean/orderBean'
import { TemplateUtils } from '../../features/template/template.util'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import CloseIcon from '@material-ui/icons/Close'
import { SnackbarAction } from '../../redux/reducer/snackbarReducer'
import { Sms } from '@material-ui/icons'
import _ from 'lodash/fp'

function SelectTemplateDialog(props: { order?: OrderBean }) {
  const dispatch = useAppDispatch()
  const { order } = props
  const [open, setOpen] = useState(false)
  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)
  const [templateId, setTemplateId] = useState(0)
  const [selectCategory, setSelectCategory] = useState('ALL')
  const templates = useTypedSelector(state => state.templates.templates)
  const isLoading = useTypedSelector(state => state.templates.isLoading)

  function sanitizePhoneNumber(rawPhoneNumber: string): string {
    const withoutDash = rawPhoneNumber.trim().replace('-', '')
    if (withoutDash.startsWith('+601')) {
      return withoutDash.replace('+', '')
    } else if (withoutDash.startsWith('601')) {
      return withoutDash
    } else if (withoutDash.startsWith('01')) {
      return `6${withoutDash}`
    } else {
      throw new Error(`${rawPhoneNumber} is invalid mobile number`)
    }
  }

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(preview)
      .then(() => {
        dispatch(SnackbarAction.open('Success Copy Order Summary'))
      })
      .catch(() => {
        dispatch(
          SnackbarAction.open('Unsuccessful Copy Order Summary', 'warning')
        )
      })
  }
  const sentToWhatsapp = () => {
    if (order?.mobileNo) {
      try {
        const phoneNumber = sanitizePhoneNumber(order?.mobileNo)

        const msg = encodeURI(preview)
        const url = `https://wa.me/${phoneNumber}?text=${msg}`

        window.open(url, '_blank')
      } catch (e) {
        dispatch(SnackbarAction.openError(e.message))
      }
    } else {
      dispatch(SnackbarAction.open('Opss... no mobile number.'))
    }
  }
  const preview = useMemo(() => {
    if (!order) return ''

    const template = templates.find(o => o.id === templateId)
    if (!template) return ''

    return TemplateUtils.parseTemplate(template.content, order)
  }, [order, templateId, templates])

  const templateCategories: string[] = useMemo(() => {
    return _.compose(_.uniq, _.map(_.get('category')))(templates)
  }, [templates])

  const displayTemplates = useMemo(() => {
    if (selectCategory === 'ALL') return templates
    return templates.filter(({ category }) => category === selectCategory)
  }, [selectCategory, templates])

  if (!order) return null

  return (
    <>
      <Button
        onClick={onOpen}
        disabled={isLoading}
        startIcon={<Sms />}
        color={'inherit'}
      >
        Order Summary
      </Button>

      <Dialog open={open} onClose={onClose} maxWidth={'lg'} fullWidth>
        <DialogTitle>Select Template </DialogTitle>

        <DialogContent>
          <FormControl fullWidth>
            <InputLabel id='category'>Select Category</InputLabel>
            <Select
              name='category'
              value={selectCategory}
              onChange={event => {
                setSelectCategory(String(event.target.value))
                setTemplateId(0)
              }}
            >
              <MenuItem value={'ALL'}>ALL</MenuItem>
              {templateCategories.map(o => (
                <MenuItem key={o} value={o}>
                  {o}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id='state'>Select Template</InputLabel>
            <Select
              name='template'
              value={templateId}
              onChange={event => {
                setTemplateId(Number(event.target.value))
              }}
            >
              <MenuItem value={0}>None</MenuItem>
              {displayTemplates.map(o => (
                <MenuItem key={o.id} value={o.id}>
                  {o.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div>
            <div className='py-4 text-lg'>Preview</div>
            <div className='p-2 border whitespace-pre my-2'>{preview}</div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant={'outlined'}
            onClick={onClose}
            startIcon={<CloseIcon />}
          >
            Close
          </Button>
          <Button
            disabled={templateId === 0}
            variant={'contained'}
            color={'primary'}
            startIcon={<FileCopyIcon />}
            onClick={copyToClipboard}
          >
            Copy
          </Button>
          <Button
            disabled={templateId === 0}
            variant={'contained'}
            color={'primary'}
            startIcon={<WhatsAppIcon />}
            onClick={sentToWhatsapp}
          >
            Sent Whatsapp
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SelectTemplateDialog
