import client, { AxiosResponse } from 'axios'
import { LoginResponse } from '../bean/loginResponse'
import { AgentRegisterPostBody } from '../bean/agentRegisterPostBody'
import qs from 'qs'
import { GrantTokenResponse } from '../bean/GrantTokenResponse'
import UserBean from '../../sharePortal/userManagement/bean/userBean'

const url = `/api/v1/auth`
const headers = {
  'Access-Control-Allow-Origin': '*',
}

export class AuthenticationService {
  static login = function (
    username: string,
    password: string
  ): Promise<AxiosResponse<GrantTokenResponse>> {
    return client.post(
      '/oauth/token',
      qs.stringify({
        username,
        password,
        grant_type: 'password',
      }),
      {
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': '*',
        },
        auth: {
          username: 'lionix-portal',
          password: 'lionix-portal-secret',
        },
      }
    )
  }
  static refreshToken = function (
    refreshToken: string
  ): Promise<AxiosResponse<GrantTokenResponse>> {
    return client.post(
      '/oauth/token',
      qs.stringify({
        refresh_token: refreshToken,
        grant_type: 'refresh_token',
      }),
      {
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': '*',
        },
        auth: {
          username: 'lionix-portal',
          password: 'lionix-portal-secret',
        },
      }
    )
  }

  static getSelf = (): Promise<AxiosResponse<UserBean>> => {
    return client.get('/api/v1/users/self')
  }
  static register = function (
    data: AgentRegisterPostBody
  ): Promise<AxiosResponse<{ id: string }>> {
    const sanitisedData = Object.fromEntries(
      Object.entries(data).map(([key, value]) => {
        return [key, value.trim()]
      })
    )
    return client.post(
      url + '/register-agent',
      {
        ...sanitisedData,
      },
      {
        headers,
      }
    )
  }

  static verifyEmail = function (
    token: string
  ): Promise<AxiosResponse<LoginResponse>> {
    return client.post(
      url + '/verify-email',
      {
        token,
      },
      {
        headers,
      }
    )
  }

  static forgetPassword = function (
    username: string
  ): Promise<AxiosResponse<LoginResponse>> {
    return client.get(url + '/reset-password/' + username, {
      headers,
    })
  }
  static resetPassword = function (
    resetCode: string,
    newPassword: string
  ): Promise<AxiosResponse<LoginResponse>> {
    return client.post(
      url + '/reset-password',
      {
        resetCode,
        newPassword,
      },
      {
        headers,
      }
    )
  }

  static verifyUser = function (userId: number): Promise<AxiosResponse> {
    return client.post(url + '/user/' + userId + '/verify')
  }
  static verifyUserAttachment = function (
    userId: number
  ): Promise<AxiosResponse> {
    return client.post(url + '/user/' + userId + '/verify-attachments')
  }
  static disableUser = function (userId: number): Promise<AxiosResponse> {
    return client.post(url + '/user/' + userId + '/disable')
  }
  static deleteUser = function (userId: number): Promise<AxiosResponse> {
    return client.delete(url + '/user/' + userId)
  }
}
