import React, { useEffect, useMemo, useState } from 'react'
import UserBean from '../../sharePortal/userManagement/bean/userBean'
import WithdrawalService from '../../features/claim/service/withdrawalService'
import { WithdrawalBean } from '../../features/claim/bean/WithdrawalBean'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import { useDebounce } from '../../common/useDebounce'
import _ from 'lodash'
import WithdrawalTableForAgent from '../../features/claim/component/withdrawalTableForAgent'
import TransactionType from '../../common/constant/transactionType'

const AgentWithdrawalTab = (props: { user: UserBean }) => {
  const { user } = props

  const [withdrawals, setWithdrawals] = useState<WithdrawalBean[]>([])
  const [filterWord, setFilterWord] = useState('')
  const [debounceWord, setDebounceWord] = useDebounce('', 500)
  const [filterTransactionType, setFilterTransactionType] = useState('')

  useEffect(() => {
    WithdrawalService.findAll({ search: `agentId:${user.id}` })
      .then(result => result.data)
      .then(setWithdrawals)
  }, [user.id])

  const displayWithdrawals = useMemo(() => {
    const generalFiltered =
      debounceWord === ''
        ? withdrawals
        : withdrawals.filter(value =>
            [
              value.description,
              value.refOrderEntity?.productCategory,
              value.refOrderEntity?.productPackage,
              value.refOrderEntity?.orderNo,
              value.refOrderEntity?.accountNo,
              value.refOrderEntity?.invoiceNo,
              value.remark,
              value.refOrderEntity?.remarks,
            ]
              .filter(o => !_.isNil(o))
              .map(_.toLower)
              .some(o => o?.includes(debounceWord))
          )

    return filterTransactionType === ''
      ? generalFiltered
      : generalFiltered.filter(
          value => value.transactionType === filterTransactionType
        )
  }, [debounceWord, filterTransactionType, withdrawals])

  return (
    <div>
      <div className='py-4'>
        <FormControl fullWidth={true}>
          <InputLabel>Transaction Type</InputLabel>
          <Select
            name={'transactionType'}
            label={'Transaction Type'}
            value={filterTransactionType}
            fullWidth={true}
            onChange={event =>
              setFilterTransactionType(String(event.target.value))
            }
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            {Object.keys(TransactionType.toDisplays).map(name => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <TextField
        placeholder='Filter withdrawals'
        value={filterWord}
        onChange={event => {
          setFilterWord(event.target.value)
          setDebounceWord(event.target.value.toLowerCase())
        }}
        fullWidth={true}
      />
      <div>
        <WithdrawalTableForAgent withdrawals={displayWithdrawals} />
      </div>
    </div>
  )
}

export default AgentWithdrawalTab
