import client, { AxiosResponse } from 'axios'

const url = `/api/v1/payment-vouchers`
class PaymentVoucherService {
  static voucherByClaimId(claimId: number): Promise<AxiosResponse> {
    return client.get(`${url}/${claimId}`, {
      responseType: 'blob',
    })
  }
}

export default PaymentVoucherService
