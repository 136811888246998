import client, { AxiosResponse } from 'axios'
import { Product } from './bean/product'

const url = `/api/v1/products`
export default class ProductService {
  static getProducts = function (): Promise<AxiosResponse<Product[]>> {
    return client.get(url)
  }

  static postProduct(
    name: string,
    image?: string
  ): Promise<AxiosResponse<any>> {
    return client.post(url, { name, image })
  }

  static deleteProduct(id: number): Promise<AxiosResponse> {
    return client.delete(`${url}/${id}`)
  }

  static getById(productId: any): Promise<AxiosResponse<Product>> {
    return client.get(`${url}/${productId}`)
  }
}
