import React, { useEffect, useState } from 'react'
import {
  AppBar,
  CircularProgress,
  TablePagination,
  Toolbar,
  Typography,
} from '@material-ui/core'
import AuditService from './auditService'
import { Pageable } from '../../common/bean/Pageable'
import { AuditBean } from './bean/AuditBean'
import { useTypedSelector } from '../../redux/reducer'
import { PortalSettingReducer } from '../../redux/reducer/portalSettingReducer'
import AuditDetailDialog from './auditDetailDialog'
import AuditListComponent from './auditListComponent'

function AuditPage() {
  const portalSetting: PortalSettingReducer = useTypedSelector(
    state => state.portalSetting
  )
  const [pageable, setPageable] = useState({
    page: 0,
    size: portalSetting.paginator.defaultRowPerPage || 20,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [selectedAudit, setSelectedAudit] = useState<AuditBean | undefined>()
  const [pageableAudit, setPageableAudit] = useState<Pageable<AuditBean>>()

  useEffect(() => {
    setIsLoading(true)
    AuditService.getAuditsPageable(pageable.page, pageable.size)
      .then(value => {
        setPageableAudit(value.data)
      })
      .finally(() => setIsLoading(false))
  }, [pageable.page, pageable.size])

  function handleChangePage(event: unknown, newPage: number) {
    setPageable({
      ...pageable,
      page: newPage,
    })
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setPageable({
      page: 0,
      size: Number(event.target.value),
    })
  }

  function renderPaginator() {
    return (
      <TablePagination
        rowsPerPageOptions={portalSetting.paginator.rowPerPageOptions}
        component='div'
        count={pageableAudit?.totalElements || 0}
        rowsPerPage={pageableAudit?.size || 0}
        page={pageableAudit?.number || 0}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    )
  }
  return (
    <div>
      <AppBar position='relative'>
        <Toolbar className='space-x-3'>
          <Typography variant='h6' noWrap>
            Audit
          </Typography>
        </Toolbar>
      </AppBar>

      <div className='m-8'>{renderPaginator()}</div>
      <div className='m-8'>
        {(!pageableAudit || isLoading) && (
          <div className='flex items-center space-y-3 justify-center'>
            <CircularProgress />
            Loading...
          </div>
        )}
        {pageableAudit?.empty && <div>List is Empty</div>}

        <AuditListComponent
          audits={pageableAudit?.content || []}
          setSelectedAudit={setSelectedAudit}
        />
      </div>

      <div className='m-8'>{renderPaginator()}</div>
      <AuditDetailDialog
        selectedAudit={selectedAudit}
        setSelectedAudit={setSelectedAudit}
      />
    </div>
  )
}

export default AuditPage
