import React, { useMemo, useState } from 'react'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { Permission } from '../../../common/constant/permission'
import UserUpdatePermissionDialog from '../dialog/userUpdatePermission.dialog'
import tokenService from '../../../common/service/tokenService'
import UserBean from '../../../sharePortal/userManagement/bean/userBean'
import userProfileService from '../../../common/service/userProfileService'

interface UserActionsMenuProps {
  user?: UserBean
  handleDisableAgent: () => void
}

const UserActionsMenuComponent = (props: UserActionsMenuProps) => {
  const { user } = props

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [updatePermission, setUpdatePermission] = useState(false)

  const handleClick = (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const permissions = useMemo(() => {
    return tokenService.getPermissions()
  }, [])

  return (
    <>
      <IconButton
        aria-controls='user-actions-menu'
        aria-haspopup='true'
        onClick={handleClick}
        color={'inherit'}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id='user-actions-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => setUpdatePermission(true)}
          disabled={
            ![
              Permission.PERMISSION_ASSIGN,
              Permission.PERMISSION_REMOVE,
            ].some(o => permissions.includes(o))
          }
        >
          Update Permission
        </MenuItem>
        <MenuItem
          onClick={props.handleDisableAgent}
          disabled={!userProfileService.isManager() || !user?.enabled}
        >
          Disable User
        </MenuItem>
      </Menu>

      <UserUpdatePermissionDialog
        agentId={user?.id}
        open={updatePermission}
        onDialogClose={() => {
          setUpdatePermission(false)
        }}
      />
    </>
  )
}

export default UserActionsMenuComponent
