import client, { AxiosResponse } from 'axios'
import { WithdrawalBean } from '../bean/WithdrawalBean'
import { ClaimBean } from '../bean/claimBean'

interface SearchResponseBean {
  withdrawals: WithdrawalBean[]
  claims: ClaimBean[]
}

const url = `/api/v1/search`

export const SearchService = {
  searchWithdrawalAndClaim(
    searchString: string
  ): Promise<AxiosResponse<SearchResponseBean>> {
    return client.get(url, {
      params: { searchString: encodeURI(searchString) },
    })
  },
}
