import React, { useEffect, useMemo, useState } from 'react'
import { useTypedSelector } from '../../../redux/reducer'
import { useDispatch } from 'react-redux'
import { ProductCategoryAction } from '../../../redux/reducer/productCategoryReducer'
import { ProductCategory } from '../../productCategoryManagement/bean/productCategory'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from '@material-ui/core'
import {
  getAllOrderSummaryMessage,
  updateOrderSummaryMessage,
} from '../../../redux/slice/orderSummaryMessageSlice'
import { OrderSummaryBean } from '../bean/OrderSummaryBean'
import { ProductAction } from '../../../redux/reducer/productReducer'
import { Product } from '../../productManagement/bean/product'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import _ from 'lodash'
import OrderSummaryMessageDialog from './OrderSummaryMessageDialog'

const OrderSummaryMessageComponent = () => {
  const dispatch = useDispatch()
  const categories: ProductCategory[] = useTypedSelector(
    state => state.productCategory.categories
  )

  const products: Product[] = useTypedSelector(state => state.product.products)

  useEffect(() => {
    dispatch(ProductCategoryAction.getCategory())
    dispatch(ProductAction.getProduct())

    dispatch(getAllOrderSummaryMessage())
  }, [dispatch])

  const [selected, setSelected] = useState<
    { id: string; name: string } | undefined
  >(undefined)

  const displayList: Record<
    string,
    {
      productId: string
      categoryName: string
      productName: string
      categoryId: string
    }[]
  > = useMemo(() => {
    const c: ProductCategory[] = [...categories]
    c.sort((a, b) => a.productId - b.productId)

    const re: {
      productId: string
      categoryName: string
      productName: string
      categoryId: string
    }[] = c.map(value => {
      const find = products.find(value1 => value1.id === value.productId)
      return {
        productId: find?.id.toString() || '0',
        productName: find?.name || '',
        categoryId: value.id.toString(),
        categoryName: value.name,
      }
    })

    return _.groupBy(re, function (param) {
      return param.productName
    })
  }, [categories, products])
  return (
    <div>
      <div className='m-4' />
      {_.entries(displayList).map(value => {
        const [productName, beans] = value

        return (
          <Accordion key={productName}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {productName}
            </AccordionSummary>
            <AccordionDetails>
              <div className='grid grid-cols-4 gap-2 w-full'>
                {beans.map(bean => {
                  return (
                    <Button
                      key={bean.categoryId}
                      variant={'outlined'}
                      color={'primary'}
                      onClick={() => {
                        setSelected({
                          id: bean.categoryId,
                          name: bean.categoryName,
                        })
                      }}
                    >
                      {bean.categoryName}
                    </Button>
                  )
                })}
              </div>
            </AccordionDetails>
          </Accordion>
        )
      })}

      <OrderSummaryMessageDialog
        open={!!selected}
        value={selected?.id}
        name={selected?.name}
        onClose={(orderSummaryBean: OrderSummaryBean | undefined) => {
          const { id = 0 } = selected || {}
          setSelected(undefined)
          if (orderSummaryBean) {
            const newBean = {
              [id.toString()]: orderSummaryBean,
            }

            dispatch(updateOrderSummaryMessage(newBean))
          }
        }}
      />
    </div>
  )
}

export default OrderSummaryMessageComponent
