import React, { useMemo } from 'react'
import { Button } from '@material-ui/core'
import HistoryIcon from '@material-ui/icons/History'
import { OrderBean } from '../bean/orderBean'
import { useAppDispatch } from '../../../redux/store'
import { AlertDialogAction } from '../../../redux/reducer/alertDialogReducer'
import OrderService from '../../../agentPortal/orderManagement/orderService'
import { catchErrorWithDispatch } from '../../../common/ApiUtils'
import tokenService from '../../../common/service/tokenService'
import { Permission } from '../../../common/constant/permission'
import OrderStatus from '../../../common/constant/orderStatus'
import { LoadingAction } from '../../../redux/reducer/loadingBackdropReducer'
import { SnackbarAction } from '../../../redux/reducer/snackbarReducer'
import { useHistory } from 'react-router-dom'

interface Props {
  order?: OrderBean
}

const OrderResetStatusButton = ({ order }: Props) => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  function resetStatus() {
    if (order) {
      dispatch(LoadingAction.open('Resetting Order Status'))
      OrderService.resetOrderStatus(order.id)

        .then(() => {
          dispatch(
            SnackbarAction.open(
              'Success Reset Order Status and Delete Withdrawal'
            )
          )
          history.goBack()
        })
        .catch(catchErrorWithDispatch(dispatch))
        .finally(() => dispatch(LoadingAction.close()))
    }
  }

  function handleOnClick() {
    dispatch(
      AlertDialogAction.open(
        'Reset Status to AGENT SUBMITTED',
        'Are you sure wan to reset status and delete all withdrawals?',
        resetStatus
      )
    )
  }
  const isPostComplete = useMemo(() => {
    return (
      !!order &&
      order.orderStatus === OrderStatus.POST_COMPLETE &&
      order.documentCompleted
    )
  }, [order])
  const havePermission = useMemo(
    () => tokenService.havePermission(Permission.ORDER_STATUS_RESET),
    []
  )

  if (!havePermission || !isPostComplete) return null

  return (
    <>
      <Button
        onClick={handleOnClick}
        startIcon={<HistoryIcon />}
        color={'inherit'}
      >
        Reset Status
      </Button>
    </>
  )
}

export default OrderResetStatusButton
