import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../redux/reducer'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {
  AppBar,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { ProductPackageAction } from '../../redux/reducer/productPackageReducer'
import { ProductPackage } from './bean/productPackage'
import { ProductPackagePatchBody } from './bean/productPackagePatchBody'
import ProductPackageService from './productPackageService'
import { SnackbarAction } from '../../redux/reducer/snackbarReducer'
import { LoadingAction } from '../../redux/reducer/loadingBackdropReducer'
import { ProductCategory } from '../productCategoryManagement/bean/productCategory'
import ProductCategoryService from '../productCategoryManagement/productCagetoryService'
import { catchErrorWithDispatch } from '../../common/ApiUtils'
import ProductPackageEditDialog from './productPackageEditDialog'
import AddProductPackageComponent from './addProductPackageComponent'
import PackageItemActionsMenu from './packageItemActionsMenu'
import ProductPackageDuplicateDialog from './productPackageDuplicateDialog'

function ProductPackagePage() {
  const toDisplayList = ['name', 'description', 'price', 'Commission Type']
  const dispatch = useDispatch()
  const history = useHistory()

  const { categoryId } = useParams()
  const [category, setCategory] = useState<ProductCategory>()

  const productPackageState = useTypedSelector(state => state.productPackage)
  const [
    selectedProductPackage,
    setSelectedProductPackage,
  ] = React.useState<ProductPackage>()
  const [
    duplicatePackage,
    setDuplicatePackage,
  ] = React.useState<ProductPackage>()

  const handleDuplicateDialogOpen = (value: ProductPackage) => {
    setDuplicatePackage(value)
  }

  const handleDuplicateDialogClose = (productPackage?: ProductPackage) => {
    setDuplicatePackage(undefined)
    if (productPackage) {
      dispatch(LoadingAction.open('Duplicate Package...'))
      ProductPackageService.duplicatePackage(
        productPackage?.id,
        productPackage.name
      )
        .then(() => {
          dispatch(SnackbarAction.open('Success Duplicate Package'))
          dispatch(ProductPackageAction.getPackageByCategoryId(categoryId))
        })
        .catch(catchErrorWithDispatch(dispatch))
        .finally(() => dispatch(LoadingAction.close()))
    }
  }

  const handleEditDialogOpen = (value: ProductPackage) => {
    setSelectedProductPackage(value)
  }

  const handleEditDialogClose = (productPackage?: ProductPackage) => {
    setSelectedProductPackage(undefined)
    if (productPackage) {
      const patchBody: ProductPackagePatchBody = {
        description: productPackage?.description,
        name: productPackage?.name,
        price: productPackage?.price,
        commissionType: productPackage?.commissionType,
      }

      dispatch(LoadingAction.open('Updating Package...'))
      ProductPackageService.patchPackage(productPackage?.id, patchBody)
        .then(() => {
          dispatch(SnackbarAction.open('Success Update Package'))
          dispatch(ProductPackageAction.getPackageByCategoryId(categoryId))
        })
        .catch(catchErrorWithDispatch(dispatch))
        .finally(() => dispatch(LoadingAction.close()))
    }
  }

  useEffect(() => {
    dispatch(ProductPackageAction.getPackageByCategoryId(categoryId))
  }, [dispatch, categoryId])

  useEffect(() => {
    ProductCategoryService.getById(categoryId).then(value =>
      setCategory(value.data)
    )
  }, [dispatch, categoryId])

  return (
    <div>
      <AppBar position='relative'>
        <Toolbar className='space-x-3'>
          <IconButton color='inherit' onClick={history.goBack}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant='h6'>
            Product Package {category && <span>for {category.name}</span>}
          </Typography>
        </Toolbar>
      </AppBar>

      <div className='m-8'>
        <AddProductPackageComponent categoryId={categoryId} />
      </div>

      <div className='m-8'>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {toDisplayList.map(text => (
                  <TableCell key={text}>{text.toUpperCase()}</TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {productPackageState.packages.map((item: ProductPackage) => {
                return (
                  <TableRow
                    key={item.id}
                    className='hover:bg-gray-200 cursor-pointer select-none'
                  >
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.description}</TableCell>
                    <TableCell align='right'>{item.price.toFixed(2)}</TableCell>
                    <TableCell>{item.commissionType}</TableCell>
                    <TableCell align='right'>
                      <PackageItemActionsMenu
                        productPackage={item}
                        updateClick={handleEditDialogOpen}
                        duplicateClick={handleDuplicateDialogOpen}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <ProductPackageEditDialog
        open={selectedProductPackage}
        onClose={handleEditDialogClose}
      />
      <ProductPackageDuplicateDialog
        open={duplicatePackage}
        onClose={handleDuplicateDialogClose}
      />
    </div>
  )
}

export default ProductPackagePage
