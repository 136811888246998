import client, { AxiosResponse } from 'axios'
import { AnnouncementBean } from './bean/AnnouncementBean'
import { Pageable } from '../../common/bean/Pageable'

const url = `/api/v1/announcements`

export class AnnouncementService {
  static findAll(
    page: number = 0,
    size: number = 20
  ): Promise<AxiosResponse<Pageable<AnnouncementBean>>> {
    return client.get(url, {
      params: {
        sort: 'id,desc',
        page: page,
        size: size,
      },
    })
  }

  static findAllPublished(): Promise<AxiosResponse<AnnouncementBean[]>> {
    return client.get(url + '/published')
  }

  static createAnnouncement(
    title: string,
    content: string,
    files: File[]
  ): Promise<AxiosResponse<AnnouncementBean>> {
    const formData = new FormData()
    files.forEach(file => formData.append('files', file))
    formData.append('title', title)
    formData.append('content', content)

    return client.post(url, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
  }

  static publishAnnouncement(id: number): Promise<AxiosResponse> {
    return client.patch(`${url}/${id}/publish`)
  }

  static deleteById(id: number): Promise<AxiosResponse> {
    return client.delete(`${url}/${id}`)
  }
}
