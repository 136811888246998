import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Typography,
} from '@material-ui/core'
import { AuthenticationService } from './service/authenticationService'
import { ErrorResponse } from '../errorResponse'
import { Alert, Color } from '@material-ui/lab'

const VerifyEmailPage = () => {
  const history = useHistory()
  const { token } = useParams()
  const [snackBarOpen, setSnackBarOpen] = useState<{
    open: boolean
    message: string
    severity: Color | undefined
  }>({
    open: false,
    message: '',
    severity: 'success',
  })
  useEffect(() => {
    const errorCodeMessage: {
      [key: number]: string
    } = {
      200: 'You have success verify your account, please proceed to login.',
      404: 'Your verification code have problem, please contact us.',
      400: 'Your account is verified, please proceed to login.',
    }

    const handleSnackBarOpen = (status: number) => {
      setSnackBarOpen({
        message: errorCodeMessage[status],
        open: true,
        severity: status === 404 ? 'error' : 'success',
      })
    }
    AuthenticationService.verifyEmail(token)
      .then(value => {
        handleSnackBarOpen(value.status)
      })
      .catch(reason => {
        const errorResponse = reason.response.data as ErrorResponse
        handleSnackBarOpen(errorResponse.status)
      })
  }, [token])

  return (
    <div className='bg-gray-300 min-h-screen p-10'>
      <Container className='m-4'>
        <Card>
          <CardContent>
            <Typography variant='h4' className='text-center'>
              Verifying Your Email
            </Typography>

            <div className='text-center'>
              {snackBarOpen.message ? (
                <Alert severity={snackBarOpen.severity}>
                  {snackBarOpen.message}
                </Alert>
              ) : (
                <CircularProgress color='inherit' />
              )}
            </div>

            <div className='m-4' />
            <Button
              className='w-full'
              variant='contained'
              color='primary'
              size='large'
              onClick={() => history.replace('/')}
            >
              Back to Login
            </Button>
          </CardContent>
        </Card>
      </Container>
    </div>
  )
}

export default VerifyEmailPage
