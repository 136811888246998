import client, { AxiosResponse } from 'axios'
import UserBean from '../../../sharePortal/userManagement/bean/userBean'
import { CommissionQuota } from '../bean/commissionQuota'
import { AgentPatchBody } from '../bean/agentPatchBody'

const url = `/api/v1/users`
class AgentService {
  static findById(id: number): Promise<AxiosResponse<UserBean>> {
    return client.get(`${url}/${id}`)
  }

  static getAllAgents(params: {
    excludeSelf: boolean
    excludeDisable: boolean
  }): Promise<AxiosResponse<UserBean[]>> {
    return client.get(url + '/agent', {
      params,
    })
  }

  static getSubAgents(id: number): Promise<AxiosResponse<UserBean[]>> {
    return client.get(`${url}/agent/${id}/sub-agent`)
  }
  static patchAgent(
    agentId: number,
    body: AgentPatchBody
  ): Promise<AxiosResponse> {
    return client.patch(url + '/agent/' + agentId, body)
  }

  static getAgentCommission(
    userId: number,
    productId?: number
  ): Promise<AxiosResponse<CommissionQuota[]>> {
    return client.get(url + '/' + userId + '/commissions', {
      params: {
        productId,
      },
    })
  }

  static getAvailableSuperior(
    agentId: number
  ): Promise<AxiosResponse<UserBean[]>> {
    return client.get(`${url}/agent/${agentId}/available-principle`)
  }

  static deleteSuperior(agentId: number): Promise<AxiosResponse<UserBean[]>> {
    return client.delete(`${url}/agent/${agentId}/superior-id`)
  }

  static getUnverifiedAgent(): Promise<AxiosResponse<UserBean[]>> {
    return client.get(url + `/agent/unverified`)
  }

  static getUnverifiedAgentsCount(): Promise<AxiosResponse<UserBean[]>> {
    return client.get(url + '/agent/unverified/count')
  }
}

export default AgentService
