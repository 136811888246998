import { format } from 'date-fns'

export default class DateUtils {
  static monthString = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  static getMonthYearString = (d: Date): string => {
    const month = DateUtils.monthString[d.getMonth()]
    const year = d.getFullYear().toString().slice(2, 4)
    return `${month}'${year}`
  }

  static toLocaleDateString = function (dateString?: string | Date): string {
    return dateString ? new Date(dateString).toLocaleDateString() : ''
  }

  static toLocaleString = function (dateString?: string | Date): string {
    return dateString ? new Date(dateString).toLocaleString() : ''
  }
  static toDateString = function (dateString?: string | Date): string {
    return dateString ? new Date(dateString).toDateString() : ''
  }

  static getCurrentDateTime = function (): string {
    const date = new Date()
    return [
      date.getFullYear(),
      date.getMonth().toString().padStart(2, '0'),
      date.getDate().toString().padStart(2, '0'),
      '_',
      date.getHours().toString().padStart(2, '0'),
      date.getMinutes().toString().padStart(2, '0'),
      date.getSeconds().toString().padStart(2, '0'),
    ].join('')
  }

  static appointmentDateFormat = (dateString?: string) => {
    if (dateString) {
      return format(new Date(dateString), 'EEEE - dd MMMM yyyy')
    } else return ''
  }
  static shortDateFormat = (dateString?: string) => {
    if (dateString) {
      const date = new Date(dateString)
      return format(date, 'yyyy-MM-dd')
    } else {
      return ''
    }
  }
}
