import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import UserSettingService from '../../common/service/UserSettingService'
import { LoadingAction } from '../reducer/loadingBackdropReducer'

interface Props {
  orderDisplayList: string[]
}

const initialState: Props = {
  orderDisplayList: [],
}

const getUserSettingOrderDisplay = createAsyncThunk(
  'userSettings/getOrderDisplayList',
  () => {
    return UserSettingService.getSetting('ORDER_DISPLAY_LIST')
      .then(r => {
        return JSON.parse(r.data.value)
      })
      .catch(() => {
        return []
      })
  }
)
const updateUserSettingOrderDisplay = createAsyncThunk<void, string[]>(
  'userSettings/updateOrderDisplayList',
  async (arg, {dispatch}) => {
    dispatch(LoadingAction.open('Updating Order Display List...'))
    await UserSettingService.updateSetting('ORDER_DISPLAY_LIST', arg).finally(
      () => {
        dispatch(LoadingAction.close())
      }
    )
    dispatch(getUserSettingOrderDisplay())
  }
)

const userSettingsSlice = createSlice({
  name: 'userSettings',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getUserSettingOrderDisplay.fulfilled, (state, action) => {
      state.orderDisplayList = action.payload
    })
  },
})

export const userSettingsAction = {
  getUserSettingOrderDisplay,
  updateUserSettingOrderDisplay,
}

export default userSettingsSlice
