import React, { useEffect, useState } from 'react'
import ProductPackageService from '../../adminPortal/productPackageManagement/productPackageService'
import { ProductDisplayPackage } from '../../adminPortal/productPackageManagement/bean/productDisplayPackage'
import { TextField } from '@material-ui/core'
import { useTypedSelector } from '../../redux/reducer'

interface ProductSelectionDisplayComponentProps {
  packageId: number | string
}

const ProductSelectionDisplayComponent = (
  props: ProductSelectionDisplayComponentProps
) => {
  const { packageId } = props

  const [display, setDisplay] = useState<ProductDisplayPackage | undefined>()

  const packageShowDescription: boolean = useTypedSelector(
    state => state.portalSetting.order.packageShowDescription
  )

  useEffect(() => {
    if (packageId)
      ProductPackageService.getDisplayPackages(packageId).then(result =>
        setDisplay(result.data)
      )
  }, [packageId])

  if (!display) return null

  return (
    <div className='grid-cols-3 grid gap-4'>
      <TextField label='Product' value={display.product.name} disabled />
      <TextField
        label='Product Category'
        value={display.category.name}
        disabled
      />
      <TextField
        label='Product Package'
        value={
          (packageShowDescription
            ? display.package.description
            : display.package.name) ||
          display.package.description ||
          display.package.name ||
          'EMPTY'
        }
        disabled
      />
    </div>
  )
}

export default ProductSelectionDisplayComponent
