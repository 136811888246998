import React, { useEffect, useState } from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { TextField } from '@material-ui/core'

export interface ChangePasswordBean {
  oldPassword: string
  newPassword: string
}

export interface ChangePasswordDialogProps {
  open: boolean
  onClose: (result?: ChangePasswordBean) => void
}

interface ChangePasswordInput {
  oldPassword: string
  newPassword: string
  retypeNewPassword: string
}

interface ChangePasswordInputError {
  oldPassword?: string
  newPassword?: string
  retypeNewPassword?: string
  notSame?: string
}

const ChangePasswordDialog = (props: ChangePasswordDialogProps) => {
  const { open, onClose } = props

  const [formInput, setFormInput] = useState<ChangePasswordInput>({
    oldPassword: '',
    newPassword: '',
    retypeNewPassword: '',
  })

  const [formInputError, setFormInputError] = useState<
    ChangePasswordInputError | undefined
  >(undefined)

  useEffect(() => {
    setFormInput({
      oldPassword: '',
      newPassword: '',
      retypeNewPassword: '',
    })
    setFormInputError(undefined)
  }, [open])

  function handleOnClose(input?: ChangePasswordInput) {
    if (input) {
      setFormInputError(undefined)
      const error: ChangePasswordInputError = {}
      if (!input.oldPassword) {
        error['oldPassword'] = 'Old Password is Mandatory'
      }
      if (!input.newPassword) {
        error['newPassword'] = 'New Password is Mandatory'
      }
      if (!input.retypeNewPassword) {
        error['retypeNewPassword'] = 'Retype Password is Mandatory'
      }
      if (input.newPassword !== input.retypeNewPassword) {
        error['notSame'] = 'Does not match with new password'
      }

      if (Object.keys(error).length) {
        setFormInputError(error)
        return
      }
      onClose({
        oldPassword: input.oldPassword,
        newPassword: input.newPassword,
      })
    } else {
      onClose(undefined)
    }
  }

  function handleOnChange(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    setFormInput({
      ...formInput,
      [e.target.name]: e.target.value,
    })
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleOnClose(undefined)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>Change Password</DialogTitle>

      <DialogContent className='space-y-3'>
        <TextField
          fullWidth
          type={'password'}
          name='oldPassword'
          label={'Old Password'}
          error={!!formInputError?.oldPassword}
          helperText={formInputError?.oldPassword}
          onChange={handleOnChange}
        />
        <TextField
          fullWidth
          type={'password'}
          name='newPassword'
          label={'New Password'}
          error={!!formInputError?.newPassword}
          helperText={formInputError?.newPassword}
          onChange={handleOnChange}
        />
        <TextField
          fullWidth
          type={'password'}
          name='retypeNewPassword'
          label={'Retype New Password'}
          error={
            !!formInputError?.retypeNewPassword || !!formInputError?.notSame
          }
          helperText={
            formInputError?.retypeNewPassword || formInputError?.notSame
          }
          onChange={handleOnChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleOnClose(undefined)} color='secondary'>
          Cancel
        </Button>
        <Button
          onClick={() => handleOnClose(formInput)}
          color='primary'
          variant={'contained'}
          autoFocus
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ChangePasswordDialog
