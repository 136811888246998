import client, { AxiosResponse } from 'axios'
import { StateBean } from './StateBean'
import { BankBean } from './BankBean'

const url = `/api/v1/public`
export default class CommonApiService {
  static getStates(): Promise<AxiosResponse<StateBean[]>> {
    return client.get(url + '/states')
  }

  static getBanks(): Promise<AxiosResponse<BankBean[]>> {
    return client.get(url + '/banks')
  }
}
