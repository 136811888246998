import _ from 'lodash'
import { OrderDisplayModel } from '../../adminPortal/settings/component/order/model/OrderDisplayModel'
import { OrderBean } from '../../sharePortal/orderManagement/bean/orderBean'

const availableList = Object.freeze([
  'fullName',
  'product',
  'productCategory',
  'productPackage',
  'price',
  'companyName',
  'companyRegNo',
  'address',
  'fullAddress',
  'nric',
  'email',
  'mobileNo',
  'telNo',
  'orderStatus',
  'orderNo',
  'loginId',
  'eform',
  'invoiceNo',
  'appointmentDate',
  'appointmentTime',
  'alternativeNo',
  'm2uRemarks',
  'remarks',
  'adminRemarks',
  'sporaReferenceNumber',
  'sporaProcessingId',
  'ociTicketNumber',
  'agentUsername',
  'agentName',
  'agentCompany',
  'agentMobileNo',
  'agentEmail',
  'submittedDate',
  'activatedOn',
  'documentCompleted',
  'processingId',
  'productPackage',
  'unitNo',
  'streetType',
  'streetName',
  'section',
  'floorNo',
  'buildingName',
  'city',
  'state',
  'postcode',
  'createdDate',
  'accountNo',
])

const sampleTemplateContent = `*Dear Valued Customer, Thanks for you support, Your application have been successful submit.*

*Package* : $\{productPackage}
*Company Name* : $\{companyName}
*Company Reg No* : $\{companyRegNo}
*Address* : $\{fullAddress}
*Full Name* : $\{fullName}
*NRIC* : $\{nric}
*Email* : $\{email}
*Contact* : $\{mobileNo}
*Application e-Form ID* : $\{eform}`

function parseTemplate(userTemplate: string, order: OrderBean) {
  try {
    _.templateSettings.interpolate = /\${([\s\S]+?)}/g
    const templateExecutor = _.template(userTemplate)

    return templateExecutor(new OrderDisplayModel(order, ''))
  } catch (e) {
    return userTemplate
  }
}

export const TemplateUtils = {
  availableList,
  sampleTemplateContent,
  parseTemplate,
}
