import KeyValue from '../bean/keyValue'

export type OrderStatusType =
  | 'PENDING'
  | 'WAITING_LIST'
  | 'EARLY_STAGE'
  | 'POST_COMPLETE'
  | 'CANCEL'
  | 'KIV'
  | 'NEW_AREA'
  | 'AGENT_SUBMITTED'
  | 'REJECTED'
  | 'EXPIRED'
  | 'PENDING_PCMS'
  | 'UNIT_MISSING'
  | 'PORT_FULL'

export default class OrderStatus {
  static PENDING: string = 'PENDING'
  static WAITING_LIST: string = 'WAITING_LIST'
  static EARLY_STAGE: string = 'EARLY_STAGE'
  static POST_COMPLETE: string = 'POST_COMPLETE'
  static CANCEL: string = 'CANCEL'
  static KIV: string = 'KIV'
  static NEW_AREA: string = 'NEW_AREA'
  static AGENT_SUBMITTED: string = 'AGENT_SUBMITTED'
  static REJECTED = 'REJECTED'
  static EXPIRED = 'EXPIRED'
  static PENDING_PCMS = 'PENDING_PCMS'
  static UNIT_MISSING = 'UNIT_MISSING'
  static PORT_FULL = 'PORT_FULL'

  static toDisplay: Record<string, string> = {
    PENDING: 'Pending',
    WAITING_LIST: 'Waiting List',
    EARLY_STAGE: 'Early Stage',
    POST_COMPLETE: 'Post Complete',
    CANCEL: 'Cancel',
    KIV: 'KIV',
    NEW_AREA: 'New Area',
    AGENT_SUBMITTED: 'Agent Submitted',
    REJECTED: 'Rejected',
    EXPIRED: 'Expired',
    PENDING_PCMS: 'Pending PCMS',
    UNIT_MISSING: 'Unit Missing',
    PORT_FULL: 'Port Full',
  }

  static isAgentSubmitted(status?: string): boolean {
    return !!status && status === OrderStatus.AGENT_SUBMITTED
  }

  // KIV, CANCEL, POST_COMPLETE cannot change status
  static canChangeOrderStatus(status: string = ''): boolean {
    return ![OrderStatus.POST_COMPLETE].includes(status)
  }

  static orderStatusDdl: KeyValue<string, string>[] = [
    OrderStatus.PENDING,
    OrderStatus.WAITING_LIST,
    OrderStatus.EARLY_STAGE,
    OrderStatus.POST_COMPLETE,
    OrderStatus.CANCEL,
    OrderStatus.KIV,
    OrderStatus.NEW_AREA,
    OrderStatus.AGENT_SUBMITTED,
    OrderStatus.REJECTED,
    OrderStatus.EXPIRED,
    OrderStatus.PENDING_PCMS,
    OrderStatus.UNIT_MISSING,
    OrderStatus.PORT_FULL,
  ].map(o => ({ key: o, value: o }))

  static inProgress = [
    OrderStatus.PENDING,
    OrderStatus.WAITING_LIST,
    OrderStatus.EARLY_STAGE,
    OrderStatus.KIV,
    OrderStatus.NEW_AREA,
    OrderStatus.AGENT_SUBMITTED,
    OrderStatus.PENDING_PCMS,
    OrderStatus.UNIT_MISSING,
    OrderStatus.PORT_FULL,
  ]

  static completed = [OrderStatus.POST_COMPLETE]
}
