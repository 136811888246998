import React, { useEffect } from 'react'
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom'
import ProductPage from './productManagement/productPage'
import ProductCategoryPage from './productCategoryManagement/productCategoryPage'
import ProductPackagePage from './productPackageManagement/productPackagePage'
import AuditPage from './audit/auditPage'
import userProfileService from '../common/service/userProfileService'
import {
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import DashboardIcon from '@material-ui/icons/Dashboard'
import SettingsIcon from '@material-ui/icons/Settings'
import CommissionManagementPage from './userManagement/commissionManagementPage'
import LoyaltyIcon from '@material-ui/icons/Loyalty'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import NavListItem from '../common/component/navListItem'
import OrderDetailPage from '../sharePortal/orderManagement/orderDetailPage'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import ClaimDetailPage from '../features/claim/claimDetailPage'
import AdminDashboard from './dashboard/adminDashboard'
import AdminManagementPage from './userManagement/adminManagementPage'
import AgentManagementPage from './userManagement/agentManagementPage'
import AgentVerifyManagementPage from './userManagement/agentVerifyManagementPage'
import UserRole from '../common/constant/userRole'
import { PAGE, ROUTES } from '../common/constant/pageKeyConstant'
import CreateWithdrawal from '../features/claim/createWithdrawal'
import NewAdminPage from './userManagement/newAdminPage'
import ProfileDetailPage from '../sharePortal/userManagement/profileDetailPage'
import NewOrderPage from '../agentPortal/orderManagement/newOrderPage'
import AgentDetailPage from './userManagement/agentDetailPage'
import LandingPageAttachmentsPage from './landingPageAttachments/landingPageAttachmentsPage'
import AllWithdrawalPage from '../features/claim/allWithdrawalPage'
import AdminSubmitClaimPage from '../features/claim/adminSubmitClaimPage'
import AnnouncementManagementPage from './announcementManagement/announcementManagementPage'
import SettingPage from './settings/SettingPage'
import { PortalSettingActions } from '../redux/reducer/portalSettingReducer'
import { useDispatch } from 'react-redux'
import SearchClaimPage from '../features/claim/searchClaimPage'
import { CommonActions } from '../redux/reducer/commonReducer'
import { getAllOrderSummaryMessage } from '../redux/slice/orderSummaryMessageSlice'
import {
  getDefaultCreatedOrderStatus,
  getOrderDisplayList,
} from '../redux/slice/orderSettingsSlice'
import WithdrawalPage from './withdrawals/withdrawal.page'
import ClaimPageV2 from '../features/claim/claimPageV2'
import OrderListingV2Page from '../sharePortal/orderManagement/orderListingV2.page'
import TemplatePage from '../features/template/template.page'
import { TemplateCreatePage } from '../features/template/templateCreate.page'
import TemplateDetailPage from '../features/template/templateDetail.page'
import AppNavContainer from '../AppNavContainer'
import UserSettingPage from '../common/userSetting/userSettingPage'

const pagePermissionMap: Record<string, string[]> = {
  AdminDashboard: [
    UserRole.ROLE_STAFF,
    UserRole.ROLE_ADMIN,
    UserRole.ROLE_MANAGER,
  ],
  ProductPage: [UserRole.ROLE_ADMIN, UserRole.ROLE_MANAGER],
  ProductCategoryPage: [UserRole.ROLE_ADMIN, UserRole.ROLE_MANAGER],
  ProductPackagePage: [UserRole.ROLE_ADMIN, UserRole.ROLE_MANAGER],
  AuditPage: [UserRole.ROLE_MANAGER],
  AgentManagementPage: [UserRole.ROLE_MANAGER],
  AgentVerifyManagementPage: [UserRole.ROLE_MANAGER],
  CommissionManagementPage: [UserRole.ROLE_ADMIN, UserRole.ROLE_MANAGER],
  OrderManagementPage: [
    UserRole.ROLE_STAFF,
    UserRole.ROLE_ADMIN,
    UserRole.ROLE_MANAGER,
  ],
  OrderManagementPage2: [
    UserRole.ROLE_STAFF,
    UserRole.ROLE_ADMIN,
    UserRole.ROLE_MANAGER,
  ],
  OrderDetailPage: [
    UserRole.ROLE_STAFF,
    UserRole.ROLE_ADMIN,
    UserRole.ROLE_MANAGER,
  ],
  ClaimDetailPage: [UserRole.ROLE_ADMIN, UserRole.ROLE_MANAGER],
  [PAGE.ClaimPage]: [UserRole.ROLE_ADMIN, UserRole.ROLE_MANAGER],
  [PAGE.CreateWithdrawalPage]: [UserRole.ROLE_ADMIN, UserRole.ROLE_MANAGER],
  [PAGE.AdminManagementPage]: [UserRole.ROLE_MANAGER, UserRole.ROLE_ADMIN],
  [PAGE.NewAdminPage]: [UserRole.ROLE_MANAGER],
  [PAGE.UserProfileDetailPage]: [
    UserRole.ROLE_MANAGER,
    UserRole.ROLE_ADMIN,
    UserRole.ROLE_STAFF,
  ],
  [PAGE.NewOrderPage]: [
    UserRole.ROLE_MANAGER,
    UserRole.ROLE_ADMIN,
    UserRole.ROLE_STAFF,
  ],
  [PAGE.AllWithdrawalPage]: [UserRole.ROLE_MANAGER, UserRole.ROLE_ADMIN],
  [PAGE.AgentDetailPage]: [UserRole.ROLE_MANAGER, UserRole.ROLE_ADMIN],
  [PAGE.LandingPageAttachments]: [UserRole.ROLE_MANAGER],
  [PAGE.AdminSubmitClaim]: [UserRole.ROLE_MANAGER, UserRole.ROLE_ADMIN],
  [PAGE.AnnouncementManagementPage]: [
    UserRole.ROLE_MANAGER,
    UserRole.ROLE_ADMIN,
    UserRole.ROLE_STAFF,
  ],
  [PAGE.SettingPage]: [UserRole.ROLE_MANAGER],
  [PAGE.SearchClaimPage]: [UserRole.ROLE_MANAGER, UserRole.ROLE_ADMIN],
  [PAGE.WithdrawalPage]: [UserRole.ROLE_MANAGER],
  [PAGE.TemplatePage]: [
    UserRole.ROLE_MANAGER,
    UserRole.ROLE_ADMIN,
    UserRole.ROLE_STAFF,
  ],
  [PAGE.TemplateCreatePage]: [
    UserRole.ROLE_MANAGER,
    UserRole.ROLE_ADMIN,
    UserRole.ROLE_STAFF,
  ],
  [PAGE.UserSettingPage]: [
    UserRole.ROLE_MANAGER,
    UserRole.ROLE_STAFF,
    UserRole.ROLE_ADMIN,
  ],
}

function havePermissionToView(pageName: string): boolean {
  const role = userProfileService.getUserProfile()?.role || ''
  const permittedRole = pagePermissionMap[pageName]
  return permittedRole.includes(role)
}

function AdminPortalIndex() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(PortalSettingActions.getUserCode())
    dispatch(PortalSettingActions.getPackageShowDescription())
    dispatch(getDefaultCreatedOrderStatus())
    dispatch(PortalSettingActions.getOrderSummarySetting())
    dispatch(PortalSettingActions.getOrderShowCommissionRate())
    dispatch(PortalSettingActions.getRoleAbleToViewCommissionRate())
    dispatch(CommonActions.getBanks())
    dispatch(CommonActions.getStates())
    dispatch(getAllOrderSummaryMessage())
    dispatch(getOrderDisplayList())
  }, [dispatch])

  return (
    <AppNavContainer
      navList={<AdminPortalNavList />}
      switch={<AdminPortalSwitch />}
    />
  )
}

const AdminPortalNavList = () => {
  const routeMatch = useRouteMatch()

  return (
    <>
      <NavLink
        to={routeMatch.path + '/dashboard'}
        activeClassName='text-indigo-600 italic'
      >
        <ListItem button>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary={'Dashboard'} />
        </ListItem>
      </NavLink>

      <Divider />

      <NavListItem
        title='Product Management'
        icon={<AssignmentIndIcon />}
        navItems={[
          {
            key: PAGE.ProductPage,
            name: 'Product',
            url: routeMatch.path + '/product',
          },
        ].filter(value => havePermissionToView(value.key))}
      />

      <NavListItem
        title='Order Management'
        icon={<AssignmentIndIcon />}
        navItems={[
          {
            key: PAGE.NewOrderPage,
            name: 'New Order',
            url: routeMatch.path + '/new-order',
          },
          {
            key: PAGE.OrderManagementPage2,
            name: 'View Orders',
            url: routeMatch.path + '/order-management2',
          },
        ].filter(value => havePermissionToView(value.key))}
      />

      <NavListItem
        title='User Management'
        icon={<LoyaltyIcon />}
        navItems={[
          {
            key: PAGE.AdminManagementPage,
            name: 'Admin Management',
            url: routeMatch.path + '/admin-management',
          },
          {
            key: PAGE.AgentManagementPage,
            name: 'Agent Management',
            url: routeMatch.path + '/agent-management',
          },
          {
            key: PAGE.AgentVerifyManagementPage,
            name: 'Agent Verify Management',
            url: routeMatch.path + '/agent-verify-management',
          },
          {
            key: PAGE.CommissionManagementPage,
            name: 'Agent Commission',
            url: routeMatch.path + '/commission-management',
          },
        ].filter(value => havePermissionToView(value.key))}
      />

      <NavListItem
        title='Claim Management'
        icon={<AttachMoneyIcon />}
        navItems={[
          {
            key: PAGE.ClaimPage,
            name: 'View Claims',
            url: routeMatch.path + '/claims',
          },

          {
            key: PAGE.AllWithdrawalPage,
            name: 'All Withdrawal',
            url: routeMatch.path + '/all-withdrawals',
          },
          {
            key: PAGE.WithdrawalPage,
            name: 'Export Withdrawals',
            url: routeMatch.path + '/withdrawals',
          },
          {
            key: PAGE.CreateWithdrawalPage,
            name: 'Create Withdrawal',
            url: routeMatch.path + '/create-withdrawal',
          },
          {
            key: PAGE.SearchClaimPage,
            name: 'Search Claim and Withdrawal',
            url: routeMatch.path + '/search-claim-and-withdrawal',
          },
        ].filter(value => havePermissionToView(value.key))}
      />

      <NavListItem
        title='Settings Management'
        icon={<SettingsIcon />}
        navItems={[
          {
            key: PAGE.LandingPageAttachments,
            name: 'Landing Page Attachment',
            url: routeMatch.path + '/landing-page-attachment-management',
          },
          {
            key: PAGE.AnnouncementManagementPage,
            name: 'Announcement Management',
            url: routeMatch.path + '/announcement-management',
          },
          {
            key: PAGE.SettingPage,
            name: 'Setting Page',
            url: routeMatch.path + '/setting-page',
          },
          {
            key: PAGE.TemplatePage,
            name: 'Template Page',
            url: routeMatch.path + '/templates',
          },
          {
            key: PAGE.UserSettingPage,
            name: 'User Setting Page',
            url: routeMatch.path + '/' + ROUTES.userSetting(),
          },
        ].filter(value => havePermissionToView(value.key))}
      />

      {userProfileService.isManager() && (
        <>
          <NavLink
            to={routeMatch.path + '/audit'}
            activeClassName='text-indigo-600 italic'
          >
            <ListItem button>
              <ListItemText inset primary={'Audit'} />
            </ListItem>
          </NavLink>
          <Divider />
        </>
      )}
    </>
  )
}
const AdminPortalSwitch = () => {
  const routeMatch = useRouteMatch()

  const routes: {
    path: string
    page: JSX.Element
    key: string
    exact?: boolean
  }[] = [
    { key: PAGE.AdminDashboard, path: '/dashboard', page: <AdminDashboard /> },
    { key: PAGE.ProductPage, path: '/product', page: <ProductPage /> },
    {
      key: PAGE.ProductCategoryPage,
      path: '/product-category/:productId',
      page: <ProductCategoryPage />,
    },
    {
      key: PAGE.ProductPackagePage,
      path: '/product-package/:categoryId',
      page: <ProductPackagePage />,
    },
    { key: PAGE.AuditPage, path: '/audit', page: <AuditPage /> },
    {
      key: PAGE.AdminManagementPage,
      path: '/admin-management',
      page: <AdminManagementPage />,
    },
    {
      key: PAGE.NewAdminPage,
      path: '/new-admin',
      page: <NewAdminPage />,
    },
    {
      key: PAGE.AgentManagementPage,
      path: '/agent-management',
      page: <AgentManagementPage />,
    },
    {
      key: PAGE.AgentVerifyManagementPage,
      path: '/agent-verify-management',
      page: <AgentVerifyManagementPage />,
    },
    {
      key: PAGE.CommissionManagementPage,
      path: '/commission-management',
      page: <CommissionManagementPage />,
    },
    {
      key: PAGE.OrderManagementPage2,
      path: '/order-management2',
      page: <OrderListingV2Page />,
    },
    {
      key: PAGE.OrderDetailPage,
      path: '/order-detail/:id',
      page: <OrderDetailPage />,
    },
    {
      key: PAGE.ClaimDetailPage,
      path: '/view-claim/:claimId',
      page: <ClaimDetailPage />,
    },
    {
      key: PAGE.CreateWithdrawalPage,
      page: <CreateWithdrawal />,
      path: '/create-withdrawal',
    },
    {
      key: PAGE.UserProfileDetailPage,
      page: <ProfileDetailPage />,
      path: '/profile-detail',
    },
    {
      key: PAGE.NewOrderPage,
      page: <NewOrderPage />,
      path: '/new-order',
    },
    {
      key: PAGE.AgentDetailPage,
      page: <AgentDetailPage />,
      path: '/agent-detail/:id',
    },
    {
      key: PAGE.LandingPageAttachments,
      page: <LandingPageAttachmentsPage />,
      path: '/landing-page-attachment-management',
    },
    {
      key: PAGE.AllWithdrawalPage,
      page: <AllWithdrawalPage />,
      path: '/all-withdrawals',
    },

    {
      key: PAGE.AdminSubmitClaim,
      page: <AdminSubmitClaimPage />,
      path: '/admin-submit-claim/:agentId',
    },

    {
      key: PAGE.AnnouncementManagementPage,
      page: <AnnouncementManagementPage />,
      path: '/announcement-management',
    },
    {
      key: PAGE.SettingPage,
      page: <SettingPage />,
      path: '/setting-page',
    },
    {
      key: PAGE.SearchClaimPage,
      page: <SearchClaimPage />,
      path: '/search-claim-and-withdrawal',
    },
    {
      key: PAGE.WithdrawalPage,
      page: <WithdrawalPage />,
      path: '/withdrawals',
      exact: true,
    },
    {
      key: PAGE.ClaimPage,
      page: <ClaimPageV2 />,
      path: '/claims',
      exact: true,
    },
    {
      key: PAGE.TemplatePage,
      page: <TemplatePage />,
      path: '/templates',
      exact: true,
    },
    {
      key: PAGE.TemplateCreatePage,
      page: <TemplateCreatePage />,
      path: '/templates/create',
      exact: true,
    },
    {
      key: PAGE.TemplatePage,
      page: <TemplateDetailPage />,
      path: '/templates/:id',
      exact: true,
    },
    {
      key: PAGE.UserSettingPage,
      page: <UserSettingPage />,
      path: '/' + ROUTES.userSetting(),
    },
  ]

  return (
    <Switch>
      {routes.map(value => {
        const { exact = false } = value
        return (
          <Route
            key={value.key}
            path={`${routeMatch.path}${value.path}`}
            exact={exact}
          >
            {havePermissionToView(value.key) ? (
              value.page
            ) : (
              <Redirect to={`${routeMatch.path}/dashboard`} />
            )}
          </Route>
        )
      })}

      <Route path={routeMatch.path}>
        <Redirect to={`${routeMatch.path}/dashboard`} />
        Hello World
      </Route>
    </Switch>
  )
}

export default AdminPortalIndex
