import client, { AxiosResponse } from 'axios'
import UserProfileAttahcmentBean from '../bean/userProfileAttachmentBean'

const url = `/api/v1/user-profile-attachments`

export class UserProfileAttachemntService {
  static createUserProfileAttachment(
    userId: string,
    files: File[],
    category: string
  ): Promise<AxiosResponse> {
    if (!category) {
      throw Error('Category is mandatory.')
    }

    const formData = new FormData()
    files.forEach(file => formData.append('files', file))
    formData.append('userId', userId)

    return client.post(`${url}/${category}`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
  }

  static getUserProfileAttachmentByUserId(
    userId: string | number
  ): Promise<AxiosResponse<UserProfileAttahcmentBean[]>> {
    if (!userId) {
      throw Error('User id is mandatory.')
    }

    return client.get(`${url}/user/${userId}`)
  }
}
