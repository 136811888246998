import _ from 'lodash'
import { useTypedSelector } from '../../redux/reducer'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'qs'
import React, { useMemo } from 'react'

interface PaginationParams {
  params: { [key: string]: string }
  page: number
  size: number
  rowPerPageOptions: number[]
  onChangeSize: (event: React.ChangeEvent<HTMLInputElement>) => void
  onChangePage: (event: unknown, newPage: number) => void
  updateFilter: (params: object) => void
}

export function usePaginationParams(): PaginationParams {
  const portalSettingReducer = useTypedSelector(state => state.portalSetting)
  const defaultRowPerPage = portalSettingReducer.paginator.defaultRowPerPage
  const rowPerPageOptions = portalSettingReducer.paginator.rowPerPageOptions

  const history = useHistory()
  const location = useLocation()

  const parsedQs = useMemo(() => {
    return qs.parse(location.search, {
      ignoreQueryPrefix: true,
    })
  }, [location.search])

  const page = _.get(parsedQs, 'page', '0')
  const size = _.get(parsedQs, 'size', _.toString(defaultRowPerPage))

  function onChangePage(event: unknown, newPage: number) {
    const parsedQs = qs.parse(location.search, { ignoreQueryPrefix: true })
    parsedQs['page'] = _.toString(newPage)
    parsedQs['size'] = _.toString(size)

    const newQuery = qs.stringify(parsedQs, { addQueryPrefix: true })

    history.replace({
      search: newQuery,
    })
  }

  function onChangeSize(event: React.ChangeEvent<HTMLInputElement>) {
    const parsedQs = qs.parse(location.search, { ignoreQueryPrefix: true })
    parsedQs['page'] = _.toString(0)

    const newSize = event.target.value
    parsedQs['size'] = _.toString(newSize)

    const newQuery = qs.stringify(parsedQs, { addQueryPrefix: true })

    history.replace({
      search: newQuery,
    })
  }

  function updateFilter(params: object) {
    const parsedQs = qs.parse(location.search, { ignoreQueryPrefix: true })
    parsedQs['page'] = _.toString(0)

    const newQuery = qs.stringify(
      {
        ...parsedQs,
        ...params,
      },
      { addQueryPrefix: true }
    )

    history.replace({
      search: newQuery,
    })
  }

  return {
    params: parsedQs as { [key: string]: string },
    page: _.toNumber(page),
    size: _.toNumber(size),
    rowPerPageOptions,
    onChangePage,
    onChangeSize,
    updateFilter,
  }
}
