import client, { AxiosResponse } from 'axios'
import { IPermissionBean } from './IPermissionBean'

const url = `/api/v1/permissions`

export default class PermissionService {
  static getPermission(): Promise<AxiosResponse<IPermissionBean[]>> {
    return client.get(url)
  }
}
