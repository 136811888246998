import React, { useEffect, useMemo, useState } from 'react'
import {
  AppBar,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { useTypedSelector } from '../../redux/reducer'
import { useAppDispatch } from '../../redux/store'
import { TemplateActions } from '../../redux/slice/template.slice'
import { useHistory, useParams } from 'react-router-dom'
import AppBackButton from '../../common/component/appBackButton'
import { LoadingAction } from '../../redux/reducer/loadingBackdropReducer'
import { SnackbarAction } from '../../redux/reducer/snackbarReducer'
import { TemplateUtils } from './template.util'
import TemplateAvailableListComponent from './templateAvailableList.component'
import TemplatePreviewMessageComponent from './templatePreviewMessage.component'
import DeleteIcon from '@material-ui/icons/Delete'
import { AlertDialogAction } from '../../redux/reducer/alertDialogReducer'
import { catchErrorWithDispatch } from '../../common/ApiUtils'

function TemplateDetailPage() {
  const { id } = useParams<{ id?: string }>()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const isLoading = useTypedSelector(state => state.templates.isLoading)
  const templateDetail = useTypedSelector(
    state => state.templates.templateDetail
  )

  useEffect(() => {
    dispatch(TemplateActions.selectTemplate(Number(id)))
    return () => {
      dispatch(TemplateActions.removeSelectedTemplate())
    }
  }, [id, dispatch])

  const initialState = useMemo(() => {
    return {
      title: '',
      content: '',
      category: 'GENERAL',
    }
  }, [])
  const [formData, setFormData] = useState(initialState)

  useEffect(() => {
    if (templateDetail) {
      setFormData({
        title: templateDetail.title,
        content: templateDetail.content,
        category: templateDetail.category,
      })
    } else if (templateDetail === null) {
      history.goBack()
    } else {
      setFormData(initialState)
    }
  }, [history, initialState, templateDetail])

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target
    setFormData(old => {
      const val = name === 'category' ? value.toUpperCase().trim() : value
      return { ...old, [name]: val }
    })
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const action = TemplateActions.updateTemplate({
      id: Number(id),
      title: formData.title,
      content: formData.content,
      category: formData.category,
    })

    dispatch(LoadingAction.open('Updating Template...'))
    dispatch(action)
      .then(() => {
        dispatch(SnackbarAction.open('Success Update Template'))
        history.goBack()
      })
      .finally(() => dispatch(LoadingAction.close()))
  }

  const onDelete = (id: number) => {
    dispatch(
      AlertDialogAction.open(
        'Delete Template',
        `Are your sure wan to delete template (${id})`,

        () => {
          dispatch(LoadingAction.open('Deleting Template...'))
          dispatch(TemplateActions.deleteTemplate(id))
            .then(() => {
              history.goBack()
            })
            .catch(catchErrorWithDispatch(dispatch))
            .finally(() => {
              dispatch(LoadingAction.close())
            })
        }
      )
    )
  }
  return (
    <div>
      <AppBar position='relative'>
        <Toolbar className='space-x-3'>
          <AppBackButton />
          <Typography variant='h6' noWrap>
            Update Template ({id})
          </Typography>
          {id && (
            <IconButton color={'inherit'} onClick={() => onDelete(Number(id))}>
              <DeleteIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      <Grid container spacing={1} className='p-4'>
        <Grid item xs={12}>
          <TemplateAvailableListComponent />
        </Grid>

        <Grid item xs={6}>
          <TemplatePreviewMessageComponent template={formData.content} />
        </Grid>
        <Grid item xs={6}>
          <form onSubmit={onSubmit}>
            <Card>
              <CardContent>
                <TextField
                  label={'Template Title'}
                  name={'title'}
                  value={formData.title}
                  onChange={onChange}
                  fullWidth
                  required
                />

                <TextField
                  label={'Template Category'}
                  name={'category'}
                  value={formData.category}
                  onChange={onChange}
                  fullWidth
                  required
                />

                <div className='mt-2' />
                <TextField
                  label='Template Content'
                  name='content'
                  value={formData.content}
                  onChange={onChange}
                  rows={20}
                  multiline
                  fullWidth
                  required
                />
              </CardContent>
              <CardActions className='justify-end'>
                <Button
                  onClick={() => {
                    setFormData({
                      title: formData.title,
                      content: TemplateUtils.sampleTemplateContent,
                      category: formData.category,
                    })
                  }}
                >
                  Sample
                </Button>
                <Button
                  color={'primary'}
                  variant={'contained'}
                  type='submit'
                  disabled={isLoading}
                >
                  Submit
                </Button>
              </CardActions>
            </Card>
          </form>
        </Grid>
      </Grid>
    </div>
  )
}

export default TemplateDetailPage
