import React, { useState } from 'react'
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { AuthenticationService } from './service/authenticationService'
import { useDispatch } from 'react-redux'
import { UserProfileType } from '../redux/reducer/userProfileReducer'
import userProfileService from '../common/service/userProfileService'
import { SnackbarAction } from '../redux/reducer/snackbarReducer'
import { useTypedSelector } from '../redux/reducer'
import { CommonReducer } from '../redux/reducer/commonReducer'
import { catchErrorWithDispatch } from '../common/ApiUtils'
import tokenService from '../common/service/tokenService'
import UserRole from '../common/constant/userRole'

const LoginPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [open, setOpen] = React.useState(false)
  const commonReducer: CommonReducer = useTypedSelector(state => state.common)

  const onSignInClick = async () => {
    AuthenticationService.login(username, password)
      .then(value => {
        if (value.data) {
          tokenService.setToken(value.data)

          return AuthenticationService.getSelf()
        }
      })
      .then(value => {
        if (value) {
          dispatch({
            type: UserProfileType.LOGIN_SUCCESS,
            payload: value.data,
          })
          dispatch(SnackbarAction.open(`Welcome, ${value.data.fullName}`))
          userProfileService.setUserProfile(value.data)

          const { role } = value.data
          if (UserRole.isAgent(role)) {
            history.push('/agent-portal')
          } else if (UserRole.isAgentStaff(role)) {
            history.push('/agent-staff-portal')
          } else {
            history.push('/admin-portal')
          }
        } else {
          return Promise.reject('ERROR')
        }
      })
      .catch(catchErrorWithDispatch(dispatch))
  }

  const onResetPasswordClick = () => {
    setOpen(true)
  }
  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key.toLowerCase() === 'enter' && username && password) {
      onSignInClick()
    }
  }

  function renderImage() {
    if (!commonReducer.landingPageAttachments.length) {
      return <div />
    }

    const index = commonReducer.landingPageAttachments?.length - 1
    const url = commonReducer.landingPageAttachments[index].attachmentUrl

    return (
      <div className='flex-1'>
        <div className='flex justify-center'>
          <div>
            <img className='max-h-screen' src={url} alt='announcement' />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='bg-gray-300 h-screen px-4'>
      <div className='flex justify-center items-center h-screen'>
        {renderImage()}
        <Container className='prose m-4 flex-1'>
          <Card>
            <CardContent onKeyPress={onKeyPress}>
              <h1>Welcome Back,</h1>
              <div>Enter your login details below</div>

              <TextField
                label='Username'
                margin='normal'
                onChange={event => setUsername(event.target.value)}
                fullWidth
                required
              />
              <TextField
                id='password'
                label='Password'
                type='password'
                margin='normal'
                onChange={event => setPassword(event.target.value)}
                fullWidth
                required
              />
              <div className='p-4' />
              <Button
                variant='contained'
                color='primary'
                size='large'
                fullWidth
                onClick={onSignInClick}
              >
                Sign In
              </Button>
              <div className='my-2' />

              <Button
                variant='text'
                color='secondary'
                fullWidth
                onClick={onResetPasswordClick}
              >
                Forget Password
              </Button>

              <Divider />

              <div className='text-center'>
                <span>Not an agent yet?</span>
                <span className='ml-4'>
                  <Button
                    color='primary'
                    size='large'
                    variant='outlined'
                    onClick={() => history.push('/sign-up')}
                  >
                    Sign up
                  </Button>
                </span>
              </div>
            </CardContent>
          </Card>
        </Container>
      </div>
      <ResetPasswordDialog open={open} closeDialog={() => setOpen(false)} />
    </div>
  )
}

export default LoginPage

function ResetPasswordDialog({
  open,
  closeDialog,
}: {
  open: boolean
  closeDialog: Function
}) {
  const dispatch = useDispatch()
  const [username, setUsername] = useState('')

  const [loading, setLoading] = useState(false)
  const handleClose = () => {
    closeDialog()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
    >
      <DialogTitle id='form-dialog-title'>Forgot Password</DialogTitle>
      <form
        onSubmit={e => {
          e.preventDefault()
          setLoading(true)

          console.log(username)

          AuthenticationService.forgetPassword(username)
            .then(() => {
              dispatch(SnackbarAction.open('Success, please check your email.'))
              setUsername('')
              closeDialog()
            })
            .catch(catchErrorWithDispatch(dispatch))
            .finally(() => {
              setLoading(false)
            })
        }}
      >
        <DialogContent>
          <DialogContentText>
            To reset your password, please enter your username here. We will
            send an email with reset link to your email address.
          </DialogContentText>

          <TextField
            autoFocus
            margin='dense'
            id='username'
            label='username'
            value={username}
            onChange={e => {
              setUsername(e.target.value)
            }}
            required
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='secondary'>
            Cancel
          </Button>
          <Button color='primary' type='submit' disabled={loading}>
            {loading ? (
              <CircularProgress size={20} color='inherit' />
            ) : (
              'Submit'
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
