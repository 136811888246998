import React from 'react'
import ReactDOM from 'react-dom'
import './assets/main.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import store from './redux/store'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import axios, { AxiosError } from 'axios'
import tokenService from './common/service/tokenService'
import { AuthenticationService } from './preLogin/service/authenticationService'
import { createMuiTheme } from '@material-ui/core/'
import { ThemeProvider } from '@material-ui/core'

const muiTheme = createMuiTheme({
  typography: {
    fontSize: 12,
  },
  props: {
    MuiList: {
      dense: true,
    },
  },
})

axios.interceptors.request.use(config => {
  config.baseURL = process.env.REACT_APP_API_URL
  const token = tokenService.getAccessToken()
  if (token && !config.headers['Authorization']) {
    config.headers['Authorization'] = `Bearer ${token}`
  }
  config.headers['Access-Control-Allow-Origin'] = '*'

  return config
})

axios.interceptors.response.use(
  value => {
    return value
  },
  (error: AxiosError) => {
    const originalRequest = error.config
    if (error.response?.status === 401) {
      const { error: err, error_description } = error.response?.data
      const cond1 = err === 'invalid_token'
      const cond2 = error_description?.startsWith('Access token expired')
      const cond3 = error_description?.startsWith(
        'Invalid refresh token (expired)'
      )

      const disabled = err === 'unauthorized'
      const disabled1 = error_description === 'User is disabled'

      if (disabled && disabled1) {
        console.warn('USER IS DISABLED')

        tokenService.clearToken()
        window.location.replace('/#/login')

        return Promise.reject({
          message: 'User is disabled, please contact your superior.',
        })
      } else if (cond1 && cond3) {
        console.warn('INVALID REFRESH TOKEN')

        tokenService.clearToken()
        window.location.replace('/#/login')

        return Promise.reject({
          message: 'Token Expired, Please Login Again.',
        })
      } else if (cond1 && cond2) {
        console.warn('ACCESS TOKEN EXPIRED')

        return AuthenticationService.refreshToken(
          tokenService.getRefreshToken() || ''
        ).then(res => {
          tokenService.clearToken()
          tokenService.setToken(res.data)

          originalRequest.headers[
            'Authorization'
          ] = `Bearer ${res.data.access_token}`
          return axios(originalRequest)
        })
      }
    }

    if (error.response?.status === 400) {
      const { error: err, error_description } = error.response?.data
      const cond1 = err === 'invalid_grant'
      const cond2 = error_description?.startsWith('User is disabled') //Haven't verified email
      const cond3 = error_description?.startsWith('User account is locked') //Not approved by manager

      if (cond1 && cond2) {
        console.warn('USER IS DISABLED')

        tokenService.clearToken()
        window.location.replace('/#/login')

        return Promise.reject({
          message:
            'Account is disabled. Please verify your email via the activation link that we have sent to your email.',
        })
      } else if (cond1 && cond3) {
        console.warn('USER IS LOCKED')

        tokenService.clearToken()
        window.location.replace('/#/login')

        return Promise.reject({
          message:
            'Account is locked. Please wait for the manager to verify your account first.',
        })
      }
    }
    return Promise.reject(error)
  }
)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={muiTheme}>
          <App />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
