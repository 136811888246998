import React from 'react'
import UserCodeComponent from './component/userCode.component'
import PackageShowDescriptionComponent from './component/packageShowDescription.component'

export const GeneralTab = () => {
  return (
    <>
      <div className='my-2'>
        <UserCodeComponent />
      </div>
      <div className='my-2'>
        <PackageShowDescriptionComponent />
      </div>
      {/*<div className='my-2'>*/}
      {/*  <OrderEmailToAgentComponent />*/}
      {/*</div>*/}
    </>
  )
}
