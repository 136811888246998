import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Tooltip,
} from '@material-ui/core'
import UserService from '../service/userService'
import { IUserPermission } from '../bean/IUserPermission'
import { catchErrorWithDispatch } from '../../../common/ApiUtils'
import { useDispatch } from 'react-redux'
import PermissionService from '../../../domain/permission/PermissionService'
import { IPermissionBean } from '../../../domain/permission/IPermissionBean'
import _ from 'lodash'
import { SnackbarAction } from '../../../redux/reducer/snackbarReducer'

interface UserUpdatePermissionDialogProps {
  open: boolean
  agentId?: number
  onDialogClose: () => void
}

const UserUpdatePermissionDialog = (props: UserUpdatePermissionDialogProps) => {
  const dispatch = useDispatch()
  const { open, onDialogClose, agentId } = props

  const [permissions, setPermissions] = useState<IPermissionBean[] | undefined>(
    undefined
  )
  const [userPermissions, setUserPermissions] = useState<
    IUserPermission[] | undefined
  >(undefined)
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([])

  const handleDialogClose = () => {
    setPermissions(undefined)
    setSelectedPermissions([])
    onDialogClose()
  }

  const toggleSelectedPermissions = (value: string) => {
    setSelectedPermissions(prevState => {
      const b = _.includes(prevState, value)
      if (b) {
        return _.filter(prevState, v => !_.isEqual(v, value))
      } else {
        return [...prevState, value]
      }
    })
  }

  const handleUpdateClick = () => {
    if (agentId) {
      const permissionIds: number[] = selectedPermissions
        .filter(
          selected =>
            !_.find(userPermissions, o => _.isEqual(o.value, selected))
        )
        .map(value => permissions?.find(o => o.value === value)?.id || 0)
        .filter(o => !!o)

      UserService.assignPermission(agentId, permissionIds)
        .then(() => {
          dispatch(SnackbarAction.open('Success assign permission.'))
          handleDialogClose()
        })
        .catch(catchErrorWithDispatch(dispatch))
    }
  }

  const toDisplay: Record<string, IPermissionBean[]> = useMemo(
    () => _.groupBy(permissions, p => _.split(p.value, ':')[0]),
    [permissions]
  )

  useEffect(() => {
    if (open && agentId) {
      PermissionService.getPermission()
        .then(value => setPermissions(value.data))
        .catch(catchErrorWithDispatch(dispatch))

      UserService.getPermissions(agentId)
        .then(o => {
          setUserPermissions(o.data)
          setSelectedPermissions(o.data.map(value => value.value))
        })
        .catch(catchErrorWithDispatch(dispatch))
    }
  }, [agentId, dispatch, open])

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      fullWidth={true}
      maxWidth={'md'}
    >
      <DialogTitle>Update Principle for Agent {agentId}</DialogTitle>
      <DialogContent>
        {permissions ? (
          <>
            {Object.entries(toDisplay).map(([key, values]) => {
              return (
                <div className='my-2'>
                  <div>{key}</div>
                  {values.map(permission => {
                    console.log(
                      selectedPermissions,
                      permission.value,
                      selectedPermissions.includes(permission.value)
                    )
                    const selected = selectedPermissions.includes(
                      permission.value
                    )

                    return (
                      <Tooltip title={permission.description}>
                        <FormControlLabel
                          checked={selected}
                          disabled={
                            !!_.find(userPermissions, o =>
                              _.isEqual(o.value, permission.value)
                            )
                          }
                          control={
                            <Checkbox
                              name={permission.value}
                              onChange={() =>
                                toggleSelectedPermissions(permission.value)
                              }
                            />
                          }
                          label={permission.value}
                        />
                      </Tooltip>
                    )
                  })}
                </div>
              )
            })}
          </>
        ) : (
          <CircularProgress />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant={'outlined'}
          color={'default'}
          onClick={handleDialogClose}
        >
          Cancel
        </Button>
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={handleUpdateClick}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UserUpdatePermissionDialog
