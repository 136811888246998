import { WithdrawalBean } from '../../features/claim/bean/WithdrawalBean'
import { ExportToCsv, Options as csvOptions } from 'export-to-csv'
import _ from 'lodash'
import CommissionType from '../../common/constant/commissionType'
import DateUtils from '../../common/dateUtils'

export function exportWithdrawalsToCsv(
  data: WithdrawalBean[],
  fileName: string,
  title: string
) {
  if (!data) return

  const options: csvOptions = {
    filename: fileName,
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    title,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
  }

  const csvExporter = new ExportToCsv(options)

  // sanitise data, make all undefined or null to ''
  //  and filter unused data
  const sanitisedWithdrawals = data
    .map((input: WithdrawalBean) =>
      _(input)
        .toPairs()
        .map(([key, value]) => (_.isNil(value) ? [key, ''] : [key, value]))
        .fromPairs()
        .value()
    )
    .map(input => {
      // rearrange columns and remove entity

      const displayCommissionRate =
        (input.commissionType &&
          (input.commissionType === CommissionType.PERCENTAGE
            ? `${input.commissionRate}%`
            : `RM ${input.commissionRate}`)) ||
        'N/A'

      return {
        '#': input.id,
        transactionDetails: input.description,
        productCategory: input?.refOrderEntity?.productCategory ?? '',
        productPackage: input.packageName,
        accountNo: input.accountNo,
        orderNo: input.refOrderEntity.orderNo ?? '',
        invoiceNo: input.refOrderEntity.invoiceNo ?? '',
        date: DateUtils.toDateString(
          input.refOrderEntity?.activatedOn || input.createdDate
        ),
        transactionType: input.transactionType,
        amount: input.amount,
        commissionRate: displayCommissionRate,
      }
    })
  csvExporter.generateCsv(sanitisedWithdrawals)
}
