import client, { AxiosResponse } from 'axios'

const url = `/api/v1/enquiries`
class EnquiryService {
  static postEnquiry(
    customerId: number,
    question: string
  ): Promise<AxiosResponse> {
    return client.post(`${url}`, { customerId, question })
  }
}

export default EnquiryService
