import React, { useEffect, useState } from 'react'
import { AnnouncementService } from '../announcementService'
import { AnnouncementBean } from '../bean/AnnouncementBean'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core'
import DateUtils from '../../../common/dateUtils'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import PublishIcon from '@material-ui/icons/Publish'

const AnnouncementListComponent = () => {
  const [announcements, setAnnouncements] = useState<AnnouncementBean[]>([])
  const [refresh, setRefresh] = useState(false)
  useEffect(() => {
    AnnouncementService.findAll().then(resp =>
      setAnnouncements(resp.data.content)
    )
  }, [refresh])

  function onPublishClick(id: number) {
    AnnouncementService.publishAnnouncement(id).then(() => setRefresh(!refresh))
  }

  function onDeleteClick(id: number) {
    AnnouncementService.deleteById(id).then(() => setRefresh(!refresh))
  }

  return (
    <div>
      <Card>
        <CardHeader title={'Announcements'} />
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Attachments</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell>Create Date</TableCell>
                <TableCell>Publish Date</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {announcements.map(value => {
                return (
                  <TableRow key={value.id}>
                    <TableCell> {value.id}</TableCell>
                    <TableCell> {value.title}</TableCell>
                    <TableCell>
                      {value.attachmentUrls.map((v, index) => {
                        return (
                          <Button key={v}>
                            <a
                              href={v}
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              Attachment {index + 1}
                            </a>
                          </Button>
                        )
                      })}
                    </TableCell>
                    <TableCell> {value.createdBy}</TableCell>
                    <TableCell>
                      {DateUtils.toLocaleDateString(
                        value.createDate.toString()
                      )}
                    </TableCell>
                    <TableCell>
                      {DateUtils.toLocaleDateString(
                        value.publishDate?.toString()
                      )}
                    </TableCell>
                    <TableCell align='right'>
                      <Tooltip title={'Publish'}>
                        <IconButton
                          disabled={!!value.publishDate}
                          color={'inherit'}
                          onClick={() => onPublishClick(value.id)}
                        >
                          <PublishIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title={'Delete'}>
                        <IconButton
                          color={'inherit'}
                          onClick={() => onDeleteClick(value.id)}
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  )
}

export default AnnouncementListComponent
