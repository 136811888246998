import React from 'react'
import { Paper, TableContainer } from '@material-ui/core'
import { ClaimBean } from '../bean/claimBean'
import ClaimTable from './claimTable'

const ClaimTableComponent = (props: { claims: ClaimBean[] }) => {
  const { claims } = props

  return (
    <TableContainer component={Paper}>
      <ClaimTable claims={claims} />
    </TableContainer>
  )
}

export default ClaimTableComponent
