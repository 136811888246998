import React, { useEffect, useState } from 'react'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom'

import {
  AppBar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { ProductAction } from '../../redux/reducer/productReducer'
import { useTypedSelector } from '../../redux/reducer'

import DeleteIcon from '@material-ui/icons/Delete'
import ProductCategoryPage from '../productCategoryManagement/productCategoryPage'
import ProductPackagePage from '../productPackageManagement/productPackagePage'
import { AlertDialogAction } from '../../redux/reducer/alertDialogReducer'

function ProductPage() {
  return (
    <div>
      <ProductPageSwitch />
    </div>
  )
}

export default ProductPage

const ProductListComponent = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const productState = useTypedSelector(state => state.product)

  const toDisplayList = ['name', '']
  useEffect(() => {
    dispatch(ProductAction.getProduct())
  }, [dispatch])

  return (
    <div>
      <AppBar position='relative'>
        <Toolbar className='space-x-3'>
          <Typography variant='h6' noWrap>
            Product
          </Typography>
        </Toolbar>
      </AppBar>

      <div className='m-8'>
        <AddProductComponent />
      </div>

      <div className='m-8'>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {toDisplayList.map(text => (
                  <TableCell key={text}>{text.toUpperCase()}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {productState.products.map(item => {
                return (
                  <TableRow
                    key={item.id}
                    className='hover:bg-gray-200 cursor-pointer'
                    onClick={() => {
                      history.push('/admin-portal/product-category/' + item.id)
                    }}
                  >
                    <TableCell>{item.name}</TableCell>
                    <TableCell align='right'>
                      <IconButton
                        size='small'
                        aria-label='delete'
                        onClick={(e: React.MouseEvent) => {
                          e.preventDefault()
                          e.stopPropagation()

                          dispatch(
                            AlertDialogAction.open(
                              <>
                                Delete{' '}
                                <span className='font-bold'>{item.name}</span>
                              </>,
                              <>
                                Are you sure to delete{' '}
                                <span className='font-bold'>{item.name}</span>{' '}
                                and its <b>Categories</b> and <b>Packages</b>?
                              </>,
                              () => {
                                dispatch(ProductAction.deleteProduct(item.id))
                              }
                            )
                          )
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}

const AddProductComponent = () => {
  const dispatch = useDispatch()
  const [name, setName] = useState('')

  const handleSubmit = () => {
    dispatch(ProductAction.postProduct(name, undefined))
    resetName()
  }

  const resetName = () => setName('')

  return (
    <Card>
      <CardHeader title='Add Product' />
      <CardContent>
        <TextField
          id='name'
          name='name'
          label='Name'
          value={name}
          onChange={event => {
            setName(event.target.value)
          }}
        />
      </CardContent>
      <CardActions className='justify-end'>
        <Button onClick={resetName}>Reset</Button>
        <Button color='primary' variant='contained' onClick={handleSubmit}>
          Submit
        </Button>
      </CardActions>
    </Card>
  )
}

const ProductPageSwitch = () => {
  const routeMatch = useRouteMatch()

  return (
    <Switch>
      <Route path={`${routeMatch.path}`}>
        <ProductListComponent />
      </Route>

      <Route path={`${routeMatch.path}/:productId`}>
        <ProductCategoryPage />
      </Route>
      <Route path={`${routeMatch.path}/:categoryId`}>
        <ProductPackagePage />
      </Route>
      <Route path={routeMatch.path}>
        <Redirect to={`${routeMatch.path}`} />
        Hello World
      </Route>
    </Switch>
  )
}
