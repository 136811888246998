import React, { useState } from 'react'
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useTypedSelector } from '../../../redux/reducer'
import { WithdrawalBean } from '../bean/WithdrawalBean'
import CommissionType from '../../../common/constant/commissionType'
import userProfileService from '../../../common/service/userProfileService'
import { useDispatch } from 'react-redux'
import { SnackbarAction } from '../../../redux/reducer/snackbarReducer'
import { UserCodeBean } from '../../../common/service/UserCodeBean'
import CommonUtils from '../../../common/commonUtils'
import DateUtils from '../../../common/dateUtils'

const toDisplayList = [
  'Withdrawal ID',
  'Claim ID',
  'User ID',

  'Agent',
  'Transaction Details',
  'Product Package',
  'Account No',
  'Order No',
  'Date',
  'Transaction Type',
  'Amount (RM)',
  'Commission Rate',
  'Remark',
  'Remark Date',
]

interface WithdrawalSearchTableComponent {
  withdrawals: WithdrawalBean[]
}

const WithdrawalSearchTableComponent = ({
  withdrawals = [],
}: WithdrawalSearchTableComponent) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const portalSettingReducer = useTypedSelector(state => state.portalSetting)
  const userCode: UserCodeBean = useTypedSelector(
    state => state.portalSetting.userCode
  )

  // Paginator
  const rowPerPageOptions = portalSettingReducer.paginator.rowPerPageOptions
  const [rowsPerPage, setRowsPerPage] = React.useState(
    portalSettingReducer.paginator.defaultRowPerPage
  )
  const [page, setPage] = useState(0)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  // END Paginator

  function getDisplayWithdrawals(): WithdrawalBean[] {
    return withdrawals.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    )
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {toDisplayList.map(text => (
              <TableCell key={text}>
                <span className='font-bold text-gray-800'>{text}</span>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {getDisplayWithdrawals().map(value => {
            const textColorClass =
              Number(value.amount) < 0 ? 'text-red-500' : 'text-green-500'

            const bgColorClass =
              Number(value.amount) < 0 ? 'bg-red-500' : 'bg-green-500'

            const displayCommissionRate =
              (value.commissionType &&
                (value.commissionType === CommissionType.PERCENTAGE
                  ? `${value.commissionRate}%`
                  : `RM ${value.commissionRate}`)) ||
              'N/A'

            const displayName =
              value.refUserEntity.companyName &&
              value.refUserEntity.companyRegNo
                ? `${value.refUserEntity.companyName} (${value.refUserEntity.companyRegNo})`
                : value.refUserEntity.fullName

            return (
              <TableRow
                className='hover:bg-gray-200 cursor-pointer select-none'
                key={value.id}
                onClick={event => {
                  event.stopPropagation()
                  event.preventDefault()

                  const orderId = value.orderId

                  if (orderId) {
                    const url = userProfileService.isAgent()
                      ? `/agent-portal/order-detail/${orderId}`
                      : `/admin-portal/order-detail/${orderId}`

                    history.push(url)
                  } else {
                    dispatch(
                      SnackbarAction.open(
                        'This withdrawal not link to order.',
                        'info'
                      )
                    )
                  }
                }}
              >
                <TableCell>{value.id}</TableCell>
                <TableCell>
                  {value.claimId && (
                    <Button
                      variant={"outlined"}
                      onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()

                        userProfileService.getUserProfile()?.role ===
                        'ROLE_AGENT'
                          ? history.push({
                              pathname: `/agent-portal/view-claim/${value.id}`,
                            })
                          : history.push({
                              pathname: `/admin-portal/view-claim/${value.id}`,
                            })
                      }}
                    >
                      {value.claimId}
                    </Button>
                  )}
                </TableCell>
                <TableCell>
                  {CommonUtils.getUserCode(value.refUserEntity, userCode)}
                </TableCell>
                <TableCell>
                  <Tooltip title={value.refUserEntity.fullName}>
                    <div>{displayName}</div>
                  </Tooltip>
                </TableCell>

                <TableCell>{value.description}</TableCell>
                <TableCell>
                  <div className='mb-2 font-bold italic'>
                    {value?.refOrderEntity?.productCategory}
                  </div>
                  <div>{value.packageName}</div>
                </TableCell>
                <TableCell>{value.accountNo}</TableCell>
                <TableCell>{value.refOrderEntity?.orderNo}</TableCell>

                <TableCell>
                  {new Date(value.createdDate).toDateString()}
                </TableCell>
                <TableCell>
                  <span className={`p-1 text-white rounded ${bgColorClass}`}>
                    {value.transactionType}
                  </span>
                </TableCell>
                <TableCell>
                  <span className={textColorClass}>
                    {Number(value.amount).toFixed(2)}
                  </span>
                </TableCell>
                <TableCell>{displayCommissionRate}</TableCell>
                <TableCell>{value.remark}</TableCell>
                <TableCell>
                  {DateUtils.toDateString(value.remarkDate)}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={rowPerPageOptions}
        component='div'
        count={withdrawals.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </TableContainer>
  )
}

export default WithdrawalSearchTableComponent
