import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../redux/reducer'
import { AlertDialogType } from '../../redux/reducer/alertDialogReducer'

export default function AlertDialog() {
  const dialogState = useTypedSelector(state => state.alert)
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch({
      type: AlertDialogType.TOGGLE_CLOSE,
    })
  }

  const handleOkClick = () => {
    if (dialogState.onPositiveClick) {
      dialogState.onPositiveClick()
    }

    dispatch({
      type: AlertDialogType.TOGGLE_CLOSE,
    })
  }
  return (
    <Dialog
      fullWidth={true}
      maxWidth={'xs'}
      open={dialogState.open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{dialogState.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {dialogState.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {dialogState.onPositiveClick && (
          <Button onClick={handleClose} variant={'text'} color='secondary'>
            CANCEL
          </Button>
        )}
        <Button onClick={handleOkClick} variant={'contained'} color='primary'>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}
