import React, { useEffect } from 'react'
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import SubmitClaimTableComponent from '../../../common/withdrawal/SubmitClaimTableComponent'
import {
  ClaimReducerActions,
  ClaimReducerProps,
} from '../../../redux/reducer/claimReducer'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../../redux/reducer'
import WithdrawalService from '../service/withdrawalService'
import { catchErrorWithDispatch } from '../../../common/ApiUtils'
import { SnackbarAction } from '../../../redux/reducer/snackbarReducer'

interface AddWithdrawalDialogProps {
  open: boolean
  claimId?: number
  agentId?: number
  onClose: () => void
  onSuccess: () => void
}

const AddWithdrawalDialog = (props: AddWithdrawalDialogProps) => {
  const { open, claimId, agentId, onClose } = props
  const isLoading = false

  const dispatch = useDispatch()

  const { withdrawals, selectedIds }: ClaimReducerProps = useTypedSelector(
    state => state.claim
  )

  function handleCancelClick() {
    onClose()
  }

  function handleOkClick() {
    if (claimId) {
      onClose()
      const withdrawalIds = selectedIds
      WithdrawalService.assignToClaim(claimId!!, withdrawalIds)
        .then(() => {
          dispatch(
            SnackbarAction.open(
              `Success assign withdrawal (${withdrawalIds.join(
                ','
              )}) to Claim (${claimId})`
            )
          )
          props.onSuccess()
        })
        .catch(catchErrorWithDispatch(dispatch))
    }
  }

  const handleClick = (id: number) => {
    dispatch(ClaimReducerActions.withdrawalClick(id))
  }

  const handleCheckAllClick = (ids: number[], checked: boolean) => {
    dispatch(ClaimReducerActions.checkAllClick(ids, checked))
  }

  useEffect(() => {
    if (open && agentId) {
      dispatch(ClaimReducerActions.fetchWithdrawalsById(agentId))
    }
  }, [agentId, dispatch, open])
  return (
    <Dialog open={open} maxWidth={'xl'}>
      <DialogTitle>Add Withdrawal to Claim({claimId})</DialogTitle>
      <DialogContent>
        <SubmitClaimTableComponent
          agentId={agentId || 0}
          withdrawals={withdrawals}
          onClaimClick={handleClick}
          onCheckAllClick={handleCheckAllClick}
          selectedWithdrawals={selectedIds}
        />
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <div>
            <CircularProgress color='inherit' />
          </div>
        ) : (
          <>
            <Button onClick={handleCancelClick}>Cancel</Button>
            <Button color='primary' variant='contained' onClick={handleOkClick}>
              OK
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default AddWithdrawalDialog
