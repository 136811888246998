import client, { AxiosResponse } from 'axios'
import { UserCodeBean } from './UserCodeBean'
import { PackageShowDescriptionBean } from './PackageShowDescriptionBean'
import { SystemSettingBean } from './SystemSettingBean'

const url = `/api/v1/system-settings`
export default class SystemSettingService {
  static getUserCode(): Promise<AxiosResponse<UserCodeBean>> {
    return client.get(url + '/user-code')
  }

  static updateUserCode(body: {
    manager?: string
    admin?: string
    staff?: string
    agent?: string
  }): Promise<AxiosResponse> {
    return client.patch(url + '/user-code', {
      manager: body.manager,
      admin: body.admin,
      staff: body.staff,
      agent: body.agent,
    })
  }

  static getPackageShowDescription(): Promise<
    AxiosResponse<PackageShowDescriptionBean>
  > {
    return client.get(url + '/package-show-description')
  }

  static updatePackageShowDescription(
    showDescription: boolean
  ): Promise<AxiosResponse<PackageShowDescriptionBean>> {
    return client.patch(url + '/package-show-description', {
      showDescription: showDescription,
    })
  }

  static getSetting(
    settingName: string
  ): Promise<AxiosResponse<SystemSettingBean>> {
    return client.get(url + '/' + settingName)
  }

  static updateSetting(settingName: string, body: Object) {
    return client.patch(url + '/' + settingName, {
      value: JSON.stringify(body),
    })
  }
}
