const initialState = {
  open: false,
  message: '',
  severity: 'error',
}

const snackbarReducer = (
  state = initialState,
  action: {
    type: string
    payload: any
  }
) => {
  switch (action.type) {
    case SnackbarType.OPEN:
      return {
        ...state,
        ...action.payload,
      }
    case SnackbarType.CLOSE:
      return {
        ...state,
        open: false,
      }
    default:
      return state
  }
}

export const SnackbarType = {
  OPEN: 'OPEN',
  CLOSE: 'CLOSE',
}

export const SnackbarAction = {
  open: (
    message: string,
    severity?: 'success' | 'info' | 'warning' | 'error' | undefined
  ) => {
    return {
      type: SnackbarType.OPEN,
      payload: {
        open: true,
        message: message,
        severity: severity,
      },
    }
  },
  openError: (message: string) => {
    return {
      type: SnackbarType.OPEN,
      payload: {
        open: true,
        message: message,
        severity: 'error',
      },
    }
  },
  close: () => {
    return {
      type: SnackbarType.CLOSE,
    }
  },
}

export default snackbarReducer
