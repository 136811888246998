import React, { useEffect } from 'react'
import AppNavContainer from '../AppNavContainer'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import { PAGE, ROUTES } from '../common/constant/pageKeyConstant'
import NavListItem from '../common/component/navListItem'
import OrderListingV2Page from '../sharePortal/orderManagement/orderListingV2.page'
import { PortalSettingActions } from '../redux/reducer/portalSettingReducer'
import {
  getDefaultCreatedOrderStatus,
  getOrderDisplayList,
} from '../redux/slice/orderSettingsSlice'
import { useDispatch } from 'react-redux'
import OrderDetailPage from '../sharePortal/orderManagement/orderDetailPage'
import NewOrderPage from '../agentPortal/orderManagement/newOrderPage'
import SettingsIcon from '@material-ui/icons/Settings'
import UserSettingPage from '../common/userSetting/userSettingPage'

const AgentStaffPortalIndex = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(PortalSettingActions.getUserCode())
    dispatch(PortalSettingActions.getPackageShowDescription())
    dispatch(getOrderDisplayList())
    dispatch(getDefaultCreatedOrderStatus())
  }, [dispatch])

  return (
    <AppNavContainer
      navList={<StaffAgentNav />}
      switch={<StaffAgentPortalSwitch />}
    />
  )
}

function StaffAgentNav() {
  const routeMatch = useRouteMatch()

  return (
    <>
      <NavListItem
        title='Order Management'
        icon={<AssignmentIndIcon />}
        navItems={[
          {
            key: PAGE.NewOrderPage,
            name: 'New Order',
            url: routeMatch.path + '/new-order',
          },
          {
            key: PAGE.OrderManagementPage2,
            name: 'Order',
            url: routeMatch.path + '/order-management',
          },
        ]}
      />

      <NavListItem
        title='Settings Management'
        icon={<SettingsIcon />}
        navItems={[
          {
            key: PAGE.UserSettingPage,
            name: 'User Setting Page',
            url: routeMatch.path + '/' + ROUTES.userSetting(),
          },
        ]}
      />
    </>
  )
}

function StaffAgentPortalSwitch() {
  const routeMatch = useRouteMatch()

  return (
    <Switch>
      <Route path={`${routeMatch.path}/orders`}>
        <OrderListingV2Page />
      </Route>
      <Route path={`${routeMatch.path}/order-detail/:id`}>
        <OrderDetailPage />
      </Route>
      <Route path={`${routeMatch.path}/new-order`}>
        <NewOrderPage />
      </Route>
      <Route path={`${routeMatch.path}/${ROUTES.userSetting()}`} exact>
        <UserSettingPage />
      </Route>
      <Route path={routeMatch.path}>
        <Redirect to={`${routeMatch.path}/orders`} />
      </Route>
    </Switch>
  )
}

export default AgentStaffPortalIndex
