import React from 'react'
import { Card, CardContent, CardHeader, Chip } from '@material-ui/core'
import { TemplateUtils } from './template.util'

const TemplateAvailableListComponent = () => {
  return (
    <Card>
      <CardHeader title={'Available List'} />
      <CardContent>
        <div className='flex flex-wrap'>
          {TemplateUtils.availableList.map(o => (
            <Chip className='m-1' key={o} label={'${' + o + '}'} />
          ))}
        </div>
      </CardContent>
    </Card>
  )
}

export default React.memo(TemplateAvailableListComponent)
