import React, { useEffect } from 'react'
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import { ROUTES } from '../../common/constant/pageKeyConstant'
import { useHistory } from 'react-router-dom'
import AppTitleBar from '../../component/appTitleBar'
import CommonUtils from '../../common/commonUtils'
import UserRole from '../../common/constant/userRole'
import { useTypedSelector } from '../../redux/reducer'
import { UserCodeBean } from '../../common/service/UserCodeBean'
import UserBean from '../../sharePortal/userManagement/bean/userBean'
import { UserActions } from '../../redux/reducer/userReducer'
import { useAppDispatch } from '../../redux/store'
import AgentStaffActionsMenuComponent from '../../adminPortal/userManagement/component/agentStaffActionsMenu.component'

const AgentStaffPage = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()

  const agentStaffs: UserBean[] = useTypedSelector(
    state => state.user.agentStaffs
  )
  const userCode: UserCodeBean = useTypedSelector(
    state => state.portalSetting.userCode
  )

  useEffect(() => {
    dispatch(UserActions.getAgentStaffs())
  }, [dispatch])

  return (
    <React.Fragment>
      <AppTitleBar
        title='Agent Staff Management'
        actionsComponent={
          <IconButton
            color='inherit'
            onClick={() => history.push(ROUTES.agentStaffCreate())}
          >
            <GroupAddIcon />
          </IconButton>
        }
      />
      <div className='m-8'>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {['ID', 'Username', 'Full Name', 'Email', 'Role', ''].map(
                  text => (
                    <TableCell key={text}>
                      <span className='font-bold text-gray-700'>{text}</span>
                    </TableCell>
                  )
                )}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {agentStaffs.map(user => {
                return (
                  <TableRow key={user.id} className='hover:bg-gray-200'>
                    <TableCell>
                      {CommonUtils.getUserCode(user, userCode)}
                    </TableCell>
                    <TableCell>{user.username}</TableCell>
                    <TableCell>{user.fullName}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{UserRole.toDisplay[user.role]}</TableCell>
                    <TableCell align='right'>
                      <div className=' text-white'>
                        {user.enabled ? (
                          <span className='p-1 rounded bg-green-300'>
                            ENABLE
                          </span>
                        ) : (
                          <span className='p-1 rounded bg-red-300'>
                            DISABLE
                          </span>
                        )}
                      </div>
                    </TableCell>
                    <TableCell align='right'>
                      <AgentStaffActionsMenuComponent user={user} />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </React.Fragment>
  )
}

export default AgentStaffPage
