import React, { useEffect, useState } from 'react'
import {
  AppBar,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core'
import UserBean from '../../sharePortal/userManagement/bean/userBean'
import { LoadingAction } from '../../redux/reducer/loadingBackdropReducer'
import AgentService from '../../adminPortal/userManagement/service/agentService'
import { SnackbarAction } from '../../redux/reducer/snackbarReducer'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../redux/reducer'
import { WithdrawalPostBody } from '../withdrawal/bean/withdrawalPostBody'
import WithdrawalService from './service/withdrawalService'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'qs'
import PlusOneIcon from '@material-ui/icons/PlusOne'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import { catchErrorWithDispatch } from '../../common/ApiUtils'
import { UserCodeBean } from '../../common/service/UserCodeBean'
import CommonUtils from '../../common/commonUtils'
import CreateWithdrawalDialog from '../../component/withdrawal/dialog/createWithdrawalDialog'

const CreateWithdrawal = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [agents, setAgents] = useState<UserBean[]>([])
  const [selectedAgent, setSelectedAgent] = useState<UserBean>()
  const [filterString, setFilterString] = useState('')
  const [refresh, setRefresh] = useState(false)
  const location = useLocation()
  const { pageNumber = 0 } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })
  const portalSettingReducer = useTypedSelector(state => state.portalSetting)

  const userCode: UserCodeBean = useTypedSelector(
    state => state.portalSetting.userCode
  )

  // Paginator
  const rowPerPageOptions = portalSettingReducer.paginator.rowPerPageOptions
  const [rowsPerPage, setRowsPerPage] = React.useState(
    portalSettingReducer.paginator.defaultRowPerPage
  )
  const [page, setPage] = useState(0)

  const handleChangePage = (event: unknown, newPage: number) => {
    history.replace({
      search: `?pageNumber=${newPage}`,
    })
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    history.replace({
      search: `?pageNumber=${0}`,
    })
  }
  useEffect(() => {
    if (agents.length) {
      setPage(Number(pageNumber))
    }
  }, [pageNumber, agents])
  // END Paginator

  function handleEditDialogClose(postBody?: WithdrawalPostBody) {
    setSelectedAgent(undefined)
    console.log(postBody)
    if (postBody) {
      dispatch(LoadingAction.open(`Creating ${postBody.transactionType}...`))
      WithdrawalService.createWithdrawal(postBody)
        .then(() => {
          dispatch(
            SnackbarAction.open(`Success Created ${postBody.transactionType}`)
          )
          setRefresh(!refresh)
        })
        .catch(catchErrorWithDispatch(dispatch))
        .finally(() => dispatch(LoadingAction.close()))
    }
  }

  useEffect(() => {
    AgentService.getAllAgents({
      excludeDisable: false,
      excludeSelf: false,
    })
      .then(value => {
        setAgents(value.data)
      })
      .catch(catchErrorWithDispatch(dispatch))
  }, [dispatch, refresh])

  function getDisplayAgents(): UserBean[] {
    return agents
      .filter(agent => {
        return [
          CommonUtils.getUserCode(agent, userCode),
          agent.username,
          agent.fullName,
        ].some(value =>
          value.toLowerCase().includes(filterString.toLowerCase())
        )
      })
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  }

  return (
    <div>
      <AppBar position='relative'>
        <Toolbar>
          <Typography variant='h6'>Create Withdrawal</Typography>
        </Toolbar>
      </AppBar>

      <div className='m-8'>
        <TableContainer component={Paper}>
          <div className='m-4'>
            <TextField
              name='filterString'
              value={filterString}
              onChange={e => {
                setFilterString(e.target.value)
              }}
              fullWidth
              placeholder='Type something to filter...'
            />
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <span className='text-gray-700 font-bold'>Agent ID</span>
                </TableCell>
                <TableCell>
                  <span className='text-gray-700 font-bold'>Username</span>
                </TableCell>
                <TableCell>
                  <span className='text-gray-700 font-bold'>Full Name</span>
                </TableCell>
                <TableCell align='right'>
                  <span className='text-gray-700 font-bold'>
                    Add Withdrawal / Submit Claim
                  </span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getDisplayAgents().map(agent => {
                return (
                  <TableRow
                    key={agent.id}
                    className='cursor-pointer hover:bg-gray-200'
                  >
                    <TableCell>
                      {CommonUtils.getUserCode(agent, userCode)}
                    </TableCell>
                    <TableCell>{agent.username}</TableCell>
                    <TableCell>
                      <Tooltip title={agent.fullName}>
                        <span>
                          {(function (user: UserBean) {
                            return user.companyName && user.companyRegNo
                              ? `${user.companyName} (${user.companyRegNo})`
                              : user.fullName
                          })(agent)}
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell align='right'>
                      <Tooltip title={'Add Withdrawal'}>
                        <IconButton
                          aria-label='delete'
                          onClick={e => {
                            e.stopPropagation()
                            e.preventDefault()
                            setSelectedAgent(agent)
                          }}
                        >
                          <PlusOneIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title={'Submit Claim'}>
                        <IconButton
                          aria-label='delete'
                          onClick={e => {
                            e.stopPropagation()
                            e.preventDefault()
                            history.push(
                              `/admin-portal/admin-submit-claim/${agent.id}`
                            )
                          }}
                        >
                          <NoteAddIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={rowPerPageOptions}
            component='div'
            count={agents.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
      <CreateWithdrawalDialog
        agentProp={selectedAgent}
        onClose={handleEditDialogClose}
      />
    </div>
  )
}

export default CreateWithdrawal
