import React, {
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import OrderAttachmentService from './orderAttachmentService'
import { OrderAttachmentBean } from './bean/orderAttachmentBean'
import { LoadingAction } from '../../redux/reducer/loadingBackdropReducer'
import { SnackbarAction } from '../../redux/reducer/snackbarReducer'
import { catchErrorWithDispatch } from '../../common/ApiUtils'
import { Button, Chip, LinearProgress } from '@material-ui/core'
import { AlertDialogAction } from '../../redux/reducer/alertDialogReducer'
import { useDispatch } from 'react-redux'
import { FileUtils } from '../../common/fileUtils'
import { FileUtilError } from '../../common/error/fileUtilError'
import { Alert } from '@material-ui/lab'

interface OrderAttachmentComponentProps {
  orderId: number
}

const OrderAttachmentComponent = (props: OrderAttachmentComponentProps) => {
  const { orderId } = props

  const dispatch = useDispatch()
  const inputRef = useRef<HTMLInputElement>(null)
  const [files, setFiles] = useState<File[]>([])

  const [orderAttachments, setOrderAttachments] = useState<
    OrderAttachmentBean[] | undefined
  >(undefined)

  const getOrderAttachment = useCallback(() => {
    OrderAttachmentService.getOrderAttachmentByOrderId(orderId).then(value => {
      const { data } = value
      const filter = data.filter(o => o.category === '')
      filter.sort((a, b) => b.id - a.id)
      setOrderAttachments(filter)
    })
  }, [orderId])

  useEffect(() => {
    getOrderAttachment()
  }, [getOrderAttachment])

  function handleFormSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    e.stopPropagation()
    if (orderId && files?.length) {
      dispatch(LoadingAction.open('Creating Order Attachment...'))
      OrderAttachmentService.createOrderAttachment(orderId, files || [])
        .then(() => {
          dispatch(SnackbarAction.open('Success Create Order Attachment'))

          if (inputRef.current) {
            inputRef.current.value = ''
          }

          getOrderAttachment()
        })
        .catch(catchErrorWithDispatch(dispatch))
        .finally(() => {
          dispatch(LoadingAction.close())
        })
    }
  }

  function handleDeleteOrderAttachment(id: number) {
    dispatch(
      AlertDialogAction.open(
        'Delete Attachment',
        'Are you sure to delete attachment?',
        () => {
          OrderAttachmentService.deleteOrderAttachmentById(id).then(() => {
            getOrderAttachment()
          })
        }
      )
    )
  }

  return (
    <div>
      <div className='border rounded p-4'>
        <form onSubmit={handleFormSubmit}>
          <input
            ref={inputRef}
            type='file'
            multiple={true}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              try {
                const files1 = FileUtils.handleFileChange(e)
                setFiles(files1)
              } catch (error) {
                if (error instanceof FileUtilError) {
                  e.target.value = ''
                  setFiles([])

                  dispatch(AlertDialogAction.open(error.title, error.message))
                }
              }
            }}
          />
          <Button
            type={'submit'}
            variant={'contained'}
            color={'primary'}
            className='m-4'
          >
            SUBMIT
          </Button>
        </form>
      </div>
      {orderAttachments === undefined ? (
        <>
          <LinearProgress />
        </>
      ) : (
        <>
          <Alert className='my-2' color='info'>
            Click to open in new tab
          </Alert>
          <div className='flex flex-wrap'>
            {orderAttachments.map(orderAttachment => {
              const url = orderAttachment.attachmentUrl
              const label = `Attachment-${orderAttachment.id} | ${orderAttachment.fileType}`
              return (
                <div key={orderAttachment.id} className='m-2'>
                  <Chip
                    size={'medium'}
                    label={label}
                    onClick={() => {
                      window.open(url, '_blank')
                    }}
                    onDelete={() =>
                      handleDeleteOrderAttachment(orderAttachment.id)
                    }
                  />
                </div>
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}

export default OrderAttachmentComponent
