import React from 'react'
import { AppBar, Toolbar, Typography } from '@material-ui/core'

function DebitNotePage() {
  return (
    <div>
      <AppBar position='relative'>
        <Toolbar className='space-x-3'>
          <Typography variant='h6' noWrap>
            Debit Note
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  )
}

DebitNotePage.propTypes = {}

export default DebitNotePage
