import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ErrorIcon from '@material-ui/icons/Error'
import { CommissionQuota } from '../../adminPortal/userManagement/bean/commissionQuota'
import CommissionItemComponent from './CommissionItemComponent'

interface CommissionAccordionComponentProps {
  commissions: CommissionQuota[]
  categoryName: string
}

const CommissionAccordionComponent = (
  props: CommissionAccordionComponentProps
) => {
  const { categoryName, commissions } = props

  const haveZeroPercent = commissions.some(value => value.percentage <= 0)
  const titleClass = haveZeroPercent ? 'text-red-600' : ''

  return (
    <>
      <Accordion key={categoryName}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id={categoryName}>
          <div className={'w-full flex'}>
            <div
              className={
                'flex-1 space-x-3 flex items-center text-bold ' + titleClass
              }
            >
              {haveZeroPercent && (
                <Tooltip title={'Have Unset Commission, Please Complete It'}>
                  <ErrorIcon />
                </Tooltip>
              )}

              <span>{categoryName}</span>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className='w-full divide-y divide-solid'>
            {commissions.map(commission => (
              <CommissionItemComponent commission={commission} />
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default CommissionAccordionComponent
