import React, { useEffect, useMemo, useState } from 'react'
import {
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from '@material-ui/core'
import TransactionType from '../constant/transactionType'
import CommissionType from '../constant/commissionType'
import { useTypedSelector } from '../../redux/reducer'
import { useHistory } from 'react-router'
import { WithdrawalBean } from '../../features/claim/bean/WithdrawalBean'
import qs from 'qs'
import { useLocation } from 'react-router-dom'
import VisibilityIcon from '@material-ui/icons/Visibility'
import userProfileService from '../service/userProfileService'
import { SnackbarAction } from '../../redux/reducer/snackbarReducer'
import { useDispatch } from 'react-redux'
import EditIcon from '@material-ui/icons/Edit'
import EditWithdrawalDialog from '../../component/withdrawal/dialog/editWithdrawalDialog'
import WithdrawalService from '../../features/claim/service/withdrawalService'
import { LoadingAction } from '../../redux/reducer/loadingBackdropReducer'
import { ClaimReducerActions } from '../../redux/reducer/claimReducer'
import { catchErrorWithDispatch } from '../ApiUtils'
import DateUtils from '../dateUtils'
import _ from 'lodash'

const toDisplayList = [
  'Transaction Details',
  'Product Package',
  'Account No',
  'Order No',
  'Date',
  'Transaction Type',
  'Amount (RM)',
  'Commission Rate',
  'Remark',
  'Remark Date',
]

interface SubmitClaimTableComponentProps {
  withdrawals: WithdrawalBean[]
  onClaimClick: (claimId: number) => void
  onCheckAllClick: (claimId: number[], checked: boolean) => void
  selectedWithdrawals: number[]
  agentId: number
}

const SubmitClaimTableComponent = (props: SubmitClaimTableComponentProps) => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const { withdrawals, agentId } = props

  const { pageNumber = 0 } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  const [filterString, setFilterString] = useState('')

  const [selectedWithdrawal, setSelectedWithdrawal] = useState<
    WithdrawalBean | undefined
  >(undefined)

  const withdrawalsAfterFilter = useMemo(() => {
    if (filterString === '') {
      return withdrawals
    }

    return withdrawals.filter(value =>
      [
        value.amount,
        value.description,
        value?.refOrderEntity?.productCategory,
        value.packageName,
        value.refOrderEntity?.orderNo,
        value.transactionType,
        value.commissionRate,
        value.commissionType,
      ].some(
        v =>
          v
            ?.toString()
            ?.toLocaleLowerCase()
            ?.includes(filterString.toLocaleLowerCase()) || false
      )
    )
  }, [filterString, withdrawals])

  // Paginator
  const portalSettingReducer = useTypedSelector(state => state.portalSetting)
  const rowPerPageOptions = portalSettingReducer.paginator.rowPerPageOptions
  const [rowsPerPage, setRowsPerPage] = React.useState(
    portalSettingReducer.paginator.defaultRowPerPage
  )
  const [page, setPage] = useState(0)

  const handleChangePage = (event: unknown, newPage: number) => {
    history.replace({
      search: `?pageNumber=${newPage}`,
    })
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    history.replace({
      search: `?pageNumber=${0}`,
    })
  }

  // END Paginator

  const displayWithdrawals = useMemo(
    () =>
      withdrawalsAfterFilter.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [page, rowsPerPage, withdrawalsAfterFilter]
  )

  useEffect(() => {
    if (withdrawalsAfterFilter.length) {
      setPage(Number(pageNumber))
    }
  }, [pageNumber, withdrawalsAfterFilter.length])

  const isDisableCheckbox = (value: WithdrawalBean): boolean => {
    // Agent Mandatory to Checked CHARGES and CLAWBACK
    return (
      userProfileService.isAgent() &&
      [TransactionType.CHARGES, TransactionType.CLAWBACK].includes(
        value.transactionType
      )
    )
  }

  const displayIds = useMemo(() => displayWithdrawals.map(o => o.id), [
    displayWithdrawals,
  ])
  const currentDisplaySelectedId = useMemo(() => {
    return props.selectedWithdrawals.filter(id => displayIds.includes(id))
  }, [displayIds, props.selectedWithdrawals])

  return (
    <TableContainer component={Paper}>
      <div className='m-4'>{`${props.selectedWithdrawals.length} of ${withdrawals.length} selected (${currentDisplaySelectedId.length} of ${displayIds.length} in current page)`}</div>
      <div className='m-4'>
        <TextField
          name='filterString'
          value={filterString}
          onChange={e => {
            setFilterString(e.target.value)
          }}
          fullWidth
          placeholder='Type something to filter...'
        />
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Checkbox
                checked={
                  currentDisplaySelectedId.length >= displayIds.length &&
                  currentDisplaySelectedId.every(id => displayIds.includes(id))
                }
                indeterminate={
                  currentDisplaySelectedId.length > 0 &&
                  currentDisplaySelectedId.length < displayIds.length &&
                  currentDisplaySelectedId.every(id => displayIds.includes(id))
                }
                onChange={(event, checked) => {
                  props.onCheckAllClick(
                    displayWithdrawals
                      .filter(v => !isDisableCheckbox(v))
                      .map(v => v.id),
                    checked
                  )
                }}
              />
            </TableCell>
            {toDisplayList.map(text => (
              <TableCell key={text}>{text}</TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {displayWithdrawals.map(value => {
            const textColorClass =
              Number(value.amount) < 0 ? 'text-red-500' : 'text-green-500'

            const bgColorClass =
              Number(value.amount) < 0 ? 'bg-red-500' : 'bg-green-500'

            const displayCommissioRate =
              (value.commissionType &&
                (value.commissionType === CommissionType.PERCENTAGE
                  ? `${value.commissionRate}%`
                  : `RM ${value.commissionRate}`)) ||
              'N/A'

            const canEditWithdrawal = [
              TransactionType.CHARGES,
              TransactionType.INCENTIVES,
            ].includes(value.transactionType)

            return (
              <TableRow
                className='hover:bg-gray-200 cursor-pointer select-none'
                key={value.id}
                onClick={() => {
                  if (isDisableCheckbox(value)) {
                    return
                  } else props.onClaimClick(value.id)
                }}
              >
                <TableCell>
                  <Checkbox
                    disabled={isDisableCheckbox(value)}
                    checked={_.includes(props.selectedWithdrawals, value.id)}
                  />
                </TableCell>

                <TableCell>{value.description}</TableCell>
                <TableCell>
                  <div className='mb-2 font-bold italic'>
                    {value?.refOrderEntity?.productCategory}
                  </div>
                  <div>{value.packageName}</div>
                </TableCell>
                <TableCell>{value.accountNo}</TableCell>
                <TableCell>{value.refOrderEntity?.orderNo}</TableCell>
                <TableCell className='truncate'>
                  <div>
                    Created: {DateUtils.toDateString(value.createdDate)}
                  </div>
                  <div>
                    Activated:{' '}
                    {DateUtils.toDateString(value.refOrderEntity?.activatedOn)}
                  </div>
                </TableCell>
                <TableCell>
                  <span className={`p-1 text-white rounded ${bgColorClass}`}>
                    {value.transactionType}
                  </span>
                </TableCell>
                <TableCell>
                  <span className={textColorClass}>
                    {Number(value.amount).toFixed(2)}
                  </span>
                </TableCell>
                <TableCell>{displayCommissioRate}</TableCell>
                <TableCell>{value.remark}</TableCell>
                <TableCell>
                  {DateUtils.toDateString(value.remarkDate)}
                </TableCell>
                <TableCell>
                  <Tooltip title={'View Order'}>
                    <IconButton
                      onClick={event => {
                        event.stopPropagation()
                        event.preventDefault()

                        const { orderId } = value
                        if (orderId) {
                          const url = userProfileService.isAgent()
                            ? `/agent-portal/order-detail/${orderId}`
                            : `/admin-portal/order-detail/${orderId}`

                          history.push(url)
                        } else {
                          dispatch(
                            SnackbarAction.open(
                              'This withdrawal not link to order.',
                              'info'
                            )
                          )
                        }
                      }}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>

                  {!userProfileService.isAgent() && canEditWithdrawal && (
                    <Tooltip title={'Edit Withdrawal'}>
                      <IconButton
                        onClick={event => {
                          event.stopPropagation()
                          event.preventDefault()
                          setSelectedWithdrawal(value)
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={rowPerPageOptions}
        component='div'
        count={withdrawalsAfterFilter.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      <EditWithdrawalDialog
        withdrawal={selectedWithdrawal}
        onClose={body => {
          setSelectedWithdrawal(undefined)

          const id = selectedWithdrawal?.id
          if (id && body) {
            dispatch(LoadingAction.open(`Updating Withdrawal ${id}`))
            WithdrawalService.patchWithdrawal(id, body)
              .then(() => {
                dispatch(SnackbarAction.open(`Success Update Withdrawal ${id}`))
                dispatch(ClaimReducerActions.fetchWithdrawalsById(agentId))
              })
              .catch(catchErrorWithDispatch(dispatch))
              .finally(() => {
                dispatch(LoadingAction.close())
              })
          }
        }}
      />
    </TableContainer>
  )
}

export default SubmitClaimTableComponent
