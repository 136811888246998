import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  TextField,
} from '@material-ui/core'
import { PortalSettingActions } from '../../../../../redux/reducer/portalSettingReducer'
import { useDispatch } from 'react-redux'
import { UserCodeBean } from '../../../../../common/service/UserCodeBean'
import { useTypedSelector } from '../../../../../redux/reducer'

const UserCodeComponent = () => {
  const dispatch = useDispatch()

  const userCode: UserCodeBean = useTypedSelector(
    state => state.portalSetting.userCode
  )
  const [form, setForm] = useState<UserCodeBean>({
    agent: '',
    staff: '',
    admin: '',
    manager: '',
  })

  useEffect(() => {
    dispatch(PortalSettingActions.getUserCode())
  }, [dispatch])

  useEffect(() => {
    setForm(userCode)
  }, [userCode])

  function handleTextChange(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    const { name, value } = event.target
    setForm(prevState => ({
      ...prevState,
      [name]: value.trim(),
    }))
  }

  function handleReset() {
    setForm(userCode)
  }

  function handleUpdate() {
    const body = {
      manager: userCode?.manager === form.manager ? undefined : form.manager,
      admin: userCode?.admin === form.admin ? undefined : form.admin,
      staff: userCode?.staff === form.staff ? undefined : form.staff,
      agent: userCode?.agent === form.agent ? undefined : form.agent,
    }
    dispatch(PortalSettingActions.updateUserCode(body))
  }

  function isSameWithDefault(): boolean {
    return (
      userCode?.manager === form.manager &&
      userCode?.admin === form.admin &&
      userCode?.staff === form.staff &&
      userCode?.agent === form.agent
    )
  }

  return (
    <Card>
      <CardHeader title={'User Code'} />
      <CardContent>
        <div className='grid gap-4'>
          <TextField
            label={'Manager'}
            name='manager'
            value={form?.manager}
            onChange={handleTextChange}
          />
          <TextField
            label={'Admin'}
            name='admin'
            value={form?.admin}
            onChange={handleTextChange}
          />
          <TextField
            label={'Staff'}
            name='staff'
            value={form?.staff}
            onChange={handleTextChange}
          />
          <TextField
            label={'Agent'}
            name='agent'
            value={form?.agent}
            onChange={handleTextChange}
          />
        </div>
      </CardContent>
      <CardActions className='justify-end'>
        <Button variant={'outlined'} color={'secondary'} onClick={handleReset}>
          RESET
        </Button>
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={handleUpdate}
          disabled={isSameWithDefault()}
        >
          UPDATE
        </Button>
      </CardActions>
    </Card>
  )
}

export default UserCodeComponent
