import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AppDispatch, AppState } from '../store'
import SystemSettingService from '../../common/service/SystemSettingService'
import { LoadingAction } from '../reducer/loadingBackdropReducer'
import { ORDER_SETTINGS_ACTIONS } from './orderSettings.constant'
import OrderStatus from '../../common/constant/orderStatus'

const type = 'orderSettings'

interface OrderSettingsProp {
  orderDisplayList: string[]
  defaultCreatedOrderStatus: OrderStatus
}

const initialState: OrderSettingsProp = {
  orderDisplayList: [],
  defaultCreatedOrderStatus: OrderStatus.AGENT_SUBMITTED,
}

export const getOrderDisplayList = createAsyncThunk<
  undefined,
  undefined,
  { dispatch: AppDispatch }
>(`${type}/getOrderDisplayList`, async (arg, { dispatch }) => {
  try {
    const value = await SystemSettingService.getSetting(
      ORDER_SETTINGS_ACTIONS.ORDER_DISPLAY_LIST
    )
    const result = JSON.parse(value.data.value) as string[]
    dispatch(orderSettingsSlice.actions.successGetOrderDisplayList(result))
  } catch (e) {
    // catchErrorWithDispatch(dispatch)(e)
  }

  return undefined
})

export const updateOrderDisplayList = createAsyncThunk<
  undefined,
  string[],
  {
    dispatch: AppDispatch
    state: AppState
  }
>(`${type}/updateOrderDisplayList`, async (arg, { dispatch }) => {
  dispatch(LoadingAction.open('Updating Order Display List...'))
  await SystemSettingService.updateSetting(
    ORDER_SETTINGS_ACTIONS.ORDER_DISPLAY_LIST,
    arg
  ).finally(() => {
    dispatch(LoadingAction.close())
  })

  dispatch(getOrderDisplayList())

  return undefined
})

export const getDefaultCreatedOrderStatus = createAsyncThunk(
  `${type}/getDefaultCreatedOrderStatus`,
  () => {
    return SystemSettingService.getSetting('DEFAULT_CREATED_ORDER_STATUS').then(
      value => value.data
    )
  }
)

export const orderSettingsSlice = createSlice({
  name: type,
  initialState: initialState,
  reducers: {
    successGetOrderDisplayList: (state, action) => {
      state.orderDisplayList = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getDefaultCreatedOrderStatus.fulfilled, (state, action) => {
      state.defaultCreatedOrderStatus = JSON.parse(
        action.payload.value
      ) as OrderStatus
    })
  },
})
