import React, { useEffect, useReducer, useState } from 'react'
import { WithdrawalBean } from '../../features/claim/bean/WithdrawalBean'
import WithdrawalService from '../../features/claim/service/withdrawalService'
import { Button, CircularProgress, LinearProgress } from '@material-ui/core'
import _ from 'lodash'
import { DatePicker } from '@material-ui/pickers'
import { isAfter, isBefore } from 'date-fns'
import WithdrawalAppbar from './withdrawal.appbar'
import { exportWithdrawalsToCsv } from './withdrawal.utils'

enum withdrawalPageActions {
  activateDateFrom = 'activateDateFrom',
  activateDateTo = 'activateDateTo',
}

const withdrawalPageReducer = (state: any, { action, payload }: any) => {
  switch (action) {
    case withdrawalPageActions.activateDateFrom:
      return {
        ...state,
        [withdrawalPageActions.activateDateFrom]: payload,
      }
    case withdrawalPageActions.activateDateTo:
      return {
        ...state,
        [withdrawalPageActions.activateDateTo]: payload,
      }

    default:
      return state
  }
}

const WithdrawalPage = () => {
  useEffect(() => {
    WithdrawalService.findAll().then(value => setWithdrawals(value.data))
  }, [])
  const [withdrawals, setWithdrawals] = useState<WithdrawalBean[] | undefined>()
  const [filterWithdrawals, setFilterWithdrawals] = useState<
    WithdrawalBean[] | undefined
  >([])

  const [state, dispatch] = useReducer(withdrawalPageReducer, {
    [withdrawalPageActions.activateDateFrom]: null,
    [withdrawalPageActions.activateDateTo]: null,
  })

  const applyFilter = () => {
    if (!withdrawals) return

    const afterFilter = withdrawals
      .filter(value => {
        const dateFrom = state[withdrawalPageActions.activateDateFrom]
        if (dateFrom) {
          const date = value.refOrderEntity?.activatedOn || value.createdDate

          return isBefore(new Date(dateFrom), new Date(date))
        } else {
          return true
        }
      })
      .filter(value => {
        const dateTo = state[withdrawalPageActions.activateDateTo]
        if (dateTo) {
          const date = value.refOrderEntity?.activatedOn || value.createdDate

          return isAfter(new Date(dateTo), new Date(date))
        } else {
          return true
        }
      })

    setFilterWithdrawals(afterFilter)
  }

  if (!withdrawals) {
    return (
      <div>
        <WithdrawalAppbar title='Export Withdrawal to CSV' backButton={true} />
        <LinearProgress />
        <CircularProgress />
        <div>Loading... Please Wait...</div>
      </div>
    )
  }
  return (
    <div>
      <WithdrawalAppbar title='Export Withdrawal to CSV' backButton={true} />

      <>
        <div className='m-2 space-x-2 flex items-center'>
          <DatePicker
            clearable
            disableToolbar
            format='dd MMM yyyy'
            name={withdrawalPageActions.activateDateFrom}
            label={_.startCase(withdrawalPageActions.activateDateFrom)}
            value={state[withdrawalPageActions.activateDateFrom]}
            onChange={e => {
              dispatch({
                action: withdrawalPageActions.activateDateFrom,
                payload: e,
              })
            }}
          />

          <DatePicker
            clearable
            disableToolbar
            format='dd MMM yyyy'
            name={withdrawalPageActions.activateDateTo}
            label={_.startCase(withdrawalPageActions.activateDateTo)}
            value={state[withdrawalPageActions.activateDateTo]}
            onChange={e => {
              dispatch({
                action: withdrawalPageActions.activateDateTo,
                payload: e,
              })
            }}
          />
          <Button variant={'outlined'} onClick={applyFilter}>
            Apply Filter
          </Button>
        </div>
        <hr className='m-2' />
        <div className='m-2'>
          <div>Total Withdrawals:{(withdrawals || []).length}</div>
          <div>Withdrawals To Export :{filterWithdrawals?.length}</div>

          <Button
            variant={'contained'}
            color={'primary'}
            onClick={() => {
              if (filterWithdrawals) {
                exportWithdrawalsToCsv(
                  filterWithdrawals,
                  `Withdrawal_${new Date().toLocaleDateString()}`,
                  'Withdrawals'
                )
              }
            }}
            disabled={!filterWithdrawals || !filterWithdrawals.length}
          >
            Click to Export
          </Button>
        </div>
      </>
    </div>
  )
}

export default WithdrawalPage
