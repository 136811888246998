import { useCallback, useState } from 'react'
import { OrderAttachmentBean } from '../../orderAttachment/bean/orderAttachmentBean'
import OrderAttachmentService from '../../orderAttachment/orderAttachmentService'
import { useAppDispatch } from '../../../redux/store'
import { AlertDialogAction } from '../../../redux/reducer/alertDialogReducer'

interface Props {
  orderId: number
}

function useOrderAttachments({ orderId }: Props) {
  const [orderAttachments, setOrderAttachments] = useState<
    OrderAttachmentBean[] | undefined
  >(undefined)

  const dispatch = useAppDispatch()

  const getOrderAttachment = useCallback(() => {
    OrderAttachmentService.getOrderAttachmentByOrderId(orderId).then(value => {
      const { data } = value
      data.sort((a, b) => b.id - a.id)
      setOrderAttachments(data)
    })
  }, [orderId])

  const deleteOrderAttachment = useCallback(
    id => {
      dispatch(
        AlertDialogAction.open(
          'Delete Order Attachment',
          'Are you sure to delete order attachment?',

          () =>
            OrderAttachmentService.deleteOrderAttachmentById(id).then(() => {
              setOrderAttachments(prevState => {
                return prevState?.filter(bean => bean.id !== id)
              })
            })
        )
      )
    },
    [dispatch]
  )

  return {
    orderAttachments,
    getOrderAttachment,
    deleteOrderAttachment,
  }
}

export default useOrderAttachments
