import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core'
import ClaimStatus from '../../../common/constant/claimStatus'
import userProfileService from '../../../common/service/userProfileService'
import CommonUtils from '../../../common/commonUtils'
import { useHistory } from 'react-router'
import { ClaimBean } from '../bean/claimBean'
import { UserCodeBean } from '../../../common/service/UserCodeBean'
import { useTypedSelector } from '../../../redux/reducer'

const toDisplayList = [
  'Claim ID',
  'User ID',
  'Agent',
  'Amount Claimed (RM)',
  'Reference No',
  'Submitted On',
  'Status',
  'Completed On',
]

const ClaimTable = (props: { claims: ClaimBean[] }) => {
  const history = useHistory()
  const userCode: UserCodeBean = useTypedSelector(
    state => state.portalSetting.userCode
  )

  return (
    <Table>
      <TableHead>
        <TableRow>
          {toDisplayList.map(text => (
            <TableCell key={text}>{text}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {props.claims.map(value => {
          const statusToBgColor = {
            [ClaimStatus.COMPLETED]: 'bg-green-400',
            [ClaimStatus.REJECTED]: 'bg-red-400',
            [ClaimStatus.PAID]: 'bg-orange-400',
            [ClaimStatus.PENDING]: 'bg-gray-400',
            [ClaimStatus.PROCESSING]: 'bg-blue-400',
          }

          const displayName =
            value.refUserEntity.companyName && value.refUserEntity.companyRegNo
              ? `${value.refUserEntity.companyName} (${value.refUserEntity.companyRegNo})`
              : value.refUserEntity.fullName

          const bgColorClass = statusToBgColor[value.claimStatus]
          return (
            <TableRow
              key={value.id}
              className={'select-none cursor-pointer hover:bg-gray-200'}
              onClick={() => {
                userProfileService.getUserProfile()?.role === 'ROLE_AGENT'
                  ? history.push({
                      pathname: `/agent-portal/view-claim/${value.id}`,
                    })
                  : history.push({
                      pathname: `/admin-portal/view-claim/${value.id}`,
                    })
              }}
            >
              <TableCell>{value.id}</TableCell>
              <TableCell>
                {CommonUtils.getUserCode(value.refUserEntity, userCode)}
              </TableCell>
              <TableCell>
                <Tooltip title={value.refUserEntity.fullName}>
                  <div>{displayName}</div>
                </Tooltip>
              </TableCell>
              <TableCell>{value.amount.toFixed(2)}</TableCell>
              <TableCell>{value.referenceNo}</TableCell>
              <TableCell>
                {new Date(value.createdDate).toDateString()}
              </TableCell>
              <TableCell>
                <span className={`p-1 rounded text-white ${bgColorClass}`}>
                  {value.claimStatus}
                </span>
              </TableCell>
              <TableCell>
                {value.completedDate &&
                  new Date(value.completedDate).toDateString()}
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

export default ClaimTable
