import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ITemplate,
  ITemplateCreateBody,
  ITemplateReplaceBody,
  TemplateService,
} from '../../features/template/template.service'
import userProfileService from '../../common/service/userProfileService'

const fetchTemplates = createAsyncThunk('template/fetchTemplates', async () => {
  const response = await TemplateService.fetchTemplates()
  return response.data
})

const createPublicTemplate = createAsyncThunk<ITemplate, ITemplateCreateBody>(
  'template/createPublicTemplate',
  async arg => {
    const response = await TemplateService.createPublicTemplate(arg)

    return response.data
  }
)
const createPrivateTemplate = createAsyncThunk<ITemplate, ITemplateCreateBody>(
  'template/createPrivateTemplate',
  async arg => {
    const response = await TemplateService.createPrivateTemplate(arg)

    return response.data
  }
)

const updateTemplate = createAsyncThunk<null, ITemplateReplaceBody>(
  'template/updateTemplate',
  async arg => {
    await TemplateService.replaceTemplate(arg)
    return null
  }
)

const deleteTemplate = createAsyncThunk<null, number>(
  'template/deleteTemplate',
  async arg => {
    await TemplateService.deleteTemplate(arg)
    return null
  }
)

interface Props {
  isLoading: boolean
  templates: ITemplate[]
  templateDetail: ITemplate | null | undefined
}

const initialState: Props = {
  isLoading: false,
  templates: [],
  templateDetail: undefined,
}

export const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    removeSelectedTemplate(state) {
      state.templateDetail = undefined
    },
    selectTemplate(state, action: PayloadAction<number>) {
      const template = state.templates.find(t => t.id === action.payload)

      if (
        !template ||
        (userProfileService.isAgent() && template.type === 'PUBLIC')
      ) {
        state.templateDetail = null
      } else {
        state.templateDetail = template
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchTemplates.pending, state => {
      state.isLoading = true
      state.templates = []
    })
    builder.addCase(fetchTemplates.fulfilled, (state, action) => {
      state.templates = action.payload
      state.isLoading = false
    })

    builder.addCase(createPublicTemplate.pending, state => {
      state.isLoading = true
    })
    builder.addCase(createPublicTemplate.fulfilled, state => {
      state.isLoading = false
    })
  },
})

export const TemplateActions = {
  fetchTemplates,
  createPublicTemplate,
  createPrivateTemplate,
  updateTemplate,
  deleteTemplate,
  selectTemplate: templateSlice.actions.selectTemplate,
  removeSelectedTemplate: templateSlice.actions.removeSelectedTemplate,
}
