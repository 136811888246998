import React, { useEffect, useState } from 'react'
import UserBean from '../../sharePortal/userManagement/bean/userBean'
import ClaimService from '../../features/claim/service/claimService'
import { ClaimBean } from '../../features/claim/bean/claimBean'
import { Pageable } from '../../common/bean/Pageable'
import PageableClaimTableComponent from '../../features/claim/component/pageableClaimTableComponent'

const AgentClaimTab = (props: { user: UserBean }) => {
  const { user } = props

  const [claims, setClaims] = useState<Pageable<ClaimBean>>()

  useEffect(() => {
    ClaimService.getAll({
      isPage: false,
      agentId: user.id.toString(),
      sort: 'id,desc',
    })
      .then(o => o.data)
      .then(setClaims)
  }, [user.id])

  return (
    <div>
      <PageableClaimTableComponent claims={claims} />
    </div>
  )
}

export default AgentClaimTab
