import React, { useEffect, useState } from 'react'
import { Button, Card, CardContent, CardHeader } from '@material-ui/core'
import { OrderBean } from '../../../sharePortal/orderManagement/bean/orderBean'
import { useHistory } from 'react-router-dom'

const EmptyEformComponentGroupByAgent = (props: { orders: OrderBean[] }) => {
  const history = useHistory()

  const [orders, setOrders] = useState<Record<string, OrderBean[]>>({})

  useEffect(() => {
    const newValue = props.orders.reduce<Record<string, OrderBean[]>>(
      (previousValue, currentValue: OrderBean) => {
        const username = currentValue.refUser.username || 'EMPTY'
        const newArr = [...(previousValue[username] || []), currentValue]

        const newObj = { ...previousValue }
        newObj[username] = newArr

        return newObj
      },
      {}
    )

    setOrders(newValue)
  }, [props.orders])
  return (
    <Card>
      <CardHeader title={'Empty E-Form Grouped By Agent'} />
      <CardContent>
        {Object.entries(orders).map(([username, order]) => {
          return (
            <div key={username}>
              <div className='grid grid-cols-4 p-4 rounded hover:bg-gray-200'>
                <div className='col-span-1'>
                  <span className='text-gray-500'>Username: </span>
                  <span className='text-gray-700'>{username}</span>
                </div>
                <div className='col-span-3 flex'>
                  <span className='text-gray-500 mr-2'>Order ID: </span>
                  <span className='text-gray-700 flex-1'>
                    {order.map(value => (
                      <Button
                        key={value.id}
                        variant={'outlined'}
                        onClick={() =>
                          history.push(`/admin-portal/order-detail/${value.id}`)
                        }
                      >
                        {value.id.toString()}
                      </Button>
                    ))}
                  </span>
                </div>
              </div>
            </div>
          )
        })}
      </CardContent>
    </Card>
  )
}

export default EmptyEformComponentGroupByAgent
