import React, { useEffect } from 'react'
import './App.css'
import LoginPage from './preLogin/loginPage'
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom'
import SignUpPage from './preLogin/signUpPage'
import AgentPortalIndex from './agentPortal/agentPortalIndex'
import VerifyEmailPage from './preLogin/verifyEmailPage'
import LoadingBackdrop from './common/component/loadingBackdrop'
import { useTypedSelector } from './redux/reducer'
import AlertDialog from './common/component/AlertDialog'
import SnackbarComponent from './common/component/snackbarComponent'
import ResetPasswordPage from './preLogin/resetPasswordPage'
import AdminPortalIndex from './adminPortal/adminPortalIndex'
import { CommonActions } from './redux/reducer/commonReducer'
import { useDispatch } from 'react-redux'
import AgentStaffPortalIndex from './agentStaffPortal/agentStaffPortalIndex'
import SecurePage from './common/SecurePage'

const App = () => {
  const dispatch = useDispatch()
  const loadingState = useTypedSelector(state => state.loading)

  useEffect(() => {
    dispatch(CommonActions.getLandingPageAttachments())
  }, [dispatch])

  console.debug({
    NODE_ENV: process.env.NODE_ENV,
    PUBLIC_URL: process.env.PUBLIC_URL,
    REACT_APP_API_URL: process.env.REACT_APP_API_URL,
  })

  return (
    <HashRouter>
      <div className='App h-screen w-screen'>
        <Switch>
          <Route path='/login'>
            <LoginPage />
          </Route>
          <Route path='/sign-up'>
            <SignUpPage />
          </Route>
          <Route path='/verify-email/:token'>
            <VerifyEmailPage />
          </Route>
          <Route path='/reset-password/:resetCode'>
            <ResetPasswordPage />
          </Route>
          <Route path='/agent-portal'>
            <SecurePage>
              <AgentPortalIndex />
            </SecurePage>
          </Route>
          <Route path='/admin-portal'>
            <SecurePage>
              <AdminPortalIndex />
            </SecurePage>
          </Route>
          <Route path='/agent-staff-portal'>
            <SecurePage>
              <AgentStaffPortalIndex />
            </SecurePage>
          </Route>
          <Route path='/'>
            <Redirect to='/login' />
            <div>Hello WOrld</div>
          </Route>
        </Switch>
      </div>

      <LoadingBackdrop
        open={loadingState.open}
        message={loadingState.message}
      />
      <AlertDialog />
      <SnackbarComponent />
    </HashRouter>
  )
}

export default App
