import React, { useRef } from 'react'
import AppFileInput from '../../../component/app-file-input/appFileInput'
import { OrderUploadFileKey } from '../../../common/orderUtils'
import {
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'

import { AttachFile, Clear } from '@material-ui/icons'
import { OrderAttachmentBean } from '../../orderAttachment/bean/orderAttachmentBean'

function OrderUploadFile(props: {
  attachmentBeans?: Record<string, OrderAttachmentBean[]>
  files: Record<string, File[]>
  onChange: (files: Record<string, File[]>) => void
  onRemove?: (attachmentId: number) => void
}) {
  const myKadRef = useRef<HTMLInputElement | null>(null)
  const conversationRef = useRef<HTMLInputElement | null>(null)
  const utilityRef = useRef<HTMLInputElement | null>(null)

  const handleFile = (key: OrderUploadFileKey) => (files: File[]) => {
    if (files.length) {
      const prevFile = props.files[key]

      if (!prevFile) {
        props.onChange({
          ...props.files,
          [key]: files,
        })
      } else {
        const _oldFileNames = prevFile.map(o => o.name)

        const _newUniqFile = files.filter(
          ({ name: newFileName }) => !_oldFileNames.includes(newFileName)
        )
        props.onChange({
          ...props.files,
          [key]: [...prevFile, ..._newUniqFile],
        })
      }
    }
  }

  const handleFileReset = (
    key: OrderUploadFileKey,
    ref: React.MutableRefObject<HTMLInputElement | null>
  ) => {
    if (ref?.current) {
      ref.current.value = ''
    }

    const { [key]: deleteObj, ...rest } = props.files
    props.onChange(rest)
  }

  const handleRemoveLocalFile = (key: string, file: File) => {
    const newFiles = {
      ...props.files,
      [key]: props.files[key].filter(o => o.name !== file.name),
    }
    props.onChange(newFiles)
  }

  const list = [
    {
      title: "Application's MyKad",
      ref: myKadRef,
      key: OrderUploadFileKey.MY_KAD,
    },
    {
      title: 'Customer Conversation',
      ref: conversationRef,
      key: OrderUploadFileKey.CONVERSATION,
    },
    { title: 'Utility Bill', ref: utilityRef, key: OrderUploadFileKey.UTILITY },
  ]
  return (
    <div>
      <Table className='table-auto' size='small'>
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>File Name</TableCell>
            <TableCell align='right'>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map(o => {
            const newFilename = props.files[o.key]
            const attachmentBeans = (props.attachmentBeans || {})[o.key]

            return (
              <TableRow key={o.title}>
                <TableCell>{o.title}</TableCell>
                <TableCell>
                  {newFilename?.map(file => {
                    return (
                      <Chip
                        key={file.name}
                        size={'medium'}
                        label={file.name}
                        onDelete={() => {
                          handleRemoveLocalFile(o.key, file)
                        }}
                      />
                    )
                  })}
                  {(attachmentBeans || []).map(bean => {
                    return (
                      <Chip
                        variant='outlined'
                        key={bean.id}
                        color='primary'
                        size={'medium'}
                        label={bean.fileName}
                        className='cursor-pointer'
                        onClick={() => {
                          window.open(bean.attachmentUrl)
                        }}
                        onDelete={() => {
                          if (props.onRemove) props.onRemove(bean.id)
                        }}
                      />
                    )
                  })}
                </TableCell>
                <TableCell align='right'>
                  <span className='hidden'>
                    <AppFileInput
                      ref={o.ref}
                      onChange={handleFile(o.key)}
                      multiple
                    />
                  </span>

                  <IconButton
                    color={'primary'}
                    onClick={() => o.ref.current?.click()}
                  >
                    <AttachFile />
                  </IconButton>
                  <IconButton
                    disabled={!props.files[o.key]}
                    color={'secondary'}
                    onClick={() => handleFileReset(o.key, o.ref)}
                  >
                    <Clear />
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </div>
  )
}

export default OrderUploadFile
