import React from 'react'
import ClaimSentEmailToAgentWhenComponent from './claimSentEmailToAgentWhen.component'
import { Button } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import {
  getClaimSettings,
  updateClaimSettings,
} from '../../../../redux/slice/claimSettings.slice'
import ClaimSectionComponent from './claimSection.component'

const ClaimTab = () => {
  const dispatch = useDispatch()

  function handleReset() {
    dispatch(getClaimSettings())
  }

  function handleUpdate() {
    dispatch(updateClaimSettings())
  }

  return (
    <div>
      <div className='my-2'>
        <ClaimSentEmailToAgentWhenComponent />
      </div>
      <div className='my-2'>
        <ClaimSectionComponent />
      </div>

      <div className='text-right space-x-2'>
        <Button variant={'outlined'} color={'secondary'} onClick={handleReset}>
          RESET
        </Button>
        <Button variant={'contained'} color={'primary'} onClick={handleUpdate}>
          UPDATE
        </Button>
      </div>
    </div>
  )
}

export default ClaimTab
