import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import UserRole from '../../common/constant/userRole'
import { LoadingAction } from '../../redux/reducer/loadingBackdropReducer'
import { AdminService } from './service/adminService'
import { SnackbarAction } from '../../redux/reducer/snackbarReducer'
import {
  AppBar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { catchErrorWithDispatch } from '../../common/ApiUtils'

const NewAdminPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const initialState = {
    username: '',
    password: '',
    fullName: '',
    email: '',
    role: UserRole.ROLE_STAFF,
  }
  const [formState, setFormState] = useState(initialState)

  const onSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    dispatch(LoadingAction.open('Creating New Admin...'))
    AdminService.register({
      email: formState.email,
      fullName: formState.fullName,
      password: formState.password,
      role: formState.role,
      username: formState.username,
    })
      .then(value => {
        dispatch(SnackbarAction.open('Success Register'))
        history.replace('admin-management')
      })
      .catch(catchErrorWithDispatch(dispatch))
      .finally(() => {
        dispatch(LoadingAction.close())
      })
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    })
  }

  function handleFilterChange(
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) {
    setFormState({
      ...formState,
      [e.target.name!!]: e.target.value,
    })
  }

  function onResetClick() {
    setFormState(initialState)
  }

  return (
    <div>
      <AppBar position='relative'>
        <Toolbar className='space-x-3'>
          <IconButton color='inherit' onClick={history.goBack}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant='h6'>New Admin</Typography>
        </Toolbar>
      </AppBar>

      <Card className='m-8'>
        <form onSubmit={onSubmitForm}>
          <CardHeader title={'New Admin'}></CardHeader>
          <CardContent className='grid grid-cols-2 gap-4'>
            <FormControl className='col-span-2'>
              <InputLabel id='role'>Role</InputLabel>
              <Select
                id='role'
                name='role'
                label='role'
                value={formState['role']}
                onChange={handleFilterChange}
              >
                {[
                  UserRole.ROLE_MANAGER,
                  UserRole.ROLE_ADMIN,
                  UserRole.ROLE_STAFF,
                ].map((value: string) => (
                  <MenuItem key={value} value={value}>
                    {UserRole.toDisplay[value]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              onChange={handleChange}
              value={formState['username']}
              required
              id='username'
              name='username'
              label='Username'
              helperText='Your login username'
              fullWidth
            />

            <TextField
              onChange={handleChange}
              value={formState['password']}
              required
              id='password'
              name='password'
              label='Password'
              helperText='Your login password'
              type='password'
              fullWidth
            />
            <TextField
              onChange={handleChange}
              value={formState['fullName']}
              required
              id='fullName'
              name='fullName'
              label='Full Name'
              helperText='Name as NRIC'
              fullWidth
            />

            <TextField
              onChange={handleChange}
              value={formState['email']}
              required
              id='email'
              name='email'
              label='Email'
              helperText='Please use actual email, we will sent you activation code'
              type='email'
              fullWidth
            />
          </CardContent>
          <CardActions className='justify-end'>
            <Button
              variant='outlined'
              color='secondary'
              size='large'
              onClick={onResetClick}
            >
              Reset
            </Button>
            <Button
              variant='contained'
              color='primary'
              size='large'
              type='submit'
            >
              Submit
            </Button>
          </CardActions>
        </form>
      </Card>
    </div>
  )
}

export default NewAdminPage
