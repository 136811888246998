import React from 'react'
import {
  Backdrop,
  CircularProgress,
  createStyles,
  Theme,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

interface LoadingBackdropProps {
  open: boolean
  message: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  })
)

function LoadingBackdrop({ open = false, message = '' }: LoadingBackdropProps) {
  const classes = useStyles()

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <div>
        <CircularProgress color='inherit' />
      </div>
      <div> {message}</div>
    </Backdrop>
  )
}

export default LoadingBackdrop
