import React, { useState } from 'react'
import {
  AppBar,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import CustomerService from '../customer/customerService'
import { AxiosError } from 'axios'
import { Customer } from './bean/customer'
import { CustomerPostBody } from '../customer/bean/customerPostBody'
import EnquiryService from './enquiryService'
import { useDispatch } from 'react-redux'
import { LoadingAction } from '../../redux/reducer/loadingBackdropReducer'
import { AlertDialogAction } from '../../redux/reducer/alertDialogReducer'
import { SnackbarAction } from '../../redux/reducer/snackbarReducer'
import { catchErrorWithDispatch } from '../../common/ApiUtils'

const EnquiryPage = () => {
  const dispatch = useDispatch()
  const [nric, setNric] = useState('')
  const [customerId, setCustomerId] = useState<number>()
  const [question, setQuestion] = useState('')
  const [customerExist, setCustomerExist] = useState<
    'LOADING' | 'EXIST' | 'NOT EXIST'
  >('LOADING')

  const customerInitialState = {
    addresses: [],
    email: '',
    fullName: '',
    id: 0,
    mobileNo: '',
    nric: '',
    telNo: '',
  }
  const [customer, setCustomer] = useState<Customer>(customerInitialState)

  const resetState = () => {
    setQuestion('')
    setCustomerId(undefined)
    setNric('')
    setQuestion('')
    setCustomerExist('LOADING')
    setCustomer(customerInitialState)
  }
  const onCustomerDetailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    const name = e.target.name

    setCustomer({
      ...customer,
      [name]: value,
    })
  }
  const onNricChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    const match = value.match(RegExp('^[0-9-]+'))

    if (value.length > 14) {
      return
    } else if (value === '') {
      setNric(value)
    } else if (match) {
      const match1 = match[0]
      setNric(match1)
    }
  }

  const onNricSubmit = () => {
    if (nric.match(RegExp('[0-9]{6}-[0-9]{2}-[0-9]{4}'))) {
      dispatch(LoadingAction.open(`Loading ${nric} info...`))

      CustomerService.getCustomerByNric(nric)
        .then(value => {
          setCustomerExist('EXIST')
          setCustomer({
            ...value.data,
          })
          setCustomerId(value.data.id)
        })
        .catch((error: AxiosError) => {
          if (error.response?.status === 404) {
            // Customer not exists yet, prompt enter detail
            setCustomerExist('NOT EXIST')
          } else {
            catchErrorWithDispatch(dispatch)(error)
          }
        })
        .finally(() => {
          dispatch(LoadingAction.close())
        })
    } else {
      alert('NOT VALID IC')
    }
  }
  const onCustomerSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    dispatch(LoadingAction.open('Register Customer...'))

    const customerPostBody = new CustomerPostBody(
      nric,
      customer.fullName,
      customer.email,
      customer.mobileNo,
      customer.telNo
    )

    CustomerService.postCustomer(customerPostBody)
      .then(value => {
        dispatch(SnackbarAction.open('Success Register Customer'))
        setCustomerId(value.data.id)
      })
      .catch(catchErrorWithDispatch(dispatch))
      .finally(() => {
        dispatch(LoadingAction.close())
      })
  }

  const onQuestionSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (customerId) {
      dispatch(LoadingAction.open('Submitting Question...'))
      EnquiryService.postEnquiry(customerId, question)
        .then(() => {
          dispatch(AlertDialogAction.open('SUCCESS', 'Success Sent enquiry.'))

          resetState()
        })
        .catch(catchErrorWithDispatch(dispatch))
        .finally(() => {
          dispatch(LoadingAction.close())
        })
    }
  }

  return (
    <div>
      <AppBar position='relative'>
        <Toolbar className='space-x-3'>
          <Typography variant='h6' noWrap>
            Customer Enquiry
          </Typography>
        </Toolbar>
      </AppBar>
      <Card className='max-w-lg m-auto my-4'>
        <CardContent>
          <TextField
            onChange={onNricChange}
            value={nric}
            required
            id='nric'
            name='nric'
            label='Customer NRIC'
            helperText='Please Input IC with "-"'
            fullWidth
            disabled={customerExist !== 'LOADING'}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {customerExist === 'LOADING' && (
            <div className='my-2 flex justify-end'>
              <Button
                color='primary'
                variant='contained'
                onClick={onNricSubmit}
              >
                OK
              </Button>
            </div>
          )}
        </CardContent>
      </Card>

      {customerExist === 'EXIST' && (
        <div className='max-w-lg shadow rounded border m-auto my-4 p-4'>
          <div>Name: {customer.fullName}</div>
          <div>Mobile No: {customer.mobileNo}</div>
          <div>Email: {customer.email}</div>
        </div>
      )}

      {customerExist === 'NOT EXIST' && (
        <Card className='max-w-lg m-auto my-4'>
          <CardHeader title='Customer Information' />
          <CardContent>
            <form onSubmit={onCustomerSubmit}>
              <div className='my-2'>
                <TextField
                  id='fullName'
                  name='fullName'
                  label='Full Name'
                  value={customer['fullName']}
                  onChange={onCustomerDetailChange}
                  required
                  fullWidth
                  disabled={!!customerId}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>

              <div className='my-2'>
                <TextField
                  id='mobileNo'
                  name='mobileNo'
                  label='Mobile Number'
                  value={customer['mobileNo']}
                  onChange={onCustomerDetailChange}
                  required
                  fullWidth
                  disabled={!!customerId}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>

              <div className='my-2'>
                <TextField
                  id='email'
                  name='email'
                  label='Email'
                  value={customer['email']}
                  onChange={onCustomerDetailChange}
                  required
                  fullWidth
                  disabled={!!customerId}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className='my-2'>
                <TextField
                  id='telNo'
                  name='telNo'
                  label='telNo'
                  value={customer['telNo']}
                  onChange={onCustomerDetailChange}
                  fullWidth
                  disabled={!!customerId}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              {!customerId && (
                <div className='mt-2 flex justify-end space-x-3'>
                  <Button
                    color='secondary'
                    variant='outlined'
                    onClick={resetState}
                  >
                    Cancel
                  </Button>

                  <Button color='primary' variant='contained' type='submit'>
                    OK
                  </Button>
                </div>
              )}
            </form>
          </CardContent>
        </Card>
      )}

      {!!customerId && (
        <Card className='max-w-lg m-auto my-4'>
          <CardHeader title='Customer Question' />
          <CardContent>
            <form onSubmit={onQuestionSubmit}>
              <TextField
                id='question'
                name='question'
                label='Question'
                value={question}
                onChange={e => {
                  setQuestion(e.target.value)
                }}
                multiline
                rows='6'
                required
                fullWidth
              />

              <div className='mt-2 flex justify-end space-x-3'>
                <Button
                  color='secondary'
                  variant='outlined'
                  onClick={resetState}
                >
                  Cancel
                </Button>

                <Button color='primary' variant='contained' type='submit'>
                  OK
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      )}
    </div>
  )
}

export default EnquiryPage
