export const RegexUtils = {
  // 1) Must start with 0.
  // 2) follow by 1 or 2 number.
  // 3) follow by dash '-'.
  // 4) follow by any number until end.
  phoneNumberRegex: RegExp('^0[\\d]{1,2}-[\\d]+$'),
  usernameRegex: new RegExp(
    '^(?=[a-zA-Z0-9._]{8,20}$)(?!.*[_.]{2})[^_.].*[^_.]$'
  ),
  nricRegex: new RegExp('\\d{6}-\\d{2}-\\d{4}'),
}
