export default class UserRole {
  static ROLE_AGENT = 'ROLE_AGENT'
  static ROLE_ADMIN = 'ROLE_ADMIN'
  static ROLE_MANAGER = 'ROLE_MANAGER'
  static ROLE_STAFF = 'ROLE_STAFF'
  static ROLE_AGENT_STAFF = 'ROLE_AGENT_STAFF'

  static toDisplay: Record<string, string> = {
    ROLE_AGENT: 'Agent',
    ROLE_ADMIN: 'Admin',
    ROLE_MANAGER: 'Manager',
    ROLE_STAFF: 'Staff',
    ROLE_AGENT_STAFF: 'Agent Staff',
  }
  static isAgent(status?: string): boolean {
    return !!status && status === 'ROLE_AGENT'
  }
  static isAdmin(status?: string): boolean {
    return !!status && status === 'ROLE_ADMIN'
  }
  static isManager(status?: string): boolean {
    return !!status && status === 'ROLE_MANAGER'
  }
  static isStaff(status?: string): boolean {
    return !!status && status === 'ROLE_STAFF'
  }
  static isAgentStaff(status?: string): boolean {
    return !!status && status === 'ROLE_AGENT_STAFF'
  }
}
