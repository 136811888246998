import React, { useEffect, useState } from 'react'
import {
  AppBar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { OrderSummaryBean } from './bean/OrderSummaryBean'
import { OrderSummaryContentDetail } from './bean/OrderSummaryContentDetail'
import CommonUtils from '../../common/commonUtils'
import { useDispatch } from 'react-redux'
import { AlertDialogAction } from '../../redux/reducer/alertDialogReducer'
import { PortalSettingActions } from '../../redux/reducer/portalSettingReducer'
import { OrderSummaryContent } from './bean/OrderSummaryContent'
import { useTypedSelector } from '../../redux/reducer'
import { TabContext, TabPanel } from '@material-ui/lab'
import OrderSummaryMessageComponent from './component/orderSummaryMessageComponent'
import { demoOrder } from '../../sharePortal/orderManagement/bean/orderBean'
import OrderPanelComponent from './component/order/orderPanelComponent'
import { SETTING_PAGE_TAB } from './setting.constant'
import { GeneralTab } from './tab/general/general.tab'
import ClaimTab from './tab/claim/claim.tab'
import {
  getOrderDisplayList,
  updateOrderDisplayList,
} from '../../redux/slice/orderSettingsSlice'

const SettingPage = () => {
  const dispatch = useDispatch()

  const [title, setTitle] = useState('')
  const [footer, setFooter] = useState('')
  const [content, setContent] = useState<
    Record<string, OrderSummaryContentDetail>
  >({})

  const [tabIndex, setTabIndex] = useState<SETTING_PAGE_TAB>(
    SETTING_PAGE_TAB.GENERAL
  )

  const orderSummaryBean: OrderSummaryBean = useTypedSelector(
    state => state.portalSetting.notification.orderSummary
  )

  const orderDisplayList = useTypedSelector(
    state => state.orderSettings.orderDisplayList
  )

  useEffect(() => {
    dispatch(getOrderDisplayList())
  }, [dispatch])

  useEffect(() => {
    setTitle(orderSummaryBean.title)
    setFooter(orderSummaryBean.footer)
    setContent(orderSummaryBean.content)
  }, [orderSummaryBean])

  const list = [
    'product',
    'productCategory',
    'productPackage',
    'price',
    'companyName',
    'companyRegNo',
    'address',
    'fullName',
    'nric',
    'email',
    'mobileNo',
    'telNo',
    'alternativeNo',
    'orderStatus',
    'orderNo',
    'loginId',
    'eform',
    'activatedOn',
    'invoiceNo',
    'remarks',
    'adminRemarks',
    'm2uRemarks',
  ]

  const handleReset = () => {
    setTitle(orderSummaryBean.title)
    setFooter(orderSummaryBean.footer)
    setContent(orderSummaryBean.content)
  }

  return (
    <TabContext value={tabIndex}>
      <AppBar position='relative'>
        <Toolbar className='space-x-3'>
          <Typography variant='h6'>Setting Page</Typography>
        </Toolbar>

        <Tabs
          value={tabIndex}
          onChange={(event, value) => {
            setTabIndex(value)
          }}
        >
          <Tab
            label={SETTING_PAGE_TAB.GENERAL}
            value={SETTING_PAGE_TAB.GENERAL}
          />
          <Tab
            label={SETTING_PAGE_TAB.ORDER_MESSAGE}
            value={SETTING_PAGE_TAB.ORDER_MESSAGE}
          />
          <Tab label={SETTING_PAGE_TAB.ORDER} value={SETTING_PAGE_TAB.ORDER} />
          <Tab label={SETTING_PAGE_TAB.CLAIM} value={SETTING_PAGE_TAB.CLAIM} />
        </Tabs>
      </AppBar>
      <TabPanel value={SETTING_PAGE_TAB.GENERAL}>
        <GeneralTab />
      </TabPanel>
      <TabPanel value={SETTING_PAGE_TAB.CLAIM}>
        <ClaimTab />
      </TabPanel>
      <TabPanel value={SETTING_PAGE_TAB.ORDER_MESSAGE}>
        <div className='m-4'>
          {content && (
            <Card>
              <CardHeader title={'Generic'} />
              <CardContent className='grid gap-4 grid-cols-4'>
                <TextField
                  className='col-span-4'
                  multiline
                  fullWidth
                  label={'Title'}
                  value={title}
                  onChange={e => {
                    const { value } = e.target
                    setTitle(value)
                  }}
                />
                <TextField
                  className='col-span-4'
                  multiline
                  fullWidth
                  label={'Footer'}
                  value={footer}
                  onChange={e => {
                    const { value } = e.target
                    setFooter(value)
                  }}
                />

                {content &&
                  list.map(key => {
                    const value = content[key]

                    return (
                      <div key={key}>
                        <Checkbox
                          checked={value?.enabled || false}
                          onChange={(e, checked) => {
                            setContent(prevState => {
                              return {
                                ...prevState,
                                [key]: {
                                  ...prevState[key],
                                  enabled: checked,
                                },
                              }
                            })
                          }}
                        />
                        <TextField
                          label={key}
                          name={key}
                          value={value?.displayText || ''}
                          disabled={!value?.enabled}
                          onChange={e => {
                            const { value } = e.target
                            setContent(prevState => {
                              return {
                                ...prevState,
                                [key]: {
                                  ...prevState[key],
                                  displayText: value,
                                },
                              }
                            })
                          }}
                        />
                      </div>
                    )
                  })}
              </CardContent>
              <CardActions className='justify-end'>
                <Button
                  variant={'outlined'}
                  onClick={() => {
                    const patchBody: OrderSummaryBean = {
                      title: title,
                      footer: footer,
                      content: {
                        product: content['product'],
                        productCategory: content['productCategory'],
                        productPackage: content['productPackage'],
                        price: content['price'],
                        companyName: content['companyName'],
                        companyRegNo: content['companyRegNo'],
                        address: content['address'],
                        fullName: content['fullName'],
                        nric: content['nric'],
                        email: content['email'],
                        mobileNo: content['mobileNo'],
                        telNo: content['telNo'],
                        alternativeNo: content['alternativeNo'],
                        orderStatus: content['orderStatus'],
                        orderNo: content['orderNo'],
                        loginId: content['loginId'],
                        eform: content['eform'],
                        activatedOn: content['activatedOn'],
                        invoiceNo: content['invoiceNo'],
                        remarks: content['remarks'],
                        adminRemarks: content['adminRemarks'],
                        m2uRemarks: content['m2uRemarks'],
                      },
                    }

                    const summary = CommonUtils.getSummary(demoOrder, patchBody)

                    dispatch(
                      AlertDialogAction.open(
                        'Preview',
                        <>
                          <TextField
                            value={summary}
                            disabled
                            multiline={true}
                            fullWidth={true}
                          />
                        </>
                      )
                    )
                  }}
                >
                  PREVIEW
                </Button>
                <Button
                  variant={'outlined'}
                  color={'secondary'}
                  onClick={handleReset}
                >
                  RESET
                </Button>
                <Button
                  variant={'contained'}
                  color={'primary'}
                  onClick={() => {
                    dispatch(
                      PortalSettingActions.updateOrderSummarySetting(
                        title,
                        footer,
                        content as OrderSummaryContent
                      )
                    )
                  }}
                >
                  UPDATE
                </Button>
              </CardActions>
            </Card>
          )}
          <OrderSummaryMessageComponent />
        </div>
      </TabPanel>
      <TabPanel value={SETTING_PAGE_TAB.ORDER}>
        <OrderPanelComponent
          orderDisplayList={orderDisplayList}
          onSaveClick={items => {
            dispatch(updateOrderDisplayList(items))
          }}
        />
      </TabPanel>
    </TabContext>
  )
}

export default SettingPage
