import React from 'react'
import { WithdrawalBean } from '../../../features/claim/bean/WithdrawalBean'
import CommissionType from '../../../common/constant/commissionType'
import _ from 'lodash'
import CommonUtils from '../../../common/commonUtils'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'
import { EditWithdrawalModel } from './editWithdrawal.dialog'

const EditWithdrawalItemComponent = (props: {
  withdrawal: WithdrawalBean
  price: number
  onChange: (withdrawalId: number, newObject: EditWithdrawalModel) => void
}) => {
  const { withdrawal, price } = props

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    const message =
      withdrawal.commissionType === CommissionType.AMOUNT
        ? Number.parseFloat(e.target.value)
        : Number.parseInt(e.target.value)

    props.onChange(withdrawal.id, {
      commissionRate: Number.isNaN(message) ? 0 : message,
      commissionType: withdrawal.commissionType!!,
    })
  }

  function getPrice() {
    const rate = withdrawal.commissionRate

    if (withdrawal.commissionType === CommissionType.AMOUNT) {
      return rate!!.toFixed(0)
    } else {
      const number = (rate!! * price) / 100
      return number.toFixed(2)
    }
  }

  function isDisable() {
    return !_.isNil(withdrawal.claimId)
  }

  const handleCommissionTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    props.onChange(withdrawal.id, {
      commissionRate: withdrawal.commissionRate!!,
      commissionType: value as CommissionType,
    })
  }

  return (
    <div key={withdrawal.id} className='my-2'>
      <div>
        <span className='font-bold'>
          {CommonUtils.getAgentDisplayName(withdrawal.refUserEntity)}
        </span>
      </div>
      <div className='flex items-center'>
        <FormControl
          component='fieldset'
          required={true}
          disabled={isDisable()}
        >
          <RadioGroup
            row
            value={withdrawal.commissionType}
            onChange={handleCommissionTypeChange}
          >
            {Object.entries(CommissionType.toDisplay).map(([id, str]) => {
              return (
                <FormControlLabel
                  key={id}
                  value={id}
                  control={<Radio required={true} />}
                  label={str}
                />
              )
            })}
          </RadioGroup>
        </FormControl>
        <TextField
          value={withdrawal.commissionRate}
          onChange={handleOnChange}
          disabled={isDisable()}
        />
        <span>RM {getPrice()}</span>
      </div>
      {isDisable() && (
        <span className='italic text-red-300'>
          This Withdrawal is Claimed, Cannot Update Anymore.
        </span>
      )}
    </div>
  )
}

export default EditWithdrawalItemComponent
