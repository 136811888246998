import React from 'react'
import UserBean from '../../sharePortal/userManagement/bean/userBean'
import { TreeItem, TreeItemProps } from '@material-ui/lab'
import userProfileService from '../../common/service/userProfileService'
import {
  Collapse,
  createStyles,
  fade,
  Theme,
  withStyles,
} from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions'
import { useHistory } from 'react-router-dom'

const SubAgentItemComponent = ({
  user,
  agents,
  position,
}: {
  user: UserBean
  agents: Record<string, UserBean[]>
  position: string
}) => {
  const history = useHistory()
  if (!user) return null

  const userId = user.id
  const isSelf = userProfileService.getUserProfile()?.id === userId

  const displayName =
    user.companyName && user.companyRegNo
      ? `${user.companyName}<${user.companyRegNo}> | ${user.fullName}`
      : user.fullName

  const label = isSelf ? displayName + ' [YOURSELF]' : displayName

  const list: UserBean[] = agents[userId.toString()] || []
  return (
    <StyledTreeItem
      nodeId={userId.toString()}
      key={userId}
      label={`${position} [${user.id}] ${label}`}
      onLabelClick={e => {
        e.preventDefault()

        if (userProfileService.isAgent()) {
          history.push('/agent-portal/agent-detail/' + userId)
        } else {
          history.push('/admin-portal/agent-detail/' + userId)
        }
      }}
    >
      {list.map((value, index) => (
        <SubAgentItemComponent
          key={value.id}
          user={value}
          agents={agents}
          position={[position, index + 1, '.'].join('')}
        />
      ))}
    </StyledTreeItem>
  )
}

export default SubAgentItemComponent

function TransitionComponent(props: TransitionProps) {
  return <Collapse {...props} />
}

const StyledTreeItem = withStyles((theme: Theme) =>
  createStyles({
    iconContainer: {
      '& .close': {
        opacity: 0.3,
      },
    },
    group: {
      marginLeft: 7,
      paddingLeft: 18,
      borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
    },
  })
)((props: TreeItemProps) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))
