import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'
import { WithdrawalBean } from '../../../features/claim/bean/WithdrawalBean'
import TransactionType from '../../../common/constant/transactionType'
import { WithdrawalPatchBody } from '../../../features/withdrawal/bean/withdrawalPatchBody'

export interface WithdrawalEditDialogProps {
  withdrawal: WithdrawalBean | undefined
  onClose: (body: WithdrawalPatchBody | undefined) => void
}
const EditWithdrawalDialog = ({
  withdrawal,
  onClose,
}: WithdrawalEditDialogProps) => {
  const [amount, setAmount] = useState('')
  const [description, setDescription] = useState('')
  const [transactionType, setTransactionType] = useState(
    TransactionType.INCENTIVES
  )

  useEffect(() => {
    if (withdrawal?.amount) {
      setAmount(Math.abs(withdrawal?.amount).toFixed(2))
    }
    setDescription(withdrawal?.description || '')
    if (withdrawal?.transactionType) {
      setTransactionType(withdrawal?.transactionType)
    }
  }, [withdrawal])

  const handleAmountChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const name = event.target.name
    const value = event.target.value

    if (name === 'amount' && Number.isNaN(Number(value))) {
      return
    }

    setAmount(value)
  }

  function handleSubmit() {
    const withdrawalPatchBody: WithdrawalPatchBody = {
      amount: Number(amount),
      description,
      transactionType,
    }

    onClose(withdrawalPatchBody)
  }

  function handleClose() {
    onClose(undefined)
  }
  return (
    <Dialog open={!!withdrawal} maxWidth={'xs'} fullWidth={true}>
      <form
        onSubmit={e => {
          e.preventDefault()
          e.stopPropagation()
          handleSubmit()
        }}
      >
        <DialogTitle>Edit Withdrawal {withdrawal?.id}</DialogTitle>
        <DialogContent>
          <div className='grid grid-cols-1 gap-4'>
            <FormControl component='fieldset' fullWidth>
              <FormLabel component='legend' id='transactionType'>
                Transaction Type
              </FormLabel>

              <RadioGroup
                aria-label='transactionType'
                name='transactionType'
                value={transactionType}
                onChange={e => {
                  setTransactionType(e.target.value)
                }}
              >
                <FormControlLabel
                  value={TransactionType.INCENTIVES}
                  control={<Radio />}
                  label={TransactionType.toDisplays[TransactionType.INCENTIVES]}
                />
                <FormControlLabel
                  value={TransactionType.CHARGES}
                  control={<Radio />}
                  label={TransactionType.toDisplays[TransactionType.CHARGES]}
                />
              </RadioGroup>
            </FormControl>

            <TextField
              label={'Amount'}
              value={amount}
              onChange={handleAmountChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position={'start'}>
                    {transactionType === TransactionType.INCENTIVES
                      ? '+ RM'
                      : '- RM'}
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label={'Description'}
              value={description}
              onChange={e => {
                setDescription(e.target.value)
              }}
              rows={3}
              multiline
              required
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button color='secondary' variant='outlined' onClick={handleClose}>
            Cancel
          </Button>
          <Button
            color='primary'
            variant='contained'
            type={'submit'}
            disabled={
              withdrawal?.transactionType === transactionType &&
              Math.abs(withdrawal.amount).toFixed(2) ===
                Number(amount).toFixed(2) &&
              withdrawal.description === description
            }
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default EditWithdrawalDialog
