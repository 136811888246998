import React, { useEffect, useState } from 'react'
import EmptyOrderNoComponent from './emptyOrderNoComponent'
import EmptyOrderNoComponentGroupByAgent from './emptyOrderNoComponentGroupByAgent'
import DuplicatedOrderNoComponent from './duplicatedOrderNoComponent'
import { Tab, Tabs } from '@material-ui/core'
import { TabContext, TabPanel } from '@material-ui/lab'
import OrderService from '../../../agentPortal/orderManagement/orderService'
import { OrderBean } from '../../../sharePortal/orderManagement/bean/orderBean'
import { catchErrorWithDispatch } from '../../../common/ApiUtils'
import { useDispatch } from 'react-redux'

const OrderNoTabComponent = () => {
  const emptyOrderNo = 'Empty Order No'
  const groupByAgent = 'Group By Agent'
  const duplicated = 'Duplicated'
  const dispatch = useDispatch()
  const [tabIndex, setTabIndex] = useState(emptyOrderNo)

  const [orders, setOrders] = useState<OrderBean[]>([])
  useEffect(() => {
    OrderService.findAll('orderNo:').then(resp => setOrders(resp.data))
  }, [])

  const [
    orderNumberDuplicatedOrders,
    setOrderNumberDuplicatedOrders,
  ] = useState<OrderBean[]>([])

  useEffect(() => {
    OrderService.findAllDuplicatedByOrderNumber()
      .then(value => {
        setOrderNumberDuplicatedOrders(value.data)
      })
      .catch(catchErrorWithDispatch(dispatch))
  }, [dispatch])

  return (
    <TabContext value={tabIndex}>
      <Tabs
        value={tabIndex}
        onChange={(event, value) => {
          setTabIndex(value)
        }}
      >
        <Tab value={emptyOrderNo} label={emptyOrderNo} />
        <Tab value={groupByAgent} label={groupByAgent} />
        <Tab value={duplicated} label={duplicated} />
      </Tabs>
      <TabPanel value={emptyOrderNo}>
        <EmptyOrderNoComponent orders={orders} />
      </TabPanel>
      <TabPanel value={groupByAgent}>
        <EmptyOrderNoComponentGroupByAgent orders={orders} />
      </TabPanel>

      <TabPanel value={duplicated}>
        <DuplicatedOrderNoComponent orders={orderNumberDuplicatedOrders} />
      </TabPanel>
    </TabContext>
  )
}

export default OrderNoTabComponent
