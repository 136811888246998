import client, { AxiosResponse } from 'axios'
import { ProductCategoryPostBody } from './bean/productCategoryPostBody'
import { ProductCategory } from './bean/productCategory'

const url = `/api/v1/product-categories`
class ProductCategoryService {
  static getProductCategories(): Promise<AxiosResponse<ProductCategory[]>> {
    return client.get(url)
  }
  static getById(id: number): Promise<AxiosResponse<ProductCategory>> {
    return client.get(`${url}/${id}`)
  }

  static getProductCategoriesByProductId(productId: number) {
    return client.get(url, {
      params: {
        productId,
      },
    })
  }

  static extraOptionExist(
    productId: number
  ): Promise<AxiosResponse<{ exist: boolean }>> {
    return client.get(`${url}/haveExtraOption/${productId}`, {})
  }

  static postProductCategory(body: ProductCategoryPostBody) {
    return client.post(url, { ...body })
  }

  static deleteProductCategory(id: number) {
    return client.delete(`${url}/${id}`)
  }
}

export default ProductCategoryService
