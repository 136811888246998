import React, { FormEvent, useRef, useState } from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  TextField,
} from '@material-ui/core'
import { AnnouncementService } from './announcementService'
import { SnackbarAction } from '../../redux/reducer/snackbarReducer'
import { useDispatch } from 'react-redux'
import AnnouncementListComponent from './component/announcementListComponent'
import { LoadingAction } from '../../redux/reducer/loadingBackdropReducer'
import { AlertDialogAction } from '../../redux/reducer/alertDialogReducer'
import { catchErrorWithDispatch } from '../../common/ApiUtils'

const AnnouncementManagementPage = () => {
  const dispatch = useDispatch()
  const [files, setFiles] = useState<File[]>()
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)

  function handleFormSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    e.stopPropagation()
    if (content && title) {
      dispatch(LoadingAction.open('Creating Announcements...'))
      AnnouncementService.createAnnouncement(title, content, files || [])
        .then(() => {
          dispatch(SnackbarAction.open('Success Create Announcement'))

          setTitle('')
          setContent('')
          if (inputRef.current) {
            inputRef.current.value = ''
          }
        })
        .catch(catchErrorWithDispatch(dispatch))
        .finally(() => {
          dispatch(LoadingAction.close())
        })
    }
  }

  return (
    <div>
      <form onSubmit={handleFormSubmit}>
        <Card className='m-8'>
          <CardHeader title={'New Announcement'}></CardHeader>
          <CardContent className='grid gap-4'>
            <TextField
              name='title'
              value={title}
              label={'Title'}
              onChange={e => {
                setTitle(e.target.value)
              }}
              required
            />
            <TextField
              name='content'
              value={content}
              label={'Content'}
              onChange={e => setContent(e.target.value)}
              multiline
              rows={5}
              required
            />
            <input
              ref={inputRef}
              type='file'
              multiple={true}
              onChange={e => {
                if (e.target.files) {
                  const maxSize5Mb = 1024 * 5 * 1000

                  const allowList = ['png', 'jpg', 'jpeg', 'pdf']

                  const arr = []
                  for (let i = 0; i < e.target.files.length; i++) {
                    const file = e.target.files[i]
                    const strings = file.name
                      .split('.')
                      .reverse()[0]
                      .toLowerCase()

                    const isAllowed = allowList.some(value => {
                      return strings === value
                    })

                    if (!isAllowed) {
                      dispatch(
                        AlertDialogAction.open(
                          'Invalid File',
                          `${file.name} is not Image/PDF format.`
                        )
                      )
                      e.target.value = ''
                      setFiles([])
                      return
                    } else if (file.size > maxSize5Mb) {
                      dispatch(
                        AlertDialogAction.open(
                          'File Too Big',
                          `${file.name} is bigger than 5MB`
                        )
                      )
                      e.target.value = ''
                      setFiles([])
                      return
                    } else {
                      arr.push(file)
                    }
                  }
                  setFiles(arr)
                }
              }}
            />
          </CardContent>
          <CardActions className='justify-end'>
            <Button color={'primary'} variant={'contained'} type={'submit'}>
              Submit
            </Button>
          </CardActions>
        </Card>
      </form>

      <div className='m-8'>
        <AnnouncementListComponent />
      </div>
    </div>
  )
}

export default AnnouncementManagementPage
