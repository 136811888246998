import React, { useMemo, useState } from 'react'
import {
  AppBar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { AlertDialogAction } from '../../redux/reducer/alertDialogReducer'
import { useDispatch } from 'react-redux'
import { CommonActions } from '../../redux/reducer/commonReducer'

const LandingPageAttachmentsPage = () => {
  const dispatch = useDispatch()
  const [selectedFile, setSelectedFile] = useState<File>()
  const fileName = useMemo(() => selectedFile?.name || '', [selectedFile])

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null
    const maxSize5Mb = 1024 * 5 * 1000
    if (file) {
      const strings = file.name.split('.').reverse()[0].toLowerCase()

      const allowList = ['png', 'jpg', 'jpeg']
      const isAllowed = allowList.some(value => {
        return strings === value
      })

      if (!isAllowed) {
        dispatch(
          AlertDialogAction.open(
            'Invalid File',
            'This file is not Image format.'
          )
        )
        event.target.value = ''
        return
      }
      if (file.size > maxSize5Mb) {
        dispatch(
          AlertDialogAction.open(
            'File Too Big',
            'Your selected file is bigger than 5MB'
          )
        )
        event.target.value = ''
        return
      }

      setSelectedFile(file)
    } else {
      setSelectedFile(undefined)
    }
  }

  function handleOnClick() {
    if (!!selectedFile) {
      dispatch(
        CommonActions.createLandingPageAttachments(fileName, selectedFile)
      )
    }
  }

  return (
    <div>
      <AppBar position='relative'>
        <Toolbar className='space-x-3'>
          <Typography variant='h6' noWrap>
            Landing Page Attachments
          </Typography>
        </Toolbar>
      </AppBar>

      <Card className='m-8'>
        <CardHeader title={'Add New Attachment'} />
        <CardContent>
          {/*<TextField*/}
          {/*  name='name'*/}
          {/*  value={fileName}*/}
          {/*  label={'Name'}*/}
          {/*  onChange={e => {*/}
          {/*    setFileName(e.target.value)*/}
          {/*  }}*/}
          {/*/>*/}
          <input type='file' onChange={handleFileChange} />
        </CardContent>
        <CardActions>
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={handleOnClick}
          >
            SUBMIT
          </Button>
        </CardActions>
      </Card>
    </div>
  )
}

export default LandingPageAttachmentsPage
