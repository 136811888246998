import React from 'react'
import { Card, CardContent } from '@material-ui/core'
import { BoxedSimpleStatus } from '../bean/boxedSimpleStatus'

interface BoxedSimpleStatusComponentProps {
  status: BoxedSimpleStatus
}

const BoxedSimpleStatusComponent = ({
  status,
}: BoxedSimpleStatusComponentProps) => {
  return (
    <Card className='relative' key={status.title}>
      <div className='absolute text-white rounded-br'>{status.icon}</div>

      <CardContent>
        <div className='text-right text-gray-600'>{status.title}</div>
        <div className='text-right text-2xl font-bold'>{status.value}</div>
        <hr />
        <div>{status.description}</div>
      </CardContent>
    </Card>
  )
}

export default BoxedSimpleStatusComponent
