import client, { AxiosResponse } from 'axios'
import UserBean from '../../../sharePortal/userManagement/bean/userBean'
import { IUserPermission } from '../bean/IUserPermission'

const url = `/api/v1/users`

class UserService {
  static changePassword(
    oldPassword: string,
    newPassword: string
  ): Promise<AxiosResponse<UserBean>> {
    return client.post(`${url}/change-password`, {
      newPassword,
      oldPassword,
    })
  }

  static getPermissions(
    userId: number
  ): Promise<AxiosResponse<IUserPermission[]>> {
    return client.get(`${url}/${userId}/permissions`)
  }

  static assignPermission(userId: number, permissionIds: number[]) {
    return client.post(`${url}/${userId}/permissions`, { permissionIds })
  }
}

export default UserService
