import ProductService from '../../adminPortal/productManagement/productService'

import { AppThunk } from '../store'
import { Product } from '../../adminPortal/productManagement/bean/product'
import { SnackbarAction } from './snackbarReducer'
import { LoadingAction } from './loadingBackdropReducer'
import { catchErrorWithDispatch } from '../../common/ApiUtils'

const initialState: { products: Product[] } = {
  products: [],
}

const productReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case ProductReducerType.GET_PRODUCT_SUCCESS:
      return {
        ...state,
        products: [...action.payload],
      }
    default:
      return state
  }
}
export const ProductAction = {
  getProductSuccess: (payload: Product[]) => {
    return {
      type: ProductReducerType.GET_PRODUCT_SUCCESS,
      payload: payload,
    }
  },

  getProduct: (): AppThunk => async dispatch => {
    ProductService.getProducts()
      .then(value => {
        const data: Product[] = value.data

        dispatch(ProductAction.getProductSuccess(data))
      })
      .catch(catchErrorWithDispatch(dispatch))
  },
  postProduct: (name: string, image?: string): AppThunk => async dispatch => {
    ProductService.postProduct(name, image)
      .then(() => {
        dispatch(ProductAction.getProduct())
      })
      .catch(catchErrorWithDispatch(dispatch))
  },
  deleteProduct: (id: number): AppThunk => async dispatch => {
    dispatch(LoadingAction.open('Deleting Product...'))

    ProductService.deleteProduct(id)
      .then(() => {
        dispatch(SnackbarAction.open('Success Delete Product'))
        dispatch(ProductAction.getProduct())
      })
      .catch(catchErrorWithDispatch(dispatch))
      .finally(() => dispatch(LoadingAction.close()))
  },
}

export const ProductReducerType = {
  GET_PRODUCT_SUCCESS: 'GET_PRODUCT_SUCCESS',
}
export default productReducer
