import React, { useEffect, useMemo, useState } from 'react'
import {
  AppBar, Card, CardContent, CardHeader, IconButton, Tab, Tabs, TextField, Toolbar, Tooltip, Typography,
} from '@material-ui/core'
import userProfileService from '../../common/service/userProfileService'
import { useDispatch } from 'react-redux'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import { ProfileDetailUtil } from './profileDetailUtil'
import ChangePasswordDialog, { ChangePasswordBean, } from './component/changePasswordDialog'
import UserService from '../../adminPortal/userManagement/service/userService'
import { SnackbarAction } from '../../redux/reducer/snackbarReducer'
import { LoadingAction } from '../../redux/reducer/loadingBackdropReducer'
import { useTypedSelector } from '../../redux/reducer'
import CommissionAccordionComponent from '../../component/commission/CommissionAccordionComponent'
import { ProfileDetailPageActions, ProfileDetailPageProps, } from '../../redux/reducer/page/profileDetailPage'
import { Alert, TabContext, TabPanel } from '@material-ui/lab'
import InvitationComponent from '../../agentPortal/invitation/InvitationComponent'
import UserRole from '../../common/constant/userRole'
import AgentPrincipleComponent from '../../component/agent/AgentPrincipleComponent'
import { catchErrorWithDispatch } from '../../common/ApiUtils'
import AgentWithdrawalTab from '../../adminPortal/userManagement/agentWithdrawal.tab'
import SubAgentTreeComponent from '../../component/agent/subAgentTree.component'

const PageTabs = {
  PROFILE: { key: 'PROFILE', display: 'Profile' },
  COMMISSIONS: { key: 'COMMISSIONS', display: 'Commissions Rate' },
  WITHDRAWALS: { key: 'WITHDRAWALS', display: 'Withdrawals' },
  SUB_AGENT: { key: 'SUB_AGENT', display: 'Sub Agents' },
}

const ProfileDetailPage = () => {
  const dispatch = useDispatch()
  const profileDetailPageReducerProps: ProfileDetailPageProps = useTypedSelector(
    state => state.profileDetailPage
  )
  const user = useMemo(userProfileService.getUserProfile, [])

  const {
    profile,
    principle,
    commissions,
    selectedTab,
    haveUnsetCommission,
  } = profileDetailPageReducerProps
  const [dialogOpen, setDialogOpen] = useState(false)

  useEffect(() => {
    if (user) {
      dispatch(ProfileDetailPageActions.setProfile(user))
    }

    dispatch(ProfileDetailPageActions.setSelectedTab(PageTabs.PROFILE.key))
  }, [dispatch, user])

  const items = !profile
    ? []
    : userProfileService.isAgent()
    ? ProfileDetailUtil.getAgentDisplayItem(profile)
    : ProfileDetailUtil.getAdminDisplayItem(profile)

  function onChangePasswordClick() {
    setDialogOpen(true)
  }

  function handleDialogClose(result?: ChangePasswordBean) {
    setDialogOpen(false)
    if (result) {
      dispatch(LoadingAction.open('Changing Password....'))
      UserService.changePassword(result.oldPassword, result.newPassword)
        .then(() => {
          dispatch(SnackbarAction.open('Success change to new password.'))
        })
        .catch(catchErrorWithDispatch(dispatch))
        .finally(() => {
          dispatch(LoadingAction.close())
        })
    }
  }

  return (
    <TabContext value={selectedTab}>
      <AppBar position='relative'>
        <Toolbar className='space-x-3'>
          <Typography variant='h6'>User Profile</Typography>

          <Tooltip title={'Change Password'}>
            <IconButton color={'inherit'} onClick={onChangePasswordClick}>
              <VpnKeyIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>

        <Tabs
          value={selectedTab}
          onChange={(event, value) => {
            dispatch(ProfileDetailPageActions.setSelectedTab(value))
          }}
        >
          <Tab label={PageTabs.PROFILE.display} value={PageTabs.PROFILE.key} />

          {profile && UserRole.isAgent(profile.role) && (
            <Tab
              label={PageTabs.COMMISSIONS.display}
              value={PageTabs.COMMISSIONS.key}
            />
          )}
          {profile && UserRole.isAgent(profile.role) && (
            <Tab
              label={PageTabs.WITHDRAWALS.display}
              value={PageTabs.WITHDRAWALS.key}
            />
          )}
          {profile && UserRole.isAgent(profile.role) && (
            <Tab
              label={PageTabs.SUB_AGENT.display}
              value={PageTabs.SUB_AGENT.key}
            />
          )}
        </Tabs>
      </AppBar>

      {haveUnsetCommission && (
        <Alert severity='warning'>
          <div>Have unset commission</div>
          <div>
            Please Contact you Principle to to avoid{' '}
            <span className='font-bold'>NO COMMISSION</span> when complete order
          </div>
        </Alert>
      )}

      <TabPanel value={PageTabs.PROFILE.key}>
        {principle && profile && (
          <div className='mx-8'>
            <AgentPrincipleComponent
              selectedAgent={profile}
              principle={principle}
            />
          </div>
        )}
        {items
          .filter(category => !category.disable)
          .map(category => {
            return (
              <div key={category.title} className='mx-8 my-4'>
                <Card>
                  <CardHeader title={category.title} />
                  <CardContent className='grid-cols-2 gap-4 grid'>
                    {category.items.map(detailItem => {
                      return (
                        <TextField
                          key={detailItem.label}
                          className={detailItem.className}
                          label={detailItem.label}
                          value={detailItem.value}
                          disabled
                          InputLabelProps={{ shrink: true }}
                        />
                      )
                    })}
                  </CardContent>
                </Card>
              </div>
            )
          })}
      </TabPanel>
      <TabPanel value={PageTabs.COMMISSIONS.key}>
        {userProfileService.isAgent() && (
          <div className='mx-8 my-4'>
            <Card>
              <CardHeader title={'Commission Rate'} />
              {Object.entries(commissions).map(
                ([categoryName, commissions]) => {
                  return (
                    <CommissionAccordionComponent
                      key={categoryName}
                      commissions={commissions}
                      categoryName={categoryName}
                    />
                  )
                }
              )}
            </Card>
          </div>
        )}
      </TabPanel>

      <TabPanel value={PageTabs.SUB_AGENT.key}>
        <div className='mx-8'>
          <InvitationComponent />
        </div>
        <div className='m-8'>
          {user && <SubAgentTreeComponent user={user} />}
        </div>
      </TabPanel>

      <TabPanel value={PageTabs.WITHDRAWALS.key}>
        {profile && UserRole.isAgent(profile.role) && (
          <AgentWithdrawalTab user={profile} />
        )}
      </TabPanel>
      <ChangePasswordDialog open={dialogOpen} onClose={handleDialogClose} />
    </TabContext>
  )
}

export default ProfileDetailPage
