import React, { useEffect, useState } from 'react'
import UserBean from '../../../sharePortal/userManagement/bean/userBean'
import { WithdrawalPostBody } from '../../../features/withdrawal/bean/withdrawalPostBody'
import TransactionType from '../../../common/constant/transactionType'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'

export interface CreateWithdrawalDialogProps {
  agentProp?: UserBean
  onClose: (agent?: WithdrawalPostBody) => void
}

const CreateWithdrawalDialog = (props: CreateWithdrawalDialogProps) => {
  const { onClose, agentProp } = props
  const [postBody, setPostBody] = useState<WithdrawalPostBody | undefined>()
  const handleClose = (agent?: WithdrawalPostBody) => {
    onClose(agent)
  }

  useEffect(() => {
    setPostBody({
      agentId: agentProp?.id!! || 0,
      amount: 0.0,
      description: '',
      transactionType: TransactionType.INCENTIVES,
      orderId: undefined,
      packageName: '',
    })
  }, [agentProp])

  const onChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const name = event.target.name
    const value = event.target.value

    if (name === 'amount' && Number.isNaN(Number(value))) {
      return
    }

    const newPkg = {
      ...postBody,
      [name]: value,
    } as WithdrawalPostBody

    setPostBody(newPkg)
  }

  const handleSelectChange = (
    event: React.ChangeEvent<{ name?: string; value: any }>
  ) => {
    if (event.target.name) {
      const newAgent = {
        ...postBody,
        [event.target.name]: event.target.value,
      } as WithdrawalPostBody

      setPostBody(newAgent)
    }
  }

  return (
    <Dialog
      onClose={() => handleClose()}
      aria-labelledby='simple-dialog-title'
      open={!!agentProp}
    >
      <form
        onSubmit={e => {
          e.preventDefault()
          e.stopPropagation()
          handleClose(postBody)
        }}
      >
        <DialogTitle id='simple-dialog-title'>
          Create{' '}
          <span
            className={
              postBody?.transactionType === TransactionType.INCENTIVES
                ? 'text-green-500'
                : 'text-red-500'
            }
          >
            {postBody?.transactionType}
          </span>{' '}
          for{' '}
          <span className='font-bold'>
            {agentProp?.fullName} ({agentProp?.username})
          </span>
        </DialogTitle>
        <DialogContent className='space-y-3'>
          <FormControl component='fieldset' fullWidth>
            <FormLabel component='legend' id='transactionType'>
              Transaction Type
            </FormLabel>

            <RadioGroup
              aria-label='transactionType'
              name='transactionType'
              value={postBody?.transactionType || TransactionType.INCENTIVES}
              onChange={handleSelectChange}
            >
              <FormControlLabel
                value={TransactionType.INCENTIVES}
                control={<Radio />}
                label={TransactionType.toDisplays[TransactionType.INCENTIVES]}
              />
              <FormControlLabel
                value={TransactionType.CHARGES}
                control={<Radio />}
                label={TransactionType.toDisplays[TransactionType.CHARGES]}
              />
            </RadioGroup>
          </FormControl>

          <TextField
            label={'Amount'}
            name='amount'
            value={postBody?.amount || ''}
            onChange={onChange}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position={'start'}>
                  {postBody?.transactionType === TransactionType.INCENTIVES
                    ? '+ RM'
                    : '- RM'}
                </InputAdornment>
              ),
            }}
          />

          <TextField
            required
            label={'Description'}
            name='description'
            value={postBody?.description || ''}
            onChange={onChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
            rows={3}
            multiline
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleClose()}
            variant={'outlined'}
            color={'secondary'}
          >
            Cancel
          </Button>
          <Button
            variant={'contained'}
            type={'submit'}
            color={'primary'}
            disabled={!postBody?.amount || postBody?.amount <= 0}
          >
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default CreateWithdrawalDialog
