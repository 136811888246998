import { AppThunk } from '../store'
import { ProductPackagePostBody } from '../../adminPortal/productPackageManagement/bean/productPackagePostBody'
import { SnackbarAction } from './snackbarReducer'
import { ProductPackage } from '../../adminPortal/productPackageManagement/bean/productPackage'
import ProductPackageService from '../../adminPortal/productPackageManagement/productPackageService'
import { LoadingAction } from './loadingBackdropReducer'
import { catchErrorWithDispatch } from '../../common/ApiUtils'

const initialState: { packages: ProductPackage[] } = {
  packages: [],
}

const actionType = {
  GET_PRODUCT_PACKAGE_SUCCESS: 'GET_PRODUCT_PACKAGE_SUCCESS',
}

const productPackageReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case actionType.GET_PRODUCT_PACKAGE_SUCCESS:
      return {
        ...state,
        packages: [...action.payload],
      }
    default:
      return state
  }
}

export const ProductPackageAction = {
  getPackageSuccess: (payload: ProductPackage[]) => {
    return {
      type: actionType.GET_PRODUCT_PACKAGE_SUCCESS,
      payload: payload,
    }
  },

  getPackage: (): AppThunk => async dispatch => {
    ProductPackageService.getPackages()
      .then(value => {
        const data: ProductPackage[] = value.data

        dispatch(ProductPackageAction.getPackageSuccess(data))
      })
      .catch(catchErrorWithDispatch(dispatch))
  },
  getPackageByCategoryId: (categoryId: number): AppThunk => async dispatch => {
    ProductPackageService.getPackagesByCategoryId(categoryId)
      .then(value => {
        const data: ProductPackage[] = value.data

        dispatch(ProductPackageAction.getPackageSuccess(data))
      })
      .catch(catchErrorWithDispatch(dispatch))
  },
  postPackage: (body: ProductPackagePostBody): AppThunk => async dispatch => {
    ProductPackageService.postPackage(body)
      .then(() => {
        dispatch(ProductPackageAction.getPackageByCategoryId(body.categoryId))
      })
      .catch(catchErrorWithDispatch(dispatch))
  },
  deletePackage: (
    id: number,
    categoryId?: number
  ): AppThunk => async dispatch => {
    dispatch(LoadingAction.open('Deleting Product Package...'))
    ProductPackageService.deletePackage(id)
      .then(() => {
        dispatch(SnackbarAction.open('Success Delete Package'))
        dispatch(
          categoryId
            ? ProductPackageAction.getPackageByCategoryId(categoryId)
            : ProductPackageAction.getPackage()
        )
      })
      .catch(catchErrorWithDispatch(dispatch))
      .finally(() => dispatch(LoadingAction.close()))
  },
}

export default productPackageReducer
