import React, { useState } from 'react'
import { TextField } from '@material-ui/core'
import { RegexUtils } from '../../common/regexUtils'
import { BaseInputFieldProps } from './index'

interface NricTextFieldProps extends BaseInputFieldProps {
  onChange: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
}
const NricTextField = (props: NricTextFieldProps) => {
  const {
    label,
    name,
    onChange,
    shrink = false,
    value,
    disabled = false,
  } = props

  const [error, setError] = useState(false)
  function handleChange(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    const { value } = e.target
    const validInput = RegExp('(^[\\d-]+$)').test(value)

    const validNric = RegexUtils.nricRegex.test(value)

    setError(!validNric)
    if (validInput || value === '') {
      onChange(e)
    }
  }

  return (
    <TextField
      label={label}
      name={name}
      value={value || ''}
      InputLabelProps={{ shrink: shrink }}
      onChange={handleChange}
      placeholder='999999-01-1234'
      error={error}
      required
      inputProps={{ maxLength: 14 }}
      helperText={`Please Input NRIC with "-"`}
      disabled={disabled}
    />
  )
}

export default NricTextField
