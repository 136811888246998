import client, { AxiosResponse } from 'axios'
import { CommissionEntity } from '../bean/commissionEntity'

const url = `/api/v1/commissions`
class CommissionService {
  static getCommissionByAgentId(
    agentId: number
  ): Promise<AxiosResponse<CommissionEntity[]>> {
    return client.get(url, {
      params: {
        userId: agentId,
      },
    })
  }
  static postCommission(
    userId: number,
    productPackageId: number,
    newPercentage: number
  ): Promise<AxiosResponse<CommissionEntity>> {
    return client.post(`${url}`, {
      percentage: newPercentage,
      userId: userId,
      productPackageId: productPackageId,
    })
  }

  static patchCommission(
    commissionId: number,
    newPercentage: number
  ): Promise<AxiosResponse> {
    return client.patch(`${url}/${commissionId}`, {
      percentage: newPercentage,
    })
  }
}

export default CommissionService
