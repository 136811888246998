import React, { useMemo, useState } from 'react'
import OrderServiceV2 from '../../agentPortal/orderManagement/orderServiceV2'
import { Pageable } from '../../common/bean/Pageable'
import { OrderBean } from './bean/orderBean'
import {
  AppBar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Fab,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { usePaginationParams } from '../../common/hook/usePaginationParams'
import { Alert, Autocomplete } from '@material-ui/lab'
import _ from 'lodash'

import { useTypedSelector } from '../../redux/reducer'
import OrderBgColorByStatus from '../../common/constant/orderBgColorByStatus'
import { OrderDisplayModel } from '../../adminPortal/settings/component/order/model/OrderDisplayModel'
import CommonUtils from '../../common/commonUtils'
import { Cancel, CheckCircle } from '@material-ui/icons'
import EditIcon from '@material-ui/icons/Edit'
import OrderStatus from '../../common/constant/orderStatus'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import userProfileService from '../../common/service/userProfileService'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'
import { AlertDialogAction } from '../../redux/reducer/alertDialogReducer'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { UserCodeBean } from '../../common/service/UserCodeBean'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import qs from 'qs'
import { LoadingAction } from '../../redux/reducer/loadingBackdropReducer'
import OrderService from '../../agentPortal/orderManagement/orderService'
import { catchErrorWithDispatch } from '../../common/ApiUtils'
import { SnackbarAction } from '../../redux/reducer/snackbarReducer'
import DateUtils from '../../common/dateUtils'
import { ExportToCsv, Options as csvOptions } from 'export-to-csv'
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop'
import GetAppIcon from '@material-ui/icons/GetApp'
import { DatePicker } from '@material-ui/pickers'
import { format } from 'date-fns'
import SelectAgentTextComponent from '../../agentPortal/orderManagement/component/selectAgentText.component'
import ProductSelectionComponent from '../../common/component/ProductSelectionComponent'

function idIsEqual(a: any) {
  return function (b: any) {
    return a?.toString() === b?.id?.toString()
  }
}

const filterOption: {
  [label: string]: {
    key: string
    label: string
    value: {
      key: string
      value: string
    }[]
  }
} = {
  docCompleted: {
    key: 'docCompleted',
    label: 'Doc Completed',
    value: [
      { key: 'COMPLETE', value: '1' },
      { key: 'NOT COMPLETE', value: '0' },
    ],
  },
  paymentClaimCompleted: {
    key: 'paymentClaimCompleted',
    label: 'Payment Claim Completed',
    value: [
      { key: 'COMPLETE', value: '1' },
      { key: 'NOT COMPLETE', value: '0' },
    ],
  },
  isSubmitted: {
    key: 'isSubmitted',
    label: 'Is Submitted',
    value: [
      { key: 'YES', value: '1' },
      { key: 'NO', value: '0' },
    ],
  },
  hasInvoiceNo: {
    key: 'hasInvoiceNo',
    label: 'Has Invoice No',
    value: [
      { key: 'YES', value: '1' },
      { key: 'NO', value: '0' },
    ],
  },

  expired: {
    key: 'expired',
    label: 'Expired',
    value: [
      { key: 'YES', value: '1' },
      { key: 'NO', value: '0' },
    ],
  },
}

const OrderListingV2Page = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const {
    page,
    onChangePage,
    rowPerPageOptions,
    onChangeSize,
    size,
    params,
    updateFilter,
  } = usePaginationParams()

  const [orders, setOrders] = useState<Pageable<OrderBean>>()
  const [triggerRefresh, setTriggerRefresh] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [searchParams, setSearchParams] = useState<Record<string, string>>({
    ...params,
  })

  const [searchParamStatus, setSearchParamStatus] = useState<String[]>(
    ((params['status'] as unknown) as string[]) || []
  )

  const portalSettingOrderDisplayList = useTypedSelector(
    state => state.orderSettings.orderDisplayList
  )
  const userSettingOrderDisplayList = useTypedSelector(
    state => state.userSettings.orderDisplayList
  )

  const orderDisplayList = useMemo(() => {
    if (userSettingOrderDisplayList.length) return userSettingOrderDisplayList
    return portalSettingOrderDisplayList
  }, [portalSettingOrderDisplayList, userSettingOrderDisplayList])

  const userCode: UserCodeBean = useTypedSelector(
    state => state.portalSetting.userCode
  )

  React.useEffect(
    () => {
      const timeOut = setTimeout(() => {
        if (!(_.isEmpty(searchParams) && _.isEmpty(searchParamStatus))) {
          updateFilter({
            ...searchParams,
            status: searchParamStatus,
          })
        }
      }, 750)

      return () => clearTimeout(timeOut)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams, searchParamStatus]
  )

  React.useEffect(() => {
    setIsLoading(true)
    OrderServiceV2.findAll({
      sort: `id,desc`,
      size: size,
      page: page,
      params: { ...params },
    })
      .then(o => o.data)
      .then(setOrders)
      .catch(catchErrorWithDispatch(dispatch))
      .finally(() => setIsLoading(false))
  }, [dispatch, page, params, size])

  function handleDeleteOrder(orderId: number) {
    dispatch(LoadingAction.open('Deleting Order...'))
    OrderService.deleteOrderById(orderId)
      .then(() => {
        dispatch(SnackbarAction.open('Success Delete Order'))

        setTriggerRefresh(!triggerRefresh)
      })
      .catch(catchErrorWithDispatch(dispatch))
      .finally(() => dispatch(LoadingAction.close()))
  }

  function handleCompletePaymentClaim(id: number) {
    dispatch(LoadingAction.open('Set Complete Payment Claim...'))
    OrderService.completePaymentClaim(id)
      .then(() => setTriggerRefresh(!triggerRefresh))
      .catch(catchErrorWithDispatch(dispatch))
      .finally(() => dispatch(LoadingAction.close()))
  }
  const productState = useTypedSelector(state => state.product)
  const categoryState = useTypedSelector(state => state.productCategory)
  const packageState = useTypedSelector(state => state.productPackage)
  function onProductIdChange(value: unknown) {
    const id = Number(value)
    const result = isNaN(id) ? '' : id.toString()
    setSearchParams(prevState => ({
      ...prevState,
      productId: result,
      packageId: '',
      categoryId: '',
    }))
  }

  function onCategoryIdChange(value: unknown) {
    const id = Number(value)
    const result = isNaN(id) ? '' : id.toString()

    setSearchParams(prevState => ({
      ...prevState,
      categoryId: result,
      packageId: '',
    }))
  }

  function onPackageIdChange(value: unknown) {
    const id = Number(value)
    const result = isNaN(id) ? '' : id.toString()

    setSearchParams(prevState => ({
      ...prevState,
      packageId: result,
    }))
  }

  const handleFilterDateChange = (
    name: string,
    newDate: MaterialUiPickersDate | null
  ) => {
    handleSearchParams({
      [name]: newDate && format(newDate, 'yyyy-MM-dd'),
    })
  }
  const handleSearchParams = (param: {}) => {
    setSearchParams({
      ...searchParams,
      ...param,
    })
  }

  const handleCompleteOrder = (order: OrderBean) => {
    dispatch(LoadingAction.open('CLOSING ORDER'))
    OrderService.completeOrder(order.id)
      .then(() => setTriggerRefresh(!triggerRefresh))
      .catch(catchErrorWithDispatch(dispatch))
      .finally(() => dispatch(LoadingAction.close()))
  }

  const handleFilterClear = () => {
    const { pageNumber }: Record<any, any> = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    })
    const newQuery = qs.stringify({
      pageNumber,
    })
    history.replace({
      search: `?${newQuery}`,
    })
    setSearchParams({})
    setSearchParamStatus([])
  }

  const handleOrderOnClick = (order: OrderBean) => {
    userProfileService.isAgentStaff()
      ? history.push(`/agent-staff-portal/order-detail/${order.id}`)
      : userProfileService.isAgent()
      ? history.push(`/agent-portal/order-detail/${order.id}`)
      : history.push(`/admin-portal/order-detail/${order.id}`)
  }

  function exportOrdersToCsv(order: OrderBean[]) {
    const s = DateUtils.getCurrentDateTime()
    const options: csvOptions = {
      filename: `Orders_${s}`,
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'Orders',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    }

    const csvExporter = new ExportToCsv(options)

    // sanitise data, make all undefined or null to ''
    //  and filter unused data
    const sanitisedOrder = order
      .map((input: OrderBean) =>
        Object.fromEntries(
          Object.entries(input).map(([key, value]) => {
            return value === undefined || value === null
              ? [key, '']
              : [key, value]
          })
        )
      )
      .map(input => {
        // rearrange columns and remove entity
        return {
          id: input.id,
          username: input.refUser.username,
          agentId: input.agentId,
          product: input.product,
          productCategory: input.productCategory,
          productPackage: input.productPackage,
          price: input.price,
          accountNo: input.accountNo,

          orderStatus: input.orderStatus,
          orderNo: input.orderNo,
          eform: input.eform,
          invoiceNo: input.invoiceNo,
          documentCompleted: input.documentCompleted,
          loginId: input.loginId,
          remarks: input.remarks,
          adminRemarks: input.adminRemarks,
          m2uRemarks: input.m2uRemarks,
          processingId: input.processingId,

          companyName: input.companyName,
          companyRegNo: input.companyRegNo,
          fullName: input.fullName,
          nric: input.nric,
          email: input.email,
          mobileNo: input.mobileNo,
          telNo: input.telNo,
          alternativeNo: input.alternativeNo,

          unitNo: input.unitNo,
          streetType: input.streetType,
          streetName: input.streetName,
          section: input.section,
          floorNo: input.floorNo,
          buildingName: input.buildingName,
          city: input.city,
          postcode: input.postcode,
          state: input.state,
          address: input.address,

          clawbackDate: input.clawbackDate,
          paymentClaimDate: input.paymentClaimDate,
          activatedOn: input.activatedOn,
          createdDate: input.createdDate,
          createdBy: input.createdBy,
          modifiedDate: input.modifiedDate,
          modifiedBy: input.modifiedBy,
        }
      })
    csvExporter.generateCsv(sanitisedOrder)
  }

  const renderOrder2 = (order: OrderBean) => {
    const orderBgColor = OrderBgColorByStatus[order.orderStatus]

    const displayModel = new OrderDisplayModel(
      order,
      CommonUtils.getUserCode(order.refUser, userCode)
    )

    return (
      <TableRow
        key={order.id}
        className={`hover:bg-gray-200 cursor-pointer`}
        id={order.id.toString()}
        onDoubleClick={e => {
          e.preventDefault()
          e.stopPropagation()
          handleOrderOnClick(order)
        }}
      >
        {orderDisplayList.map((value, index) => {
          if (_.isEqual(value, 'orderStatus')) {
            return (
              <TableCell key={index} className='truncate'>
                <span className={'p-1 rounded text-white ' + orderBgColor}>
                  {_.get(displayModel, value)}
                </span>
              </TableCell>
            )
          } else if (_.isEqual(value, 'documentCompleted')) {
            return (
              <TableCell key={index} className='truncate max-w-sm'>
                {_.get(displayModel, value) === 'true' ? (
                  <CheckCircle className='text-green-400' />
                ) : (
                  <Cancel className='text-red-400' />
                )}
              </TableCell>
            )
          } else
            return (
              <TableCell key={index} className='max-w-sm'>
                {_.get(displayModel, value)}
              </TableCell>
            )
        })}

        <TableCell>
          <div className='flex space-x-3'>
            <Tooltip title='Edit Order'>
              <IconButton
                size={'small'}
                color='inherit'
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  handleOrderOnClick(order)
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>{' '}
            {order.orderStatus !== OrderStatus.POST_COMPLETE &&
              !userProfileService.isAgent() && (
                <Tooltip title='Complete Order'>
                  <span>
                    <IconButton
                      disabled={!order.documentCompleted}
                      size={'small'}
                      color='inherit'
                      onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        handleCompleteOrder(order)
                      }}
                    >
                      <DoneAllIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            {(userProfileService.isManager() || userProfileService.isAdmin()) &&
              !order.paymentClaimDate && (
                <Tooltip title={'Complete Payment Claim'}>
                  <IconButton
                    size={'small'}
                    color='inherit'
                    onClick={e => {
                      e.stopPropagation()
                      e.preventDefault()
                      handleCompletePaymentClaim(order.id)
                    }}
                  >
                    <LocalAtmIcon />
                  </IconButton>
                </Tooltip>
              )}
            {userProfileService.isManager() && (
              <Tooltip title={'Delete Order'}>
                <IconButton
                  size={'small'}
                  color='inherit'
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()

                    dispatch(
                      AlertDialogAction.open(
                        <>
                          Delete Order:{' '}
                          <span className='font-bold'>{order.id}</span>
                        </>,
                        <>
                          <div>Are you sure to delete this order?</div>
                          <div>
                            {order.fullName} ({order.nric})
                          </div>
                          <div>by {order.refUser.username}</div>
                        </>,
                        () => {
                          handleDeleteOrder(order.id)
                        }
                      )
                    )
                  }}
                >
                  <DeleteForeverIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <div id='top'>
      <div className='absolute right-2 bottom-2'>
        <Fab
          size='small'
          color='primary'
          aria-label='to top'
          onClick={() => {
            document?.getElementById('top')?.scrollIntoView()
          }}
        >
          <VerticalAlignTopIcon />
        </Fab>
      </div>

      <AppBar position='relative'>
        <Toolbar className='space-x-3'>
          <Typography variant='h6' noWrap>
            Order Management
          </Typography>
          {!userProfileService.isAgentStaff() && (
            <Tooltip title={'Download CSV File'}>
              <Button
                variant={'outlined'}
                color='inherit'
                onClick={() => exportOrdersToCsv(orders!!.content)}
                startIcon={<GetAppIcon />}
              >
                Download CSV
              </Button>
            </Tooltip>
          )}
        </Toolbar>
      </AppBar>
      {!orders && <LinearProgress />}

      <Card className='m-8'>
        <CardHeader title='Filter' />
        <CardContent className='grid grid-cols-4 gap-4'>
          <div className={'col-span-4'}>
            <ProductSelectionComponent
              disabled={false}
              onProductChange={value => {
                onProductIdChange(value?.id)
              }}
              onCategoryChange={value => {
                onCategoryIdChange(value?.id)
              }}
              onPackageChange={value => {
                onPackageIdChange(value?.id)
              }}
              productValue={
                productState.products.find(idIsEqual(searchParams.productId)) ||
                null
              }
              categoryValue={
                categoryState.categories.find(
                  idIsEqual(searchParams.categoryId)
                ) || null
              }
              packageValue={
                packageState.packages.find(idIsEqual(searchParams.packageId)) ||
                null
              }
            />
          </div>
          <DatePicker
            clearable
            disableToolbar
            format='dd MMM yyyy'
            name='submitDateFrom'
            label='Submit Date From'
            value={searchParams['submitDateFrom'] || null}
            onChange={(e: MaterialUiPickersDate) => {
              handleFilterDateChange('submitDateFrom', e)
            }}
          />
          <DatePicker
            clearable
            disableToolbar
            format='dd MMM yyyy'
            name='submitDateTo'
            label='Submit Date To'
            value={searchParams['submitDateTo'] || null}
            onChange={(e: MaterialUiPickersDate) => {
              handleFilterDateChange('submitDateTo', e)
            }}
          />
          <DatePicker
            clearable
            disableToolbar
            format='dd MMM yyyy'
            name='activatedDateFrom'
            label='Activated Date From'
            value={searchParams['activatedDateFrom'] || null}
            onChange={(e: MaterialUiPickersDate) => {
              handleFilterDateChange('activatedDateFrom', e)
            }}
          />
          <DatePicker
            clearable
            disableToolbar
            format='dd MMM yyyy'
            name='activatedDateTo'
            label='Activated Date To'
            value={searchParams['activatedDateTo'] || null}
            onChange={(e: MaterialUiPickersDate) => {
              handleFilterDateChange('activatedDateTo', e)
            }}
          />
          {Object.entries(filterOption).map(([k, filterDetail]) => {
            return (
              <FormControl key={k}>
                <InputLabel id={k}>{filterDetail.label}</InputLabel>
                <Select
                  id={k}
                  name={k}
                  value={searchParams[k] || ''}
                  onChange={e =>
                    handleSearchParams({
                      [e.target.name!!]: String(e.target.value!!),
                    })
                  }
                >
                  <MenuItem value={''}>
                    <em>None</em>
                  </MenuItem>
                  {filterDetail.value.map(name => (
                    <MenuItem key={name.key} value={name.value}>
                      {name.key}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )
          })}

          <SelectAgentTextComponent
            disabled={false}
            setAgentId={agentId => handleSearchParams({ agentId })}
            agentId={searchParams['agentId'] || ''}
            params={{ excludeSelf: false, excludeDisable: true }}
          />
          <Autocomplete
            onChange={(_, value) => {
              setSearchParamStatus(value)
            }}
            value={searchParamStatus}
            className={'col-span-2'}
            multiple
            limitTags={2}
            options={OrderStatus.orderStatusDdl.map(o => o.value)}
            disableCloseOnSelect
            renderInput={params => <TextField {...params} label='Status' />}
          />
        </CardContent>
        <CardContent>
          <TextField
            name='search'
            value={searchParams['search'] || ''}
            onChange={e => {
              handleSearchParams({ search: e.target.value })
            }}
            fullWidth
            placeholder='Search by Company Name, Full Name, Nric, Order No, Invoice No, Account No, Processing Id...'
          />
        </CardContent>
        <CardActions className='justify-end'>
          <Button variant='outlined' onClick={handleFilterClear}>
            Clear
          </Button>
        </CardActions>
      </Card>

      <div className='m-8 bg-white'>
        <Alert severity='info'>
          <span className='font-bold'>Double click</span> on order to view order
          detail.
        </Alert>

        <TablePagination
          rowsPerPageOptions={rowPerPageOptions}
          component='div'
          count={orders?.totalElements || 0}
          rowsPerPage={size}
          page={page}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeSize}
        />
        <TableContainer component={Paper}>
          <Table size='small'>
            <TableHead>
              <TableRow>
                {orderDisplayList.map(text => (
                  <TableCell key={text} id={text}>
                    {_.startCase(text)}
                  </TableCell>
                ))}
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {((!isLoading && orders?.content) || []).map(value =>
                renderOrder2(value)
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={rowPerPageOptions}
          component='div'
          count={orders?.totalElements || 0}
          rowsPerPage={size}
          page={page}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeSize}
        />
      </div>
    </div>
  )
}

export default OrderListingV2Page
