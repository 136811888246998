import UserBean from '../sharePortal/userManagement/bean/userBean'
import { UserCodeBean } from './service/UserCodeBean'
import UserRole from './constant/userRole'
import { OrderBean } from '../sharePortal/orderManagement/bean/orderBean'
import { OrderSummaryBean } from '../adminPortal/settings/bean/OrderSummaryBean'

export default class CommonUtils {
  static isDevMode = function (): boolean {
    return process.env.NODE_ENV === 'development'
  }

  static getUserCode(user: UserBean, userCode: UserCodeBean): string {
    switch (user.role) {
      case UserRole.ROLE_MANAGER:
        return userCode.manager + '-' + user.id
      case UserRole.ROLE_ADMIN:
        return userCode.admin + '-' + user.id
      case UserRole.ROLE_STAFF:
        return userCode.staff + '-' + user.id
      case UserRole.ROLE_AGENT:
        return userCode.agent + '-' + user.id
      default:
        return user.id.toString()
    }
  }

  static getSummary(order: OrderBean, setting: OrderSummaryBean): string {
    const message = [`*${setting.title}*`, '']

    const { content } = setting

    const list = [
      'product',
      'productCategory',
      'productPackage',
      'price',
      'companyName',
      'companyRegNo',
      'address',
      'fullName',
      'nric',
      'email',
      'mobileNo',
      'telNo',
      'alternativeNo',
      'orderStatus',
      'orderNo',
      'loginId',
      'eform',
      'activatedOn',
      'invoiceNo',
      'remarks',
      'adminRemarks',
      'm2uRemarks',
    ]

    list.forEach(key => {
      if (content[key]?.enabled) {
        if (key === 'address') {
          message.push(
            `*${
              content[key].displayText
            }* : ${CommonUtils.getJoinedNewFormatAddress(order)}`
          )
        } else if (key === 'activatedOn') {
          message.push(
            `*${content[key].displayText}* : ${new Date(
              order[key] as string
            ).toDateString()}`
          )
        } else {
          // @ts-ignore
          message.push(`*${content[key].displayText}* : ${order[key]}`)
        }
      }
    })

    if (setting.footer) {
      message.push(``)
      message.push(setting.footer)
    }

    return message.join('\n')
  }

  static getJoinedNewFormatAddress(order?: OrderBean) {
    // handle join new format address and put to address fields
    //   so that can easy to copy

    if (order) {
      const {
        unitNo,
        streetType,
        streetName,
        section,
        floorNo,
        buildingName,
        city,
        state,
        postcode,
      } = order

      return [
        unitNo,
        streetType,
        streetName,
        section,
        floorNo,
        buildingName,
        city,
        state,
        postcode,
      ]
        .filter(v => !!v)
        .join(' ')
        .toUpperCase()
    } else return ''
  }

  static getAgentDisplayName({ fullName, companyName }: UserBean): string {
    return companyName ? `${companyName} (${fullName})` : fullName
  }
}
