import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { AuditBean } from './bean/AuditBean'

interface AuditDetailDialogProps {
  selectedAudit: AuditBean | undefined
  setSelectedAudit: (audit: AuditBean | undefined) => void
}
const AuditDetailDialog = (props: AuditDetailDialogProps) => {
  const { selectedAudit, setSelectedAudit } = props
  return (
    <Dialog
      open={!!selectedAudit}
      onClose={() => setSelectedAudit(undefined)}
      maxWidth={'xl'}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>
        {`${selectedAudit?.createdDate} ${selectedAudit?.createdBy} -
          ${selectedAudit?.action} a ${selectedAudit?.affectedTable}`}
      </DialogTitle>

      <DialogContent>
        <div>{selectedAudit?.description}</div>
        <DialogContentText id='alert-dialog-description'>
          {selectedAudit?.details.map(value => {
            return (
              <div key={value.id}>
                {value.affectedField} : {value.oldValue} <ArrowForwardIcon />{' '}
                {value.newValue}
              </div>
            )
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setSelectedAudit(undefined)}
          color='primary'
          autoFocus
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AuditDetailDialog
