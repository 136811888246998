import { ReduxReducerAction } from './index'
import { AppThunk } from '../store'
import { ProductAction } from './productReducer'
import { ProductCategoryAction } from './productCategoryReducer'
import { ProductPackageAction } from './productPackageReducer'
import ProductCategoryService from '../../adminPortal/productCategoryManagement/productCagetoryService'

export interface OrderPackageFormReducerProps {
  productId: string
  categoryId: string
  packageId: string
  hasM2uRemark: boolean
  m2uRemark: string
}

const ActionType = {
  init: `[OrderPackageFormReducer] Init`,
  updateAll: `[OrderPackageFormReducer] UpdateAll`,
  changeProductId: `[OrderPackageFormReducer] ChangeProductId`,
  changeCategoryId: `[OrderPackageFormReducer] ChangeCategoryId`,
  changePackageId: `[OrderPackageFormReducer] ChangePackageId`,
  changeM2uRemark: `[OrderPackageFormReducer] ChangeM2uRemark`,
  changeHasM2uRemark: `[OrderPackageFormReducer] ChangeHasM2uRemark`,
}

const initialState: OrderPackageFormReducerProps = {
  productId: '',
  packageId: '',
  categoryId: '',
  hasM2uRemark: false,
  m2uRemark: '',
}

const OrderPackageFormReducerReducer = (
  state = initialState,
  action: ReduxReducerAction
) => {
  switch (action.type) {
    case ActionType.init:
      return initialState
    case ActionType.updateAll:
      return {
        ...state,
        productId: action.payload.productId,
        packageId: action.payload.packageId,
        categoryId: action.payload.categoryId,
        m2uRemark: action.payload.m2uRemark,
        hasM2uRemark: !!action.payload.m2uRemark,
      }
    case ActionType.changeProductId:
      return {
        ...state,
        productId: action.payload,
        packageId: '',
        categoryId: '',
      }
    case ActionType.changeCategoryId:
      return {
        ...state,
        categoryId: action.payload,
        packageId: '',
      }
    case ActionType.changePackageId:
      return {
        ...state,
        packageId: action.payload,
      }
    case ActionType.changeM2uRemark:
      return {
        ...state,
        hasM2uRemark: true,
        m2uRemark: action.payload,
      }
    case ActionType.changeHasM2uRemark:
      return {
        ...state,
        hasM2uRemark: action.payload,
        m2uRemark: '',
      }
    default:
      return state
  }
}

export const OrderPackageFormReducerActions = {
  init(): AppThunk {
    return dispatch => {
      dispatch({ type: ActionType.init })
      dispatch(ProductAction.getProduct())
    }
  },

  updateAll(payload: {
    productId: string
    categoryId: string
    packageId: string
    m2uRemark: string
  }): AppThunk {
    return dispatch => {
      dispatch({
        type: ActionType.updateAll,
        payload,
      })
      dispatch(ProductAction.getProduct())

      dispatch(
        ProductCategoryAction.getCategoryByProductId(Number(payload.productId))
      )

      dispatch(
        ProductPackageAction.getPackageByCategoryId(Number(payload.categoryId))
      )
    }
  },
  changeProductId(productId: string): AppThunk {
    return dispatch => {
      dispatch({
        type: ActionType.changeProductId,
        payload: productId,
      })

      if (productId)
        dispatch(
          ProductCategoryAction.getCategoryByProductId(Number(productId))
        )
    }
  },
  changeCategoryId(categoryId: string): AppThunk {
    return dispatch => {
      dispatch({
        type: ActionType.changeCategoryId,
        payload: categoryId,
      })

      if (categoryId) {
        dispatch(
          ProductPackageAction.getPackageByCategoryId(Number(categoryId))
        )
        ProductCategoryService.extraOptionExist(Number(categoryId)).then(
          value => {
            dispatch({
              type: ActionType.changeHasM2uRemark,
              payload: value.data.exist || false,
            })
          }
        )
      }
    }
  },
  changePackageId(packageId: string) {
    return {
      type: ActionType.changePackageId,
      payload: packageId,
    }
  },

  changeM2uRemark(m2uRemark: string) {
    return {
      type: ActionType.changeM2uRemark,
      payload: m2uRemark,
    }
  },
}
export default OrderPackageFormReducerReducer
