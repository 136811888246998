import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  AppBar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { OrderBean } from './bean/orderBean'
import { useDispatch } from 'react-redux'
import OrderService from '../../agentPortal/orderManagement/orderService'
import { AxiosError } from 'axios'
import { ErrorResponse } from '../../errorResponse'
import { SnackbarAction } from '../../redux/reducer/snackbarReducer'
import DateUtils from '../../common/dateUtils'
import CloseIcon from '@material-ui/icons/Close'
import DoneIcon from '@material-ui/icons/Done'
import { LoadingAction } from '../../redux/reducer/loadingBackdropReducer'
import OrderStatus from '../../common/constant/orderStatus'
import userProfileService from '../../common/service/userProfileService'
import { useTypedSelector } from '../../redux/reducer'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import MoneyOffIcon from '@material-ui/icons/MoneyOff'
import { RegexUtils } from '../../common/regexUtils'
import { catchErrorWithDispatch } from '../../common/ApiUtils'
import { AlertDialogAction } from '../../redux/reducer/alertDialogReducer'
import { OrderPackageFormReducerActions } from '../../redux/reducer/orderPackageFormReducer'
import OrderAddressComponent from '../../common/orderManagement/OrderAddressComponent'
import OrderCustomerDetailComponent from '../../common/orderManagement/OrderCustomerDetailComponent'
import ProductSelectionComponent from '../../common/component/ProductSelectionComponent'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { DatePicker } from '@material-ui/pickers'
import { OrderUploadFileKey, OrderUtils } from '../../common/orderUtils'
import { CommissionEntity } from '../../adminPortal/userManagement/bean/commissionEntity'
import CommissionService from '../../adminPortal/userManagement/service/commissionService'
import { WithdrawalBean } from '../../features/claim/bean/WithdrawalBean'
import { ClaimBean } from '../../features/claim/bean/claimBean'
import { TabContext, TabPanel } from '@material-ui/lab'
import WithdrawalSearchTableComponent from '../../features/claim/component/withdrawalSearchTableComponent'
import ClaimTableComponent from '../../features/claim/component/claimTableComponent'
import CommissionRateComponent from '../../agentPortal/orderManagement/component/commissionRate.component'
import SelectAgentTextComponent from '../../agentPortal/orderManagement/component/selectAgentText.component'
import OrderAuditComponent from './component/orderAuditComponent'
import OrderAttachmentComponent from '../orderAttachment/orderAttachmentComponent'
import OrderClawbackDialog from './component/orderClawback.dialog'
import EditWithdrawalDialog from './component/editWithdrawal.dialog'
import _ from 'lodash'
import { Permission } from '../../common/constant/permission'
import tokenService from '../../common/service/tokenService'
import { TemplateActions } from '../../redux/slice/template.slice'
import SelectTemplateDialog from './selectTemplate.dialog'
import ProductSelectionDisplayComponent from '../../common/component/ProductSelectionDisplayComponent'
import OrderResetStatusButton from './component/orderResetStatus.button'
import FromEmailComponent from '../../agentPortal/orderManagement/component/fromEmail.component'
import OrderUploadFile from './component/orderUploadFile'
import OrderAttachmentService from '../orderAttachment/orderAttachmentService'
import useOrderAttachments from './component/useOrderAttachments'
import { OrderAttachmentBean } from '../orderAttachment/bean/orderAttachmentBean'

const m2uRemarksOption: Record<string, string> = {
  'Terminate streamyx, then maintain number to UniFi.':
    'Terminate streamyx, then maintain number to UniFi.',
  'Terminate streamyx and telephone number, then register new number for UniFi.':
    'Terminate streamyx and telephone number, then register new number for UniFi.',
  'Maintain streamyx and telephone number, then new service number.':
    'Maintain streamyx and telephone number, then new service number.',
}

const ORDER_DETAIL = 'ORDER DETAIL'
const RELATED_WITHDRAWAL = 'WITHDRAWALS'
const RELATED_CLAIMS = 'CLAIMS'
const AUDIT_HISTORY = 'AUDIT_HISTORY'
const ORDER_ATTACHMENT = 'ORDER_ATTACHMENT'

const OrderDetailPage = () => {
  const { id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const [files, setFiles] = useState<Record<string, File[]>>({})
  const [tabIndex, setTabIndex] = useState(ORDER_DETAIL)
  const [clawbackDialogOpen, setClawbackDialogOpen] = useState(false)

  const [commissionList, setCommissionList] = useState<CommissionEntity[]>([])

  const [withdrawals, setWithdrawals] = useState<WithdrawalBean[]>([])
  const [claims, setClaims] = useState<ClaimBean[]>([])

  const productState = useTypedSelector(state => state.product)
  const categoryState = useTypedSelector(state => state.productCategory)
  const packageState = useTypedSelector(state => state.productPackage)
  const orderPackageForm = useTypedSelector(state => state.orderPackageForm)

  const [isDisableAllField, setIsDisableAllField] = useState(false)

  const [openEditWithdrawal, setOpenEditWithdrawal] = useState(false)
  const [triggerRefresh, setTriggerRefresh] = useState(true)
  const [order, setOrder] = useState<OrderBean>()
  const initialFormState = {
    orderStatus: '',
    remarks: '',
    orderNo: '',
    loginId: '',
    eForm: '',
    email: '',
    mobileNo: '',
    telNo: '',
    fullName: '',
    companyName: '',
    companyRegNo: '',
    address: '',
    state: '',
    postcode: '',
    invoiceNo: '',
    unitNo: '',
    streetType: '',
    streetName: '',
    section: '',
    floorNo: '',
    buildingName: '',
    city: '',
    nric: '',
    alternativeNo: '',
    adminRemarks: '',
    processingId: '',
    accountNo: '',
    activatedOn: '',
    agentId: '',
    statusChangeReason: '',
    m2uRemarks: '',
    sporaReferenceNumber: '',
    ociTicketNumber: '',
    sporaProcessingId: '',
    appointmentDate: '',
    appointmentTime: '',
  }

  type FormState = typeof initialFormState
  const [form, setForm] = useState<FormState>(initialFormState)
  const [formError, setFormError] = useState<FormState>(initialFormState)

  const isAgent =
    userProfileService.isAgent() || userProfileService.isAgentStaff()
  const isTopAgent =
    userProfileService.isTopAgent() || userProfileService.isAgentStaff()

  const showCommissionRate = useTypedSelector(
    state => state.portalSetting.order.showCommissionRate
  )

  const roleAbleToViewCommissionRate = useTypedSelector(
    state => state.portalSetting.order.roleAbleToViewCommissionRate
  )

  const ableToViewCommissionRate = useMemo(
    () =>
      showCommissionRate &&
      roleAbleToViewCommissionRate.includes(
        userProfileService.getUserProfile()?.role || ''
      ),
    [roleAbleToViewCommissionRate, showCommissionRate]
  )

  useEffect(() => {
    if (order?.refUser.id) {
      CommissionService.getCommissionByAgentId(Number(order.refUser.id)).then(
        value => {
          setCommissionList(value.data)
        }
      )
    } else {
      setCommissionList([])
    }
  }, [order])

  useEffect(() => {
    OrderService.getOrderById(id)
      .then(value => {
        setOrder(value.data)

        setIsDisableAllField(
          !OrderStatus.canChangeOrderStatus(value.data.orderStatus)
        )
        setForm(getDefaultOrder(value.data))

        dispatch(
          OrderPackageFormReducerActions.updateAll({
            categoryId: value.data.productCategoryId,
            packageId: value.data.productPackageId,
            productId: value.data.productId,
            m2uRemark: value.data.m2uRemarks || '',
          })
        )
      })
      .catch((reason: AxiosError<ErrorResponse>) => {
        catchErrorWithDispatch(dispatch)(reason)
        if (reason.response?.status === 403) {
          history.goBack()
          return
        }
      })

    OrderService.getWithdrawalsByOrderId(id)
      .then(value => {
        setWithdrawals(value.data)
      })
      .catch(catchErrorWithDispatch(dispatch))

    OrderService.getClaimsByOrderId(id)
      .then(value => {
        setClaims(value.data)
      })
      .catch(catchErrorWithDispatch(dispatch))
  }, [dispatch, history, id, triggerRefresh])

  useEffect(() => {
    dispatch(TemplateActions.fetchTemplates())
    dispatch(OrderPackageFormReducerActions.init())
  }, [dispatch])

  const { orderAttachments, getOrderAttachment, deleteOrderAttachment } =
    useOrderAttachments({
      orderId: id,
    })
  useEffect(getOrderAttachment, [])

  const hasUpdateOrderNoPermission = useMemo(
    () =>
      tokenService.getPermissions().includes(Permission.ORDER_ORDER_NO_UPDATE),
    []
  )

  const onTextChange = (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<any>
  ) => {
    const name = e.target.name
    const value = e.target.value
    const shouldTrim = OrderUtils.shouldTrim(name)

    setForm({
      ...form,
      [name]: shouldTrim ? value.toString().trim() : value,
    })
  }
  const handleResetClick = () => {
    setForm(getDefaultOrder(order!!))
    setFormError(initialFormState)
    dispatch(
      OrderPackageFormReducerActions.updateAll({
        categoryId: order!!.productCategoryId,
        packageId: order!!.productPackageId,
        productId: order!!.productId,
        m2uRemark: order!!.m2uRemarks || '',
      })
    )
  }

  const handleSubmitClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const errors: Record<string, string> = {}

    if (!!form.mobileNo && !form.mobileNo.match(RegexUtils.phoneNumberRegex)) {
      errors.mobileNo = 'Mobile Number Wrong Format'
    }
    if (
      !!form.alternativeNo &&
      !form.alternativeNo.match(RegexUtils.phoneNumberRegex)
    ) {
      errors.alternativeNo = 'Alternative Number Wrong Format'
    }
    if (!!form.postcode && !form.postcode.match(RegExp('[0-9]{5}'))) {
      errors.postcode = 'Postcode Wrong Format'
    }

    setFormError({ ...initialFormState, ...errors })
    if (Object.keys(errors).length) {
      dispatch(SnackbarAction.openError('Form have error, please change.'))
      return
    }

    const defaultOrderValue: FormState = getDefaultOrder(order!!)
    const patchBody = Object.keys(form)
      .filter(key => {
        const key1 = key as keyof FormState
        return defaultOrderValue[key1] !== form[key1]
      })
      .reduce((newPatchBody, key) => {
        return {
          ...newPatchBody,
          [key]: form[key as keyof FormState],
        }
      }, {})

    const isPackagesChanged = () => {
      return (
        order?.productPackageId.toString() !==
        orderPackageForm.packageId.toString()
      )
    }
    const isOrderDetailChanged = () => {
      return !!Object.keys(patchBody).length
    }

    const isM2uRemarkChanged = () => {
      return (order?.m2uRemarks || '') !== orderPackageForm.m2uRemark
    }
    const ifFileChanged = () => Object.entries(files).length > 0
    if (
      !isOrderDetailChanged() &&
      !isPackagesChanged() &&
      !isM2uRemarkChanged() &&
      !ifFileChanged()
    ) {
      dispatch(SnackbarAction.open('The Order is same, no update.'))
      return
    }

    dispatch(LoadingAction.open('Updating Order...'))

    const apiList = []

    if (isPackagesChanged()) {
      apiList.push(() =>
        OrderService.patchOrderPackage(id, Number(orderPackageForm.packageId))
      )
    }

    if (isOrderDetailChanged() || isM2uRemarkChanged()) {
      apiList.push(() =>
        OrderService.patchOrder(id, {
          ...patchBody,
          m2uRemarks: orderPackageForm.m2uRemark,
        })
      )
    }

    const fileEntries = Object.entries(files)
    if (fileEntries.length) {
      fileEntries.forEach(fileEntry => {
        const [key, fileList] = fileEntry

        apiList.push(() =>
          OrderAttachmentService.createOrderAttachmentWithCategory(
            id,
            fileList,
            key
          )
        )
      })
    }

    apiList
      .reduce(
        (pre: Promise<any>, cur: () => Promise<any>) => pre.then(() => cur()),
        Promise.resolve()
      )
      .then(() => {
        dispatch(SnackbarAction.open('Success Update Order'))
        history.goBack()
      })
      .catch(catchErrorWithDispatch(dispatch))
      .finally(() => dispatch(LoadingAction.close()))
  }

  function handleCompleteDocument(order?: OrderBean) {
    if (!order) {
      return
    }
    dispatch(LoadingAction.open('SET DOCUMENT COMPLETE...'))
    OrderService.completeDocument(order.id)
      .then(() => setTriggerRefresh(!triggerRefresh))
      .catch(catchErrorWithDispatch(dispatch))
      .finally(() => dispatch(LoadingAction.close()))
  }

  function handleCompletePaymentClaim(order?: OrderBean) {
    if (!order) {
      return
    }
    dispatch(LoadingAction.open('Set Payment Claim Complete...'))
    OrderService.completePaymentClaim(order.id)
      .then(() => setTriggerRefresh(!triggerRefresh))
      .catch(catchErrorWithDispatch(dispatch))
      .finally(() => dispatch(LoadingAction.close()))
  }

  function handleAddSubmittedDate(order?: OrderBean) {
    if (!order) {
      return
    }
    dispatch(LoadingAction.open('Set Submitted Date...'))
    OrderService.addSubmittedDate(order.id)
      .then(() => setTriggerRefresh(!triggerRefresh))
      .catch(catchErrorWithDispatch(dispatch))
      .finally(() => dispatch(LoadingAction.close()))
  }

  const undoClawback = () => {
    const fireClawback = () => {
      OrderService.undoClawbackOrder(order!!.id)
        .then(() => {
          dispatch(SnackbarAction.open('Success Undo Clawback.'))
          setTriggerRefresh(prevState => !prevState)
        })
        .catch(catchErrorWithDispatch(dispatch))
    }

    dispatch(
      AlertDialogAction.open(
        `Undo Clawback for Order ${order?.id}`,
        'Are you sure to delete all CLAWBACK withdrawal for this order?',
        fireClawback
      )
    )
  }

  function getDefaultOrder(order: OrderBean): FormState {
    return {
      agentId: order.refUser.id.toString(),
      orderStatus: order.orderStatus,
      eForm: order?.eform || '',
      loginId: order?.loginId || '',
      orderNo: order?.orderNo || '',
      remarks: order?.remarks || '',
      email: order?.email || '',
      mobileNo: order?.mobileNo || '',
      telNo: order?.telNo || '',
      fullName: order?.fullName || '',
      companyName: order?.companyName || '',
      companyRegNo: order?.companyRegNo || '',
      address: order?.address || '',
      state: order?.state || '',
      postcode: order?.postcode || '',
      invoiceNo: order?.invoiceNo || '',
      unitNo: order?.unitNo || '',
      streetType: order?.streetType || '',
      streetName: order?.streetName || '',
      section: order?.section || '',
      floorNo: order?.floorNo || '',
      buildingName: order?.buildingName || '',
      city: order?.city || '',
      nric: order.nric || '',

      alternativeNo: order?.alternativeNo || '',
      adminRemarks: order?.adminRemarks || '',
      m2uRemarks: order?.m2uRemarks || '',
      processingId: order?.processingId || '',
      accountNo: order?.accountNo || '',
      activatedOn: order?.activatedOn || '',
      statusChangeReason: order?.statusChangeReason || '',
      sporaReferenceNumber: order?.sporaReferenceNumber || '',
      ociTicketNumber: order?.ociTicketNumber || '',
      appointmentDate: order?.appointmentDate || '',
      appointmentTime: order?.appointmentTime || '',
      sporaProcessingId: order?.sporaProcessingId || '',
    }
  }

  const isAgentButNotTopAgent = isAgent && !isTopAgent

  function renderOrderStatusSelect() {
    const orderStatus = [
      OrderStatus.PENDING,
      OrderStatus.WAITING_LIST,
      OrderStatus.EARLY_STAGE,
      OrderStatus.POST_COMPLETE,
      OrderStatus.CANCEL,
      OrderStatus.KIV,
      OrderStatus.NEW_AREA,
      OrderStatus.AGENT_SUBMITTED,
      OrderStatus.REJECTED,
      OrderStatus.EXPIRED,
      OrderStatus.PENDING_PCMS,
      OrderStatus.PORT_FULL,
      OrderStatus.UNIT_MISSING,
    ]

    const isStatusDisable = {
      [OrderStatus.REJECTED]: isAgent,
      [OrderStatus.EXPIRED]: isAgent,
      [OrderStatus.PENDING_PCMS]: isAgent,

      [OrderStatus.UNIT_MISSING]: isAgentButNotTopAgent,
      [OrderStatus.PORT_FULL]: isAgentButNotTopAgent,
      [OrderStatus.AGENT_SUBMITTED]: isAgentButNotTopAgent,
      [OrderStatus.PENDING]: isAgentButNotTopAgent,
      [OrderStatus.EARLY_STAGE]: isAgentButNotTopAgent,
      [OrderStatus.WAITING_LIST]: isAgentButNotTopAgent,
      [OrderStatus.NEW_AREA]: isAgentButNotTopAgent,
      [OrderStatus.KIV]: isAgentButNotTopAgent,
      [OrderStatus.CANCEL]: isAgentButNotTopAgent,
      [OrderStatus.POST_COMPLETE]: isAgent,
    }

    // Agent not able to change order status.
    // Top agent can change to certain order status.
    const isDisableSelect = isAgentButNotTopAgent || isDisableAllField

    return (
      <FormControl required disabled={isDisableSelect}>
        <InputLabel id='state'>Order Status</InputLabel>
        <Select
          id='orderStatus'
          name='orderStatus'
          value={form['orderStatus']}
          onChange={onTextChange}
        >
          {orderStatus.map(status => (
            <MenuItem
              key={status}
              value={status}
              disabled={isStatusDisable[status]}
            >
              {OrderStatus.toDisplay[status]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }

  function handleDeleteOrder(orderBean: OrderBean) {
    dispatch(
      AlertDialogAction.open(
        <>
          Delete Order: <span className='font-bold'>{orderBean.id}</span>
        </>,
        <>
          <div>Are you sure to delete this order?</div>
          <div>
            {orderBean.fullName} ({orderBean.nric})
          </div>
          <div>by {orderBean.refUser.username}</div>
        </>,
        () => {
          dispatch(LoadingAction.open('Deleting Order...'))
          OrderService.deleteOrderById(id)
            .then(() => {
              dispatch(SnackbarAction.open('Success Delete Order'))
              history.goBack()
            })
            .catch(catchErrorWithDispatch(dispatch))
            .finally(() => dispatch(LoadingAction.close()))
        }
      )
    )
  }

  function handleClawbackOrder() {
    setClawbackDialogOpen(true)
  }

  function canUserEditPackage(): boolean {
    const havePermission = tokenService
      .getPermissions()
      .includes(Permission.ORDER_PACKAGE_UPDATE)

    return (
      userProfileService.isManager() ||
      userProfileService.isAdmin() ||
      userProfileService.isStaff() ||
      userProfileService.isTopAgent() ||
      userProfileService.isAgentStaff() ||
      havePermission
    )
  }

  function isOrderCompleted(): boolean {
    return [
      OrderStatus.POST_COMPLETE,
      OrderStatus.KIV,
      OrderStatus.CANCEL,
    ].some(status => order?.orderStatus === status)
  }

  function ableToUndoClawback(): boolean {
    return tokenService
      .getPermissions()
      .includes(Permission.ORDER_CLAWBACK_UNDO)
  }

  const handleDateChange = (
    name: string,
    newDate: MaterialUiPickersDate | null
  ) => {
    const value = DateUtils.shortDateFormat(newDate?.toISOString())

    setForm(prevState => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }

  const ableToEditWithdrawal = () => {
    if (!order) return false

    const isManagerOrAdmin =
      userProfileService.isManager() || userProfileService.isAdmin()
    if (!isManagerOrAdmin) return false

    const isCompleted =
      order?.orderStatus === OrderStatus.POST_COMPLETE &&
      order.documentCompleted
    if (!isCompleted) return false

    return _.isNil(order?.clawbackDate)
  }

  function isNotAgentAndAgentStaff() {
    return !userProfileService.isAgent() && !userProfileService.isAgentStaff()
  }

  const orderAttachmentBeans: Record<string, OrderAttachmentBean[]> =
    useMemo(() => {
      return [
        OrderUploadFileKey.MY_KAD,
        OrderUploadFileKey.UTILITY,
        OrderUploadFileKey.CONVERSATION,
      ].reduce((pre, key) => {
        return {
          ...pre,
          [key]: (orderAttachments || []).filter(o => o.category === key),
        }
      }, {})
    }, [orderAttachments])

  return (
    <TabContext value={tabIndex}>
      <form onSubmit={handleSubmitClick}>
        <AppBar position='relative'>
          <Toolbar className='space-x-3 flex'>
            <IconButton color='inherit' onClick={history.goBack}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant='h6'>Order Detail</Typography>

            {userProfileService.isManager() && (
              <Tooltip title={'Delete Order'}>
                <IconButton
                  color='inherit'
                  onClick={() => handleDeleteOrder(order!!)}
                >
                  <DeleteForeverIcon />
                </IconButton>
              </Tooltip>
            )}
            {isNotAgentAndAgentStaff() &&
              order?.orderStatus === OrderStatus.POST_COMPLETE &&
              !order.clawbackDate && (
                <Tooltip title={'Clawback Order'}>
                  <IconButton color='inherit' onClick={handleClawbackOrder}>
                    <MoneyOffIcon />
                  </IconButton>
                </Tooltip>
              )}
            <div className='flex-1 text-right'>
              <FromEmailComponent
                onSubmit={result => {
                  const { packageName, ...rest } = result

                  setForm(pre => {
                    return {
                      ...pre,
                      ...rest,
                    }
                  })
                }}
              />

              <OrderResetStatusButton order={order} />
              <SelectTemplateDialog order={order} />
            </div>
          </Toolbar>

          <Tabs
            value={tabIndex}
            onChange={(event, value) => setTabIndex(value)}
          >
            <Tab label={ORDER_DETAIL} value={ORDER_DETAIL} />

            {isNotAgentAndAgentStaff() && (
              <Tab label={RELATED_WITHDRAWAL} value={RELATED_WITHDRAWAL} />
            )}
            {isNotAgentAndAgentStaff() && (
              <Tab label={RELATED_CLAIMS} value={RELATED_CLAIMS} />
            )}
            {!userProfileService.isAgentStaff() && (
              <Tab label={AUDIT_HISTORY} value={AUDIT_HISTORY} />
            )}
            {!userProfileService.isAgentStaff() && (
              <Tab label={ORDER_ATTACHMENT} value={ORDER_ATTACHMENT} />
            )}
          </Tabs>
        </AppBar>

        {order?.clawbackDate && (
          <div className='p-1 text-center bg-red-500 text-white space-x-2 flex justify-center items-center'>
            <span>
              {`Clawback at ${DateUtils.toLocaleDateString(
                order?.clawbackDate.toString()
              )}`}
            </span>
            {ableToUndoClawback() && (
              <Button
                variant={'outlined'}
                color={'inherit'}
                onClick={undoClawback}
              >
                Undo Clawback
              </Button>
            )}
          </div>
        )}

        <TabPanel value={ORDER_DETAIL}>
          <Card className='m-4'>
            <CardHeader title={'Order Summary'} />
            <CardContent>
              {!canUserEditPackage() || isOrderCompleted() ? (
                <ProductSelectionDisplayComponent
                  packageId={orderPackageForm.packageId}
                />
              ) : (
                <ProductSelectionComponent
                  disabled={!canUserEditPackage() || isOrderCompleted()}
                  onProductChange={value => {
                    if (value) {
                      dispatch(
                        OrderPackageFormReducerActions.changeProductId(
                          String(value.id)
                        )
                      )
                    }
                  }}
                  onCategoryChange={value => {
                    if (value) {
                      dispatch(
                        OrderPackageFormReducerActions.changeCategoryId(
                          String(value.id)
                        )
                      )
                    }
                  }}
                  onPackageChange={value => {
                    if (value) {
                      dispatch(
                        OrderPackageFormReducerActions.changePackageId(
                          String(value.id)
                        )
                      )
                    }
                  }}
                  productValue={
                    productState.products.find(
                      value =>
                        value.id.toString() ===
                        orderPackageForm.productId.toString()
                    ) || null
                  }
                  categoryValue={
                    categoryState.categories.find(
                      value =>
                        value.id.toString() ===
                        orderPackageForm.categoryId.toString()
                    ) || null
                  }
                  packageValue={
                    packageState.packages.find(
                      value =>
                        value.id.toString() ===
                        orderPackageForm.packageId.toString()
                    ) || null
                  }
                />
              )}
            </CardContent>
            <CardContent>
              <SelectAgentTextComponent
                disabled={isDisableAllField || !userProfileService.isManager()}
                agentId={form.agentId || ''}
                setAgentId={agentId => {
                  setForm(prevState => {
                    return {
                      ...prevState,
                      agentId: agentId,
                    }
                  })
                }}
                params={{ excludeSelf: false, excludeDisable: true }}
              />
            </CardContent>
            <CardContent className='grid grid-cols-2 gap-4'>
              <TextField
                label={'Created Date'}
                value={DateUtils.toLocaleDateString(order?.createdDate)}
                InputLabelProps={{ shrink: true }}
                disabled
              />
            </CardContent>
            <CardContent>
              {ableToViewCommissionRate && (
                <CommissionRateComponent
                  packageId={orderPackageForm.packageId}
                  packages={packageState.packages}
                  commissions={commissionList}
                />
              )}
            </CardContent>
          </Card>
          <Card className='m-4'>
            <CardHeader title={'Order Status'} />
            <CardContent className='grid-cols-2 grid gap-4'>
              {renderOrderStatusSelect()}

              <TextField
                name='statusChangeReason'
                label='Status Change Reason'
                value={form['statusChangeReason']}
                onChange={onTextChange}
                required={[
                  OrderStatus.CANCEL,
                  OrderStatus.EXPIRED,
                  OrderStatus.REJECTED,
                ].includes(form['orderStatus'])}
              />

              <TextField
                name='invoiceNo'
                label='Reference Invoice No'
                value={form['invoiceNo']}
                onChange={onTextChange}
              />

              <TextField
                name='processingId'
                label='Processing ID'
                value={form['processingId']}
                onChange={onTextChange}
              />

              <TextField
                name='accountNo'
                label='Account No'
                value={form['accountNo']}
                onChange={onTextChange}
              />

              <div />
              <div className='space-y-3'>
                <div className='flex '>
                  <div>Document Completed:</div>
                  <div>
                    {order?.documentCompleted ? (
                      <DoneIcon className='bg-green-500 rounded-full text-white' />
                    ) : (
                      <CloseIcon className='bg-red-500 rounded-full text-white ' />
                    )}
                  </div>
                </div>
                {!order?.documentCompleted &&
                  order?.orderStatus !== OrderStatus.CANCEL &&
                  isNotAgentAndAgentStaff() && (
                    <Button
                      color={'primary'}
                      variant={'contained'}
                      size={'small'}
                      onClick={() => handleCompleteDocument(order)}
                    >
                      Complete Document
                    </Button>
                  )}
              </div>

              <div className='space-y-3'>
                <div className='flex '>
                  <div>Payment Claim Completed:</div>
                  <div>
                    {!!order?.paymentClaimDate ? (
                      <DoneIcon className='bg-green-500 rounded-full text-white' />
                    ) : (
                      <CloseIcon className='bg-red-500 rounded-full text-white ' />
                    )}
                  </div>
                </div>
                {!order?.paymentClaimDate &&
                  order?.orderStatus !== OrderStatus.CANCEL &&
                  isNotAgentAndAgentStaff() && (
                    <Button
                      color={'primary'}
                      variant={'contained'}
                      size={'small'}
                      onClick={() => handleCompletePaymentClaim(order)}
                    >
                      Complete Payment Claim
                    </Button>
                  )}
              </div>
              <div className='space-y-3'>
                <div className='flex '>
                  <div>Submitted Date:</div>
                  <div>
                    {!!order?.submittedDate ? (
                      <div> {DateUtils.toDateString(order?.submittedDate)}</div>
                    ) : (
                      <CloseIcon className='bg-red-500 rounded-full text-white ' />
                    )}
                  </div>
                </div>
                {!order?.submittedDate &&
                  order?.orderStatus === OrderStatus.POST_COMPLETE &&
                  isNotAgentAndAgentStaff() && (
                    <Button
                      color={'primary'}
                      variant={'contained'}
                      size={'small'}
                      onClick={() => handleAddSubmittedDate(order)}
                    >
                      Add Submitted Date
                    </Button>
                  )}
              </div>
              <div />

              <TextField
                id='orderNo'
                name='orderNo'
                label='Order No'
                value={form['orderNo']}
                onChange={onTextChange}
                disabled={isDisableAllField && !hasUpdateOrderNoPermission}
                fullWidth
                required={form['orderStatus'] === OrderStatus.POST_COMPLETE}
              />
              <TextField
                id='eForm'
                name='eForm'
                label='eForm (TM-Only)'
                value={form['eForm']}
                onChange={onTextChange}
                fullWidth
              />
              <TextField
                id='loginId'
                name='loginId'
                label='User Id'
                value={form['loginId']}
                onChange={onTextChange}
                fullWidth
              />

              <DatePicker
                clearable
                value={form['activatedOn'] || null}
                onChange={(e: MaterialUiPickersDate) => {
                  handleDateChange('activatedOn', e)
                }}
                format='dd MMM yyyy'
                name='activatedOn'
                label='Activated Date'
              />
              <TextField
                id='ociTicketNumber'
                name='ociTicketNumber'
                label='OCI Ticket Number'
                value={form['ociTicketNumber']}
                onChange={onTextChange}
                fullWidth
              />
              <div />

              <TextField
                id='sporaReferenceNumber'
                name='sporaReferenceNumber'
                label='Spora Reference Number'
                value={form['sporaReferenceNumber']}
                onChange={onTextChange}
                fullWidth
              />

              <TextField
                id='sporaProcessingId'
                name='sporaProcessingId'
                label='Spora Processing Id'
                value={form['sporaProcessingId']}
                onChange={onTextChange}
                fullWidth
              />

              <DatePicker
                clearable
                disableToolbar
                format='dd MMM yyyy'
                name={'appointmentDate'}
                label={'Appointment Date'}
                value={form['appointmentDate'] || null}
                onChange={(e: MaterialUiPickersDate) => {
                  handleDateChange('appointmentDate', e)
                }}
                fullWidth
              />
              <FormControl fullWidth>
                <InputLabel id='appointmentTime'>Appointment Time</InputLabel>
                <Select
                  id='appointmentTime'
                  name='appointmentTime'
                  label='Appointment Time'
                  value={form['appointmentTime']}
                  onChange={(
                    e: React.ChangeEvent<{ name?: string; value: any }>
                  ) => {
                    setForm({
                      ...form,
                      appointmentTime: e.target.value,
                    })
                  }}
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  {OrderUtils.installationPeriodDdl.map(({ key, value }) => {
                    return (
                      <MenuItem value={key} key={key}>
                        {value}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>

              <TextField
                className='col-span-2'
                id='remarks'
                name='remarks'
                label='Remarks'
                value={form['remarks']}
                onChange={onTextChange}
                fullWidth
                multiline
                rows='4'
              />

              <TextField
                className='col-span-2'
                id='adminRemarks'
                name='adminRemarks'
                label='Admin Remarks'
                value={form['adminRemarks']}
                onChange={onTextChange}
                fullWidth
                multiline
                rows='4'
              />

              {orderPackageForm.hasM2uRemark && (
                <FormControl
                  component='fieldset'
                  required={true}
                  className='col-span-2'
                >
                  <FormLabel component='legend'>M2U Remarks</FormLabel>
                  <RadioGroup
                    value={orderPackageForm.m2uRemark}
                    onChange={e => {
                      dispatch(
                        OrderPackageFormReducerActions.changeM2uRemark(
                          e.target.value
                        )
                      )
                    }}
                  >
                    {Object.entries(m2uRemarksOption).map(([id, str]) => {
                      return (
                        <FormControlLabel
                          key={id}
                          value={str}
                          control={<Radio required={true} />}
                          label={str}
                        />
                      )
                    })}
                  </RadioGroup>
                </FormControl>
              )}
            </CardContent>
          </Card>
          <Card className='m-4'>
            <CardHeader title={'Customer Detail'} />
            <CardContent>
              <OrderCustomerDetailComponent
                isCreateMode={false}
                form={form}
                errorForm={formError}
                onChange={(name, value) => {
                  setForm(prevState => ({
                    ...prevState,
                    [name]: value,
                  }))
                }}
              />
            </CardContent>
          </Card>
          <Card className='m-4'>
            <CardHeader title={'Address'} />
            <CardContent className='space-y-3'>
              <OrderAddressComponent
                order={order}
                form={{
                  unitNo: form.unitNo,
                  streetType: form.streetType,
                  streetName: form.streetName,
                  section: form.section,
                  floorNo: form.floorNo,
                  buildingName: form.buildingName,
                  city: form.city,
                  state: form.state,
                  postcode: form.postcode,
                }}
                errorForm={formError}
                onChange={(name, value) => {
                  setForm(prevState => ({
                    ...prevState,
                    [name]: value,
                  }))
                }}
              />
            </CardContent>
          </Card>

          <Card className='m-4'>
            <CardHeader title={'Upload File'} />
            <CardContent>
              <OrderUploadFile
                attachmentBeans={orderAttachmentBeans}
                files={files}
                onChange={setFiles}
                onRemove={deleteOrderAttachment}
              ></OrderUploadFile>
            </CardContent>
          </Card>
          <CardActions className='m-4 justify-end'>
            <Button
              color={'secondary'}
              size={'large'}
              onClick={handleResetClick}
            >
              Reset
            </Button>
            <Button
              color={'primary'}
              variant={'contained'}
              type={'submit'}
              size={'large'}
              disabled={
                !orderPackageForm.packageId ||
                (orderPackageForm.hasM2uRemark && !orderPackageForm.m2uRemark)
              }
            >
              Submit
            </Button>
          </CardActions>
        </TabPanel>

        <TabPanel value={RELATED_WITHDRAWAL}>
          <WithdrawalSearchTableComponent withdrawals={withdrawals} />
          <div className='my-2 text-right'>
            {ableToEditWithdrawal() && (
              <Button
                variant={'contained'}
                color={'primary'}
                onClick={() => setOpenEditWithdrawal(true)}
              >
                EDIT WITHDRAWAL
              </Button>
            )}
          </div>
        </TabPanel>
        <TabPanel value={RELATED_CLAIMS}>
          <ClaimTableComponent claims={claims} />
        </TabPanel>
        <TabPanel value={AUDIT_HISTORY}>
          <OrderAuditComponent orderId={id} />
        </TabPanel>
        <TabPanel value={ORDER_ATTACHMENT}>
          <OrderAttachmentComponent orderId={id} />
        </TabPanel>
      </form>

      <OrderClawbackDialog
        open={clawbackDialogOpen}
        orderBean={order}
        onClose={resp => {
          setClawbackDialogOpen(false)

          if (resp) {
            const { remark, remarkDate } = resp
            dispatch(LoadingAction.open('Submitting Clawback...'))

            OrderService.clawbackOrderById(id, remark, remarkDate)
              .then(() => {
                dispatch(SnackbarAction.open('Success Clawback Order'))
                setTriggerRefresh(!triggerRefresh)
              })
              .catch(catchErrorWithDispatch(dispatch))
              .finally(() => dispatch(LoadingAction.close()))
          }
        }}
      />
      <EditWithdrawalDialog
        onClose={() => setOpenEditWithdrawal(false)}
        onSuccess={() => setTriggerRefresh(!triggerRefresh)}
        open={openEditWithdrawal}
        order={order}
        withdrawals={withdrawals}
        productPackage={packageState.packages.find(
          value => value.id.toString() === orderPackageForm.packageId.toString()
        )}
      />
    </TabContext>
  )
}

export default OrderDetailPage
