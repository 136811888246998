import React, { useState } from 'react'
import {
  AppBar,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import AppBackButton from '../../common/component/appBackButton'
import { useAppDispatch } from '../../redux/store'
import { TemplateActions } from '../../redux/slice/template.slice'
import { useHistory } from 'react-router-dom'
import { useTypedSelector } from '../../redux/reducer'
import userProfileService from '../../common/service/userProfileService'
import { TemplateUtils } from './template.util'
import TemplateAvailableListComponent from './templateAvailableList.component'
import TemplatePreviewMessageComponent from './templatePreviewMessage.component'

export function TemplateCreatePage() {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const isLoading = useTypedSelector(state => state.templates.isLoading)

  const initialState = {
    title: '',
    content: '',
    category: 'GENERAL',
  }

  const [formData, setFormData] = useState(initialState)

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target
    setFormData(old => {
      const val = name === 'category' ? value.toUpperCase().trim() : value
      return { ...old, [name]: val }
    })
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const action = userProfileService.isAgent()
      ? TemplateActions.createPrivateTemplate({
          title: formData.title,
          content: formData.content,
          category: formData.category,
        })
      : TemplateActions.createPublicTemplate({
          title: formData.title,
          content: formData.content,
          category: formData.category,
        })

    dispatch(action).then(history.goBack)
  }
  return (
    <div>
      <AppBar position='relative'>
        <Toolbar className='space-x-3'>
          <AppBackButton />
          <Typography variant='h6' noWrap>
            Create Template
          </Typography>
        </Toolbar>
      </AppBar>

      <Grid container spacing={1} className='p-4'>
        <Grid item xs={12}>
          <TemplateAvailableListComponent />
        </Grid>

        <Grid item xs={6}>
          <TemplatePreviewMessageComponent template={formData.content} />
        </Grid>
        <Grid item xs={6}>
          <form onSubmit={onSubmit}>
            <Card>
              <CardContent>
                <TextField
                  label={'Template Title'}
                  name={'title'}
                  value={formData.title}
                  onChange={onChange}
                  fullWidth
                  required
                />

                <TextField
                  label={'Template Category'}
                  name={'category'}
                  value={formData.category}
                  onChange={onChange}
                  fullWidth
                  required
                />

                <div className='mt-2' />
                <TextField
                  label='Template Content'
                  name='content'
                  value={formData.content}
                  onChange={onChange}
                  rows={20}
                  multiline
                  fullWidth
                  required
                />
              </CardContent>
              <CardActions className='justify-end'>
                <Button
                  onClick={() => {
                    setFormData({
                      title: formData.title,
                      content: TemplateUtils.sampleTemplateContent,
                      category: formData.category,
                    })
                  }}
                >
                  Sample
                </Button>
                <Button
                  color={'primary'}
                  variant={'contained'}
                  type='submit'
                  disabled={isLoading}
                >
                  Submit
                </Button>
              </CardActions>
            </Card>
          </form>
        </Grid>
      </Grid>
    </div>
  )
}
