import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  Button,
  Card,
  CardContent,
  Container,
  TextField,
} from '@material-ui/core'
import { AuthenticationService } from './service/authenticationService'
import { SnackbarAction } from '../redux/reducer/snackbarReducer'
import { useDispatch } from 'react-redux'
import { LoadingType } from '../redux/reducer/loadingBackdropReducer'
import { catchErrorWithDispatch } from '../common/ApiUtils'

function ResetPasswordPage() {
  const { resetCode } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const [password, setPassword] = useState('')
  const [retypePassword, setRetypePassword] = useState('')

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (password !== retypePassword) {
      alert('Password is not same')
      return
    }

    dispatch({
      type: LoadingType.TOGGLE_LOADING,
      payload: 'Submitting...',
    })

    console.log(resetCode)

    AuthenticationService.resetPassword(resetCode, password)
      .then(() => {
        dispatch(SnackbarAction.open(`Success reset password.`))
        history.replace('/')
      })
      .catch(catchErrorWithDispatch(dispatch))
      .finally(() => {
        dispatch({
          type: LoadingType.TOGGLE_LOADING,
        })
      })
  }
  return (
    <div className='bg-gray-300 min-h-screen p-10'>
      <Container className='prose m-4'>
        <Card>
          <CardContent>
            <h1>Reset Password</h1>
            <div>Enter your new password below</div>

            <form onSubmit={onFormSubmit}>
              <TextField
                label='Password'
                margin='normal'
                type='password'
                onChange={event => setPassword(event.target.value)}
                fullWidth
                required
              />
              <TextField
                label='Retype Password'
                margin='normal'
                type='password'
                onChange={event => setRetypePassword(event.target.value)}
                fullWidth
                required
              />
              <div className='p-4' />
              <Button
                variant='contained'
                color='primary'
                type='submit'
                fullWidth
              >
                Submit
              </Button>
            </form>
          </CardContent>
        </Card>
      </Container>
    </div>
  )
}

export default ResetPasswordPage
