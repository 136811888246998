import React from 'react'
import { AppBar, Tab, Toolbar, Typography } from '@material-ui/core'
import OrderPanelComponent from '../../adminPortal/settings/component/order/orderPanelComponent'
import { TabContext, TabPanel } from '@material-ui/lab'
import { useTypedSelector } from '../../redux/reducer'
import { userSettingsAction } from '../../redux/slice/userSettingsSlice'
import { useAppDispatch } from '../../redux/store'

const ORDER_DISPLAY_TAB = '1'

function UserSettingPage() {
  const dispatch = useAppDispatch()
  const orderDisplayList = useTypedSelector(
    state => state.userSettings.orderDisplayList
  )

  return (
    <TabContext value={ORDER_DISPLAY_TAB}>
      <AppBar position='relative'>
        <Toolbar className='space-x-3'>
          <Typography variant='h6'>User Setting Page</Typography>
        </Toolbar>

        <Tab label={'Order Display'} value={ORDER_DISPLAY_TAB}/>
      </AppBar>

      <TabPanel value={ORDER_DISPLAY_TAB}>
        <OrderPanelComponent
          orderDisplayList={orderDisplayList}
          onSaveClick={items => {
            dispatch(userSettingsAction.updateUserSettingOrderDisplay(items))
          }}
        />
      </TabPanel>
    </TabContext>
  )
}

export default UserSettingPage
