import React from 'react'
import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useHistory } from 'react-router'

interface WithdrawalAppbarProps {
  title: string
  backButton: boolean
}
const WithdrawalAppbar = (props: WithdrawalAppbarProps) => {
  const history = useHistory()
  return (
    <AppBar position='relative'>
      <Toolbar className='space-x-3'>
        {props.backButton ? (
          <IconButton color='inherit' onClick={history.goBack}>
            <ArrowBackIcon />
          </IconButton>
        ) : null}
        <Typography variant='h6' noWrap>
          {props.title}
        </Typography>
      </Toolbar>
    </AppBar>
  )
}

export default WithdrawalAppbar
