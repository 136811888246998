import React from 'react'
import { Card, CardContent, CardHeader } from '@material-ui/core'
import { TemplateUtils } from './template.util'
import { demoOrder } from '../../sharePortal/orderManagement/bean/orderBean'

interface Props {
  template: string
}
function TemplatePreviewMessageComponent(props: Props) {
  function preview() {
    return TemplateUtils.parseTemplate(props.template, demoOrder)
  }

  return (
    <Card>
      <CardHeader title={'Preview Message'} />
      <CardContent>
        <div className='whitespace-pre-line'>{preview()}</div>
      </CardContent>
    </Card>
  )
}

export default TemplatePreviewMessageComponent
