import React from 'react'
import { AppBar, Toolbar, Typography } from '@material-ui/core'
import InvitationComponent from './InvitationComponent'

const InvitationPage = () => {
  return (
    <div>
      <AppBar position='relative'>
        <Toolbar className='space-x-3'>
          <Typography variant='h6' noWrap>
            Invitation Link
          </Typography>
        </Toolbar>
      </AppBar>
      <div className='m-8'>
        <InvitationComponent />
      </div>
    </div>
  )
}

export default InvitationPage
