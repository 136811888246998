import React, { useMemo, useState } from 'react'
import userProfileService from '../../../common/service/userProfileService'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { WithdrawalBean } from '../bean/WithdrawalBean'
import { SnackbarAction } from '../../../redux/reducer/snackbarReducer'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { LoadingAction } from '../../../redux/reducer/loadingBackdropReducer'
import WithdrawalService from '../service/withdrawalService'
import { catchErrorWithDispatch } from '../../../common/ApiUtils'
import EditWithdrawalDialog from '../../../component/withdrawal/dialog/editWithdrawalDialog'
import { AlertDialogAction } from '../../../redux/reducer/alertDialogReducer'
import { Permission } from '../../../common/constant/permission'
import tokenService from '../../../common/service/tokenService'

interface WithdrawalActionsMenuProps {
  withdrawal: WithdrawalBean
  successEdit: (withdrawalId: number) => void
  canEditClaims: boolean
  canEditWithdrawal: boolean
}
const WithdrawalActionsMenuComponent = (props: WithdrawalActionsMenuProps) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { withdrawal, successEdit, canEditClaims, canEditWithdrawal } = props
  const { orderId } = withdrawal

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const [selectedWithdrawal, setSelectedWithdrawal] = useState(false)

  const permissions = useMemo(() => {
    return tokenService.getPermissions()
  }, [])

  const handleClick = (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const goToOrder = () => {
    if (orderId) {
      const url = userProfileService.isAgent()
        ? `/agent-portal/order-detail/${orderId}`
        : `/admin-portal/order-detail/${orderId}`

      history.push(url)
    } else {
      dispatch(
        SnackbarAction.open('This withdrawal not link to order.', 'info')
      )
    }
  }

  const removeWithdrawalFromClaim = () => {
    dispatch(
      AlertDialogAction.open(
        <>
          Remove Withdrawal: <span className='font-bold'>{withdrawal.id}</span>
        </>,
        <>
          <div>Are you sure to remove this withdrawal?</div>
          <br />
          <div>
            RM{withdrawal.amount.toFixed(2)} {withdrawal.transactionType}
          </div>
          <div>{withdrawal.description}</div>
        </>,
        () => {
          dispatch(LoadingAction.open('Removing Withdrawal...'))

          WithdrawalService.removeFromClaim(withdrawal.id)
            .then(() => {
              dispatch(SnackbarAction.open('Success Remove Withdrawal'))
              successEdit(withdrawal.id)
            })
            .catch(catchErrorWithDispatch(dispatch))
            .finally(() => dispatch(LoadingAction.close()))
        }
      )
    )
  }
  const deleteWithdrawal = () => {
    dispatch(
      AlertDialogAction.open(
        <div className='text-red-500 font-medium text-2xl text-center'>
          <div>!! WARNING !!</div>
          <div>This action CANNOT be UNDONE</div>
        </div>,
        <>
          <div>Are you sure to DELETE this withdrawal({withdrawal.id})?</div>
          <br />
          <div>
            RM{withdrawal.amount.toFixed(2)} {withdrawal.transactionType}
          </div>
          <div>{withdrawal.description}</div>
        </>,
        () => {
          dispatch(LoadingAction.open('Deleting Withdrawal...'))

          WithdrawalService.deleteWithdrawal(withdrawal.id)
            .then(() => {
              dispatch(SnackbarAction.open('Success Delete Withdrawal'))
              successEdit(withdrawal.id)
            })
            .catch(catchErrorWithDispatch(dispatch))
            .finally(() => dispatch(LoadingAction.close()))
        }
      )
    )
  }

  return (
    <>
      <IconButton
        aria-controls='withdrawal-actions-menu'
        aria-haspopup='true'
        onClick={handleClick}
        color={'inherit'}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id='withdrawal-actions-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={goToOrder}>View Order</MenuItem>
        <MenuItem
          onClick={removeWithdrawalFromClaim}
          disabled={
            userProfileService.isAgent() ||
            !canEditClaims ||
            !withdrawal.claimId
          }
        >
          Remove Withdrawal From Claim
        </MenuItem>
        <MenuItem
          onClick={deleteWithdrawal}
          disabled={
            !permissions.includes(Permission.WITHDRAWAL_DELETE) ||
            !!withdrawal.claimId
          }
        >
          Delete Withdrawal
        </MenuItem>
        <MenuItem
          onClick={() => setSelectedWithdrawal(true)}
          disabled={
            !canEditClaims ||
            !canEditWithdrawal ||
            userProfileService.isAgent() ||
            !!withdrawal.claimId
          }
        >
          Edit Withdrawal
        </MenuItem>

        {selectedWithdrawal && (
          <EditWithdrawalDialog
            withdrawal={withdrawal}
            onClose={body => {
              setSelectedWithdrawal(false)

              const id = withdrawal?.id
              if (id && body) {
                dispatch(LoadingAction.open(`Updating Withdrawal ${id}`))
                WithdrawalService.patchWithdrawal(id, body)
                  .then(() => {
                    dispatch(
                      SnackbarAction.open(`Success Update Withdrawal ${id}`)
                    )
                    successEdit(id)
                  })
                  .catch(catchErrorWithDispatch(dispatch))
                  .finally(() => {
                    dispatch(LoadingAction.close())
                  })
              }
            }}
          />
        )}
      </Menu>
    </>
  )
}

export default WithdrawalActionsMenuComponent
