import React, { useEffect, useState } from 'react'
import { Card, CardContent, CardHeader } from '@material-ui/core'

import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import OrderService from '../orderManagement/orderService'
import { OrderBean } from '../../sharePortal/orderManagement/bean/orderBean'
import OrderStatus from '../../common/constant/orderStatus'
import CachedIcon from '@material-ui/icons/Cached'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import { addMonths, addYears } from 'date-fns'
import DateUtils from '../../common/dateUtils'
import userProfileService from '../../common/service/userProfileService'
import ViewPublishedAnnouncementComponent from '../announcementManagement/component/ViewPublishedAnnouncementComponent'

const Dashboard = () => {
  const [orders, setOrders] = useState<OrderBean[]>([])
  useEffect(() => {
    OrderService.findAll().then(value => {
      const afterFilter = value.data.filter(value1 => {
        if (userProfileService.isAgent())
          return (
            value1.refUser.username ===
            userProfileService.getUserProfile()?.username
          )
        else return true
      })
      setOrders(afterFilter)
    })
  }, [])

  const getOrderChartData = (values: OrderBean[]) => {
    const monthCount: Record<string, number> = {}
    const getObjectKeyFromDate = (d: Date): string => {
      return DateUtils.getMonthYearString(d)
    }

    const d = addYears(new Date(), -1)
    for (let i = 0; i < 12; i++) {
      const newD = addMonths(d, i)
      const s = getObjectKeyFromDate(newD)

      monthCount[s] = 0
    }

    values
      .filter(
        value =>
          ![OrderStatus.CANCEL, OrderStatus.KIV].includes(value.orderStatus)
      )
      .forEach(value => {
        const date = new Date(value.createdDate)
        const s = getObjectKeyFromDate(date)

        if (monthCount[s]) {
          monthCount[s]++
        } else {
          monthCount[s] = 1
        }
      })

    return Object.entries(monthCount).map(([key, value]) => {
      return {
        month: key,
        count: value,
      }
    })
  }

  const getSubmissionStatus = () => {
    const submissions = {
      value: orders.filter(
        value => value.orderStatus === OrderStatus.AGENT_SUBMITTED
      ).length,
      description: 'Total Submissions',
      title: 'Submission',
      icon: (
        <div className='bg-orange-400 p-4'>
          <NoteAddIcon />
        </div>
      ),
    }

    const inProgress = {
      value: orders.filter(value =>
        [
          OrderStatus.PENDING,
          OrderStatus.WAITING_LIST,
          OrderStatus.EARLY_STAGE,
          OrderStatus.NEW_AREA,
        ].includes(value.orderStatus)
      ).length,
      description: 'Total In Progress',
      title: 'In Progress',
      icon: (
        <div className='bg-teal-400 p-4'>
          <CachedIcon />
        </div>
      ),
    }

    const completed = {
      value: orders.filter(
        value => value.orderStatus === OrderStatus.POST_COMPLETE
      ).length,
      description: 'Total Completed',
      title: 'Completed',
      icon: (
        <div className='bg-green-400 p-4'>
          <ThumbUpIcon />
        </div>
      ),
    }

    const rejected = {
      value: orders.filter(value =>
        [OrderStatus.CANCEL, OrderStatus.KIV].includes(value.orderStatus)
      ).length,
      description: 'Total Cancelled or KIV',
      title: 'Rejected',
      icon: (
        <div className='bg-indigo-400 p-4'>
          <ThumbDownIcon />
        </div>
      ),
    }

    return [submissions, inProgress, completed, rejected]
  }

  const getBestSellingPackage = (values: OrderBean[]) => {
    const obj = values
      .filter(
        value =>
          ![OrderStatus.CANCEL, OrderStatus.KIV].includes(value.orderStatus)
      )
      .reduce((cum, { productPackage }) => {
        if (cum[productPackage]) {
          cum[productPackage]++
        } else {
          cum[productPackage] = 1
        }
        return cum
      }, {} as Record<string, number>)

    return Object.entries(obj)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 5)
  }
  return (
    <div>
      <div className='m-4'>
        <ViewPublishedAnnouncementComponent />
      </div>
      <div className='grid grid-cols-4 gap-4 m-4'>
        {getSubmissionStatus().map(status => {
          return (
            <Card className='relative' key={status.title}>
              <div className='absolute text-white rounded-br'>
                {status.icon}
              </div>

              <CardContent>
                <div className='text-right text-gray-600'>{status.title}</div>
                <div className='text-right text-2xl font-bold'>
                  {status.value}
                </div>
                <hr />
                <div>{status.description}</div>
              </CardContent>
            </Card>
          )
        })}
      </div>
      <Card className='m-4'>
        <CardHeader
          title={'Monthly Customer Applications'}
          subheader={
            'Total customer applications for past 12 months excluding KIV and Cancelled.'
          }
        />
        <CardContent>
          <ResponsiveContainer width={'100%'} height={180}>
            <BarChart data={getOrderChartData(orders)}>
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='month' />
              <YAxis />
              <Tooltip />
              <Bar dataKey='count' label={false} fill='#8884d8' />
            </BarChart>
          </ResponsiveContainer>
        </CardContent>
      </Card>

      <Card className='m-4'>
        <CardHeader title={'Top 5 Selling Package'} />
        <CardContent className='divide-y-2 divide-gray-300 divide-solid'>
          {getBestSellingPackage(orders).map(([key, value]) => {
            return (
              <div key={key} className='flex py-2'>
                <div className='flex-1'>{key}</div>
                <div className='w-20 text-right font-medium text-lg'>
                  {value}
                </div>
              </div>
            )
          })}
        </CardContent>
      </Card>

    </div>
  )
}

Dashboard.propTypes = {}

export default Dashboard
