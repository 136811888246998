import React, { useState } from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core'
import { useTypedSelector } from '../../../redux/reducer'
import { WithdrawalBean } from '../bean/WithdrawalBean'
import CommissionType from '../../../common/constant/commissionType'
import TransactionType from '../../../common/constant/transactionType'
import DateUtils from '../../../common/dateUtils'
import WithdrawalActionsMenuComponent from './withdrawalActionsMenu.component'
import _ from 'lodash'

const toDisplayList = [
  '#',
  'Transaction Details',
  'Product Package',
  'Account No',
  'Invoice No',
  'Order No',
  'Date',
  'Transaction Type',
  'Amount (RM)',
  'Commission Rate',
  'Remark',
  'Remark Date',
]

interface WithdrawalTableComponentProps {
  withdrawals: WithdrawalBean[]
  canEditClaims: boolean
  successEdit: (withdrawalId: number) => void
}

const WithdrawalTableComponent = ({
  withdrawals = [],
  canEditClaims,
  successEdit,
}: WithdrawalTableComponentProps) => {
  const portalSettingReducer = useTypedSelector(state => state.portalSetting)

  // Paginator
  const rowPerPageOptions = portalSettingReducer.paginator.rowPerPageOptions
  const [rowsPerPage, setRowsPerPage] = React.useState(
    portalSettingReducer.paginator.defaultRowPerPage
  )
  const [page, setPage] = useState(0)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  // END Paginator

  function getDisplayWithdrawals(): WithdrawalBean[] {
    return withdrawals.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    )
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {toDisplayList.map(text => (
              <TableCell key={text}>
                <span className='font-bold text-gray-800'>{text}</span>
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {getDisplayWithdrawals().map((value, index) => {
            const textColorClass =
              Number(value.amount) < 0 ? 'text-red-500' : 'text-green-500'

            const bgColorClass =
              Number(value.amount) < 0 ? 'bg-red-500' : 'bg-green-500'

            const displayCommissioRate =
              (value.commissionType &&
                (value.commissionType === CommissionType.PERCENTAGE
                  ? `${value.commissionRate}%`
                  : `RM ${value.commissionRate}`)) ||
              'N/A'

            // const canEditClaim =

            const canEditWithdrawal = [
              TransactionType.CHARGES,
              TransactionType.INCENTIVES,
            ].includes(value.transactionType)

            const invoiceNo = _.isNil(value.refOrderEntity)
              ? 'N/A'
              : value.refOrderEntity.invoiceNo

            return (
              <TableRow
                className='hover:bg-gray-200 select-none'
                key={value.id}
              >
                <TableCell>{index + 1}</TableCell>
                <TableCell>{value.description}</TableCell>
                <TableCell>
                  <div className='mb-2 font-bold italic'>
                    {value?.refOrderEntity?.productCategory}
                  </div>
                  <div>{value.packageName}</div>
                </TableCell>
                <TableCell>{value.accountNo}</TableCell>
                <TableCell>{invoiceNo}</TableCell>
                <TableCell>{value.refOrderEntity?.orderNo}</TableCell>
                <TableCell className='truncate'>
                  <div>
                    Created: {DateUtils.toDateString(value.createdDate)}
                  </div>
                  <div>
                    Activated:{' '}
                    {DateUtils.toDateString(value.refOrderEntity?.activatedOn)}
                  </div>
                </TableCell>
                <TableCell>
                  <span className={`p-1 text-white rounded ${bgColorClass}`}>
                    {value.transactionType}
                  </span>
                </TableCell>
                <TableCell>
                  <span className={textColorClass}>
                    {Number(value.amount).toFixed(2)}
                  </span>
                </TableCell>
                <TableCell>{displayCommissioRate}</TableCell>
                <TableCell>{value.remark}</TableCell>
                <TableCell>
                  {DateUtils.toDateString(value.remarkDate)}
                </TableCell>
                <TableCell>
                  <WithdrawalActionsMenuComponent
                    withdrawal={value}
                    successEdit={successEdit}
                    canEditClaims={canEditClaims}
                    canEditWithdrawal={canEditWithdrawal}
                  />
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={rowPerPageOptions}
        component='div'
        count={withdrawals.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </TableContainer>
  )
}

export default WithdrawalTableComponent
