const initialState = {
  open: false,
  message: '',
}
const loadingBackdropReducer = (
  state = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case LoadingType.TOGGLE_LOADING:
      return {
        open: !state.open,
        message: !!action.payload ? action.payload : '',
      }

    case LoadingType.CLOSE_LOADING:
      return {
        open: false,
        message: '',
      }

    default:
      return state
  }
}

export const LoadingType = {
  TOGGLE_LOADING: '[LOADING] OPEN LOADING',
  CLOSE_LOADING: '[LOADING] CLOSE LOADING',
}

export const LoadingAction = {
  open: (message?: string) => {
    return {
      type: LoadingType.TOGGLE_LOADING,
      payload: message,
    }
  },
  close: () => {
    return {
      type: LoadingType.CLOSE_LOADING,
    }
  },
}

export default loadingBackdropReducer
