import React from 'react'
import { Card, CardContent, CardHeader } from '@material-ui/core'
import SelectionSwitch from '../../../../common/component/selectionSwitch'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../../../redux/reducer'
import { claimSettingsSlice } from '../../../../redux/slice/claimSettings.slice'

const ClaimSectionComponent = () => {
  const dispatch = useDispatch()

  const requiredInvoiceNo = useTypedSelector(
    state => state.claimSettings.requiredInvoiceNoToCompleteClaim
  )
  const requiredInvoiceNoToggle = () => {
    dispatch(
      claimSettingsSlice.actions.requiredInvoiceNoToCompleteClaimToggle()
    )
  }
  return (
    <Card>
      <CardHeader title={'Claim Settings'} />
      <CardContent>
        <SelectionSwitch
          title='All Order Must Have Invoice Number To Complete Claim'
          leftDescription='No'
          rightDescription='Yes'
          checked={requiredInvoiceNo}
          onChange={requiredInvoiceNoToggle}
        />
      </CardContent>
    </Card>
  )
}

export default ClaimSectionComponent
