import React from 'react'

const initialState: {
  open: boolean
  title: React.ReactNode | string
  message: React.ReactNode | string
  onPositiveClick?: () => void
} = {
  open: false,
  title: '',
  message: '',
  onPositiveClick: undefined,
}

interface AlertDialogReducerAction {
  type: string
  payload: {
    title: React.ReactNode | string
    message: React.ReactNode | string
    onPositiveClick?: () => void
  }
}

const alertDialogReducer = (
  state = initialState,
  action: AlertDialogReducerAction
) => {
  switch (action.type) {
    case AlertDialogType.TOGGLE_OPEN:
      return {
        open: true,
        title: action.payload.title,
        message: action.payload.message,
        onPositiveClick: action.payload.onPositiveClick,
      }
    case AlertDialogType.TOGGLE_CLOSE:
      return { ...initialState }

    default:
      return state
  }
}

export const AlertDialogType = {
  TOGGLE_OPEN: 'TOGGLE_OPEN',
  TOGGLE_CLOSE: 'TOGGLE_CLOSE',
}
export default alertDialogReducer

export const AlertDialogAction = {
  open: (
    title?: React.ReactNode | string,
    message?: React.ReactNode | string,
    onPositiveClick?: () => void
  ) => {
    return {
      type: AlertDialogType.TOGGLE_OPEN,
      payload: {
        title,
        message,
        onPositiveClick,
      },
    }
  },
  close: () => {
    return {
      type: AlertDialogType.TOGGLE_CLOSE,
    }
  },
}
