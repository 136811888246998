export default class ClaimStatus {
  static PENDING = 'PENDING'
  static PROCESSING = 'PROCESSING'
  static PAID = 'PAID'
  static COMPLETED = 'COMPLETED'
  static REJECTED = 'REJECTED'

  static toDisplay: Record<string, string> = {
    PENDING: 'Pending',
    PROCESSING: 'Processing',
    PAID: 'Paid',
    COMPLETED: 'Completed',
    REJECTED: 'Rejected',
  }
}
