import React, { useEffect, useState } from 'react'
import { Button, Card, CardContent, CardHeader } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { ClaimBean } from '../../../features/claim/bean/claimBean'
import ClaimService from '../../../features/claim/service/claimService'

const EmptyClaimReferenceNoComponent = () => {
  const history = useHistory()

  // const [claimNoPage, setClaimNoPage] = useState(0)

  // const { claimPageNumber = 0 } = qs.parse(location.search, {
  //   ignoreQueryPrefix: true,
  // })

  const [claims, setClaims] = useState<Record<string, ClaimBean[]>>({})
  // const [claimRowsPerPage, setClaimNoRowsPerPage] = React.useState(
  //   portalSettingReducer.paginator.defaultRowPerPage
  // )
  // const handleClaimChangeRowsPerPage = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setClaimNoRowsPerPage(parseInt(event.target.value, 10))
  //   handleClaimChangePage(undefined, 0)
  // }

  // const handleClaimChangePage = (event: unknown, newPage: number) => {
  //   const query: Record<any, any> = qs.parse(location.search, {
  //     ignoreQueryPrefix: true,
  //   })
  //   query['claimPageNumber'] = newPage
  //   const newQuery = qs.stringify(query)
  //   history.replace({
  //     search: `?${newQuery}`,
  //   })
  // }

  // useEffect(() => {
  //   if (claim.length) {
  //     setClaimNoPage(Number(claimPageNumber))
  //   }
  // }, [claimPageNumber, claim])

  useEffect(() => {
    ClaimService.getAllEmptyReferenceNo().then(resp => {
      const newValue = resp.data.reduce<Record<string, ClaimBean[]>>(
        (previousValue, currentValue) => {
          const username = currentValue.refUserEntity.username || 'EMPTY'
          const newArr = [...(previousValue[username] || []), currentValue]

          const newObj = { ...previousValue }
          newObj[username] = newArr

          return newObj
        },
        {}
      )

      setClaims(newValue)
    })
  }, [])
  return (
    <Card>
      <CardHeader title={'Empty Claim Reference No'} />
      <CardContent>
        {Object.entries(claims).map(([username, claim]) => {
          return (
            <div key={username}>
              <div className='grid grid-cols-4 p-4 rounded hover:bg-gray-200'>
                <div className='col-span-1'>
                  <span className='text-gray-500'>Username: </span>
                  <span className='text-gray-700'>{username}</span>
                </div>
                <div className='col-span-3 flex'>
                  <span className='text-gray-500 mr-2'>Claim ID: </span>
                  <span className='text-gray-700 flex-1'>
                    {claim.map(value => (
                      <Button
                        key={value.id}
                        variant={'outlined'}
                        onClick={() =>
                          history.push(`/admin-portal/view-claim/${value.id}`)
                        }
                      >
                        {value.id.toString()}
                      </Button>
                    ))}
                  </span>
                </div>
              </div>
            </div>
          )
        })}

        {/*<TablePagination*/}
        {/*  className='w-full'*/}
        {/*  rowsPerPageOptions={rowPerPageOptions}*/}
        {/*  component='div'*/}
        {/*  count={claim.length}*/}
        {/*  rowsPerPage={claimRowsPerPage}*/}
        {/*  page={claimNoPage}*/}
        {/*  onChangePage={handleClaimChangePage}*/}
        {/*  onChangeRowsPerPage={handleClaimChangeRowsPerPage}*/}
        {/*/>*/}
      </CardContent>
    </Card>
  )
}

export default EmptyClaimReferenceNoComponent
