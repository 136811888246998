import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core'
import { OrderFormState } from '../newOrderPage'
import { Email } from '@material-ui/icons'
import { OrderUtils } from '../../../common/orderUtils'

function FromEmailComponent(props: {
  onSubmit(result: Partial<OrderFormState> & { packageName?: string, productName?: string }): void
}) {
  const [open, setOpen] = useState(false)
  const [textField, setTextField] = useState('')

  function handleClose() {
    setOpen(false)
    setTextField('')
  }

  function handleSubmit() {
    const isMaxis = textField.includes('Registration Summary')
    const result = isMaxis
      ? OrderUtils.maxisStringToOrderParser(textField)
      : OrderUtils.stringToOrderParser(textField)

    props.onSubmit(result)
    handleClose()
  }
  return (
    <>
      <Button
        onClick={() => {
          setOpen(true)
        }}
        color={'inherit'}
        startIcon={<Email />}
      >
        From Email
      </Button>
      <Dialog open={open}>
        <DialogTitle>Paste Copied Item Here</DialogTitle>
        <DialogContent>
          <TextField
            onChange={event => setTextField(event.target.value)}
            value={textField}
            placeholder='Paste here...'
            multiline
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button color={'secondary'} onClick={handleClose}>
            Close
          </Button>
          <Button variant={'outlined'} onClick={handleSubmit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default FromEmailComponent
