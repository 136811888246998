import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import UserRole from "../../../common/constant/userRole";
import {
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup
} from "@material-ui/core";
import { LoadingAction } from "../../../redux/reducer/loadingBackdropReducer";
import { AdminService } from "../service/adminService";
import { SnackbarAction } from "../../../redux/reducer/snackbarReducer";
import { UserActions } from "../../../redux/reducer/userReducer";
import UserBean from "../../../sharePortal/userManagement/bean/userBean";


interface UpdateRoleDialogProps {
  agent?: UserBean
  onClose: () => void
}


const UpdateRoleDialog = (props: UpdateRoleDialogProps) => {
  const dispatch = useDispatch()
  const oldRole = props.agent?.role || UserRole.ROLE_STAFF
  const [newRole, setNewRole] = useState(UserRole.ROLE_STAFF)

  useEffect(() => {
    setNewRole(props.agent?.role || UserRole.ROLE_STAFF)
  }, [props])
  return (
    <Dialog
      fullWidth
      open={!!props.agent}
      onClose={() => {
        props.onClose()
      }}
    >
      <DialogTitle>
        Update <span className='font-bold'>{props.agent?.username}</span> Role
        to
      </DialogTitle>
      <DialogContent>
        <FormControl component='fieldset' required={true}>
          <FormLabel component='legend'>Role</FormLabel>
          <RadioGroup
            value={newRole}
            onChange={(event, value) => {
              setNewRole(value)
            }}
          >
            {[
              UserRole.ROLE_MANAGER,
              UserRole.ROLE_ADMIN,
              UserRole.ROLE_STAFF,
            ].map(role => {
              return (
                <FormControlLabel
                  key={role}
                  value={role}
                  disabled={oldRole === role}
                  control={<Radio required={true} />}
                  label={UserRole.toDisplay[role]}
                />
              )
            })}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color={'secondary'} onClick={() => props.onClose()}>
          Cancel
        </Button>
        <Button
          color={'primary'}
          variant={'contained'}
          disabled={oldRole === newRole}
          onClick={() => {
            if (props.agent?.id) {
              props.onClose()
              dispatch(LoadingAction.open('Updating User Role...'))
              AdminService.updateRole(props.agent?.id, newRole)
                .then(() => {
                  dispatch(SnackbarAction.open('Success Update Role'))
                  dispatch(UserActions.getAdmins())
                })
                .finally(() => {
                  dispatch(LoadingAction.close())
                })
            }
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default UpdateRoleDialog;
