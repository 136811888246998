import { useDispatch } from 'react-redux'
import { SnackbarAction } from '../../redux/reducer/snackbarReducer'
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React from 'react'
import { useTypedSelector } from '../../redux/reducer'

const SnackbarComponent = () => {
  const snackbarState = useTypedSelector(state => state.snackbar)
  const { open, message, severity } = snackbarState

  const dispatch = useDispatch()

  const onClose = () => dispatch(SnackbarAction.close())
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      key='snackbar-error'
    >
      <Alert onClose={onClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default SnackbarComponent
