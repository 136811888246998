import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { OrderBean } from '../bean/orderBean'
import { WithdrawalBean } from '../../../features/claim/bean/WithdrawalBean'
import CommissionType from '../../../common/constant/commissionType'
import EditWithdrawalItemComponent from './editWithdrawalItem.component'
import { useDispatch } from 'react-redux'
import { catchErrorWithDispatch } from '../../../common/ApiUtils'
import WithdrawalService from '../../../features/claim/service/withdrawalService'
import _ from 'lodash'
import CommissionService from '../../../adminPortal/userManagement/service/commissionService'
import { CommissionEntity } from '../../../adminPortal/userManagement/bean/commissionEntity'
import { ProductPackage } from '../../../adminPortal/productPackageManagement/bean/productPackage'

interface EditWithdrawalDialogProps {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  order: OrderBean | undefined
  withdrawals: WithdrawalBean[] | undefined
  productPackage: ProductPackage | undefined
}

export interface EditWithdrawalModel {
  commissionType: CommissionType
  commissionRate: number
}

const EditWithdrawalDialog = (props: EditWithdrawalDialogProps) => {
  const dispatch = useDispatch()
  const { onClose, open, order, productPackage } = props

  const [withdrawals, setWithdrawals] = useState(props.withdrawals)
  const [commissions, setCommissions] = useState<
    CommissionEntity[] | undefined
  >(undefined)

  function getDisplayPrice() {
    return 'RM ' + (order?.price || 0).toFixed(2)
  }

  useEffect(() => {
    setWithdrawals(props.withdrawals)
  }, [props.withdrawals])

  useEffect(() => {
    if (!props.withdrawals) return

    const requests = props.withdrawals
      .map(value => value.refUserEntity.id)
      .map(value => CommissionService.getCommissionByAgentId(Number(value)))

    Promise.all(requests)
      .then(value => {
        const commissions = value.map(resp =>
          resp.data.find(
            commission =>
              _.isEqual(
                commission.productPackageId.toString(),
                order?.productPackageId.toString()
              ) &&
              _.isEqual(
                commission.productCategoryId.toString(),
                order?.productCategoryId.toString()
              )
          )
        )

        const res = commissions.filter(o => !_.isNil(o)) as CommissionEntity[]

        setCommissions(res)
      })
      .catch(catchErrorWithDispatch(dispatch))
  }, [dispatch, order, props.withdrawals])

  function handleUpdateClick() {
    if (withdrawals) {
      const apiCall = withdrawals
        ?.filter(value => _.isNil(value.claimId))
        ?.map(value => {
          return WithdrawalService.patchWithdrawal(value.id, {
            commissionType: value.commissionType,
            commissionRate: value.commissionRate,
          })
        })

      props.onClose()
      Promise.all(apiCall)
        .then(props.onSuccess)
        .catch(catchErrorWithDispatch(dispatch))
    }
  }

  const getMaxPrice = () => {
    const isPercentage = _.isEqual(
      productPackage?.commissionType,
      CommissionType.PERCENTAGE
    )

    const max = getMaxPercentage(commissions)

    if (_.isNil(order) || _.isNil(max)) return 0

    const { price } = order
    if (isPercentage) {
      return (max * price) / 100
    } else {
      return max
    }
  }

  const getMaxPercentage = (commissions: CommissionEntity[] | undefined) =>
    _(commissions)
      .map(o => _.get(o, 'percentage'))
      .max() || 0

  const getWithdrawalSum = () => {
    const number = _.sumBy(withdrawals, value => _.get(value, 'amount')) || 0
    return Number(number.toFixed(2))
  }

  return (
    <Dialog open={open} maxWidth={'lg'} fullWidth={true} onClose={onClose}>
      <DialogTitle>Edit Withdrawal for Order {order?.id}</DialogTitle>
      <DialogContent>
        <div>
          {order?.productCategory} <br />
          {order?.productPackage} <br />
          Package Price: {getDisplayPrice()} <br />
          Max Commission Rate is: {getMaxPercentage(commissions)}
        </div>

        <div>{}</div>

        <br />

        <div className='divide-y'>
          {withdrawals &&
            withdrawals?.map(value => {
              return (
                <EditWithdrawalItemComponent
                  withdrawal={value}
                  price={order?.price || 0}
                  onChange={(
                    withdrawalId: number,
                    newObject: EditWithdrawalModel
                  ) => {
                    setWithdrawals(prevState => {
                      return prevState?.map(o => {
                        if (withdrawalId === o.id) {
                          return {
                            ...o,
                            commissionType: newObject.commissionType,
                            commissionRate: newObject.commissionRate,
                            amount:
                              newObject.commissionType ===
                              CommissionType.PERCENTAGE
                                ? ((order?.price || 0) *
                                    newObject.commissionRate) /
                                  100
                                : newObject.commissionRate,
                          }
                        } else return o
                      })
                    })
                  }}
                />
              )
            })}
        </div>

        <div>
          <div
            className={
              getWithdrawalSum() > getMaxPrice()
                ? 'text-red-500 font-bold text-lg'
                : ''
            }
          >
            Max Price for current order: RM {getMaxPrice()}
          </div>
          <div className='font-bold'>
            Total: {getWithdrawalSum().toFixed(2)}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>cancel</Button>
        <Button
          color={'primary'}
          variant={'contained'}
          onClick={handleUpdateClick}
          disabled={getWithdrawalSum() > getMaxPrice()}
        >
          update
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditWithdrawalDialog
