import client, { AxiosResponse } from 'axios'
import { SystemSettingBean } from './SystemSettingBean'

const url = `/api/v1/user-settings`
export default class UserSettingService {
  static getSetting(
    settingName: string
  ): Promise<AxiosResponse<SystemSettingBean>> {
    return client.get(url + '/' + settingName)
  }

  static updateSetting(settingName: string, body: Object) {
    return client.patch(url + '/' + settingName, {
      value: JSON.stringify(body),
    })
  }
}
