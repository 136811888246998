import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from '@material-ui/core'
import { TabContext, TabPanel } from '@material-ui/lab'
import EmptyEformComponent from './emptyEformComponent'
import DuplicatedEformComponent from './duplicatedEformComponent'
import OrderService from '../../../agentPortal/orderManagement/orderService'
import { OrderBean } from '../../../sharePortal/orderManagement/bean/orderBean'
import EmptyEformComponentGroupByAgent from './emptyEformComponentGroupByAgent'

const EformTabComponent = () => {
  const emptyEform = 'Empty E-Form'
  const groupByAgent = 'Group By Agent'
  const duplicated = 'Duplicated'

  const [tabIndex, setTabIndex] = useState(emptyEform)

  const [orders, setOrders] = useState<OrderBean[]>([])
  const [ordersDuplicatedByEform, setOrdersDuplicatedByEform] = useState<
    OrderBean[]
  >([])
  useEffect(() => {
    OrderService.findAll('eForm:').then(resp => setOrders(resp.data))
  }, [])

  useEffect(() => {
    OrderService.findAllDuplicatedByEForm().then(resp =>
      setOrdersDuplicatedByEform(resp.data)
    )
  }, [])

  return (
    <TabContext value={tabIndex}>
      <Tabs
        value={tabIndex}
        onChange={(event, value) => {
          setTabIndex(value)
        }}
      >
        <Tab value={emptyEform} label={emptyEform} />
        <Tab value={groupByAgent} label={groupByAgent} />
        <Tab value={duplicated} label={duplicated} />
      </Tabs>
      <TabPanel value={emptyEform}>
        <EmptyEformComponent orders={orders} />
      </TabPanel>
      <TabPanel value={groupByAgent}>
        <EmptyEformComponentGroupByAgent orders={orders} />
      </TabPanel>

      <TabPanel value={duplicated}>
        <DuplicatedEformComponent orders={ordersDuplicatedByEform} />
      </TabPanel>
    </TabContext>
  )
}

export default EformTabComponent
