import React, { useEffect, useState } from 'react'
import { Button, Card, CardContent, CardHeader } from '@material-ui/core'
import { OrderBean } from '../../../sharePortal/orderManagement/bean/orderBean'
import { useHistory } from 'react-router-dom'

const DuplicatedEformComponent = (props: { orders: OrderBean[] }) => {
  const history = useHistory()
  const [eFormDuplicatedOrders, setEFormDuplicatedOrders] = useState<
    Record<string, OrderBean[]>
  >({})

  useEffect(() => {
    const newValue = props.orders.reduce<Record<string, OrderBean[]>>(
      (previousValue, currentValue: OrderBean) => {
        const eform = currentValue?.eform?.toString() || 'EMPTY'
        const newArr = [...(previousValue[eform] || []), currentValue]

        const newObj = { ...previousValue }
        newObj[eform] = newArr

        return newObj
      },
      {}
    )

    setEFormDuplicatedOrders(newValue)
  }, [props.orders])

  return (
    <Card>
      <CardHeader title={'Duplicated E-Form'} />
      <CardContent>
        {!Object.keys(eFormDuplicatedOrders).length && <div>EMPTY</div>}
        {Object.entries(eFormDuplicatedOrders).map(([eform, _orders]) => {
          const list = _orders

          return (
            <div key={eform}>
              <div className='grid grid-cols-4 p-4 rounded hover:bg-gray-200'>
                <div className='col-span-1'>
                  <span className='text-gray-500'>E-Form: </span>
                  <span className='text-gray-700'>{eform}</span>
                </div>
                <div className='col-span-3'>
                  <span className='text-gray-500'>Order ID: </span>
                  <span className='text-gray-700'>
                    {list.map(value => (
                      <Button
                        key={value.id}
                        variant={'outlined'}
                        onClick={() =>
                          history.push(`/admin-portal/order-detail/${value.id}`)
                        }
                      >
                        {value.id.toString()}
                      </Button>
                    ))}
                  </span>
                </div>
              </div>
            </div>
          )
        })}
      </CardContent>
    </Card>
  )
}

export default DuplicatedEformComponent
