import OrderStatus from './orderStatus'

const OrderBgColorByStatus = {
  [OrderStatus.EARLY_STAGE]: 'bg-blue-400',
  [OrderStatus.POST_COMPLETE]: 'bg-green-400',

  [OrderStatus.PORT_FULL]: 'bg-gray-400',
  [OrderStatus.UNIT_MISSING]: 'bg-gray-400',
  [OrderStatus.AGENT_SUBMITTED]: 'bg-gray-400',
  [OrderStatus.PENDING]: 'bg-gray-400',
  [OrderStatus.WAITING_LIST]: 'bg-gray-400',
  [OrderStatus.KIV]: 'bg-gray-400',
  [OrderStatus.NEW_AREA]: 'bg-gray-400',
  [OrderStatus.PENDING_PCMS]: 'bg-gray-400',

  [OrderStatus.EXPIRED]: 'bg-red-400',
  [OrderStatus.REJECTED]: 'bg-red-400',
  [OrderStatus.CANCEL]: 'bg-red-400',
}
export default OrderBgColorByStatus
