import client, { AxiosResponse } from 'axios'
import { OrderBean } from '../../sharePortal/orderManagement/bean/orderBean'
import { OrderPatchBody } from './bean/orderPatchBody'
import { OrderPostBody2 } from './bean/orderPostBody2'
import { WithdrawalBean } from '../../features/claim/bean/WithdrawalBean'
import { ClaimBean } from '../../features/claim/bean/claimBean'
import { filterOrderStatus } from '../../sharePortal/orderManagement/bean/order.constant'
import OrderStatus from '../../common/constant/orderStatus'

const url = `/api/v1/orders`
class OrderService {
  static getOrderById(id: number): Promise<AxiosResponse<OrderBean>> {
    return client.get(`${url}/${id}`)
  }

  static getWithdrawalsByOrderId(
    id: number
  ): Promise<AxiosResponse<WithdrawalBean[]>> {
    return client.get(`${url}/${id}/withdrawals`)
  }
  static getClaimsByOrderId(id: number): Promise<AxiosResponse<ClaimBean[]>> {
    return client.get(`${url}/${id}/claims`)
  }

  static postOrderV2(body: OrderPostBody2): Promise<AxiosResponse<OrderBean>> {
    return client.post(`${url}`, body)
  }

  static completeOrder(id: number): Promise<AxiosResponse<OrderBean>> {
    return client.post(`${url}/${id}/completed`, {})
  }

  static completeDocument(id: number): Promise<AxiosResponse> {
    return client.post(`${url}/${id}/complete-document`, {})
  }
  static completePaymentClaim(id: number): Promise<AxiosResponse> {
    return client.post(`${url}/${id}/complete-payment-claim`, {})
  }
  static addSubmittedDate(id: number): Promise<AxiosResponse> {
    return client.post(`${url}/${id}/add-submitted-date`, {})
  }
  static patchOrder(
    id: number,
    order: OrderPatchBody
  ): Promise<AxiosResponse<OrderBean>> {
    return client.patch(`${url}/${id}`, {
      ...order,
    })
  }

  static findAll(
    search?: string,
    orderStatus?: string
  ): Promise<AxiosResponse<OrderBean[]>> {
    let os = undefined
    if (orderStatus) {
      switch (orderStatus) {
        case filterOrderStatus.IN_PROGRESS:
          os = OrderStatus.inProgress.join(',')
          break
        case filterOrderStatus.COMPLETED:
          os = OrderStatus.completed.join(',')
          break
        default:
          os = undefined
          break
      }
    }

    return client.get(url, {
      params: {
        search: search,
        orderStatus: os,
      },
    })
  }

  static findAllDuplicatedByOrderNumber(): Promise<AxiosResponse<OrderBean[]>> {
    return client.get(url + '/duplicatedByOrderNumber', {})
  }

  static findAllDuplicatedByEForm(): Promise<AxiosResponse<OrderBean[]>> {
    return client.get(url + '/duplicatedByEForm', {})
  }

  static deleteOrderById(orderId: number): Promise<AxiosResponse> {
    return client.delete(`${url}/${orderId}`)
  }

  static clawbackOrderById(
    orderId: number,
    remark: string,
    remarkDate: Date
  ): Promise<AxiosResponse> {
    return client.post(`${url}/clawback`, { orderId, remark, remarkDate })
  }

  static patchOrderPackage(orderId: number, newPackageId: number) {
    return client.patch(`${url}/${orderId}/package`, {
      packageId: newPackageId,
    })
  }

  static undoClawbackOrder(orderId: number) {
    return client.delete(`/api/v1/orders/clawback/${orderId}`)
  }

  static resetOrderStatus(orderId: number) {
    return client.post(`/api/v1/orders/${orderId}/reset-status`)
  }
}

export default OrderService
