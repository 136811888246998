import React, { useState } from 'react'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import UserBean from '../../../sharePortal/userManagement/bean/userBean'
import { AlertDialogAction } from '../../../redux/reducer/alertDialogReducer'
import { SnackbarAction } from '../../../redux/reducer/snackbarReducer'
import { catchErrorWithDispatch } from '../../../common/ApiUtils'
import { useDispatch } from 'react-redux'
import { UserActions } from '../../../redux/reducer/userReducer'
import { AgentStaffService } from '../service/agentStaffService'

interface Props {
  user: UserBean
}

const AgentStaffActionsMenuComponent = ({ user }: Props) => {
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleClick = (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  function handleDisableUser() {
    dispatch(
      AlertDialogAction.open(
        `Disable User`,
        `Are you sure to disable ${user?.fullName}`,
        () => {
          if (user?.id) {
            AgentStaffService.disableAgentStaff(user?.id)
              .then(() => {
                dispatch(SnackbarAction.open('Success Enable User'))
                dispatch(UserActions.getAgentStaffs())
                handleClose()
              })
              .catch(catchErrorWithDispatch(dispatch))
          }
        }
      )
    )
  }

  function handleEnableUser() {
    dispatch(
      AlertDialogAction.open(
        `Enable User`,
        `Are you sure to enable ${user?.fullName}`,
        () => {
          if (user?.id) {
            AgentStaffService.verifyAgentStaff(user?.id)
              .then(() => {
                dispatch(SnackbarAction.open('Success Enable User'))
                dispatch(UserActions.getAgentStaffs())
                handleClose()
              })
              .catch(catchErrorWithDispatch(dispatch))
          }
        }
      )
    )
  }

  return (
    <>
      <IconButton
        aria-controls='admin-actions-menu'
        aria-haspopup='true'
        onClick={handleClick}
        color={'inherit'}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id='admin-actions-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {user?.enabled ? (
          <MenuItem onClick={handleDisableUser}>Disable User</MenuItem>
        ) : (
          <MenuItem onClick={handleEnableUser}>Enable User</MenuItem>
        )}
      </Menu>
    </>
  )
}

export default AgentStaffActionsMenuComponent
