import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import {
  AppBar,
  Button,
  Card,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core'
import AgentService from '../../adminPortal/userManagement/service/agentService'
import UserBean from '../../sharePortal/userManagement/bean/userBean'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useDispatch } from 'react-redux'
import SubmitClaimTableComponent from '../../common/withdrawal/SubmitClaimTableComponent'
import {
  ClaimReducerActions,
  ClaimReducerProps,
} from '../../redux/reducer/claimReducer'
import { useTypedSelector } from '../../redux/reducer'
import SubmitClaimTotalPriceComponent from '../../common/withdrawal/SubmitClaimTotalPriceComponent'
import _ from 'lodash'

const AdminSubmitClaimPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { agentId } = useParams()
  const [agent, setAgent] = useState<UserBean>()

  const { withdrawals, selectedIds }: ClaimReducerProps = useTypedSelector(
    state => state.claim
  )

  const handleSubmitClaim = () => {
    dispatch(ClaimReducerActions.submitClaim(selectedIds, agentId))
  }

  function resetSelectedWithdrawals() {
    dispatch(ClaimReducerActions.reset())
  }

  const handleClick = (id: number) => {
    dispatch(ClaimReducerActions.withdrawalClick(id))
  }

  const handleCheckAllClick = (ids: number[], checked: boolean) => {
    dispatch(ClaimReducerActions.checkAllClick(ids, checked))
  }

  useEffect(() => {
    AgentService.findById(agentId).then(value => setAgent(value.data))
  }, [agentId])

  useEffect(() => {
    dispatch(ClaimReducerActions.fetchWithdrawalsById(agentId))
  }, [agentId, dispatch])

  return (
    <div>
      <AppBar position='relative'>
        <Toolbar>
          <IconButton color='inherit' onClick={history.goBack}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant='h6'>Submit Claims</Typography>
        </Toolbar>
      </AppBar>

      <div className='mx-8 my-4 text-center'>
        <Card className='p-4'>
          Submit Claim on behalf of{' '}
          <span className='font-bold'>
            {agent?.username} ({agent?.fullName})
          </span>
        </Card>
      </div>

      <div>
        <SubmitClaimTotalPriceComponent
          withdrawals={withdrawals}
          selectedWithdrawals={selectedIds}
        />
      </div>

      <div className='m-4'>
        <SubmitClaimTableComponent
          agentId={agentId}
          onClaimClick={handleClick}
          onCheckAllClick={handleCheckAllClick}
          selectedWithdrawals={selectedIds}
          withdrawals={withdrawals}
        />
      </div>

      <div className='m-4 text-right space-x-3'>
        <Button
          color='secondary'
          variant='outlined'
          onClick={resetSelectedWithdrawals}
        >
          Reset
        </Button>
        <Button
          color='primary'
          variant='contained'
          disabled={_.isEmpty(selectedIds)}
          onClick={handleSubmitClaim}
        >
          Submit
        </Button>
      </div>
    </div>
  )
}

export default AdminSubmitClaimPage
