import React, { useEffect } from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import {
  claimSettingsSlice,
  getClaimSettings,
} from '../../../../redux/slice/claimSettings.slice'
import ClaimStatus from '../../../../common/constant/claimStatus'
import { useTypedSelector } from '../../../../redux/reducer'

const ClaimSentEmailToAgentWhenComponent = () => {
  const dispatch = useDispatch()
  const supportedActions = [
    ClaimStatus.COMPLETED,
    ClaimStatus.PROCESSING,
    ClaimStatus.PAID,
    ClaimStatus.REJECTED,
  ]

  const sentEmailToAgentWhenStatus = useTypedSelector(
    state => state.claimSettings.sentEmailToAgentWhenStatus
  )

  useEffect(() => {
    dispatch(getClaimSettings())
  }, [dispatch])
  return (
    <Card>
      <CardHeader title={'Sent Email To Agent When'} />
      <CardContent>
        {supportedActions.map(value => {
          const checked = sentEmailToAgentWhenStatus.includes(value)
          return (
            <div key={value}>
              <FormControlLabel
                label={value}
                control={
                  <Checkbox
                    name={value}
                    color='primary'
                    checked={checked}
                    onClick={() => {
                      dispatch(
                        claimSettingsSlice.actions.sentEmailToAgentWhenStatusChange(
                          value
                        )
                      )
                    }}
                  />
                }
              />
            </div>
          )
        })}
      </CardContent>
    </Card>
  )
}

export default ClaimSentEmailToAgentWhenComponent
