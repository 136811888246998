import React, { useEffect, useMemo, useState } from 'react'
import AgentService from './service/agentService'
import UserBean from '../../sharePortal/userManagement/bean/userBean'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core'
import ProductService from '../productManagement/productService'
import { Product } from '../productManagement/bean/product'
import { CommissionQuota } from './bean/commissionQuota'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import CommissionService from './service/commissionService'
import { SnackbarAction } from '../../redux/reducer/snackbarReducer'
import { useDispatch } from 'react-redux'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ErrorIcon from '@material-ui/icons/Error'
import CommissionType from '../../common/constant/commissionType'
import AgentPrincipleComponent from '../../component/agent/AgentPrincipleComponent'
import { catchErrorWithDispatch } from '../../common/ApiUtils'
import SelectAgentTextComponent from '../../agentPortal/orderManagement/component/selectAgentText.component'

const CommissionManagementPage = () => {
  const [agents, setAgents] = useState<UserBean[]>([])
  const [products, setProducts] = useState<Product[]>([])
  const [agentId, setAgentId] = useState<number>(0)
  const [productId, setProductId] = useState<number>(0)
  const [shouldRefresh, setShouldRefresh] = useState(false)
  const [selectedGroupCommission, setSelectedGroupCommission] = useState<{
    commissions: CommissionQuota[]
    agentId: number
    categoryName: string
  }>()

  const [groupedCommissions, setGroupedCommissions] = useState<
    Record<string, CommissionQuota[]>
  >({})
  const [selectedCommission, setSelectedCommission] = useState<{
    commission: CommissionQuota
    agentId: number
  }>()

  const selectedAgent = useMemo(() => {
    return agents.find(agent => agent.id === agentId)
  }, [agents, agentId])

  const principle = useMemo(() => {
    return agents.find(agent => agent.id === selectedAgent?.superiorId)
  }, [agents, selectedAgent])

  useEffect(() => {
    AgentService.getAllAgents({
      excludeSelf: true,
      excludeDisable: true,
    }).then(value => setAgents(value.data))
    ProductService.getProducts().then(value => setProducts(value.data))
  }, [])

  useEffect(() => {
    if (agentId && productId) {
      AgentService.getAgentCommission(agentId, productId).then(value => {
        const newGroupedCommission = value.data.reduce<
          Record<string, CommissionQuota[]>
        >((all, cur) => {
          const allElement = all[cur.categoryName]
          if (allElement) {
            return {
              ...all,
              [cur.categoryName]: [...allElement, cur],
            }
          } else {
            return {
              ...all,
              [cur.categoryName]: [cur],
            }
          }
        }, {})

        setGroupedCommissions(
          Object.fromEntries(
            Object.entries(newGroupedCommission).map(([key, coms]) => {
              return [
                key,
                coms.sort((a, b) => a.packageName.localeCompare(b.packageName)),
              ]
            })
          )
        )
      })
    }
  }, [agentId, productId, shouldRefresh])

  return (
    <>
      <AppBar position='relative'>
        <Toolbar className='space-x-3'>
          <Typography variant='h6' noWrap>
            Edit Agent's Commission
          </Typography>
        </Toolbar>
      </AppBar>

      {selectedAgent && principle && (
        <div className='mx-8 my-4 '>
          <AgentPrincipleComponent
            selectedAgent={selectedAgent}
            principle={principle}
          />
        </div>
      )}

      <div className='m-8'>
        <div className='p-4 m-4 rounded shadow'>
          <SelectAgentTextComponent
            agentId={agentId.toString()}
            setAgentId={agentId1 => {
              if (agentId1) setAgentId(Number(agentId1))
            }}
            disabled={false}
            params={{ excludeDisable: true, excludeSelf: true }}
          />
        </div>

        <div className='p-4 m-4 rounded shadow'>
          <FormControl required fullWidth>
            <InputLabel id='productName'>Select Product</InputLabel>
            <Select
              id='productName'
              name='productName'
              label='productName'
              value={productId}
              onChange={event => setProductId(Number(event.target.value))}
            >
              <MenuItem value={0}>
                <em>None</em>
              </MenuItem>
              {products.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className=''>
          {!Object.keys(groupedCommissions)?.length && (
            <div className='m-auto text-center p-4'>
              Empty List, please select...
            </div>
          )}

          {Object.entries(groupedCommissions).map(([key, commissions]) => {
            const haveZeroPercent = commissions.some(
              value => value.percentage <= 0
            )
            const titleClass = haveZeroPercent ? 'text-red-600' : ''
            return (
              <Accordion key={key}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} id={key}>
                  <div className={'w-full flex'}>
                    <div
                      className={
                        'flex-1 space-x-3 flex items-center text-bold ' +
                        titleClass
                      }
                    >
                      {haveZeroPercent && (
                        <Tooltip
                          title={'Have Unset Commission, Please Complete It'}
                        >
                          <ErrorIcon />
                        </Tooltip>
                      )}

                      <span>{key}</span>
                    </div>

                    <Button
                      color={'primary'}
                      variant={'outlined'}
                      onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()

                        setSelectedGroupCommission({
                          categoryName: key,
                          commissions,
                          agentId: agentId,
                        })
                      }}
                    >
                      EDIT ALL
                    </Button>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className='w-full divide-y divide-solid'>
                    {commissions.map(commission => {
                      const isUnset = commission.percentage <= 0
                      const clsName = isUnset ? 'text-red-400' : ''

                      function displaytext(input: any): string {
                        return commission.commissionType ===
                          CommissionType.AMOUNT
                          ? `RM${input}`
                          : `${input}%`
                      }

                      return (
                        <div
                          key={
                            commission.id +
                            commission.packageName +
                            commission.categoryName +
                            commission.packageId +
                            commission.categoryId
                          }
                          className='p-2 hover:bg-gray-200 cursor-pointer'
                          onClick={() => {
                            setSelectedCommission({
                              commission: commission,
                              agentId: agentId,
                            })
                          }}
                        >
                          <div className={'text-md font-bold ' + clsName}>
                            {commission.packageName}
                          </div>
                          <div>
                            {`${displaytext(
                              commission.percentage
                            )} / ${displaytext(commission.availableQuota)}`}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </AccordionDetails>
              </Accordion>
            )
          })}
        </div>

        <EditCommissionDialog
          data={selectedCommission}
          onClose={() => setSelectedCommission(undefined)}
          onSuccess={() => {
            setShouldRefresh(!shouldRefresh)
          }}
        />
        <EditAllCommissionDialog
          data={selectedGroupCommission}
          onClose={() => {
            setSelectedGroupCommission(undefined)
          }}
          onSuccess={() => {
            setShouldRefresh(!shouldRefresh)
          }}
        />
      </div>
    </>
  )
}

export default CommissionManagementPage

const EditCommissionDialog = ({
  data,
  onClose,
  onSuccess,
}: {
  data?: {
    commission?: CommissionQuota
    agentId: number
  }
  onClose: () => void
  onSuccess: () => void
}) => {
  const commission = data?.commission
  const [newValue, setNewValue] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    setNewValue(commission?.percentage || 0)
  }, [commission])

  function displaytext(input: any): string {
    if (data && data.commission) {
      return data.commission.commissionType === CommissionType.AMOUNT
        ? `RM${input}`
        : `${input}%`
    } else return ''
  }

  function close() {
    setNewValue(0)
    onClose()
  }

  function onChange(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    const number = Number(event.target.value)

    if (Number.isNaN(number) || number < 0) {
      return
    } else if (number > commission!!.availableQuota) {
      setNewValue(commission!!.availableQuota)
    } else {
      setNewValue(number)
    }
  }

  function onSubmit() {
    if (commission?.id) {
      // Have id, update commission

      setIsLoading(true)
      CommissionService.patchCommission(commission.id, newValue)
        .then(() => {
          dispatch(
            SnackbarAction.open(
              `Success update commission for ${commission.packageName}.`
            )
          )
          onSuccess()
          close()
        })

        .catch(catchErrorWithDispatch(dispatch))
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      // Create New Commission
      setIsLoading(true)
      CommissionService.postCommission(
        data!!.agentId,
        data!!.commission!!.packageId,
        newValue
      )
        .then(() => {
          dispatch(
            SnackbarAction.open(
              `Success Create commission for ${commission?.packageName}.`
            )
          )

          onSuccess()
          close()
        })

        .catch(catchErrorWithDispatch(dispatch))
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  return (
    <Dialog
      open={!!commission}
      onClose={close}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>
        {commission?.packageName}
      </DialogTitle>
      <DialogContent>
        <div>Maximum rate is:</div>
        <div>{displaytext(commission?.availableQuota)}</div>

        <div className='my-2' />
        <TextField
          id='commission'
          name='Commission'
          label='New Commission'
          helperText={`current rate is ${displaytext(commission?.percentage)}`}
          value={newValue}
          onChange={event => onChange(event)}
        />
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <div>
            <CircularProgress color='inherit' />
          </div>
        ) : (
          <>
            <Button onClick={close}>Cancel</Button>
            <Button
              disabled={newValue === commission?.percentage}
              color='primary'
              variant='contained'
              onClick={onSubmit}
            >
              OK
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}

const EditAllCommissionDialog = ({
  data,
  onClose,
  onSuccess,
}: {
  data?: {
    categoryName: string
    commissions: CommissionQuota[]
    agentId: number
  }
  onClose: () => void
  onSuccess: () => void
}) => {
  const commission = data?.commissions
  const [newValue, setNewValue] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [maxQuota, setMaxQuota] = useState(0)
  const dispatch = useDispatch()

  useEffect(() => {
    if (data && data.commissions) {
      const maxAmount = getMaxAmount(data.commissions)
      setMaxQuota(maxAmount)
    }
  }, [data])

  function getMaxAmount(commissions: CommissionQuota[]): number {
    return commissions
      .map(value => value.availableQuota)
      .reduce((pre, cur) => (cur > pre ? cur : pre), 0)
  }

  function getSelfOrMax(self: number, max: number): number {
    return self > max ? max : self
  }

  function displaytext(com: CommissionQuota) {
    return function (input: any): string {
      if (com) {
        return com.commissionType === CommissionType.AMOUNT
          ? `RM${input}`
          : `${input}%`
      } else return ''
    }
  }

  function close() {
    setNewValue(0)
    onClose()
  }

  function onChange(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    const number = Number(event.target.value)

    if (Number.isNaN(number) || number < 0) {
      return
    } else if (number > maxQuota) {
      setNewValue(maxQuota)
    } else {
      setNewValue(number)
    }
  }

  function onSubmit() {
    if (newValue >= 0) {
      // setIsLoading(true)
      const promises = data?.commissions.map(v => {
        const newPercentage = getSelfOrMax(newValue, v.availableQuota)

        if (v.id) {
          return CommissionService.patchCommission(v.id, newPercentage)
        } else {
          return CommissionService.postCommission(
            data!!.agentId,
            v.packageId,
            newPercentage
          )
        }
      })

      Promise.all(Array.of(promises))
        .then(() => {
          dispatch(
            SnackbarAction.open(
              `Success update commission for ${data?.categoryName}.`
            )
          )
          onSuccess()
          close()
        })
        .catch(catchErrorWithDispatch(dispatch))
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  return (
    <Dialog
      maxWidth={'sm'}
      fullWidth
      open={!!commission}
      onClose={close}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{data?.categoryName}</DialogTitle>
      <DialogContent>
        {/*<div>Maximum rate is:</div>*/}
        {/*<div>{displaytext(commission?.availableQuota)}</div>*/}

        <TextField
          id='commission'
          name='Commission'
          label='New Commission'
          // helperText={`current rate is ${displaytext(commission?.percentage)}`}
          value={newValue}
          onChange={event => onChange(event)}
          fullWidth
        />

        <div className='divide-y divide-solid'>
          {data?.commissions.map(v => {
            const isEqualMaxQuota =
              getSelfOrMax(newValue, v.availableQuota) === v.availableQuota

            const clsName = isEqualMaxQuota ? 'font-bold' : ''

            return (
              <div className='w-full py-2'>
                <div className='font-bold text-lg'>{v.packageName}</div>
                <div className={clsName}>
                  {displaytext(v)(getSelfOrMax(newValue, v.availableQuota))} /{' '}
                  {displaytext(v)(v.availableQuota)}
                </div>
              </div>
            )
          })}
        </div>
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <div>
            <CircularProgress color='inherit' />
          </div>
        ) : (
          <>
            <Button onClick={close}>Cancel</Button>
            <Button
              disabled={newValue < 0}
              color='primary'
              variant='contained'
              onClick={onSubmit}
            >
              OK
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}
