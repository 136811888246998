export const PAGE = {
  AdminDashboard: 'AdminDashboard',
  ProductPage: 'ProductPage',
  ProductCategoryPage: 'ProductCategoryPage',
  ProductPackagePage: 'ProductPackagePage',
  AuditPage: 'AuditPage',
  AdminManagementPage: 'AdminManagementPage',
  AgentManagementPage: 'AgentManagementPage',
  AgentVerifyManagementPage: 'AgentVerifyManagementPage',
  CommissionManagementPage: 'CommissionManagementPage',
  OrderManagementPage2: 'OrderManagementPage2',
  OrderDetailPage: 'OrderDetailPage',
  ClaimPage: 'ClaimPage',
  ClaimDetailPage: 'ClaimDetailPage',
  CreateWithdrawalPage: 'CreateWithdrawalPage',
  NewOrderPage: 'NewOrderPage',
  CompletedOrderPage: 'CompletedOrderPage',
  SubmitClaimPage: 'SubmitClaimPage',
  DebitNotePage: 'DebitNotePage',
  EnquiryPage: 'EnquiryPage',
  AgentCommissionsPage: 'AgentCommissionsPage',
  InvitationPage: 'InvitationPage',
  NewAdminPage: 'NewAdminPage',
  UserProfileDetailPage: 'UserProfileDetailPage',
  AgentDetailPage: 'AgentDetailPage',
  LandingPageAttachments: 'LandingPageAttachments',
  AllWithdrawalPage: 'AllWithdrawalPage',
  AdminSubmitClaim: 'AdminSubmitClaim',
  AnnouncementManagementPage: 'AnnouncementManagementPage',
  SettingPage: 'SettingPage',
  SearchClaimPage: 'SearchClaimPage',
  WithdrawalPage: 'WithdrawalPage',
  TemplatePage: 'TemplatePage',
  TemplateDetail: 'TemplateDetail',
  TemplateCreatePage: 'TemplateCreatePage',
  AgentStaffPage: 'AgentStaffPage',
  UserSettingPage: 'UserSettingPage',
}

export const ROUTES = {
  agentStaffListing: () => 'agent-staffs',
  agentStaffCreate: () => 'agent-staffs/create',
  userSetting: () => 'user-settings',
}
