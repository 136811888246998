import client, { AxiosResponse } from 'axios'
import { ClaimBean } from '../bean/claimBean'
import { ClaimPatchBody } from '../bean/claimPatchBody'
import { Pageable } from '../../../common/bean/Pageable'
import ClaimStatus from '../../../common/constant/claimStatus'
import qs from 'qs'
import _ from 'lodash'

const url = `/api/v1/claims`

export type ClaimSearchParams = {
  isPage: boolean
  sort?: string
  page?: number
  size?: number
  agentId?: string
  referenceNo?: string
  claimStatus?: ClaimStatus[]
  createdDateFrom?: Date | null
  createdDateTo?: Date | null
  completedDateFrom?: Date | null
  completedDateTo?: Date | null
}

class ClaimService {
  static createNewClaim(
    withdrawalIds: number[],
    agentId: number
  ): Promise<AxiosResponse<ClaimBean>> {
    return client.post(url, {
      agentId: agentId,
      withdrawalIds: withdrawalIds,
    })
  }

  static patchClaim(id: number, body: ClaimPatchBody): Promise<AxiosResponse> {
    return client.patch(`${url}/${id}`, { ...body })
  }

  static updateStatus(id: number, status: string): Promise<AxiosResponse> {
    return client.patch(`${url}/${id}`, { status })
  }

  static getAll(
    search: ClaimSearchParams
  ): Promise<AxiosResponse<Pageable<ClaimBean>>> {
    const searchParam = {
      ...search,

      createdDate: [
        search.createdDateFrom?.toISOString() || '',
        search.createdDateTo?.toISOString() || '',
      ].join(','),
      completedDate: [
        search.completedDateFrom?.toISOString() || '',
        search.completedDateTo?.toISOString() || '',
      ].join(','),
    }

    return client.get(url, {
      params: _.omit(searchParam, [
        'createdDateFrom',
        'createdDateTo',
        'completedDateFrom',
        'completedDateTo',
      ]),
      paramsSerializer: param => qs.stringify(param, { arrayFormat: 'repeat' }),
    })
  }

  static getAllEmptyReferenceNo(): Promise<AxiosResponse<ClaimBean[]>> {
    return client.get(url + '/emptyReferenceNo')
  }

  static findById(claimId: number): Promise<AxiosResponse<ClaimBean>> {
    return client.get(`${url}/${claimId}`)
  }

  static addAttachment(claimId: number, file: File): Promise<AxiosResponse> {
    const formData = new FormData()
    formData.append('file', file)

    return client.post(`${url}/${claimId}/attachment`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
  }

  static delete(claimId: number): Promise<AxiosResponse> {
    return client.delete(`${url}/${claimId}`)
  }

  static exportCsv(): Promise<AxiosResponse> {
    return client.get(`${url}/export`, {
      responseType: 'blob',
    })
  }
}

export default ClaimService
