import React, { useCallback, useEffect } from 'react'
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { useAppDispatch } from '../../redux/store'
import { TemplateActions } from '../../redux/slice/template.slice'
import { useTypedSelector } from '../../redux/reducer'
import { Edit } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import userProfileService from '../../common/service/userProfileService'

function TemplatePage() {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const templates = useTypedSelector(state => state.templates.templates)

  const fetchData = useCallback(() => {
    dispatch(TemplateActions.fetchTemplates())
  }, [dispatch])

  useEffect(fetchData, [])

  return (
    <div>
      <AppBar position='relative'>
        <Toolbar className='space-x-3'>
          <Typography variant='h6' noWrap>
            Templates
          </Typography>
        </Toolbar>
      </AppBar>

      <Box display={'flex'} justifyContent={'end'} p={2}>
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={() => {
            history.push({ pathname: 'templates/create' })
          }}
        >
          Create Template
        </Button>
      </Box>

      <div className='p-4'>
        <TableContainer component={Paper}>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell align='right'>Category</TableCell>
                <TableCell align='right'>Type</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {templates.map(o => {
                const onClick = () =>
                  history.push({ pathname: 'templates/' + o.id })

                return (
                  <TableRow key={o.id}>
                    <TableCell>{o.title}</TableCell>
                    <TableCell align='right'>{o.category}</TableCell>
                    <TableCell align='right'>{o.type}</TableCell>
                    <TableCell align='right'>
                      {o.type === 'PUBLIC' &&
                      userProfileService.isAgent() ? null : (
                        <IconButton size={'small'} onClick={onClick}>
                          <Edit />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}

export default TemplatePage
