import React, { useState } from 'react'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { ProductPackage } from './bean/productPackage'
import { AlertDialogAction } from '../../redux/reducer/alertDialogReducer'
import { useDispatch } from 'react-redux'
import { ProductPackageAction } from '../../redux/reducer/productPackageReducer'

interface PackageItemActionsMenuProps {
  productPackage: ProductPackage
  updateClick: (value: ProductPackage) => void
  duplicateClick: (value: ProductPackage) => void
}

const PackageItemActionsMenu = (props: PackageItemActionsMenuProps) => {
  const { productPackage, updateClick, duplicateClick } = props
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleClick = (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOnDelete = (
    e: React.MouseEvent<HTMLLIElement> | React.MouseEvent<HTMLAnchorElement>
  ) => {
    e.preventDefault()
    e.stopPropagation()

    dispatch(
      AlertDialogAction.open(
        <>
          Delete <span className='font-bold'>{productPackage.name}</span>
        </>,
        <>
          Are you sure to delete{' '}
          <span className='font-bold'>{productPackage.name}</span>?
        </>,
        () => handlePackageDelete(productPackage)
      )
    )
  }

  const handlePackageDelete = (item: ProductPackage) => {
    dispatch(ProductPackageAction.deletePackage(item.id, item.categoryId))
  }

  return (
    <>
      <IconButton
        aria-controls='package-item-action-menu'
        aria-haspopup='true'
        onClick={handleClick}
        color={'inherit'}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id='package-item-action-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => updateClick(productPackage)}>
          Update Package
        </MenuItem>
        <MenuItem onClick={() => duplicateClick(productPackage)}>
          Duplicate Package
        </MenuItem>
        <MenuItem onClick={handleOnDelete}>Delete Package</MenuItem>
      </Menu>
    </>
  )
}

export default PackageItemActionsMenu
