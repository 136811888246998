import UserBean from '../../sharePortal/userManagement/bean/userBean'
import { ReduxReducerAction } from './index'
import { AdminService } from '../../adminPortal/userManagement/service/adminService'
import { AppThunk } from '../store'
import AgentService from '../../adminPortal/userManagement/service/agentService'
import { catchErrorWithDispatch } from '../../common/ApiUtils'
import { AgentStaffService } from '../../adminPortal/userManagement/service/agentStaffService'

export interface UserReducer {
  admins: UserBean[]
  agents: UserBean[]
  agentStaffs: UserBean[]
}

const initialState: UserReducer = {
  admins: [],
  agents: [],
  agentStaffs: [],
}
const actionType = {
  GET_ADMINS_SUCCESS: '[User] Get Admins Success',
  GET_AGENTS_SUCCESS: '[User] Get Agents Success',
  GET_AGENT_STAFFS_SUCCESS: '[User] Get Agent Staffs Success',
}

const userReducer = (state = initialState, action: ReduxReducerAction) => {
  switch (action.type) {
    case actionType.GET_ADMINS_SUCCESS:
      return {
        ...state,
        admins: action.payload,
      }

    case actionType.GET_AGENTS_SUCCESS:
      return {
        ...state,
        agents: action.payload,
      }

    case actionType.GET_AGENT_STAFFS_SUCCESS:
      return {
        ...state,
        agentStaffs: action.payload,
      }
    default:
      return state
  }
}

export const UserActions = {
  getAdmins(): AppThunk {
    return async dispatch => {
      AdminService.getAll()
        .then(response => {
          dispatch(this.getAdminsSuccess(response.data))
        })
        .catch(catchErrorWithDispatch(dispatch))
    }
  },
  getAdminsSuccess(payload: UserBean[]): ReduxReducerAction {
    return {
      type: actionType.GET_ADMINS_SUCCESS,
      payload: payload,
    }
  },
  getAgents(): AppThunk {
    return async dispatch => {
      AgentService.getAllAgents({
        excludeDisable: false,
        excludeSelf: false,
      })
        .then(response => {
          dispatch(this.getAgentsSuccess(response.data))
        })
        .catch(catchErrorWithDispatch(dispatch))
    }
  },
  getAgentsSuccess(payload: UserBean[]): ReduxReducerAction {
    return {
      type: actionType.GET_AGENTS_SUCCESS,
      payload: payload,
    }
  },

  getAgentStaffs(): AppThunk {
    return async dispatch => {
      AgentStaffService.getAll()
        .then(response => {
          dispatch(this.getAgentStaffsSuccess(response.data))
        })
        .catch(catchErrorWithDispatch(dispatch))
    }
  },
  getAgentStaffsSuccess(payload: UserBean[]): ReduxReducerAction {
    return {
      type: actionType.GET_AGENT_STAFFS_SUCCESS,
      payload: payload,
    }
  },
}
export default userReducer
