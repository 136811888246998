import React, { useEffect } from 'react'
import tokenService from './service/tokenService'
import { Redirect, useLocation } from 'react-router-dom'
import userProfileService from './service/userProfileService'
import { useAppDispatch } from '../redux/store'
import { userSettingsAction } from '../redux/slice/userSettingsSlice'

const SecurePageContainer: React.FC = props => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    console.log('Secure Page Container Effect')
    dispatch(userSettingsAction.getUserSettingOrderDisplay())
  }, [dispatch])

  return <>{props.children}</>
}
const SecurePage: React.FC = props => {
  const location = useLocation()

  const noToken = () => !tokenService.getAccessToken()
  const notAdmin = () =>
    location.pathname.startsWith('/admin-portal') &&
    !userProfileService.isManager() &&
    !userProfileService.isAdmin() &&
    !userProfileService.isStaff()
  const notAgent = () =>
    location.pathname.startsWith('/agent-portal') &&
    !userProfileService.isAgent()
  const notAgentStaff = () =>
    location.pathname.startsWith('/agent-staff-portal') &&
    !userProfileService.isAgentStaff()

  if (noToken() || notAdmin() || notAgent() || notAgentStaff()) {
    return <Redirect to={'/'} />
  }

  return <SecurePageContainer>{props.children}</SecurePageContainer>
}

export default SecurePage
