import client, { AxiosResponse } from 'axios'
import { WithdrawalBean } from '../bean/WithdrawalBean'
import { WithdrawalPostBody } from '../../withdrawal/bean/withdrawalPostBody'
import { WithdrawalPatchBody } from '../../withdrawal/bean/withdrawalPatchBody'

const url = `/api/v1/withdrawals`
class WithdrawalService {
  static findAll(search?: object): Promise<AxiosResponse<WithdrawalBean[]>> {
    return client.get(url, { params: search || {} })
  }

  static findAllUnclaimed(): Promise<AxiosResponse<WithdrawalBean[]>> {
    return client.get(url + '/unclaimed')
  }

  static findAllUnclaimedByAgentId(
    agentId: number
  ): Promise<AxiosResponse<WithdrawalBean[]>> {
    return client.get(url + '/agent/' + agentId)
  }

  static createWithdrawal(
    body: WithdrawalPostBody
  ): Promise<AxiosResponse<WithdrawalBean>> {
    return client.post(url, { ...body })
  }

  static patchWithdrawal(
    id: number,
    body: WithdrawalPatchBody
  ): Promise<AxiosResponse> {
    return client.patch(`${url}/${id}`, body)
  }

  static assignToClaim(
    claimId: number,
    withdrawalIds: number[]
  ): Promise<AxiosResponse> {
    return client.patch(`${url}/assignToClaim`, {
      claimId,
      withdrawalIds,
    })
  }

  static removeFromClaim(id: number): Promise<AxiosResponse> {
    return client.patch(`${url}/${id}/remove-claim`)
  }

  static deleteWithdrawal(id: number): Promise<AxiosResponse> {
    return client.delete(url + '/' + id)
  }
}

export default WithdrawalService
