import React from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { BaseInputFieldProps } from './index'
import { useTypedSelector } from '../../redux/reducer'
import { StateBean } from '../../common/service/StateBean'

interface StateSelectFieldProps extends BaseInputFieldProps {
  onChange: (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => void
}
const StateSelectField = (props: StateSelectFieldProps) => {
  const states: StateBean[] = useTypedSelector(state => state.common.states)

  const { value, disabled = false, label, name, onChange } = props
  return (
    <FormControl
      disabled={disabled}
      required={props.required || false}
      fullWidth
    >
      <InputLabel>{label}</InputLabel>
      <Select name={name} label={label} value={value || ''} onChange={onChange}>
        <MenuItem value='' disabled>
          <em>None</em>
        </MenuItem>
        {states.map(({ name }) => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default StateSelectField
