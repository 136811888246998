import React, { useEffect, useState } from 'react'
import { Button, Card, CardContent, CardHeader } from '@material-ui/core'
import { OrderBean } from '../../../sharePortal/orderManagement/bean/orderBean'
import { useHistory } from 'react-router-dom'

const DuplicatedOrderNoComponent = (props: { orders: OrderBean[] }) => {
  const history = useHistory()

  const [
    orderNumberDuplicatedOrders,
    setOrderNumberDuplicatedOrders,
  ] = useState<Record<string, OrderBean[]>>({})

  useEffect(() => {
    const newValue = props.orders.reduce<Record<string, OrderBean[]>>(
      (previousValue, currentValue: OrderBean) => {
        const orderNo = currentValue?.orderNo?.toString() || 'EMPTY'
        const newArr = [...(previousValue[orderNo] || []), currentValue]

        const newObj = { ...previousValue }
        newObj[orderNo] = newArr

        return newObj
      },
      {}
    )

    setOrderNumberDuplicatedOrders(newValue)
  }, [props.orders])

  return (
    <Card>
      <CardHeader title={'Duplicated Order No'} />
      <CardContent>
        {!Object.keys(orderNumberDuplicatedOrders).length && <div>EMPTY</div>}
        {Object.entries(orderNumberDuplicatedOrders).map(
          ([orderNumber, _orders]) => {
            const list = _orders

            return (
              <div key={orderNumber}>
                <div className='grid grid-cols-4 p-4 rounded hover:bg-gray-200'>
                  <div className='col-span-1'>
                    <span className='text-gray-500'>Order Number: </span>
                    <span className='text-gray-700'>{orderNumber}</span>
                  </div>
                  <div className='col-span-3'>
                    <span className='text-gray-500'>Order ID: </span>
                    <span className='text-gray-700'>
                      {list.map(value => (
                        <Button
                          key={value.id}
                          variant={'outlined'}
                          onClick={() =>
                            history.push(
                              `/admin-portal/order-detail/${value.id}`
                            )
                          }
                        >
                          {value.id.toString()}
                        </Button>
                      ))}
                    </span>
                  </div>
                </div>
              </div>
            )
          }
        )}
      </CardContent>
    </Card>
  )
}

export default DuplicatedOrderNoComponent
