import { GrantTokenResponse } from '../../preLogin/bean/GrantTokenResponse'
import { Permission } from '../constant/permission'

class TokenService {
  TOKEN_KEY = 'TOKEN_KEY'

  clearToken = () => {
    sessionStorage.removeItem(this.TOKEN_KEY)
  }
  setToken = (value: GrantTokenResponse) => {
    sessionStorage.setItem(this.TOKEN_KEY, JSON.stringify(value))
  }

  getAccessToken = (): string | undefined => {
    const item = sessionStorage.getItem(this.TOKEN_KEY)

    if (item) {
      const response = JSON.parse(item) as GrantTokenResponse
      return response.access_token
    }
  }
  getRefreshToken = (): string | undefined => {
    const item = sessionStorage.getItem(this.TOKEN_KEY)

    if (item) {
      const response = JSON.parse(item) as GrantTokenResponse
      return response.refresh_token
    }
  }

  getPermissions = (): string[] => {
    const token = this.getAccessToken()
    if (token) {
      const string = token.split('.')[1]
      const text = atob(string)
      const parse = JSON.parse(text)

      return parse.authorities
    }
    return []
  }

  havePermission = (permission: Permission) =>
    this.getPermissions().includes(permission)
}

const tokenService = new TokenService()
export default tokenService
