import React, { ChangeEvent, FC } from 'react'
import { Switch } from '@material-ui/core'

const SelectionSwitch: FC<{
  title: string
  leftDescription: string
  rightDescription: string
  checked: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void
}> = props => {
  const { title, leftDescription, rightDescription, checked, onChange } = props
  return (
    <>
      <div className='font-bold'>{title}</div>
      <div>
        {leftDescription}
        <Switch checked={checked} onChange={onChange} />
        {rightDescription}
      </div>
    </>
  )
}

export default SelectionSwitch
