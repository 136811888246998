import React, { useEffect, useState } from 'react'
import { ProductPackage } from './bean/productPackage'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'
import CommissionType from '../../common/constant/commissionType'

export interface SimpleDialogProps {
  open?: ProductPackage
  onClose: (productPackage?: ProductPackage) => void
}

const ProductPackageEditDialog = (props: SimpleDialogProps) => {
  const { onClose, open } = props
  const [pkg, setPkg] = useState<ProductPackage | undefined>(open)

  const handleClose = (productPackage?: ProductPackage) => {
    onClose(productPackage)
  }

  useEffect(() => {
    setPkg(open)
  }, [open])

  const onChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const name = event.target.name
    const value = event.target.value

    if (name === 'price' && isNaN(Number(value))) {
      return
    }

    const newPkg = {
      ...pkg,
      [name]: value,
    } as ProductPackage

    setPkg(newPkg)
  }
  return (
    <Dialog
      onClose={() => handleClose()}
      aria-labelledby='simple-dialog-title'
      open={!!open}
    >
      <DialogTitle id='simple-dialog-title'>Edit Package</DialogTitle>
      <DialogContent className='space-y-4'>
        <TextField
          label={'Name'}
          name='name'
          value={pkg?.name}
          onChange={onChange}
          fullWidth
        />
        <TextField
          label={'Description'}
          name='description'
          value={pkg?.description}
          onChange={onChange}
          fullWidth
          multiline
        />
        <TextField
          label={'Price'}
          name='price'
          value={pkg?.price}
          onChange={onChange}
          fullWidth
        />
        <FormControl component='fieldset' fullWidth>
          <FormLabel component='legend' id='commissionType'>
            Commission Type
          </FormLabel>

          <RadioGroup
            row
            aria-label='commissionType'
            name='commissionType'
            value={pkg?.commissionType}
            onChange={onChange}
          >
            <FormControlLabel
              value={CommissionType.PERCENTAGE}
              label={CommissionType.PERCENTAGE}
              control={<Radio />}
            />
            <FormControlLabel
              value={CommissionType.AMOUNT}
              label={CommissionType.AMOUNT}
              control={<Radio />}
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleClose()}
          variant={'outlined'}
          color={'secondary'}
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleClose(pkg)}
          variant={'contained'}
          color={'primary'}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ProductPackageEditDialog
