export default class TransactionType {
  static SELF = 'SELF'
  static AGENT = 'AGENT'
  static CLAWBACK = 'CLAWBACK'
  static CHARGES = 'CHARGES'
  static INCENTIVES = 'INCENTIVES'

  static toDisplays: Record<string, string> = {
    SELF: 'Self',
    AGENT: 'Agent',
    CLAWBACK: 'Clawback',
    CHARGES: 'Charges',
    INCENTIVES: 'Incentives',
  }
}
