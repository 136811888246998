import React from 'react'
import userProfileService from '../../common/service/userProfileService'

import { useHistory } from 'react-router'
import UserBean from '../../sharePortal/userManagement/bean/userBean'

const AgentPrincipleComponent = ({
  principle,
  selectedAgent,
}: {
  selectedAgent: UserBean
  principle: UserBean
}) => {
  const history = useHistory()

  const currentLoginUser = userProfileService.getUserProfile()

  const isYourSuperior = currentLoginUser?.superiorId === principle.id
  const principleIsYou = principle?.id === currentLoginUser?.id

  const currentLoginUserIsSelectedAgent =
    currentLoginUser?.id === selectedAgent.id

  const displayText =
    isYourSuperior && currentLoginUserIsSelectedAgent
      ? 'You Principle is'
      : 'Principle is'

  const displayPrincipleName = principleIsYou
    ? 'You'
    : `${principle?.username} (${principle?.fullName})`
  const cursor =
    isYourSuperior && currentLoginUserIsSelectedAgent ? '' : 'cursor-pointer'
  return (
    <div
      className={`text-center rounded shadow bg-white p-4 ${cursor}`}
      onClick={() => {
        if (currentLoginUserIsSelectedAgent && isYourSuperior) {
          return
        } else if (principleIsYou) {
          history.push('/agent-portal/profile-detail')
        } else if (userProfileService.isAgent()) {
          history.push('/agent-portal/agent-detail/' + principle.id)
        } else {
          history.push('/admin-portal/agent-detail/' + principle.id)
        }
      }}
    >
      {displayText} <span className='font-bold'>{displayPrincipleName}</span>
    </div>
  )
}

export default AgentPrincipleComponent
