export class CustomerPostBody {
  email: string
  fullName: string
  mobileNo: string
  nric: string
  telNo?: string

  constructor(
    nric: string,
    fullName: string,
    email: string,
    mobileNo: string,
    telNo?: string
  ) {
    this.email = email
    this.fullName = fullName
    this.mobileNo = mobileNo
    this.nric = nric
    this.telNo = telNo
  }
}
