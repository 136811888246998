import React, { useEffect, useState } from 'react'
import { AnnouncementService } from '../../../adminPortal/announcementManagement/announcementService'
import { AnnouncementBean } from '../../../adminPortal/announcementManagement/bean/AnnouncementBean'
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  CardHeader,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const ViewPublishedAnnouncementComponent = () => {
  const [announcements, setAnnouncements] = useState<AnnouncementBean[]>([])
  useEffect(() => {
    AnnouncementService.findAllPublished().then(resp =>
      setAnnouncements(resp.data.slice(0, 20))
    )
  }, [])
  return (
    <Card>
      <CardHeader title={'Announcement'} />
      <CardContent>
        {announcements.map(value => {
          return (
            <Accordion key={value.id} variant='outlined' defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className='font-bold text-lg'
              >
                {value.title}
              </AccordionSummary>
              <AccordionDetails>
                <div className='w-full'>
                  <div className='whitespace-pre-line my-2'>
                    {value.content}
                  </div>
                </div>
              </AccordionDetails>

              {!!value.attachmentUrls.length &&
                value.attachmentUrls
                  .filter(o => !o.endsWith('.pdf'))
                  .map((att, index) => {
                    return <img src={att} alt={`img ${index}`} key={att} />
                  })}

              {!!value.attachmentUrls.length && (
                <AccordionActions>
                  <div className='my-4'>
                    <hr />
                  </div>
                  <div className='space-x-3'>
                    {value.attachmentUrls
                      .filter(o => o.endsWith('.pdf'))
                      .map((att, index) => {
                        return (
                          <Button variant={'outlined'} key={att}>
                            <a
                              href={att}
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              PDF {index + 1}
                            </a>
                            r
                          </Button>
                        )
                      })}
                  </div>
                </AccordionActions>
              )}
            </Accordion>
          )
        })}
      </CardContent>
    </Card>
  )
}

export default ViewPublishedAnnouncementComponent
