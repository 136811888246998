import React from 'react'
import { FileUtilError } from './error/fileUtilError'

export class FileUtils {
  static handleFileChange(e: React.ChangeEvent<HTMLInputElement>): File[] {
    if (e.target.files) {
      const maxSize5Mb = 1024 * 5 * 1000

      const allowList = ['png', 'jpg', 'jpeg', 'pdf']

      const arr: File[] = []
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i]
        const strings = file.name.split('.').reverse()[0].toLowerCase()

        const isAllowed = allowList.some(value => {
          return strings === value
        })

        if (!isAllowed) {
          throw new FileUtilError(
            'Invalid File',
            `${file.name} is not Image/PDF format.`
          )
        } else if (file.size > maxSize5Mb) {
          throw new FileUtilError('File Too Big', `${file.name} is bigger than 5MB`)
        } else {
          arr.push(file)
        }
      }
      return arr
    }
    return []
  }
}
