import React, { useEffect, useRef, useState } from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from '@material-ui/core'
import { SnackbarAction } from '../../redux/reducer/snackbarReducer'
import { useDispatch } from 'react-redux'
import userProfileService from '../../common/service/userProfileService'

const InvitationComponent = () => {
  const [token, setToken] = useState<string>()

  const divRef = useRef<HTMLInputElement>(null)
  const dispatch = useDispatch()

  //localhost:3000/#/sign-up?token=a2V2aW4ubHU=
  function getInvitationLink() {
    const url: string = process.env.REACT_APP_PORTAL_URL?.toString() || ''

    return `${url}/#/sign-up?token=${token}`
  }

  useEffect(() => {
    const username = userProfileService.getUserProfile()?.username
    const t = btoa(username || '')
    setToken(t)
  }, [])

  return (
    <Card>
      <CardHeader title={'Your Invitation Link'} />
      <CardContent className='font-medium text-center'>
        <input
          ref={divRef}
          className='w-full'
          value={getInvitationLink() || ''}
          readOnly
        />
        <hr />
      </CardContent>
      <CardActions>
        <Button
          color={'primary'}
          variant={'contained'}
          size={'large'}
          fullWidth
          onClick={() => {
            if (divRef.current) {
              divRef.current.select()
              console.log(divRef.current?.value)
              document.execCommand('copy')
              dispatch(SnackbarAction.open('Success Copy'))
            }
          }}
        >
          COPY
        </Button>
      </CardActions>
    </Card>
  )
}

export default InvitationComponent
