import React, { useState } from 'react'
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Button,
  LinearProgress,
  Toolbar,
  Typography,
} from '@material-ui/core'
import WithdrawalTableComponent from './component/withdrawalTableComponent'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { BoxedSimpleStatus } from '../../common/bean/boxedSimpleStatus'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import BoxedSimpleStatusComponent from '../../common/component/boxedSimpleStatusComponent'
import { SimpleStatus } from '../../common/bean/simpleStatus'
import SimpleStatusComponent from '../../common/component/simpleStatusComponent'

import { useHistory } from 'react-router'
import { useAllWithdrawal } from './hook/useAllWithdrawal'
import CurrencyUtils from '../../common/currencyUtils'

const AllWithdrawalPage = () => {
  const history = useHistory()
  const [expandedList, setExpandedList] = useState<Set<string>>(new Set())

  let {
    getUnclaimedWithdrawal,
    unclaimedAmount,
    unclaimedWithdrawals,
    withdrawals,
    displayModel,
    deductAmount,
  } = useAllWithdrawal()

  function getSimpleStatus(): BoxedSimpleStatus[] {
    return [
      {
        value: CurrencyUtils.format(unclaimedAmount),
        title: 'RM',
        description: 'Total Unclaimed Amount',
        icon: (
          <div className='bg-green-400 p-4'>
            <AddIcon />
          </div>
        ),
      },
      {
        value: CurrencyUtils.format(deductAmount),
        title: 'RM',
        description: 'Total Deduct Claims',
        icon: (
          <div className='bg-orange-400 p-4'>
            <RemoveIcon />
          </div>
        ),
      },
    ]
  }

  function handleExpanded(value: string) {
    if (!expandedList.has(value)) {
      const set = new Set(expandedList)
      set.add(value)
      setExpandedList(set)
    }
  }

  return (
    <div>
      <AppBar position='relative'>
        <Toolbar>
          <Typography variant='h6'>All Unclaimed Withdrawal</Typography>
        </Toolbar>
      </AppBar>
      {!unclaimedWithdrawals && <LinearProgress />}
      <div className='grid grid-cols-4 gap-4 m-8'>
        {getSimpleStatus().map(status => {
          return (
            <BoxedSimpleStatusComponent
              status={status}
              key={status.description}
            />
          )
        })}
      </div>

      <div className='m-8'>
        {Object.entries(displayModel).map(([agentId, display]) => {
          const { personalDisplay, companyDisplay, agentCode } = display
          const value = withdrawals[agentId]

          function getPositive(): SimpleStatus {
            return {
              icon: <AddIcon />,
              desc: 'Unclaimed Amount',
              color: 'green',
              amount: value
                .filter(w => w.amount > 0)
                .map(w => w.amount)
                .reduce((pre, cur) => pre + cur, 0),
            }
          }

          function getNegative(): SimpleStatus {
            return {
              icon: <RemoveIcon />,
              desc: 'Clawback / Charge Amount',
              color: 'orange',
              amount: value
                .filter(w => w.amount < 0)
                .map(w => w.amount)
                .reduce((pre, cur) => pre + cur, 0),
            }
          }

          const isExpanded = expandedList.has(agentId)

          const toDisplay = [companyDisplay, personalDisplay].filter(o => !!o)

          return (
            <Accordion key={agentId} onChange={() => handleExpanded(agentId)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div className='flex flex-row w-full'>
                  <div className='flex-1'>
                    <div className='font-bold mb-2'>{agentCode}</div>
                    <div>{toDisplay[0]}</div>
                    {toDisplay[1] && (
                      <div className='font-light text-gray-400 text-xs'>
                        {toDisplay[1]}
                      </div>
                    )}
                  </div>

                  <div className='text-white flex flex-row'>
                    <SimpleStatusComponent status={getPositive()} />
                    <SimpleStatusComponent status={getNegative()} />
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {isExpanded && (
                  <WithdrawalTableComponent
                    withdrawals={value}
                    successEdit={getUnclaimedWithdrawal}
                    canEditClaims={true}
                  />
                )}
              </AccordionDetails>
              <AccordionActions>
                <Button
                  color={'primary'}
                  variant={'contained'}
                  onClick={() => {
                    history.push(`/admin-portal/admin-submit-claim/${agentId}`)
                  }}
                >
                  Submit Claims
                </Button>
              </AccordionActions>
            </Accordion>
          )
        })}
      </div>
    </div>
  )
}

export default AllWithdrawalPage
