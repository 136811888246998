import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosError } from 'axios'
import { ErrorResponse } from '../errorResponse'
import { SnackbarAction } from '../redux/reducer/snackbarReducer'

export function catchErrorWithDispatch(
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): (reason: AxiosError<ErrorResponse>) => void {
  return function (reason: AxiosError): void {
    const errorResponse =
      reason.response?.data.error_description ||
      reason.response?.data.message ||
      reason.message

    console.group('API ERROR')
    console.error('reason.toJSON()', reason?.toJSON && reason?.toJSON())
    console.error('ERROR: ', reason.response?.data)
    console.groupEnd()

    const fileReader = new FileReader()
    fileReader.onload = (ev: ProgressEvent<FileReader>) => {
      if (ev.target) {
        const { result } = ev.target

        if (typeof result === 'string') {
          const parse = JSON.parse(result) as ErrorResponse

          dispatch(SnackbarAction.openError(parse.message))
        } else {
          dispatch(SnackbarAction.openError(errorResponse))
        }
      }
    }

    if (reason.response && reason.response?.data instanceof Blob) {
      fileReader.readAsText(reason.response.data)
    } else {
      dispatch(SnackbarAction.openError(errorResponse))
    }
  }
}
