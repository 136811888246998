import React from 'react'
import { AppBar, Toolbar, Typography } from '@material-ui/core'
import AppBackButton from '../../common/component/appBackButton'

interface Props {
  title: string
  hasBackButton?: boolean
  actionsComponent?: React.ReactNode
}
const AppTitleBar = (props: Props) => {
  const { hasBackButton = false } = props
  return (
    <React.Fragment>
      <AppBar position='relative'>
        <Toolbar className='space-x-3'>
          {hasBackButton && <AppBackButton />}

          <Typography variant='h6' noWrap>
            {props.title}
          </Typography>

          {props.actionsComponent || null}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  )
}

export default AppTitleBar
