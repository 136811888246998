import React, { useEffect, useState } from 'react'
import {
  AppBar,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { useHistory } from 'react-router'
import { UserCodeBean } from '../../common/service/UserCodeBean'
import { useTypedSelector } from '../../redux/reducer'
import CommonUtils from '../../common/commonUtils'
import UserBean from '../../sharePortal/userManagement/bean/userBean'
import AgentService from './service/agentService'
import { formatDistanceToNow } from 'date-fns'

const AgentVerifyManagementPage = () => {
  const history = useHistory()
  const [agents, setAgents] = useState()

  const userCode: UserCodeBean = useTypedSelector(
    state => state.portalSetting.userCode
  )

  useEffect(() => {
    AgentService.getUnverifiedAgent().then(result => setAgents(result.data))
  }, [])

  return (
    <div>
      <AppBar position='relative'>
        <Toolbar className='space-x-3'>
          <Typography variant='h6'>Agent Verify Management</Typography>
        </Toolbar>
      </AppBar>

      <div className='m-8'>
        <TableContainer component={Paper}>
          <title>List of agents that are not verified</title>
          {!agents ? (
            'Error: No data found'
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  {[
                    'ID',
                    'Username',
                    'Full Name',
                    'Company',
                    'Mobile No',
                    'Email',
                    'Status',
                    'Created',
                  ].map(text => (
                    <TableCell key={text}>
                      <span className='font-bold text-gray-700'>{text}</span>
                    </TableCell>
                  ))}

                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {agents.map((agent: UserBean) => (
                  <TableRow
                    key={agent.id}
                    className='hover:bg-gray-200 cursor-pointer'
                    onClick={() => {
                      history.push('/admin-portal/agent-detail/' + agent.id)
                    }}
                  >
                    <TableCell>
                      {CommonUtils.getUserCode(agent, userCode)}
                    </TableCell>
                    <TableCell>{agent.username}</TableCell>
                    <TableCell>{agent.fullName}</TableCell>
                    <TableCell>{agent.companyName}</TableCell>
                    <TableCell>{agent.mobileNo}</TableCell>
                    <TableCell>{agent.email}</TableCell>
                    <TableCell>
                      <span className='p-1 rounded bg-red-300 text-white'>
                        UNVERIFIED
                      </span>
                    </TableCell>
                    <TableCell>
                      {formatDistanceToNow(new Date(agent.createdDate)) +
                        ' ago'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </div>
    </div>
  )
}
export default AgentVerifyManagementPage
