import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'
import qs from 'qs'
import { ClaimFilterProps } from '../bean/claimFilterProps'
import { useTypedSelector } from '../../../redux/reducer'
import { useHistory } from 'react-router'
import _ from 'lodash'

export function useClaimQs(): [
  ClaimFilterProps,
  (key: string, value: string) => void
] {
  const paginatorSetting: {
    rowPerPageOptions: number[]
    defaultRowPerPage: number
  } = useTypedSelector(state => state.portalSetting.paginator)
  const history = useHistory()

  const location = useLocation()

  const filter: ClaimFilterProps = useMemo(() => {
    const {
      claimStatusFilter = [],
      completeDateFrom = '',
      completeDateTo = '',
      submitDateFrom = '',
      submitDateTo = '',
      agentIdFilter = '',
      pageNumber = 0,
      pageSize = paginatorSetting.defaultRowPerPage,
    } = qs.parse(location.search, { ignoreQueryPrefix: true })

    return {
      claimStatusFilter: claimStatusFilter as string[],
      completeDateFrom: completeDateFrom
        ? new Date(String(completeDateFrom))
        : null,
      completeDateTo: completeDateTo ? new Date(String(completeDateTo)) : null,
      submitDateFrom: submitDateFrom ? new Date(String(submitDateFrom)) : null,
      submitDateTo: submitDateTo ? new Date(String(submitDateTo)) : null,
      agentIdFilter: agentIdFilter.toString(),

      page: Number(pageNumber),
      size: Number(pageSize),
    }
  }, [location.search, paginatorSetting.defaultRowPerPage])

  function setFilter(key: string, value: string) {
    const parsedQs: Record<any, any> = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    })

    const newQuery = (function () {
      if (value === '') {
        return _.omit(parsedQs, key)
      }

      if (key !== 'pageNumber') {
        return {
          ...parsedQs,
          [key]: value,
          pageNumber: 0,
        }
      }

      return _.set(parsedQs, key, value)
    })()

    history.replace({
      search: qs.stringify(newQuery, { addQueryPrefix: true }),
    })
  }

  return [filter, setFilter]
}
