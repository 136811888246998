import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../redux/reducer'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {
  AppBar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { ProductCategoryAction } from '../../redux/reducer/productCategoryReducer'
import { useHistory, useParams } from 'react-router-dom'
import ProductService from '../productManagement/productService'
import { Product } from '../productManagement/bean/product'
import { AlertDialogAction } from '../../redux/reducer/alertDialogReducer'

function ProductCategoryPage() {
  const dispatch = useDispatch()
  const history = useHistory()

  const { productId } = useParams()
  const [product, setProduct] = useState<Product>()
  const productCategoryState = useTypedSelector(state => state.productCategory)

  const toDisplayList = ['name', 'description', '']
  useEffect(() => {
    dispatch(ProductCategoryAction.getCategoryByProductId(productId))
  }, [dispatch, productId])

  useEffect(() => {
    ProductService.getById(productId).then(value => setProduct(value.data))
  }, [dispatch, productId])
  return (
    <div>
      <AppBar position='relative'>
        <Toolbar className='space-x-3'>
          <IconButton color='inherit' onClick={history.goBack}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant='h6'>
            Product Category {product && <span>for {product.name}</span>}
          </Typography>
        </Toolbar>
      </AppBar>

      <div className='m-8'>
        <AddProductCategoryComponent productId={productId} />
      </div>

      <div className='m-8'>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {toDisplayList.map(text => (
                  <TableCell key={text}>{text.toUpperCase()}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {productCategoryState.categories.map(item => {
                return (
                  <TableRow
                    key={item.id}
                    className='cursor-pointer hover:bg-gray-200 '
                    onClick={() => {
                      history.push('/admin-portal/product-package/' + item.id)
                    }}
                  >
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.description}</TableCell>
                    <TableCell align='right'>
                      <IconButton
                        size='small'
                        aria-label='delete'
                        onClick={e => {
                          e.stopPropagation()
                          e.preventDefault()

                          dispatch(
                            AlertDialogAction.open(
                              <>
                                Delete{' '}
                                <span className='font-bold'>{item.name}</span>
                              </>,
                              <>
                                Are you sure to delete{' '}
                                <span className='font-bold'>{item.name}</span>{' '}
                                and its <b>Packages</b>?
                              </>,
                              () => {
                                dispatch(
                                  ProductCategoryAction.deleteCategory(
                                    item.id,
                                    productId
                                  )
                                )
                              }
                            )
                          )
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}

ProductCategoryPage.propTypes = {}

export default ProductCategoryPage
const AddProductCategoryComponent = ({ productId }: { productId: number }) => {
  console.log('productId', productId)
  const dispatch = useDispatch()
  const [formState, setFormState] = useState({
    name: '',
    description: '',
  })

  const handleSubmit = () => {
    dispatch(
      ProductCategoryAction.postCategory({
        productId: productId,
        name: formState.name,
        description: formState.description,
      })
    )
    resetName()
  }

  const resetName = () =>
    setFormState({
      name: '',
      description: '',
    })

  const onTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    })
  }

  return (
    <Card>
      <CardHeader title='Add Product Category' />
      <CardContent>
        <TextField
          id='name'
          name='name'
          label='Name'
          margin='normal'
          InputLabelProps={{ shrink: true }}
          value={formState.name}
          onChange={onTextFieldChange}
          fullWidth
        />
        <span className='mx-4' />
        <TextField
          id='description'
          name='description'
          label='Description'
          margin='normal'
          InputLabelProps={{ shrink: true }}
          value={formState.description}
          onChange={onTextFieldChange}
          fullWidth
        />
      </CardContent>
      <CardActions className='justify-end'>
        <Button onClick={resetName}>Reset</Button>
        <Button color='primary' variant='contained' onClick={handleSubmit}>
          Submit
        </Button>
      </CardActions>
    </Card>
  )
}
