import React, { useMemo } from 'react'
import TransactionType from '../constant/transactionType'
import { WithdrawalBean } from '../../features/claim/bean/WithdrawalBean'
import { Divider } from '@material-ui/core'
import _ from 'lodash'

interface SubmitClaimTotalPriceComponentProps {
  withdrawals: WithdrawalBean[]
  selectedWithdrawals: number[]
}

const SubmitClaimTotalPriceComponent = (
  props: SubmitClaimTotalPriceComponentProps
) => {
  const { selectedWithdrawals, withdrawals } = props
  const selectedWithdrawalList = useMemo(() => {
    return _.filter(withdrawals, w => _.includes(selectedWithdrawals, w.id))
  }, [selectedWithdrawals, withdrawals])

  function getSelectedAmount(): number {
    return _.chain(selectedWithdrawalList)
      .filter(w =>
        _.includes(
          [TransactionType.AGENT, TransactionType.SELF],
          w.transactionType
        )
      )
      .sumBy('amount')
      .value()
  }

  function getSelectedIncentive(): number {
    return _.chain(selectedWithdrawalList)
      .filter(w => _.isEqual(TransactionType.INCENTIVES, w.transactionType))
      .sumBy('amount')
      .value()
  }

  function getDeductAmount(): number {
    return _.chain(withdrawals)
      .filter(w =>
        _.includes(
          [TransactionType.CLAWBACK, TransactionType.CHARGES],
          w.transactionType
        )
      )
      .sumBy('amount')
      .value()
  }
  function getClaimableAmount(): number {
    return _.chain(withdrawals)
      .filter(w =>
        _.includes(
          [
            TransactionType.AGENT,
            TransactionType.SELF,
            TransactionType.INCENTIVES,
          ],
          w.transactionType
        )
      )
      .sumBy('amount')
      .value()
  }

  function getSelectedCharges() {
    return _.chain(selectedWithdrawalList)
      .filter(w => _.isEqual(TransactionType.CHARGES, w.transactionType))
      .sumBy('amount')
      .value()
  }

  function getTotalAmount() {
    return _.sumBy(withdrawals, 'amount')
  }

  function getTotalSelected() {
    return _.sumBy(selectedWithdrawalList, 'amount')
  }

  return (
    <div className='flex justify-between text-right'>
      <div className='flex-1 flex'>
        {displayComponent({
          title: 'Total Selected',
          amount: getTotalSelected(),
        })}
        <Divider variant={'middle'} orientation={'vertical'} />

        {[
          {
            title: 'Selected Amount',
            amount: getSelectedAmount(),
          },
          {
            title: 'Selected Incentive',
            amount: getSelectedIncentive(),
          },
          {
            title: 'Selected Charges',
            amount: getSelectedCharges(),
          },
        ].map(v =>
          displayComponent({
            title: v.title,
            amount: v.amount,
          })
        )}
      </div>

      <div className='flex'>
        {[
          {
            title: 'Total Claimable',
            amount: getClaimableAmount(),
          },
          {
            title: 'Total Charges',
            amount: getDeductAmount(),
          },
          {
            title: 'Total Amount'.toUpperCase(),
            amount: getTotalAmount(),
          },
        ].map(v =>
          displayComponent({
            title: v.title,
            amount: v.amount,
          })
        )}
      </div>
    </div>
  )
}

export default SubmitClaimTotalPriceComponent

const displayComponent = ({
  title,
  amount,
}: {
  title: string
  amount: number
}) => {
  return (
    <div key={title} className='m-2 p-2'>
      <div className='text-sm text-gray-500'>{title}</div>
      <div className='text-lg font-semibold'>RM {amount.toFixed(2)}</div>
    </div>
  )
}
