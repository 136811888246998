import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
} from '@material-ui/core'
import {
  defaultOrderSummaryBean,
  OrderSummaryBean,
} from '../bean/OrderSummaryBean'
import { useTypedSelector } from '../../../redux/reducer'
import { OrderSummaryContent } from '../bean/OrderSummaryContent'
import _ from 'lodash'
import CommonUtils from '../../../common/commonUtils'
import { AlertDialogAction } from '../../../redux/reducer/alertDialogReducer'
import { useDispatch } from 'react-redux'
import { demoOrder } from '../../../sharePortal/orderManagement/bean/orderBean'

interface DialogProps {
  open: boolean
  value: string | undefined
  name: string | undefined
  onClose: (orderSummaryBean?: OrderSummaryBean) => void
}
const OrderSummaryMessageDialog = (props: DialogProps) => {
  const dispatch = useDispatch()
  const settings: Record<string, OrderSummaryBean> = useTypedSelector(
    state => state.orderSummaryMessage.settings
  )

  const selectedSettings: OrderSummaryBean = useMemo(() => {
    if (props.value) {
      return settings[props.value] || defaultOrderSummaryBean
    } else {
      return defaultOrderSummaryBean
    }
  }, [props.value, settings])

  const genericOrderSummaryBean: OrderSummaryBean = useTypedSelector(
    state => state.portalSetting.notification.orderSummary
  )

  const [title, setTitle] = useState('')
  const [footer, setFooter] = useState('')
  const [content, setContent] = useState<OrderSummaryContent | undefined>()
  useEffect(() => {
    setTitle(selectedSettings.title)
    setFooter(selectedSettings.footer)
    setContent(selectedSettings.content)
  }, [selectedSettings])

  function handleResetClick() {
    setTitle(selectedSettings.title)
    setFooter(selectedSettings.footer)
    setContent(selectedSettings.content)
  }

  function handleSubmitClick() {
    if (content) {
      const orderSummaryBean: OrderSummaryBean = {
        footer: footer,
        title: title,
        content: content,
      }
      props.onClose(orderSummaryBean)
    }
  }

  function handlePreviewClick() {
    if (content) {
      const patchBody: OrderSummaryBean = {
        title: title,
        footer: footer,
        content: {
          product: content['product'],
          productCategory: content['productCategory'],
          productPackage: content['productPackage'],
          price: content['price'],
          companyName: content['companyName'],
          companyRegNo: content['companyRegNo'],
          address: content['address'],
          fullName: content['fullName'],
          nric: content['nric'],
          email: content['email'],
          mobileNo: content['mobileNo'],
          telNo: content['telNo'],
          alternativeNo: content['alternativeNo'],
          orderStatus: content['orderStatus'],
          orderNo: content['orderNo'],
          loginId: content['loginId'],
          eform: content['eform'],
          activatedOn: content['activatedOn'],
          invoiceNo: content['invoiceNo'],
          remarks: content['remarks'],
          adminRemarks: content['adminRemarks'],
          m2uRemarks: content['m2uRemarks'],
        },
      }

      const summary = CommonUtils.getSummary(demoOrder, patchBody)

      dispatch(
        AlertDialogAction.open(
          'Preview',
          <>
            <TextField
              variant='outlined'
              value={summary}
              disabled
              multiline={true}
              fullWidth={true}
            />
          </>
        )
      )
    }
  }

  function handleUserGeneric() {
    setTitle(genericOrderSummaryBean.title)
    setFooter(genericOrderSummaryBean.footer)
    setContent(genericOrderSummaryBean.content)
  }

  return (
    <Dialog
      open={props.open}
      maxWidth={'xl'}
      onClose={() => props.onClose(undefined)}
    >
      <DialogTitle>{props.name || ''}</DialogTitle>
      <DialogTitle>
        {content && (
          <div className='grid gap-4 grid-cols-4'>
            <TextField
              className='col-span-4'
              multiline
              fullWidth
              label={'Title'}
              value={title}
              rows={2}
              onChange={e => {
                setTitle(e.target.value)
              }}
            />
            <TextField
              className='col-span-4'
              multiline
              fullWidth
              label={'Footer'}
              value={footer}
              rows={2}
              onChange={e => {
                setFooter(e.target.value)
              }}
            />

            {content &&
              _.keys(content).map(key => {
                const value = content[key]

                return (
                  <div key={key}>
                    <Checkbox
                      checked={value?.enabled || false}
                      onChange={(e, checked) => {
                        setContent({
                          ...content,
                          [key]: {
                            ...content[key],
                            enabled: checked,
                          },
                        })
                      }}
                    />
                    <TextField
                      label={key}
                      name={key}
                      value={value?.displayText || ''}
                      disabled={!value?.enabled}
                      onChange={e => {
                        setContent({
                          ...content,
                          [key]: {
                            ...content[key],
                            displayText: e.target.value,
                          },
                        })
                      }}
                    />
                  </div>
                )
              })}
          </div>
        )}
      </DialogTitle>

      <DialogActions>
        <Button
          variant={'outlined'}
          onClick={() => {
            handleUserGeneric()
          }}
        >
          USE GENERIC
        </Button>
        <Button
          variant={'outlined'}
          onClick={() => {
            handlePreviewClick()
          }}
        >
          PREVIEW
        </Button>
        <Button
          variant={'outlined'}
          color={'secondary'}
          onClick={() => {
            handleResetClick()
          }}
        >
          RESET
        </Button>
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={() => {
            handleSubmitClick()
          }}
        >
          UPDATE
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default OrderSummaryMessageDialog
