import React, { useEffect, useState } from 'react'
import {
  AppBar,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import UserBean from '../../sharePortal/userManagement/bean/userBean'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../redux/reducer'
import { UserActions, UserReducer } from '../../redux/reducer/userReducer'
import { useHistory } from 'react-router'
import { UserCodeBean } from '../../common/service/UserCodeBean'
import CommonUtils from '../../common/commonUtils'

const AgentManagementPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const userReducer: UserReducer = useTypedSelector(state => state.user)
  const [filterString, setFilterString] = useState('')

  const userCode: UserCodeBean = useTypedSelector(
    state => state.portalSetting.userCode
  )
  useEffect(() => {
    dispatch(UserActions.getAgents())
  }, [dispatch])

  function getDisplayAgents(): UserBean[] {
    return userReducer.agents.filter(agent => {
      return [
        CommonUtils.getUserCode(agent, userCode),
        agent.username,
        agent.fullName,
        agent.email,
        agent.companyName,
        agent.companyRegNo,
        agent.mobileNo,
        agent.telNo,
      ].some(value =>
        (value || '').toLowerCase().includes(filterString.toLowerCase())
      )
    })
  }

  return (
    <div>
      <AppBar position='relative'>
        <Toolbar className='space-x-3'>
          <Typography variant='h6'>Agent Management</Typography>
        </Toolbar>
      </AppBar>

      <div className='m-8'>
        <TableContainer component={Paper}>
          <div className='m-4'>
            <TextField
              name='filterString'
              value={filterString}
              onChange={e => {
                setFilterString(e.target.value)
              }}
              fullWidth
              placeholder='Type something to filter...'
            />
          </div>
          <Table>
            <TableHead>
              <TableRow>
                {[
                  'ID',
                  'Username',
                  'Full Name',
                  'Company',
                  'Mobile No',
                  'Email',
                ].map(text => (
                  <TableCell key={text}>
                    <span className='font-bold text-gray-700'>{text}</span>
                  </TableCell>
                ))}

                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {getDisplayAgents().map(agent => {
                return (
                  <TableRow
                    key={agent.id}
                    className='hover:bg-gray-200 cursor-pointer'
                    onClick={() => {
                      history.push('/admin-portal/agent-detail/' + agent.id)
                    }}
                  >
                    <TableCell>
                      {CommonUtils.getUserCode(agent, userCode)}
                    </TableCell>
                    <TableCell>{agent.username}</TableCell>
                    <TableCell>{agent.fullName}</TableCell>
                    <TableCell>
                      {(function (user: UserBean) {
                        return user.companyName && user.companyRegNo
                          ? `${user.companyName} (${user.companyRegNo})`
                          : ''
                      })(agent)}
                    </TableCell>
                    <TableCell>{agent.mobileNo}</TableCell>
                    <TableCell>{agent.email}</TableCell>
                    <TableCell align='right'>
                      <div className=' text-white'>
                        {agent.enabled ? (
                          <span className='p-1 rounded bg-green-300'>
                            ENABLE
                          </span>
                        ) : (
                          <span className='p-1 rounded bg-red-300'>
                            DISABLE
                          </span>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}

export default AgentManagementPage
