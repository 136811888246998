import React, { useEffect, useState } from 'react'
import AgentService from '../../adminPortal/userManagement/service/agentService'
import _ from 'lodash'
import UserBean from '../../sharePortal/userManagement/bean/userBean'
import { Alert, Skeleton, TreeView } from '@material-ui/lab'
import SubAgentItemComponent from './subAgentItem.component'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { ChevronRight } from '@material-ui/icons'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

interface SubAgentTreeComponentProps {
  user: UserBean | undefined
}

const SubAgentTreeComponent = ({ user }: SubAgentTreeComponentProps) => {
  const [agents, setAgents] = useState<Record<string, UserBean[]> | undefined>()

  const [expanded, setExpanded] = React.useState<string[]>([])
  const [selected, setSelected] = React.useState<string[]>([])

  const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setExpanded(nodeIds)
  }

  const handleSelect = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setSelected(nodeIds)
  }

  useEffect(() => {
    if (user?.id)
      AgentService.getSubAgents(user.id)
        .then(value => value.data)
        .then(value => {
          const dictionary = _.groupBy(value, 'superiorId')
          setAgents(dictionary)

          const map = [user.id.toString()].concat(
            value.map(o => o.id.toString())
          )
          setExpanded(map)
        })
  }, [user])

  return user && !agents ? (
    <>
      <Skeleton variant={'text'} animation={'wave'} />
      <Skeleton variant={'text'} animation={'wave'} />
      <Skeleton variant={'text'} animation={'wave'} />
    </>
  ) : (
    <>
      <Alert severity='info'>
        <div>
          Click <ExpandMoreIcon /> to expand or collapse.
        </div>
        <div>Click name go to agent detail.</div>
      </Alert>
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRight />}
        defaultEndIcon={<CloseSquare />}
        expanded={expanded}
        selected={selected}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
      >
        <SubAgentItemComponent
          user={user!!}
          agents={agents || {}}
          position=''
        />
      </TreeView>
    </>
  )
}

export default SubAgentTreeComponent

function CloseSquare(props: SvgIconProps) {
  return (
    <SvgIcon
      className='close'
      fontSize='inherit'
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d='M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z' />
    </SvgIcon>
  )
}
