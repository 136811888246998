import { OrderBean } from '../../../../../sharePortal/orderManagement/bean/orderBean'
import DateUtils from '../../../../../common/dateUtils'
import CommonUtils from '../../../../../common/commonUtils'
import { formatDistanceToNow } from 'date-fns'

export class OrderDisplayModel {
  private id: string
  private product: string
  private productCategory: string
  private productPackage: string
  private productDescription?: string
  private price: string
  private companyName: string
  private companyRegNo: string
  private postcode: string
  private state: string
  private fullName: string
  private nric: string
  private email: string
  private mobileNo: string
  private telNo: string
  private orderStatus: string
  private remarks: string
  private orderNo: string
  private loginId: string
  private eform: string
  private documentCompleted: string
  private activatedOn: string
  private createdDate: string
  private createdBy: string
  private modifiedDate: string
  private modifiedBy: string
  private invoiceNo: string
  private clawbackDate: string
  private fullAddress: string
  private unitNo: string
  private streetType: string
  private streetName: string
  private section: string
  private floorNo: string
  private buildingName: string
  private city: string
  private alternativeNo: string
  private adminRemarks: string
  private m2uRemarks: string
  private paymentClaimDate: string
  private processingId: string
  private accountNo: string

  private submittedDate: string
  private statusChangeReason: string
  private sporaReferenceNumber: string
  private ociTicketNumber: string
  private sporaProcessingId: string
  private appointmentDate: string
  private appointmentTime: string

  private agentUsername: string
  private agentName: string
  private agentCompany: string
  private agentId: string
  private agentNric: string
  private agentBankName: string
  private agentBankAccNo: string
  private agentEmail: string
  private agentMobileNo: string

  private lastEditedDate: string

  constructor(order: OrderBean, agentCode: string = '') {
    this.id = order.id.toString()
    this.product = order.product.toString()
    this.productCategory = order.productCategory.toString()
    this.productPackage = order.productPackage.toString()
    this.productDescription = order.productDescription
    this.price = order.price.toFixed(2)
    this.companyName = order.companyName.toString()
    this.companyRegNo = order.companyRegNo.toString()
    this.postcode = order.postcode.toString()
    this.state = order.state.toString()
    this.fullName = order.fullName.toString()
    this.nric = order.nric.toString()
    this.email = order.email.toString()
    this.mobileNo = order.mobileNo.toString()
    this.telNo = order.telNo?.toString() || ''
    this.orderStatus = order.orderStatus.toString()
    this.remarks = order.remarks?.toString() || ''
    this.orderNo = order.orderNo?.toString() || ''
    this.loginId = order.loginId?.toString() || ''
    this.eform = order.eform?.toString() || ''
    this.documentCompleted = order.documentCompleted ? 'YES' : 'NO'
    this.activatedOn = DateUtils.toDateString(order.activatedOn)
    this.createdDate = DateUtils.toDateString(order.createdDate)
    this.createdBy = order.createdBy.toString()
    this.modifiedDate = order.modifiedDate.toString()
    this.modifiedBy = order.modifiedBy.toString()
    this.invoiceNo = order.invoiceNo?.toString() || ''
    this.clawbackDate = DateUtils.toDateString(order.clawbackDate)
    this.unitNo = order.unitNo.toString()
    this.streetType = order.streetType.toString()
    this.streetName = order.streetName.toString()
    this.section = order.section.toString()
    this.floorNo = order.floorNo.toString()
    this.buildingName = order.buildingName.toString()
    this.city = order.city.toString()
    this.alternativeNo = order.alternativeNo?.toString() || ''
    this.adminRemarks = order.adminRemarks?.toString() || ''
    this.m2uRemarks = order.m2uRemarks?.toString() || ''
    this.paymentClaimDate = DateUtils.toDateString(order.paymentClaimDate)
    this.processingId = order.processingId?.toString() || ''
    this.accountNo = order.accountNo?.toString() || ''

    const refUser = order.refUser
    this.agentUsername = refUser.username
    this.agentName = refUser.fullName
    this.agentCompany =
      refUser.companyName && refUser.companyRegNo
        ? refUser.companyName + `(${refUser.companyRegNo})`
        : ''
    this.agentNric = refUser.nric
    this.agentBankName = refUser.bankName
    this.agentBankAccNo = refUser.bankAccNo
    this.agentEmail = refUser.email
    this.agentMobileNo = refUser.mobileNo
    this.agentId = agentCode

    this.submittedDate = DateUtils.toDateString(order.submittedDate)
    this.statusChangeReason = order.statusChangeReason || ''
    this.sporaReferenceNumber = order.sporaReferenceNumber || ''
    this.ociTicketNumber = order.ociTicketNumber || ''
    this.sporaProcessingId = order.sporaProcessingId || ''
    this.appointmentDate = DateUtils.appointmentDateFormat(
      order.appointmentDate
    )
    this.appointmentTime = order.appointmentTime || ''

    this.fullAddress = CommonUtils.getJoinedNewFormatAddress(order)

    this.lastEditedDate =
      formatDistanceToNow(new Date(order.modifiedDate)) + ' ago'
  }
}
