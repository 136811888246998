import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { OrderBean } from '../../sharePortal/orderManagement/bean/orderBean'
import OrderService from '../../agentPortal/orderManagement/orderService'
import {
  AppBar,
  Card,
  CardContent,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@material-ui/core'
import OrderStatus from '../../common/constant/orderStatus'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import CachedIcon from '@material-ui/icons/Cached'
import PersonIcon from '@material-ui/icons/Person'
import { ClaimBean } from '../../features/claim/bean/claimBean'
import ClaimService from '../../features/claim/service/claimService'
import ClaimStatus from '../../common/constant/claimStatus'
import EmptyClaimReferenceNoComponent from './component/emptyClaimReferenceNoComponent'
import { catchErrorWithDispatch } from '../../common/ApiUtils'
import { TabContext, TabPanel } from '@material-ui/lab'
import OrderNoTabComponent from './component/orderNoTabComponent'
import EformTabComponent from './component/eformTabComponent'
import AgentService from '../userManagement/service/agentService'

const PageTabs = {
  orderNo: {
    key: 'orderNo',
    display: 'Order No',
  },

  eForm: {
    key: 'eForm',
    display: 'E-Form',
  },

  referenceNo: {
    key: 'referenceNo',
    display: 'Reference No',
  },
}
const AdminDashboard = () => {
  const dispatch = useDispatch()
  const [tabIndex, setTabIndex] = useState(PageTabs.orderNo.key)
  const [orders, setOrders] = useState<OrderBean[]>([])
  const [agentCount, setAgentCount] = useState()
  const [claims, setClaims] = useState<ClaimBean[]>([])

  useEffect(() => {
    OrderService.findAll()
      .then(value => {
        const pendingOrders = value.data.filter(order =>
          [
            OrderStatus.KIV,
            OrderStatus.POST_COMPLETE,
            OrderStatus.CANCEL,
          ].every(status => status !== order.orderStatus)
        )

        setOrders(pendingOrders)
      })
      .catch(catchErrorWithDispatch(dispatch))
  }, [dispatch])

  useEffect(() => {
    ClaimService.getAll({
      isPage: false,
      claimStatus: [ClaimStatus.PENDING, ClaimStatus.PROCESSING],
    })
      .then(value => value.data.content)
      .then(setClaims)
      .catch(catchErrorWithDispatch(dispatch))
  }, [dispatch])

  useEffect(() => {
    AgentService.getUnverifiedAgentsCount().then(result =>
      setAgentCount(result.data)
    )
  }, [])

  const getSubmissionStatus = useMemo(() => {
    const unverifiedAgents = {
      value: agentCount,
      description: 'Total Unverified Agents',
      title: 'New Agents',
      icon: (
        <div className='bg-yellow-400 p-4'>
          <PersonIcon />
        </div>
      ),
    }

    const submissions = {
      value: orders.filter(
        value => value.orderStatus === OrderStatus.AGENT_SUBMITTED
      ).length,
      description: 'Total Pending to Process Orders',
      title: 'Agent Submitted',
      icon: (
        <div className='bg-orange-400 p-4'>
          <NoteAddIcon />
        </div>
      ),
    }

    const inProgress = {
      value: orders.filter(value =>
        [
          OrderStatus.PENDING,
          OrderStatus.WAITING_LIST,
          OrderStatus.EARLY_STAGE,
          OrderStatus.NEW_AREA,
        ].includes(value.orderStatus)
      ).length,
      description: 'Total In Progress Orders',
      title: 'In Progress',
      icon: (
        <div className='bg-teal-400 p-4'>
          <CachedIcon />
        </div>
      ),
    }

    const completed = {
      value: claims.filter(value => value.claimStatus === ClaimStatus.PENDING)
        .length,
      description: 'Total Pending Claims',
      title: 'New Claims',
      icon: (
        <div className='bg-green-400 p-4'>
          <NoteAddIcon />
        </div>
      ),
    }

    const rejected = {
      value: claims.filter(
        value => value.claimStatus === ClaimStatus.PROCESSING
      ).length,
      description: 'Total Processing Claims',
      title: 'Processing Claims',
      icon: (
        <div className='bg-indigo-400 p-4'>
          <CachedIcon />
        </div>
      ),
    }

    return [unverifiedAgents, submissions, inProgress, completed, rejected]
  }, [agentCount, claims, orders])
  return (
    <TabContext value={tabIndex}>
      <AppBar position='relative'>
        <Toolbar className='space-x-3'>
          <Typography variant='h6'>Dashboard</Typography>
        </Toolbar>

        <Tabs
          value={tabIndex}
          onChange={(event, value) => {
            setTabIndex(value)
          }}
        >
          <Tab label={PageTabs.orderNo.display} value={PageTabs.orderNo.key} />
          <Tab label={PageTabs.eForm.display} value={PageTabs.eForm.key} />
          <Tab
            label={PageTabs.referenceNo.display}
            value={PageTabs.referenceNo.key}
          />
        </Tabs>
      </AppBar>

      <div className='grid grid-cols-5 gap-3 m-4'>
        {getSubmissionStatus.map(status => {
          return (
            <Card className='relative' key={status.title}>
              <div className='absolute text-white rounded-br'>
                {status.icon}
              </div>

              <CardContent>
                <div className='text-right text-gray-600'>{status.title}</div>
                <div className='text-right text-2xl font-bold'>
                  {status.value}
                </div>
                <hr />
                <div>{status.description}</div>
              </CardContent>
            </Card>
          )
        })}
      </div>

      <TabPanel value={PageTabs.orderNo.key}>
        <OrderNoTabComponent />
      </TabPanel>
      <TabPanel value={PageTabs.eForm.key}>
        <EformTabComponent />
      </TabPanel>
      <TabPanel value={PageTabs.referenceNo.key}>
        <EmptyClaimReferenceNoComponent />
      </TabPanel>
    </TabContext>
  )
}

export default AdminDashboard
