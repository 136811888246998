import client, { AxiosResponse } from 'axios'
import { Pageable } from '../../common/bean/Pageable'
import { AuditBean } from './bean/AuditBean'

const url = `/api/v1/audits`
export default class AuditService {
  static getAuditsPageable = function (
    page: number = 0,
    size: number = 20
  ): Promise<AxiosResponse<Pageable<AuditBean>>> {
    return client.get(url, {
      params: {
        sort: 'id,desc',
        page: page,
        size: size,
      },
    })
  }

  static getOrderAuditByOrderId = function (
    orderId: number,
    page: number = 0,
    size: number = 20
  ): Promise<AxiosResponse<Pageable<AuditBean>>> {
    return client.get(url + '/ORDER/' + orderId, {
      params: {
        sort: 'id,desc',
        page: page,
        size: size,
      },
    })
  }
}
