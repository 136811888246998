import React, { useEffect, useState } from 'react'
import UserBean from '../../../sharePortal/userManagement/bean/userBean'
import AgentService from '../../../adminPortal/userManagement/service/agentService'
import { catchErrorWithDispatch } from '../../../common/ApiUtils'
import { useDispatch } from 'react-redux'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import CommonUtils from '../../../common/commonUtils'
import userProfileService from '../../../common/service/userProfileService'
import { UserCodeBean } from '../../../common/service/UserCodeBean'
import { useTypedSelector } from '../../../redux/reducer'

interface SelectAgentTextComponentProp {
  agentId: string
  setAgentId: (agentId: string) => void
  disabled: boolean
  params: {
    excludeDisable: boolean
    excludeSelf: boolean
  }
}
const SelectAgentTextComponent = (props: SelectAgentTextComponentProp) => {
  const { agentId, setAgentId, disabled, params } = props
  const { excludeSelf, excludeDisable } = params
  const dispatch = useDispatch()

  const userCode: UserCodeBean = useTypedSelector(
    state => state.portalSetting.userCode
  )

  const [agents, setAgents] = useState<UserBean[]>([])

  useEffect(() => {
    AgentService.getAllAgents({ excludeDisable, excludeSelf })
      .then(value => setAgents(value.data))
      .catch(catchErrorWithDispatch(dispatch))
  }, [dispatch, excludeDisable, excludeSelf])

  return (
    <Autocomplete
      disabled={disabled}
      renderInput={params => (
        <TextField
          {...params}
          label='Agent'
          inputProps={{ ...params.inputProps }}
          required
        />
      )}
      getOptionLabel={option => {
        const prefix = CommonUtils.getUserCode(option, userCode)
        const { fullName, username, id } = option

        const displayName =
          option.companyName && option.companyRegNo
            ? `${option.companyName} (${option.companyRegNo})`
            : `${fullName} (${username})`

        const suffix =
          userProfileService.getUserProfile()?.id === id ? '[YOURSELF]' : ''

        return `[${prefix}] ${displayName} ${suffix}`
      }}
      options={agents}
      value={agents.find(value => value.id.toString() === agentId) || null}
      onChange={(event, value) => {
        if (value) {
          setAgentId(String(value.id))
        } else {
          setAgentId('')
        }
      }}
    />
  )
}

export default SelectAgentTextComponent
