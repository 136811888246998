import React from 'react'
import { AuditBean } from './bean/AuditBean'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import DateUtils from '../../common/dateUtils'
import InfoIcon from '@material-ui/icons/InfoOutlined'

interface AuditListComponentProps {
  audits: AuditBean[]
  setSelectedAudit: (audit: AuditBean) => void
}

const AuditListComponent = (props: AuditListComponentProps) => {
  const { audits, setSelectedAudit } = props
  return (
    <div>
      {audits.map(audit => {
        const {
          affectedTable,
          createdBy,
          createdDate,
          description,
          details,
          action,
        } = audit
        let displayText = `${audit.id}) ${createdBy} - ${action} a ${affectedTable}`
        return (
          <ListItem
            key={audit.id}
            button
            onClick={() => {
              if (!!details.length || !!description) {
                setSelectedAudit(audit)
              }
            }}
          >
            <ListItemText
              primary={displayText}
              secondary={DateUtils.toLocaleString(createdDate.toString())}
            />
            <ListItemIcon>
              {(!!details.length || !!description) && <InfoIcon />}
            </ListItemIcon>
          </ListItem>
        )
      })}
    </div>
  )
}

export default AuditListComponent
