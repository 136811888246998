import React from 'react'
import { CommissionQuota } from '../../adminPortal/userManagement/bean/commissionQuota'
import CommissionType from '../../common/constant/commissionType'

interface CommissionItemComponentProps {
  commission: CommissionQuota
  onClick?: (commission: CommissionQuota) => void
}

function getDisplayTextByCommissionType(
  input: any,
  commissionType: CommissionType
): string {
  return commissionType === CommissionType.AMOUNT ? `RM${input}` : `${input}%`
}

const CommissionItemComponent = (props: CommissionItemComponentProps) => {
  const { commission } = props

  const isUnset = commission.percentage <= 0
  const clsName = isUnset ? 'text-red-400' : ''

  const currentAmountToAvailableQuota = getDisplayTextByCommissionType(
    commission.percentage,
    commission.commissionType
  )

  return (
    <>
      <div
        key={
          commission.id +
          commission.packageName +
          commission.categoryName +
          commission.packageId +
          commission.categoryId
        }
        className='p-2 hover:bg-gray-200 cursor-pointer'
        onClick={() => {
          if (props.onClick) {
            props.onClick(commission)
          }
        }}
      >
        <div className={'text-md font-bold ' + clsName}>
          {commission.packageName}
        </div>
        <div>{currentAmountToAvailableQuota}</div>
      </div>
    </>
  )
}

export default CommissionItemComponent
