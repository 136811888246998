import client, { AxiosResponse } from 'axios'

const url = `/api/v1/templates`

function fetchTemplates(): Promise<AxiosResponse<ITemplate[]>> {
  return client.get(url)
}

function createPublicTemplate(body: ITemplateCreateBody) {
  return client.post(url + '/public', body)
}

function createPrivateTemplate(body: ITemplateCreateBody) {
  return client.post(url + '/private', body)
}

function replaceTemplate(body: ITemplateReplaceBody) {
  return client.put(url + '/' + body.id, body)
}

function deleteTemplate(id: number) {
  return client.delete(url + '/' + id)
}

export const TemplateService = {
  fetchTemplates,
  replaceTemplate,
  deleteTemplate,
  createPublicTemplate,
  createPrivateTemplate,
}

export interface ITemplate {
  id: number
  userId: number
  title: string
  content: string
  category: string
  type: string
  createdBy: string
  createdDate: string
  modifiedBy: string
  modifiedDate: string
}

export interface ITemplateCreateBody {
  title: string
  content: string
  category: string
}

export interface ITemplateReplaceBody {
  id: number
  title: string
  content: string
  category: string
}
