import React, { useMemo, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from '@material-ui/core'
import DialogContentText from '@material-ui/core/DialogContentText'
import { OrderBean } from '../bean/orderBean'

interface OrderClawbackDialogProps {
  open: boolean
  orderBean?: OrderBean
  onClose: (resp?: { remark: string; remarkDate: Date }) => void
}

const OrderClawbackDialog = (props: OrderClawbackDialogProps) => {
  const { open, orderBean, onClose } = props

  const [remark, setRemark] = useState('')

  function handleClose() {
    onClose()
  }

  function handleOkClick() {
    if (orderBean) {
      onClose({ remark, remarkDate: new Date() })
      setRemark('')
    }
  }

  const displayName = useMemo(() => {
    if (!orderBean) {
      return ''
    }
    const { username, companyName, companyRegNo, fullName } = orderBean?.refUser

    const name = `${fullName} (${username})`
    const com =
      companyName && companyRegNo ? `${companyName} (${companyRegNo})` : ``

    return [name, com].filter(v => !!v).join(' | ')
  }, [orderBean])

  return (
    <Dialog open={open} maxWidth={'xs'} fullWidth={true}>
      <DialogTitle>
        Clawback Order: <span className='font-bold'>{orderBean?.id}</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div>Are you sure to Clawback this order?</div>
          <div>
            {orderBean?.fullName} ({orderBean?.nric})
          </div>
          <div>by {displayName}</div>

          <div className='my-2'>
            <Divider />
          </div>

          <TextField
            fullWidth
            rows={2}
            multiline
            placeholder='Write Remark Here'
            value={remark}
            onChange={event => setRemark(event.target.value)}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant={'text'} color='secondary'>
          CANCEL
        </Button>
        <Button
          onClick={handleOkClick}
          variant={'contained'}
          color='primary'
          disabled={!orderBean}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default OrderClawbackDialog
