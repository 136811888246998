import React from 'react'
import { useHistory } from 'react-router-dom'
import { IconButton } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

function AppBackButton() {
  const history = useHistory()

  return (
    <IconButton color='inherit' onClick={history.goBack}>
      <ArrowBackIcon />
    </IconButton>
  )
}

export default AppBackButton
